import React, { useMemo } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { LookupsCell } from "@wagerlab/admin/src/components/lookups/shared/add/LookupsCell";
import { NameCell } from "@wagerlab/admin/src/components/lookups/shared/add/NameCell";
import { RowNotice } from "@wagerlab/admin/src/components/lookups/shared/add/RowNotice";
import { ActionsCell } from "@wagerlab/admin/src/components/lookups/shared/add/ActionsCell";

export const UnknownsTable = ({ unknownEntities, searchFilter, handleAdd, handleOpenModal, getters }) => {
  const unknownEntityEntries = useMemo(() => {
    return Object.entries(unknownEntities || {})
      .filter(([lookupKey, unknownEntity]) => searchFilter(lookupKey))
      .sort(([lookupKeyA, unknownEntityA], [lookupKeyB, unknownEntityB]) => (getters.getSortName(unknownEntityA) || "").localeCompare(getters.getSortName(unknownEntityB)));
  }, [unknownEntities, searchFilter]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell sx={{ fontSize: 22 }}>Unknown {getters.getEntityTypeDisplayName(true)} Name</TableCell>
          <TableCell sx={{ fontSize: 22 }}>Orig. Lookups</TableCell>
          <TableCell sx={{ fontSize: 22 }}>Search Lookups</TableCell>
          <TableCell sx={{ fontSize: 22 }}>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {unknownEntityEntries.map(([lookupKey, unknownEntity]) => {
          return (
            <React.Fragment key={lookupKey}>
              <TableRow
                sx={{
                  "cursor": "pointer",
                  "& td": {
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    borderBottom: "none",
                  },
                }}
                onClick={() => handleAdd(lookupKey)}
              >
                <NameCell
                  sourceID={getters.getSourceID(unknownEntity)}
                  sourceEntityID={getters.getSourceEntityID(unknownEntity)}
                  name={getters.getName(unknownEntity)}
                  unknownEntity={unknownEntity}
                  handleOpenModal={handleOpenModal}
                  lookupKey={lookupKey}
                />
                <LookupsCell lookupsList={getters.getEntityNameLookupsOrig(unknownEntity)} />
                <LookupsCell lookupsList={getters.getEntityNameLookups(unknownEntity)} />
                <ActionsCell name={getters.getName(unknownEntity)} />
              </TableRow>
              <RowNotice unknownEntity={unknownEntity} getters={getters} />
            </React.Fragment>
          );
        })}
      </TableBody>
    </Table>
  );
};
