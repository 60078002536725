import React from "react";
import { Grid, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { DateTimePicker } from "@wagerlab/admin/src/components/inputs/DateTimePicker";
import { SelectMenu } from "@wagerlab/admin/src/components/inputs/SelectMenu";
import { SelectBoolean } from "@wagerlab/admin/src/components/inputs/SelectBoolean";

const ODDS_CLOSE_TYPE_OPTIONS = {
  start: "Automatically at event start time",
  date: "Automatically at specific time",
  manual: "When manually closed",
};

export const EventFormStatusTiming = ({ isNew }) => {
  const { values } = useFormikContext();

  const showOddsDatePicker = values.oddsCloseType === "date";

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">Schedule/Timing</Typography>
      </Grid>

      <Grid item xs={12} sm={6}>
        <DateTimePicker name="startsAt" label="Starts Time" required />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectBoolean name="hardStart" label="Hard Start" />
      </Grid>

      <Grid item xs={12} sm={6}>
        <SelectMenu name="oddsCloseType" label="Close Odds At" options={ODDS_CLOSE_TYPE_OPTIONS} />
      </Grid>
      <Grid item xs={12} sm={6}>
        {showOddsDatePicker ? <DateTimePicker name="oddsCloseAtDate" label="Odds Close Date" required /> : null}
      </Grid>
    </>
  );
};
