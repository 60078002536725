export const getDataUpdatedColor = (dataUpdatedAt) => {
  const now = new Date();
  const updatedAt = new Date(dataUpdatedAt);
  const diff = now - updatedAt;

  // 3 hours - green
  // 3 days - orange
  // 3 days+ - red
  if (diff < 1000 * 60 * 60 * 3) {
    return "green";
  } else if (diff < 1000 * 60 * 60 * 24 * 3) {
    return "orange";
  } else {
    return "red";
  }
};
