const { isNumber } = require("@wagerlab/utils/data/types");
const { formatDate, formatTime, selectVariant } = require("@wagerlab/utils/displays/displayUtils");
const { LEAGUE_CONFIG } = require("@wagerlab/utils/sports/leagueConfig");
const { SPORT_CONFIG } = require("@wagerlab/utils/sports/sportConfig");
const _ = require("lodash");

class EventDisplays {
  constructor(eventData) {
    this.event = eventData;
    this.sportConfig = SPORT_CONFIG[eventData?.sportID];
    this.leagueConfig = LEAGUE_CONFIG[eventData?.leagueID];
  }

  getTeamDisplay(side, length) {
    const { short, medium, long } = this.event?.teams?.[side]?.names || {};
    return selectVariant(length, { long, medium, short });
  }

  getMatchTitle(length) {
    const awayTeam = this.getTeamDisplay("away", length);
    const homeTeamWithContext = this.getOpponentContext("away", length);
    if (!awayTeam || !homeTeamWithContext) return "";
    return `${awayTeam} ${homeTeamWithContext}`;
  }

  getMatchTitleList(length) {
    const awayTeam = this.getTeamDisplay("away", length);
    const homeTeamWithContext = this.getOpponentContext("away", length);
    if (!awayTeam || !homeTeamWithContext) return [];
    return [awayTeam, homeTeamWithContext];
  }

  getPropTitle(length) {
    if (this.event?.type !== "prop") return "";
    const prop = this.event?.props?.prop0 || {};
    return prop?.propTitle || "";
  }

  getPropTitleList(length) {
    const propTitle = this.getPropTitle(length);
    return propTitle ? [propTitle] : [];
  }

  getOverriddenTitle(length) {
    const { long, medium, short } = this.event?.names || {};
    const overriddenTitle = selectVariant(length, { long, medium, short, default: this.event?.eventName });
    return overriddenTitle || "";
  }

  getTitle(length) {
    const overriddenTitle = this.getOverriddenTitle(length);
    if (overriddenTitle) return overriddenTitle;

    if (this.event?.type === "prop") return this.getPropTitle(length);
    return this.getMatchTitle(length);
  }

  getTitleList(length) {
    const overriddenTitle = this.getOverriddenTitle(length);
    if (overriddenTitle) return [overriddenTitle];

    if (this.event?.type === "prop") return this.getPropTitleList(length);

    return this.getMatchTitleList(length);
  }

  // If upcoming -> startTime =  Wed. 7/21 @ 5:30PM
  // If live match -> clock/Live (display) = 4:33 (2nd)
  // If ended/delayed -> display = Final
  getClockDateStatus(length, timezone = "") {
    const eventType = this.event?.type;
    const { displayShort, displayLong, clock, started, ended, live, currentPeriodID, cancelled, completed, delayed } = this.event?.status || {};
    if (!started && !ended) return this.getStartTime(length, timezone, false) || "";

    const defaultStatusLong = (cancelled && "Cancelled") || (completed && "Final") || (delayed && "Delayed") || (live && "Live") || "";
    const defaultStatusShort = (cancelled && "CANC") || (completed && "F") || (delayed && "DLY") || "";

    if (eventType === "match" && live) {
      if (length === "short") return displayShort || defaultStatusShort;
      const prefix = clock || (currentPeriodID && "Live") || "";
      if (length === "long") {
        return prefix && displayLong ? `${prefix} (${displayLong})` : displayLong || defaultStatusLong;
      }
      return prefix && displayShort ? `${prefix} (${displayShort})` : displayLong || defaultStatusLong;
    }

    if (length === "short") return displayShort || defaultStatusShort;
    return displayLong || defaultStatusLong;
  }

  getOpponentContext(sideID, length) {
    if (sideID !== "home" && sideID !== "away") return "";
    const opponentSide = sideID === "home" ? "away" : "home";
    const opponentTeamDisplay = this.getTeamDisplay(opponentSide, length);
    if (!opponentTeamDisplay) return "";
    if (opponentSide === "home" && this.sportConfig?.hasMeaningfulHomeAway) return `at ${opponentTeamDisplay}`;
    return `vs ${opponentTeamDisplay}`;
  }

  getStartTime(length, timezone = "", includeSoftStarts = true) {
    if (this.event?.type === "prop" && !this.event?.status?.hardStart) return includeSoftStarts ? formatDate(this.event.status.startsAt, length, timezone) : "";
    return formatTime(this.event?.status?.startsAt, length, timezone);
  }

  getEventWord(isPlural, length, isStartCase = false) {
    const eventWordConfig = length === "short" ? this.sportConfig?.eventWord?.short : this.sportConfig?.eventWord?.long;
    const word = (isPlural ? eventWordConfig?.plural : eventWordConfig?.singular) || "";
    return isStartCase ? _.startCase(word) : word;
  }

  getEventDetails() {
    return this.event?.info?.details || "";
  }

  getLeagueDisplay(length) {
    return selectVariant(length, { short: this.leagueConfig?.shortName, long: this.leagueConfig?.name, medium: this.leagueConfig?.name });
  }

  getSportDisplay(length) {
    return selectVariant(length, { short: this.sportConfig?.shortName, long: this.sportConfig?.name });
  }

  getResults(entityID, periodID, statID) {
    return this?.event?.results?.[periodID]?.[entityID]?.[statID];
  }

  getScore(entityID, periodID) {
    return this.getResults(entityID, periodID, "points");
  }

  getScoreWithTeam(entityID, periodID) {
    const score = this.getResults(entityID, periodID, "points");
    const team = this.getTeamDisplay(entityID, "long");

    return `${team}: ${score || "0"}`;
  }

  getTeamScore(sideID) {
    if (this.event?.type !== "match" || !this.event?.status?.started || this?.event?.status?.cancelled) return null;
    const eventTeam = this.event?.teams?.[sideID];
    if (!eventTeam?.teamID || !isNumber(eventTeam?.score)) return null;
    return eventTeam?.score;
  }

  getDisplayString(displayType) {
    switch (displayType) {
      case "getFinalScore_home":
        return this.getScore("home", "game");
      case "getFinalScore_away":
        return this.getScore("away", "game");

      case "homeTeam_long":
        return this.getTeamDisplay("home", "long");
      case "awayTeam_long":
        return this.getTeamDisplay("away", "long");
      case "homeTeam_medium":
        return this.getTeamDisplay("home", "medium");
      case "awayTeam_medium":
        return this.getTeamDisplay("away", "medium");
      case "homeTeam_short":
        return this.getTeamDisplay("home", "short");
      case "awayTeam_short":
        return this.getTeamDisplay("away", "short");

      case "title_long":
        return this.getTitle("long");
      case "title_medium":
        return this.getTitle("medium");
      case "title_short":
        return this.getTitle("short");
      case "titleList_long":
        return this.getTitleList("long");
      case "titleList_medium":
        return this.getTitleList("medium");
      case "titleList_short":
        return this.getTitleList("short");

      case "propTitle_long":
        return this.getPropTitle("long");
      case "propTitle_medium":
        return this.getPropTitle("medium");
      case "propTitle_short":
        return this.getPropTitle("short");
      case "propTitleList_long":
        return this.getPropTitleList("long");
      case "propTitleList_medium":
        return this.getPropTitleList("medium");
      case "propTitleList_short":
        return this.getPropTitleList("short");

      case "matchTitle_long":
        return this.getMatchTitle("long");
      case "matchTitle_medium":
        return this.getMatchTitle("medium");
      case "matchTitle_short":
        return this.getMatchTitle("short");
      case "matchTitleList_long":
        return this.getMatchTitleList("long");
      case "matchTitleList_medium":
        return this.getMatchTitleList("medium");
      case "matchTitleList_short":
        return this.getMatchTitleList("short");

      case "overriddenTitle_long":
        return this.getOverriddenTitle("long");
      case "overriddenTitle_medium":
        return this.getOverriddenTitle("medium");
      case "overriddenTitle_short":
        return this.getOverriddenTitle("short");

      case "details":
        return this.getEventDetails();

      case "league_long":
        return this.getLeagueDisplay("long");
      case "league_medium":
        return this.getLeagueDisplay("medium");
      case "league_short":
        return this.getLeagueDisplay("short");

      case "sport_short":
        return this.getSportDisplay("short");
      case "sport_long":
        return this.getSportDisplay("long");

      case "homeTeamOpponentContext_long":
        return this.getOpponentContext("home", "long");
      case "awayTeamOpponentContext_long":
        return this.getOpponentContext("away", "long");
      case "homeTeamOpponentContext_medium":
        return this.getOpponentContext("home", "medium");
      case "awayTeamOpponentContext_medium":
        return this.getOpponentContext("away", "medium");
      case "homeTeamOpponentContext_short":
        return this.getOpponentContext("home", "short");
      case "awayTeamOpponentContext_short":
        return this.getOpponentContext("away", "short");

      case "startTime_ET_short":
        return this.getStartTime("short", "America/New_York");
      case "startTime_ET_medium":
        return this.getStartTime("medium", "America/New_York");
      case "startTime_ET_long":
        return this.getStartTime("long", "America/New_York");
      case "startTime_local_short":
        return this.getStartTime("short");
      case "startTime_local_medium":
        return this.getStartTime("medium");
      case "startTime_local_long":
        return this.getStartTime("long");
      case "hardStartTime_ET_short":
        return this.getStartTime("short", "America/New_York", false);
      case "hardStartTime_ET_medium":
        return this.getStartTime("medium", "America/New_York", false);
      case "hardStartTime_ET_long":
        return this.getStartTime("long", "America/New_York", false);
      case "hardStartTime_local_short":
        return this.getStartTime("short", null, false);
      case "hardStartTime_local_medium":
        return this.getStartTime("medium", null, false);
      case "hardStartTime_local_long":
        return this.getStartTime("long", null, false);

      case "clockDateStatus_ET_short":
        return this.getClockDateStatus("short", "America/New_York");
      case "clockDateStatus_ET_medium":
        return this.getClockDateStatus("medium", "America/New_York");
      case "clockDateStatus_ET_long":
        return this.getClockDateStatus("long", "America/New_York");
      case "clockDateStatus_local_short":
        return this.getClockDateStatus("short", null);
      case "clockDateStatus_local_medium":
        return this.getClockDateStatus("medium", null);
      case "clockDateStatus_local_long":
        return this.getClockDateStatus("long", null);

      case "homeTeamScore":
        return this.getTeamScore("home");
      case "awayTeamScore":
        return this.getTeamScore("away");
      default:
        return "";
    }
  }
}
exports.EventDisplays = EventDisplays;
