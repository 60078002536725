const { getOddsDisplay, getEventDisplay } = require("@wagerlab/utils/displays");
const { sideIDSorter } = require("@wagerlab/utils/odds/betTypes");
const { getOpponentOddID, classifyOdds } = require("@wagerlab/utils/odds/identifiers");
const { getPeriodOrderPriority } = require("@wagerlab/utils/sports/periods");

//* FYI:
// When displaying oddGroups for a full Event, we show all oddGroups
// When displaying oddGroups for an EventShort, we show only the first oddGroup
// Rows are displayed on 1 line (and should ideally only contain exactly 2 items)

const getOddGroups = (eventData) => {
  const sortedOdds = Object.values(eventData?.odds || {}).sort((a, b) => oddsSorter(a, b, eventData));
  const addedOddIDs = {};
  const groups = [];
  let topGroup_sportSpecific = null;
  let topGroup_sportIndependent = null;
  const eventOverriddenTitle = getEventDisplay("overriddenTitle_medium", eventData);
  sortedOdds.forEach((oddData) => {
    const oddID = oddData?.oddID;
    if (!oddID || !oddData?.available || addedOddIDs[oddData.oddID]) return;
    const opponentOddID = getOpponentOddID(oddData);
    const opponentOddData = eventData?.odds?.[opponentOddID];
    if (!opponentOddID || !opponentOddData?.oddID || !opponentOddData?.available || addedOddIDs[opponentOddID]) return;

    const oddGroupDisplayName = getOddsDisplay("oddGroupName_medium", oddData, eventData);
    if (!oddGroupDisplayName) return;

    addedOddIDs[oddID] = true;
    addedOddIDs[opponentOddID] = true;

    const oddOrderPriority = getOddOrderPriority(oddData, eventData);

    if (oddOrderPriority === ODD_ORDER_PRIORITIES.topMarketForSport) {
      const groupName = eventOverriddenTitle ? oddGroupDisplayName : "";
      const group = topGroup_sportSpecific || { name: groupName, rows: [] };
      const rowName = eventOverriddenTitle ? "" : oddGroupDisplayName;
      const row = rowName ? { name: rowName, odds: [oddData, opponentOddData] } : { odds: [oddData, opponentOddData] };
      group.rows.push(row);
      topGroup_sportSpecific = group;
    } else if (oddOrderPriority === ODD_ORDER_PRIORITIES.topMarket) {
      const groupName = eventOverriddenTitle ? oddGroupDisplayName : "";
      const group = topGroup_sportIndependent || { name: groupName, rows: [] };
      const rowName = eventOverriddenTitle ? "" : oddGroupDisplayName;
      const row = rowName ? { name: rowName, odds: [oddData, opponentOddData] } : { odds: [oddData, opponentOddData] };
      group.rows.push(row);
      topGroup_sportIndependent = group;
    } else {
      const group = { name: oddGroupDisplayName, rows: [] };
      const row = { odds: [oddData, opponentOddData] };
      group.rows.push(row);
      groups.push(group);
    }
  });

  if (topGroup_sportIndependent && topGroup_sportSpecific) groups.unshift(topGroup_sportIndependent);
  const topGroup = topGroup_sportSpecific || topGroup_sportIndependent;

  if (topGroup) {
    if (!eventOverriddenTitle) {
      topGroup.rows = topGroup.rows.map((row) => {
        const name = row.odds[0]?.oddID ? getOddsDisplay("oddGroupName_medium", row.odds[0], eventData) : "";
        return { ...row, name };
      });
      topGroup.name = "";
    }
    groups.unshift(topGroup);
  }
  return groups;
};
exports.getOddGroups = getOddGroups;

const ODD_ORDER_PRIORITIES = {
  topMarketForSport: 10,
  topMarket: 9,
  mainMarket: 8,
  otherProp: 7,
  gameProp: 6,
  teamProp: 5,
  playerProp: 4,
  default: 1,
};
const getOddOrderPriority = (oddData, eventData) => {
  const { type: eventType, sportID, leagueID } = eventData || {};
  const { isMainMarket, isTopMarket, isTopMarketForSport, isTeamProp, isPlayerProp, isGameProp, isOtherProp } = classifyOdds(oddData, eventType, sportID, leagueID);
  if (isTopMarketForSport) return ODD_ORDER_PRIORITIES.topMarketForSport;
  if (isTopMarket) return ODD_ORDER_PRIORITIES.topMarket;
  if (isMainMarket) return ODD_ORDER_PRIORITIES.mainMarket;
  if (isOtherProp) return ODD_ORDER_PRIORITIES.otherProp;
  if (isGameProp) return ODD_ORDER_PRIORITIES.gameProp;
  if (isTeamProp) return ODD_ORDER_PRIORITIES.teamProp;
  if (isPlayerProp) return ODD_ORDER_PRIORITIES.playerProp;
  return ODD_ORDER_PRIORITIES.default;
};

const oddsSorter = (oddA, oddB, eventData) => {
  const { periodID: a_periodID } = oddA || {};
  const { periodID: b_periodID } = oddB || {};

  const a_oddOrderPriority = getOddOrderPriority(oddA, eventData);
  const b_oddOrderPriority = getOddOrderPriority(oddB, eventData);

  const orderPrioritySort = sortDescending(a_oddOrderPriority, b_oddOrderPriority);
  if (orderPrioritySort !== 0) return orderPrioritySort;

  const a_periodOrderPrio = getPeriodOrderPriority(a_periodID);
  const b_periodOrderPrio = getPeriodOrderPriority(b_periodID);
  const periodOrderSort = sortDescending(a_periodOrderPrio, b_periodOrderPrio);
  if (periodOrderSort !== 0) return periodOrderSort;

  const isMainMarket = a_oddOrderPriority >= ODD_ORDER_PRIORITIES.mainMarket || b_oddOrderPriority >= ODD_ORDER_PRIORITIES.mainMarket;
  if (!isMainMarket) {
    const a_oddName = getOddsDisplay("oddGroupName_medium", oddA, eventData);
    const b_oddName = getOddsDisplay("oddGroupName_medium", oddB, eventData);
    if (a_oddName && !b_oddName) return -1;
    if (!a_oddName && b_oddName) return 1;
    if (a_oddName && b_oddName && a_oddName !== b_oddName) return a_oddName.localeCompare(b_oddName);
  }

  return sideIDSorter(oddA, oddB, eventData);
};
exports.oddsSorter = oddsSorter;

const STAT_ID_PRIORITIES = {
  points: 10,
  prop0: 9,
};

const PAIRED_STAT_ENTITY_ID_PRIORITIES = {
  home: 10,
  away: 10,
  all: 9,
  side1: 8,
  side2: 8,
};

const STAT_ENTITY_ID_PRIORITIES = {
  home: 10,
  side1: 10,
  away: 9,
  side2: 9,
  all: 8,
};

// Sorts a list of odds based on periodID, statEntityID, and statID (but on based on sideID/betTypeID). This will sort an oddID to the same priority as it's opponentOddID
const oddsSetSorter = (oddA, oddB) => {
  const { periodID: a_periodID, statEntityID: a_statEntityID, statID: a_statID } = oddA || {};
  const { periodID: b_periodID, statEntityID: b_statEntityID, statID: b_statID } = oddB || {};

  const a_statEntityPairPrio = PAIRED_STAT_ENTITY_ID_PRIORITIES[a_statEntityID] || 0;
  const b_statEntityPairPrio = PAIRED_STAT_ENTITY_ID_PRIORITIES[b_statEntityID] || 0;
  const statEntityPairOrderSort = sortDescending(a_statEntityPairPrio, b_statEntityPairPrio);
  if (statEntityPairOrderSort !== 0) return statEntityPairOrderSort;

  const a_statIDPrio = STAT_ID_PRIORITIES[a_statID] || 0;
  const b_statIDPrio = STAT_ID_PRIORITIES[b_statID] || 0;
  const statIDSort = sortDescending(a_statIDPrio, b_statIDPrio);
  if (statIDSort !== 0) return statIDSort;

  const a_periodOrderPrio = getPeriodOrderPriority(a_periodID);
  const b_periodOrderPrio = getPeriodOrderPriority(b_periodID);
  const periodOrderSort = sortDescending(a_periodOrderPrio, b_periodOrderPrio);
  if (periodOrderSort !== 0) return periodOrderSort;

  const a_statEntityIDPrio = STAT_ENTITY_ID_PRIORITIES[a_statEntityID] || 0;
  const b_statEntityIDPrio = STAT_ENTITY_ID_PRIORITIES[b_statEntityID] || 0;
  const statEntityOrderSort = sortDescending(a_statEntityIDPrio, b_statEntityIDPrio);
  if (statEntityOrderSort !== 0) return statEntityOrderSort;

  return 0;
};
exports.oddsSetSorter = oddsSetSorter;

const sortDescending = (a, b) => {
  if (!a && !b) return 0;
  if (!a) return 1;
  if (!b) return -1;
  return b - a;
};
