const { copyData, setObjVal } = require("@wagerlab/utils/data/mutations");
const _ = require("lodash");
const { isDate, isNumber, isEmptyObject, isObject } = require("@wagerlab/utils/data/types");
const { getOpponentOddID } = require("@wagerlab/utils/odds/identifiers");
const { getOddScore } = require("@wagerlab/utils/odds/scoring");
const { hasAnyAutomatedScoring, eventRequiresManualScoring } = require("@wagerlab/utils/events/getters");

const FORCE_FINALIZE_AFTER_STATUS_ENDED_HOURS = 18;
const FORCE_FINALIZE_AFTER_ALL_SOURCES_FINAL_HOURS = 1;
const MAX_PERCENT_MISSING_PLAYER_PROPS = 0.1;
const PREVENT_EVENT_FINALIZATION_ON_ODD_SCORE_MISMATCHES = true;

const setFinalized = (eventData, eventBookOddsData, finalized) => {
  if (finalized) {
    setObjVal(eventData, "status.finalized", true);
    setObjVal(eventData, "status.ended", true);
    setObjVal(eventData, "status.live", false);
    setObjVal(eventData, "status.delayed", false);
    _.unset(eventData, "status.oddsFinalizeAt");
    _.unset(eventData, "status.nextUpdateAt");
  } else {
    setObjVal(eventData, "status.finalized", false);
  }
  return finalizeOddsStatuses(eventData, eventBookOddsData);
};

exports.refreshEventFinalized = (eventData, eventBookOddsData, canAutoFinalize) => {
  const isManuallyScoredEvent = eventRequiresManualScoring(eventData);
  const { cancelled, ended, oddsFinalizeAt, finalized: prevFinalized } = eventData?.status || {};
  const nowDate = new Date();
  const canFinalizeBecausePrevFinalized = prevFinalized;
  const canFinalizeBecauseCancelled = cancelled;
  const canFinalizeBecauseManualEnded = ended && isManuallyScoredEvent;
  const canFinalizeBecauseOddsFinalizeAtPast = ended && !isManuallyScoredEvent && isDate(oddsFinalizeAt) && nowDate > oddsFinalizeAt;

  if (canFinalizeBecauseCancelled || canFinalizeBecauseManualEnded || canFinalizeBecauseOddsFinalizeAtPast || canFinalizeBecausePrevFinalized) {
    return setFinalized(eventData, eventBookOddsData, true);
  }

  if (!ended) {
    _.unset(eventData, "status.oddsFinalizeAt");
    return setFinalized(eventData, eventBookOddsData, false);
  }
  if (canAutoFinalize) {
    _.unset(eventData, "status.oddsFinalizeAt");
    return setFinalized(eventData, eventBookOddsData, true);
  }
  let hasUnfinalizedSourceData = false;
  let hasUnfinalizedPlayerPropSourceData = false;
  Object.values(eventData?.sourceContext || {}).forEach((singleSourceContext) => {
    const { supportsEventScores, supportsEventStats, supportsPlayerStats, eventScoresFinal, eventStatsFinal, playerStatsFinal, eventStatusFinal, detached, detachExpiresAt } =
      singleSourceContext || {};
    if (detached) {
      if (isDate(detachExpiresAt) && nowDate < detachExpiresAt) {
        hasUnfinalizedSourceData = true;
        // hasUnfinalizedPlayerPropSourceData = true;
      }
      return;
    }
    if (supportsEventScores && (!eventScoresFinal || !eventStatusFinal)) hasUnfinalizedSourceData = true;
    if (supportsEventStats && (!eventStatsFinal || !eventStatusFinal)) hasUnfinalizedSourceData = true;
    if (supportsPlayerStats && (!playerStatsFinal || !eventStatusFinal)) {
      hasUnfinalizedSourceData = true;
      hasUnfinalizedPlayerPropSourceData = true;
    }
  });

  if (hasUnfinalizedSourceData) {
    const newOddsFinalizeAt = new Date();
    newOddsFinalizeAt.setHours(newOddsFinalizeAt.getHours() + FORCE_FINALIZE_AFTER_STATUS_ENDED_HOURS);
    if (!isDate(oddsFinalizeAt) || newOddsFinalizeAt < oddsFinalizeAt) setObjVal(eventData, "status.oddsFinalizeAt", newOddsFinalizeAt);

    // We could optionally iterate through player props now and cancel any that have no scores if hasUnfinalizedPlayerPropSourceData is false.
    // By not doing so here and only doing so below we essentially are just waiting until the other odd types can be finalized before we cancel any player props
    // Changing that shouldn't make much difference either way

    return setFinalized(eventData, eventBookOddsData, false);
  }

  // hasUnfinalizedPlayerPropSourceData should always be false here
  let totalPlayerProps = 0;
  let unfinalPlayerProps = 0;
  let unfinalOthers = 0;
  let scoreMismatches = 0;
  Object.entries(eventData?.odds || {}).forEach(([oddID, eventOddData]) => {
    const eventBookOddData = eventBookOddsData?.odds?.[oddID];
    const isPlayerProp = !!(eventBookOddData?.playerID ?? eventOddData?.playerID);
    const oddCancelled = eventBookOddData?.cancelled ?? eventOddData?.cancelled ?? false;
    const score = eventBookOddData?.score ?? eventOddData?.score;
    const hasScore = isNumber(score);

    if (oddCancelled) return;
    if (isPlayerProp) totalPlayerProps++;
    if (!hasScore) {
      if (isPlayerProp) unfinalPlayerProps++;
      else unfinalOthers++;
      return;
    }
    const { periodID, statEntityID, statID } = eventOddData || {};
    if (PREVENT_EVENT_FINALIZATION_ON_ODD_SCORE_MISMATCHES && periodID && statEntityID && statID) {
      const copiedEventResults = copyData(eventData?.results || {});
      _.unset(copiedEventResults, `${periodID}.${statEntityID}.${statID}`);
      const altScore = getOddScore(eventOddData, { ...eventData, results: copiedEventResults });
      if (isNumber(altScore) && altScore !== score) {
        scoreMismatches++;
      }
    }
  });

  const hasUnfinalizedPlayerProps = totalPlayerProps > 0 && unfinalPlayerProps > 0 && unfinalPlayerProps / totalPlayerProps > MAX_PERCENT_MISSING_PLAYER_PROPS;
  const hasUnfinalizedOtherBets = unfinalOthers > 0;
  const hasScoreMismatches = scoreMismatches > 0;
  const shouldFinalizeEvent = !hasUnfinalizedPlayerProps && !hasUnfinalizedOtherBets && !hasScoreMismatches;
  setObjVal(eventData, "status.finalized", !!shouldFinalizeEvent);
  if (shouldFinalizeEvent) return setFinalized(eventData, eventBookOddsData, true);

  const newOddsFinalizeAt = new Date();
  newOddsFinalizeAt.setHours(newOddsFinalizeAt.getHours() + FORCE_FINALIZE_AFTER_ALL_SOURCES_FINAL_HOURS);
  if (!isDate(oddsFinalizeAt) || newOddsFinalizeAt < oddsFinalizeAt) setObjVal(eventData, "status.oddsFinalizeAt", newOddsFinalizeAt);
  return setFinalized(eventData, eventBookOddsData, false);
};

// This just sweeps through and cleans up some of the values in cases where we finalized the odds
const finalizeOddsStatuses = (event, eventBookOdds) => {
  const { finalized: eventFinalized, cancelled: eventCancelled, ended: eventEnded } = event?.status || {};

  const eventOddIDsVisited = {};
  Object.keys(event?.odds || {}).forEach((oddID_main) => {
    const eventOddData_main = event?.odds?.[oddID_main];
    const oddID_opponent = getOpponentOddID(eventOddData_main);
    const eventOddData_opponent = event?.odds?.[oddID_opponent];
    const bookOddData_main = eventBookOdds?.odds?.[oddID_main];
    const bookOddData_opponent = eventBookOdds?.odds?.[oddID_opponent];
    if (!oddID_main && !oddID_opponent) return;
    if (oddID_main && eventOddIDsVisited[oddID_main]) return;
    if (oddID_opponent && eventOddIDsVisited[oddID_opponent]) return;
    if (oddID_main) eventOddIDsVisited[oddID_main] = true;
    if (oddID_opponent) eventOddIDsVisited[oddID_opponent] = true;

    const hasBookOddData_main = isObject(bookOddData_main) && !isEmptyObject(bookOddData_main);
    const hasBookOddData_opponent = isObject(bookOddData_opponent) && !isEmptyObject(bookOddData_opponent);
    const hasAnyBookOddData = hasBookOddData_main || hasBookOddData_opponent;

    let started = hasAnyBookOddData ? !!(bookOddData_main?.started || bookOddData_opponent?.started) : !!(eventOddData_main?.started || eventOddData_opponent?.started);
    let cancelled = hasAnyBookOddData ? !!(bookOddData_main?.cancelled || bookOddData_opponent?.cancelled) : !!(eventOddData_main?.cancelled || eventOddData_opponent?.cancelled);
    let ended = hasAnyBookOddData ? !!(bookOddData_main?.ended || bookOddData_opponent?.ended) : !!(eventOddData_main?.ended || eventOddData_opponent?.ended);
    let score_main = hasAnyBookOddData ? bookOddData_main?.score : eventOddData_main?.score;
    let score_opponent = hasAnyBookOddData ? bookOddData_opponent?.score : eventOddData_opponent?.score;
    if (isNumber(score_main) && !isNumber(score_opponent)) score_opponent = 0;
    if (isNumber(score_opponent) && !isNumber(score_main)) score_main = 0;

    const hasAnyScore = isNumber(score_main) || isNumber(score_opponent);
    const hasAnyNonZeroScore = (isNumber(score_main) && score_main !== 0) || (isNumber(score_opponent) && score_opponent !== 0);
    started = started || hasAnyNonZeroScore || (eventFinalized && hasAnyScore);
    ended = ended || eventEnded || eventCancelled || eventFinalized;
    cancelled = cancelled || eventCancelled || (eventFinalized && !hasAnyScore);
    if (cancelled) {
      score_main = null;
      score_opponent = null;
    }

    if (oddID_opponent && eventOddData_opponent?.oddID) {
      setObjVal(event, `odds.${oddID_opponent}.started`, !!started);
      setObjVal(event, `odds.${oddID_opponent}.cancelled`, !!cancelled);
      setObjVal(event, `odds.${oddID_opponent}.ended`, !!ended);
      setObjVal(event, `odds.${oddID_opponent}.score`, score_opponent);
    }
    if (oddID_main && eventOddData_main?.oddID) {
      setObjVal(event, `odds.${oddID_main}.started`, !!started);
      setObjVal(event, `odds.${oddID_main}.cancelled`, !!cancelled);
      setObjVal(event, `odds.${oddID_main}.ended`, !!ended);
      setObjVal(event, `odds.${oddID_main}.score`, score_main);
    }
  });

  return { event, eventBookOdds };
};
