import { TableCell, Link, Tooltip, Button } from "@mui/material";
import { ellipsisStyle } from "@wagerlab/admin/src/components/lookups/shared/add/styles";
import DeleteIcon from "@mui/icons-material/Delete";

export const ActionsCell = ({ name = null, handleDelete = null }) => {
  return (
    <TableCell sx={{ verticalAlign: "top" }}>
      <DeleteButton handleDelete={handleDelete} />
      <GoogleSearchLink name={name} />
    </TableCell>
  );
};

const DeleteButton = ({ handleDelete }) => {
  if (!handleDelete) return null;
  return (
    <Button onClick={handleDelete}>
      <DeleteIcon />
    </Button>
  );
};

const GoogleSearchLink = ({ name }) => {
  if (!name) return null;
  const googleSearchLink = `https://www.google.com/search?q=${name.replace(/\s+/g, "+")}`;
  const googleSearchDisplay = `Google Search: ${name}`;

  const style2 = {
    ...ellipsisStyle,
    display: "block",
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };

  return (
    <Tooltip title={googleSearchLink} arrow>
      <Link href={googleSearchLink} onClick={(e) => e.stopPropagation()} target="_blank" rel="noopener noreferrer" sx={style2}>
        {googleSearchDisplay}
      </Link>
    </Tooltip>
  );
};
