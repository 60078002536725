import React, { useMemo } from "react";
import { Grid } from "@mui/material";
import { copyData, dataChanged, dataUpdates } from "@wagerlab/utils/data/mutations";
import { initializeFormData, buildEventFromFormData, formFieldValidation, validateFormData } from "@wagerlab/admin/src/utils/events/eventForm";
import { getEvent, getEventBookOdds, saveEventChanges, updateEvent, updateEventShort } from "@wagerlab/admin/src/utils/events/database";
import { useParams } from "react-router-dom";
import { EventFormIdentifiers } from "@wagerlab/admin/src/components/events/EventFormIdentifiers";
import { EventFormStatusTiming } from "@wagerlab/admin/src/components/events/EventFormStatusTiming";
import { EventFormStatusStates } from "@wagerlab/admin/src/components/events/EventFormStatusStates";
import { EventFormWrapper } from "@wagerlab/admin/src/components/events/EventFormWrapper";
import { EventFormStatusDisplays } from "@wagerlab/admin/src/components/events/EventFormStatusDisplays";
import { EventFormPropOdds } from "@wagerlab/admin/src/components/events/EventFormPropOdds";
import { deleteValue } from "@wagerlab/utils/database/firestore";
import { eventHasMeaningfulChanges } from "@wagerlab/utils/events/changes";
import { useEventsQuery } from "@wagerlab/admin/src/utils/events/useEventsQuery";

export const EditEvent = () => {
  const { eventID } = useParams();
  const { fetchedEvent, fetchedEventBookOdds, loadingEvent } = useEventsQuery(eventID);

  const screenLoading = loadingEvent || !fetchedEvent?.eventID;

  const { initialEvent, initialEventBookOdds, initialEventForm } = useMemo(() => {
    const initEventData = screenLoading ? null : copyData(fetchedEvent);
    const initEventBookOddsData = screenLoading ? null : copyData(fetchedEventBookOdds);
    const initialEventFormData = initializeFormData(initEventData, initEventBookOddsData);
    return { initialEvent: initEventData, initialEventBookOdds: initEventBookOddsData, initialEventForm: initialEventFormData };
  }, [!!screenLoading, fetchedEvent, fetchedEventBookOdds]);

  const handleSubmit = async (formData, { resetForm }) => {
    const validationError = validateFormData(formData, initialEvent, initialEventBookOdds);
    if (validationError) return validationError;
    const prevToNew = ({ event: prevEvent, eventBookOdds: prevEventBookOdds }) => buildEventFromFormData(formData, prevEvent, prevEventBookOdds);
    return await saveEventChanges(initialEvent, initialEventBookOdds, prevToNew);
  };

  return (
    <EventFormWrapper
      title={"Edit Event"}
      loading={screenLoading}
      eventData={initialEvent}
      eventBookOddsData={initialEventBookOdds}
      formData={initialEventForm}
      formValidation={formFieldValidation}
      handleSubmit={handleSubmit}
      submitButtonText={"Save Event"}
    >
      <Grid container spacing={2}>
        <EventFormIdentifiers isNew={false} />
        <EventFormStatusTiming isNew={false} />
        <EventFormStatusDisplays isNew={false} />
        <EventFormStatusStates isNew={false} />
        <EventFormPropOdds isNew={false} />
      </Grid>
    </EventFormWrapper>
  );
};
