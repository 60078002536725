import { Box, Button, Grid, Typography } from "@mui/material";
import { TextField } from "@wagerlab/admin/src/components/inputs/TextField";
import { SelectMenu } from "@wagerlab/admin/src/components/inputs/SelectMenu";
import { useState, Fragment } from "react";
import { useFormikContext, FieldArray } from "formik";
import { SelectBoolean } from "@wagerlab/admin/src/components/inputs/SelectBoolean";
import { SubmitButton } from "@wagerlab/admin/src/components/inputs/SubmitButton";
import { propSideWinnerResults } from "@wagerlab/admin/src/utils/events/propEventGraderForm";
import { useNavigate } from "react-router-dom";
import { getEventDisplay } from "@wagerlab/utils/displays";
import moment from "moment-timezone";

// cancelled, ended, displayShort, displayLong, propList
// each propList item -> periodID, statID, propTitle, propDetails, cancelled, ended, oddIDs, propSideWinner

export const PropEventGrader = ({ prevEvent }) => {
  const { values, initialValues, setFieldValue } = useFormikContext();
  const navigate = useNavigate();

  if (initialValues?.ended || initialValues?.cancelled || prevEvent?.status?.ended || prevEvent?.status?.finalized || prevEvent?.status?.cancelled || !prevEvent?.eventID)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "77vh",
        }}
      >
        <Button onClick={() => navigate(`/`)} variant="contained">
          Go Back
        </Button>
      </Box>
    );

  const propList = values?.propList || [];
  const allCancelled = !!values?.cancelled;
  const allEnded = !!values?.ended;

  const disableResultsInputs = allCancelled || allEnded;

  const canToggleEnded = !prevEvent?.status?.ended && propList.every((propListItem) => !!propSideWinnerResults(propListItem?.propSideWinner));
  const canToggleCancelled = !prevEvent?.status?.cancelled;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Typography variant="h3">{getEventDisplay("title_long", prevEvent)}</Typography>
          <Typography>{`EventID: ${prevEvent?.eventID}`}</Typography>

          <Typography>{moment(prevEvent.status?.startsAt).tz(moment.tz.guess()).format("dddd MMMM D, YYYY [@] h:mma zz")}</Typography>
          <Typography>{prevEvent?.sportID}</Typography>
          <Typography>{prevEvent?.leagueID}</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        <Grid item xs={12}>
          <Typography variant="h5">Event-scoped grading</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectBoolean name="ended" label="Ended (finalized/graded)" disabled={!canToggleEnded} tooltip="Whether ALL odds/results have been graded/finalized" />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectBoolean name="cancelled" label="Cancelled" disabled={!canToggleCancelled} tooltip="Whether ALL odds/bets should be set to cancelled" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField name="displayShort" label="Short Update" tooltip="ex: G-1 or C-EX" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField name="displayLong" label="Long Update" tooltip="One sentence description: Graded as a win for side 1." />
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        <Grid item xs={12}>
          <Typography variant="h5">Odd-scoped grading</Typography>
        </Grid>
        <Grid item xs={12}>
          {propList.map((propListItem, index) => {
            const prevOddsCancelled = !!initialValues?.propList?.[index]?.cancelled;
            const prevOddsEnded = prevOddsCancelled || !!initialValues?.propList?.[index]?.ended;

            const { periodID, statID, propTitle, propDetails, cancelled, ended, oddIDs, propSideWinner } = propListItem || {};

            const side1Display = prevEvent?.props?.[statID]?.sides?.side1?.sideDisplay || "Side 1";
            const side2Display = prevEvent?.props?.[statID]?.sides?.side2?.sideDisplay || "Side 2";

            return (
              <Fragment key={`${periodID || ""}${statID || ""}` ?? index}>
                <Grid container spacing={2} sx={{ marginBottom: 5 }}>
                  <Grid item xs={12}>
                    <Typography variant="h6">{propTitle || "ERROR - do not submit"}</Typography>
                    {propDetails ? <Typography>{propDetails}</Typography> : null}
                  </Grid>

                  <Grid item xs={6}>
                    <SelectBoolean
                      name={`propList[${index}].ended`}
                      label="Ended (finalized/graded)"
                      required
                      disabled={prevOddsEnded || disableResultsInputs || !propSideWinnerResults(propSideWinner)}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <SelectBoolean name={`propList[${index}].cancelled`} label="Odds Cancelled" required disabled={prevOddsEnded || disableResultsInputs} />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <SelectMenu
                      name={`propList[${index}].propSideWinner`}
                      label="Result"
                      options={{ side1: `${side1Display} WINS`, side2: `${side2Display} WINS`, push: "PUSH - no winner" }}
                      disabled={prevOddsEnded || disableResultsInputs}
                    />
                  </Grid>
                </Grid>
              </Fragment>
            );
          })}
        </Grid>
      </Grid>

      <Box
        sx={{
          marginTop: 8,
          marginBottom: 8,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <SubmitButton type="submit" size="medium" sx={{ width: 200 }} text={"Save Event"} />
      </Box>
    </>
  );
};
