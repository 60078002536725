import React, { useEffect, useState } from "react";
import { Typography, Container, Select, MenuItem, Box, Tabs, Tab } from "@mui/material";
import { LEAGUE_CONFIG } from "@wagerlab/utils/sports/leagueConfig";
import { ManageExistingPlayerIds } from "@wagerlab/admin/src/components/lookups/players/manage/ManageExistingPlayerIds";
import { AddNewPlayerIds } from "@wagerlab/admin/src/components/lookups/players/add/AddNewPlayerIds";
import { queryCollection } from "@wagerlab/utils/database/firestore";

export const LinkPlayers = () => {
  const [selectedLeagueID, setSelectedLeagueID] = useState("");
  const [selectedTeamID, setSelectedTeamID] = useState("");
  const [leagueTeams, setLeagueTeams] = useState([]);

  const [activeTab, setActiveTab] = useState(0);

  const handleLeagueChange = async (event) => {
    const leagueID = event.target.value;
    setSelectedLeagueID(leagueID);
    const allLeagueTeams = await queryCollection("Teams", "leagueID", "==", leagueID);
    console.log("allLeagueTeams", allLeagueTeams);

    // TODO: See if this returns array of team objects or map of teamID => team
    setLeagueTeams(Object.values(allLeagueTeams || {}));
  };

  const handleTeamChange = (event) => {
    setSelectedTeamID(event.target.value);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Container maxWidth="xl" sx={{ marginTop: 16, marginBottom: 8 }}>
      <Typography variant="h4">Link Entities</Typography>

      <Typography>Select League</Typography>
      <Select value={selectedLeagueID} onChange={handleLeagueChange} autoWidth={true}>
        {Object.values(LEAGUE_CONFIG)
          .sort((league1, league2) => league1.name.localeCompare(league2.name))
          .map((league) => (
            <MenuItem key={league.leagueID} value={league.leagueID}>
              {league.name}
            </MenuItem>
          ))}
      </Select>

      {selectedLeagueID && leagueTeams?.length && (
        <>
          <Typography>Select Team</Typography>
          <Select value={selectedTeamID} onChange={handleTeamChange} autoWidth={true}>
            {leagueTeams
              .sort((team1, team2) => team1.teamID.localeCompare(team2.teamID))
              .map((team) => (
                <MenuItem key={team.teamID} value={team.teamID}>
                  {team.teamID}
                </MenuItem>
              ))}
          </Select>
        </>
      )}

      <Box sx={{ marginTop: 4, marginBottom: 4 }}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Manage Existing Player IDs" />
          <Tab label="Add New Player ID Links" />
        </Tabs>

        {activeTab === 0 && <ManageExistingPlayerIds teamID={selectedTeamID} leagueID={selectedLeagueID} />}
        {activeTab === 1 && <AddNewPlayerIds teamID={selectedTeamID} leagueID={selectedLeagueID} />}
      </Box>
    </Container>
  );
};
