exports.syncEventBookOddsStatus = (event, eventBookOdds) => {
  const { startsAt, live, finalized, started, ended, cancelled } = event?.status || {};
  const { oddsOverview, oddsAvailable } = eventBookOdds?.status || {};
  // Note that oddsOverview and oddsAvailable are modified elsewhere
  const eventBookOddsStatus = {
    startsAt,
    live,
    finalized,
    started,
    ended,
    cancelled,
    oddsOverview,
    oddsAvailable,
  };

  eventBookOdds.status = eventBookOddsStatus;
  return eventBookOdds;
};
