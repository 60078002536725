const { isDate, isBoolean } = require("@wagerlab/utils/data/types");
const { EVENT_TYPES } = require("@wagerlab/utils/events/enums");
const { eventRequiresManualScoring, hasAnyAutomatedScoring } = require("@wagerlab/utils/events/getters");
const _ = require("lodash");

const DELAYED_MATCH_AUTOCANCEL_HOURS = 36;

exports.refreshEventStatus = (eventData) => {
  const currentEventStatus = eventData?.status || {};

  const requiresManualScoring = eventRequiresManualScoring(eventData);
  const hasAutomatedScoring = hasAnyAutomatedScoring(eventData);
  const eventType = eventData?.type;
  let { finalized, startsAt, ended, started, cancelled, delayed, inBreak, currentPeriodID, previousPeriodID, displayShort, displayLong, hardStart, oddsCloseAtStart, oddsCloseAtDate, nextUpdateAt } =
    currentEventStatus;
  const { started: periodsStarted, ended: periodsEnded } = currentEventStatus?.periods || {};

  hardStart = hardStart ?? (eventType === "prop" ? false : true);

  if (!started && periodsStarted?.includes("game")) started = true;
  // LIVE and COMPLETED are calculated fields
  ended = !!(ended || cancelled || periodsEnded?.includes("game"));
  let completed = !!(ended && !cancelled);
  delayed = !!(delayed && !completed && !cancelled && !ended && !inBreak);
  let live = !!(started && !ended && !delayed && !inBreak);

  // Check for auto-cancelling heavily delayed matches
  const nowDate = new Date();
  if (hasAutomatedScoring && eventType === EVENT_TYPES.match && hardStart && !finalized && delayed && !completed && !cancelled && isDate(startsAt) && nowDate > startsAt) {
    const autoCancelThreshold = new Date(startsAt.getTime() + DELAYED_MATCH_AUTOCANCEL_HOURS * 60 * 60 * 1000);
    if (nowDate > autoCancelThreshold) {
      cancelled = true;
      completed = false;
      delayed = false;
      ended = true;
      live = false;
      displayShort = "CANC";
      displayLong = "Cancelled";
    }
  }

  if (displayLong === "Upcoming" && (started || ended || live)) displayLong = "";

  if (!live) {
    currentPeriodID = "";
    previousPeriodID = "";
  }
  if (currentPeriodID && currentPeriodID === previousPeriodID) currentPeriodID = "";

  oddsCloseAtDate = isDate(oddsCloseAtDate) ? oddsCloseAtDate : false;

  if (!isBoolean(oddsCloseAtStart)) oddsCloseAtStart = requiresManualScoring && !oddsCloseAtDate ? true : false;

  if (!isDate(nextUpdateAt)) nextUpdateAt = hasAutomatedScoring ? new Date() : null;

  eventData.status = {
    ...currentEventStatus,
    started: !!started,
    completed: !!completed,
    cancelled: !!cancelled,
    delayed: !!delayed,
    currentPeriodID: currentPeriodID || "",
    previousPeriodID: previousPeriodID || "",
    displayLong: displayLong || "",
    displayShort: displayShort || "",
    ended: !!ended,
    live: !!live,
    hardStart: !!hardStart,
    oddsCloseAtStart: !!oddsCloseAtStart,
    oddsCloseAtDate: oddsCloseAtDate,
    nextUpdateAt,
  };

  return eventData;
};
