const { isString, isBoolean, isNumber, isArray, isObject } = require("@wagerlab/utils/data/types");

const getStringSize = (str) => {
  var numBytes = str.length;
  for (var i = str.length - 1; i >= 0; i--) {
    var code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) numBytes++;
    else if (code > 0x7ff && code <= 0xffff) numBytes += 2;
    else if (code >= 0x10000 && code <= 0x10ffff) numBytes += 3;
  }
  return numBytes + 1;
};

const getObjectSize = (obj) => {
  if (!obj) return 1;
  let numBytes = 0;
  for (var key in obj) {
    if (!Object.hasOwnProperty.call(obj, key)) continue;
    const keySize = getSize(key);
    let valueSize = 0;
    try {
      valueSize = getSize(obj[key]);
    } catch (ex) {
      if (ex instanceof RangeError) valueSize = 0;
    }
    const totalSize = keySize + valueSize;
    numBytes += totalSize;
  }
  return numBytes;
};

const getArraySize = (arr) => arr.map(getSize).reduce((totalSize, currentSize) => totalSize + currentSize, 0);

const getSize = (item) => {
  if (item == null) return 1;
  if (isString(item)) return getStringSize(item);
  if (isBoolean(item)) return 1;
  if (isNumber(item)) return 8;
  if (isArray(item)) return getArraySize(item);
  if (isObject(item)) return getObjectSize(item);
  return 0;
};

const BASE_DOCUMENT_SIZE = 32;
const getSizeOfDocument = (documentData, collectionName, collectionID, subCollectionName = "", subCollectionID = "") => {
  const collectionPathSize = getStringSize(collectionName || "") + getStringSize(collectionID || "");
  const subCollectionPathSize = subCollectionName || subCollectionID ? getStringSize(subCollectionName || "") + getStringSize(subCollectionID || "") : 0;
  const pathSize = collectionPathSize + subCollectionPathSize;
  const documentDataSize = getSize(documentData);
  return BASE_DOCUMENT_SIZE + pathSize + documentDataSize;
};
exports.getSizeOfDocument = getSizeOfDocument;
