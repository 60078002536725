import { copyData, setObjVal, stripEmptyValues } from "@wagerlab/utils/data/mutations";
import { isBoolean, isDate, isInteger } from "@wagerlab/utils/data/types";
import { refreshEvent } from "@wagerlab/utils/events/refresh";
import { getOddID } from "@wagerlab/utils/odds/identifiers";
import { ENABLED_LEAGUE_IDS } from "@wagerlab/utils/sports/leagueConfig";
import { ENABLED_SPORT_IDS } from "@wagerlab/utils/sports/sportConfig";
import * as Yup from "yup";
import { asMoneylineString, asMoneylineInt, getInverseOdds } from "@wagerlab/utils/odds/converters";
import moment from "moment-timezone";
import { newID } from "@wagerlab/utils/database/firestore";

export const initializeFormData = (eventData, eventBookOddsData) => {
  if (!eventData?.eventID) {
    const eventID = newID("Events");
    return {
      eventID,
      eventName: "",
      sportID: "",
      leagueID: "",
      type: "prop",
      startsAt: null,
      hardStart: false,
      oddsCloseType: "start",
      oddsCloseDate: null,
      displayShort: "",
      displayLong: "",

      started: false, //XXX dont show these when is new
      delayed: false,

      propOddsList: [
        {
          propIndex: 0,
          propStatID: "prop0",
          propTitle: "",
          propDetails: "",
          available: true,
          numericScoring: false,
          side1Display: "",
          side1OddsDirection: "pos",
          side1OddsValue: 100,
          side2Display: "",
        },
      ],
    };
  }

  const oddsCloseType = getOddsCloseType_fromOddsCloseStatus(eventData);
  const oddsCloseDate = oddsCloseType === "date" ? moment(eventData?.status?.oddsCloseAtDate) : null;

  return {
    eventID: eventData?.eventID,
    eventName: eventData?.eventName || "",
    sportID: eventData?.sportID || "",
    leagueID: eventData?.leagueID || "",
    type: eventData?.type || "",
    startsAt: moment(eventData?.status?.startsAt) || null,
    hardStart: isBoolean(eventData?.status?.hardStart) ? eventData?.status?.hardStart : eventData?.type === "match" ? true : false,
    oddsCloseType: oddsCloseType,
    oddsCloseDate: oddsCloseDate,
    displayShort: eventData?.status?.displayShort || "",
    displayLong: eventData?.status?.displayLong || "",
    started: !!eventData?.status?.started,
    delayed: !!eventData?.status?.delayed,
    propOddsList: getPropOddsList_fromEvent(eventData),
  };
};

export const validateFormData = (eventForm, prevEvent, prevEventBookOdds) => {
  if (!eventForm?.eventID) return "Event ID is missing";
  if (prevEvent?.status?.cancelled || prevEvent?.status?.ended) return "Event is cancelled or ended";
  if (prevEvent?.type && prevEvent?.type !== "prop") return "Can't edit a non-prop event here";
  if (eventForm?.type !== "prop") return "Can't create a non-prop event here";
  if (prevEvent?.eventID && !prevEvent?.manual) return "Existing prop event is not manual. This shouldn't happen!!!";

  return "";
};

export const buildEventFromFormData = (eventForm, prevEvent, prevEventBookOdds) => {
  if (!eventForm?.eventID) return null;

  let event = prevEvent?.eventID
    ? copyData(prevEvent)
    : {
        eventID: eventForm.eventID,
        type: "prop",
        manual: true,
        props: {},
        results: {},
        odds: {},
      };
  let eventBookOdds = prevEvent?.eventID ? copyData(prevEventBookOdds) : {};

  setObjVal(event, "eventName", eventForm.eventName);
  setObjVal(event, "sportID", eventForm.sportID);
  setObjVal(event, "leagueID", eventForm.leagueID);
  //TODO add startsAt to prevStartsAt if we changed it
  setObjVal(event, "status.startsAt", eventForm.startsAt.toDate());
  setObjVal(event, "status.hardStart", eventForm.hardStart);

  const { oddsCloseAtStart, oddsCloseAtDate } = getOddsCloseAtStatus_fromOddsCloseType(eventForm.oddsCloseType, eventForm.oddsCloseDate);

  setObjVal(event, "status.oddsCloseAtStart", oddsCloseAtStart);
  setObjVal(event, "status.oddsCloseAtDate", oddsCloseAtDate);
  setObjVal(event, "status.displayShort", eventForm.displayShort);
  setObjVal(event, "status.displayLong", eventForm.displayLong);

  setObjVal(event, "status.started", eventForm.started);
  setObjVal(event, "status.delayed", eventForm.delayed);
  setObjVal(event, "status.finalized", false);
  setObjVal(event, "status.live", eventForm.started && !prevEvent?.status?.ended && !eventForm.delayed);

  setObjVal(event, "status.nextUpdateAt", new Date());

  setObjVal(event, "activity.count", prevEvent?.activity?.count || 0);
  setObjVal(event, "activity.score", prevEvent?.activity?.score || 0);

  (eventForm.propOddsList || []).forEach((propOddData) => {
    const { propIndex, propStatID, propTitle, available, propDetails, numericScoring, side1Display, side2Display, side1OddsDirection, side1OddsValue } = propOddData || {};
    if (!propStatID) return;

    const side1OddID = getOddID({ statID: propStatID, periodID: "game", betTypeID: "prop", sideID: "side1", statEntityID: "side1" });
    const side2OddID = getOddID({ statID: propStatID, periodID: "game", betTypeID: "prop", sideID: "side2", statEntityID: "side2" });
    if (!side1OddID || !side2OddID) return;

    const side1Odds = getOdds_fromDirectionValue(side1OddsDirection, side1OddsValue);
    const side2Odds = asMoneylineString(getInverseOdds(side1Odds));

    setObjVal(event, `props.${propStatID}.statID`, propStatID);
    setObjVal(event, `props.${propStatID}.propTitle`, propTitle);
    setObjVal(event, `props.${propStatID}.propDetails`, propDetails);
    setObjVal(event, `props.${propStatID}.numericScoring`, numericScoring);
    setObjVal(event, `props.${propStatID}.sides.side1.sideID`, "side1");
    setObjVal(event, `props.${propStatID}.sides.side1.sideDisplay`, side1Display);
    setObjVal(event, `props.${propStatID}.sides.side2.sideID`, "side2");
    setObjVal(event, `props.${propStatID}.sides.side2.sideDisplay`, side2Display);

    setObjVal(event, `odds.${side1OddID}.oddID`, side1OddID);
    setObjVal(event, `odds.${side1OddID}.statID`, propStatID);
    setObjVal(event, `odds.${side1OddID}.periodID`, "game");
    setObjVal(event, `odds.${side1OddID}.betTypeID`, "prop");
    setObjVal(event, `odds.${side1OddID}.sideID`, "side1");
    setObjVal(event, `odds.${side1OddID}.statEntityID`, "side1");
    setObjVal(event, `odds.${side1OddID}.available`, !!available);
    setObjVal(event, `odds.${side1OddID}.started`, eventForm.started);
    setObjVal(event, `odds.${side1OddID}.isFallbackOdds`, false);
    setObjVal(event, `odds.${side1OddID}.odds`, side1Odds);

    setObjVal(eventBookOdds, `odds.${side1OddID}.oddID`, side1OddID);
    setObjVal(eventBookOdds, `odds.${side1OddID}.statID`, propStatID);
    setObjVal(eventBookOdds, `odds.${side1OddID}.periodID`, "game");
    setObjVal(eventBookOdds, `odds.${side1OddID}.betTypeID`, "prop");
    setObjVal(eventBookOdds, `odds.${side1OddID}.sideID`, "side1");
    setObjVal(eventBookOdds, `odds.${side1OddID}.statEntityID`, "side1");
    setObjVal(eventBookOdds, `odds.${side1OddID}.fairOddsAvailable`, !!available);
    setObjVal(eventBookOdds, `odds.${side1OddID}.started`, eventForm.started);
    setObjVal(eventBookOdds, `odds.${side1OddID}.fairOdds`, side1Odds);

    setObjVal(event, `odds.${side2OddID}.oddID`, side2OddID);
    setObjVal(event, `odds.${side2OddID}.statID`, propStatID);
    setObjVal(event, `odds.${side2OddID}.periodID`, "game");
    setObjVal(event, `odds.${side2OddID}.betTypeID`, "prop");
    setObjVal(event, `odds.${side2OddID}.sideID`, "side2");
    setObjVal(event, `odds.${side2OddID}.statEntityID`, "side2");
    setObjVal(event, `odds.${side2OddID}.available`, available);
    setObjVal(event, `odds.${side2OddID}.started`, eventForm.started);
    setObjVal(event, `odds.${side2OddID}.isFallbackOdds`, false);
    setObjVal(event, `odds.${side2OddID}.odds`, side2Odds);

    setObjVal(eventBookOdds, `odds.${side2OddID}.oddID`, side2OddID);
    setObjVal(eventBookOdds, `odds.${side2OddID}.statID`, propStatID);
    setObjVal(eventBookOdds, `odds.${side2OddID}.periodID`, "game");
    setObjVal(eventBookOdds, `odds.${side2OddID}.betTypeID`, "prop");
    setObjVal(eventBookOdds, `odds.${side2OddID}.sideID`, "side2");
    setObjVal(eventBookOdds, `odds.${side2OddID}.statEntityID`, "side2");
    setObjVal(eventBookOdds, `odds.${side2OddID}.fairOddsAvailable`, !!available);
    setObjVal(eventBookOdds, `odds.${side2OddID}.started`, eventForm.started);
    setObjVal(eventBookOdds, `odds.${side2OddID}.fairOdds`, side2Odds);
  });

  ({ event, eventBookOdds } = refreshEvent(event, eventBookOdds));
  return { event, eventBookOdds };
};

const momentDateValidation = (fieldDisplayName, isNullable) => {
  const baseShape = isNullable ? Yup.mixed().nullable() : Yup.mixed();
  return baseShape.test("is-moment-object-or-null", `${fieldDisplayName} not a valid date`, (value) => (isNullable && value == null) || (moment.isMoment(value) && value.isValid()));
};

export const formFieldValidation = Yup.object().shape({
  eventID: Yup.string().required("Event ID is missing"),
  eventName: Yup.string(),
  sportID: Yup.string().oneOf(ENABLED_SPORT_IDS, "Sport ID is invalid or not enabled").required("Sport ID is Required"),
  leagueID: Yup.string().oneOf(ENABLED_LEAGUE_IDS, "League ID is invalid or not enabled").required("League ID is Required"),

  type: Yup.string().oneOf(["match", "prop"], "Type must be either 'match' or 'prop'").required("Event type is Required"),

  startsAt: momentDateValidation("Start date/time", false),
  hardStart: Yup.boolean().required("Hard Start is Required"),

  oddsCloseDate: momentDateValidation("Odds close date/time", true),

  oddsCloseType: Yup.string().required("Odds Close At Start is Required").oneOf(["start", "date", "manual"], "Odds close type must be either `start`, `date`, or `manual`"),

  displayShort: Yup.string(),
  displayLong: Yup.string(),

  started: Yup.boolean().required("Started must be Yes or No"),
  delayed: Yup.boolean().required("Delayed must be Yes or No"),
});

export const getOdds_fromDirectionValue = (direction, value) => {
  let valueInt = Math.abs(parseInt(value) || 100);
  if (!valueInt || valueInt < 100) valueInt = 100;
  if (valueInt > 10000) valueInt = 10000;
  return asMoneylineString(`${direction === "neg" ? "-" : "+"}${valueInt}`);
};

export const getDirectionValue_fromOdds = (odds) => {
  const oddsInt = asMoneylineInt(odds);
  const value = oddsInt ? Math.abs(oddsInt) : 100;
  const direction = oddsInt >= 0 ? "pos" : "neg";
  return { direction, value };
};

export const getOddsCloseType_fromOddsCloseStatus = (eventData) => {
  const oddsCloseAtStart = eventData?.status?.oddsCloseAtStart;
  const oddsCloseAtDate = eventData?.status?.oddsCloseAtDate;

  // See if the odds have been closed. If so, then reset it to manual
  const nowDate = new Date();
  const eventHasStarted = eventData?.status?.started || (isDate(eventData?.status?.startsAt) && nowDate > eventData?.status?.startsAt);
  const closedByCloseAtDate = isDate(oddsCloseAtDate) && nowDate > oddsCloseAtDate;
  const closedByStart = oddsCloseAtStart && eventHasStarted;
  const allOddsClosed = closedByCloseAtDate || closedByStart;
  if (allOddsClosed) return "manual";

  //Otherwise just go based on the setting
  if (!oddsCloseAtStart && isDate(oddsCloseAtDate)) return "date";
  return oddsCloseAtStart ? "start" : "manual";
};

export const getOddsCloseAtStatus_fromOddsCloseType = (oddsCloseType, oddsCloseDate) => {
  const oddsCloseAtDate = oddsCloseDate && moment.isMoment(oddsCloseDate) && oddsCloseDate.isValid() ? oddsCloseDate.toDate() : null;
  if (oddsCloseType === "date" && isDate(oddsCloseAtDate)) return { oddsCloseAtStart: false, oddsCloseAtDate };
  if (oddsCloseType === "manual") return { oddsCloseAtStart: false, oddsCloseAtDate: false };
  return { oddsCloseAtStart: true, oddsCloseAtDate: false };
};

export const getPropOddsList_fromEvent = (eventData) => {
  return Object.entries(eventData?.props || {})
    .reduce((propOddsList, [propID, propData]) => {
      if (!propOddsList) return null;
      const { statID, propTitle, propDetails, numericScoring, sides } = propData || {};
      const propStatID = statID || propID;
      if (!propStatID) return propOddsList;
      const side1Display = sides?.side1?.sideDisplay;
      const side2Display = sides?.side2?.sideDisplay;
      const propIndex = parseInt(propStatID.match(/prop(\d+)/)[1]);
      if (!isInteger(propIndex)) return null;

      const sharedOddIdentifiers = {
        statID: propStatID,
        periodID: "game",
        betTypeID: "prop",
      };

      const side1OddIdentifiers = { ...sharedOddIdentifiers, sideID: "side1", statEntityID: "side1" };
      const side2OddIdentifiers = { ...sharedOddIdentifiers, sideID: "side2", statEntityID: "side2" };

      const side1OddID = getOddID(side1OddIdentifiers);
      const side2OddID = getOddID(side2OddIdentifiers);

      const side1OddData = eventData?.odds?.[side1OddID];
      const side2OddData = eventData?.odds?.[side2OddID];

      const side1DirectionValue = getDirectionValue_fromOdds(side1OddData?.odds);
      const side2DirectionValue = getDirectionValue_fromOdds(side2OddData?.odds); // This could be used for error checking or as a backup. Not using it right now since we just use inverse odds.

      propOddsList.push({
        propIndex,
        propStatID: propStatID || "",
        propTitle: propTitle || "",
        propDetails: propDetails || "",
        available: !!(side1OddData?.available && side2OddData?.available),
        numericScoring: !!numericScoring,
        side1Display: side1Display || "",
        side1OddsDirection: side1DirectionValue.direction || "pos",
        side1OddsValue: side1DirectionValue.value || 100,
        side2Display: side2Display || "",
      });
      return propOddsList;
    }, [])
    .sort((a, b) => a?.propIndex - b?.propIndex);
};
