//getMainConfig, updateMainConfig

import { deepQueryCollection, deleteCollectionDoc, getCollectionDoc, setCollectionDoc, updateCollectionDoc } from "@wagerlab/utils/database/firestore";
import { EVENT_FINALIZED_STATES, EVENT_TYPES } from "@wagerlab/admin/src/utils/events/constants";
import { eventToEventShort } from "@wagerlab/utils/events/eventShorts";
import { dataChanged } from "@wagerlab/utils/data/mutations";

export const getMainConfig = async () => {
  return await getCollectionDoc("Config", "main");
};

export const updateMainConfig = async (updates) => {
  return await updateCollectionDoc("Config", "main", updates);
};
