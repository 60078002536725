let firebaseModule = null;
let loggerModule = null;

exports.initialize = (logger, firebase) => {
  firebaseModule = firebase;
  loggerModule = logger;
};

exports.getFirebaseModule = () => firebaseModule;
exports.getLoggerModule = () => loggerModule;
