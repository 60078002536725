const { isArray, isString, stringLength } = require("@wagerlab/utils/data/types");
const { parsePhoneNumberFromString, parseIncompletePhoneNumber, formatIncompletePhoneNumber } = require("libphonenumber-js/max");

const VALID_TEST_NUMBERS = ["+15555228243", "+15556106679"];

const VALID_PHONE_NUMBER_TYPES = [null, "MOBILE", "FIXED_LINE_OR_MOBILE", "VOIP", "PERSONAL_NUMBER"];

const parsePhoneNumber = (phoneNumber, countryCode = null) => (phoneNumber && isString(phoneNumber) && parsePhone("E.164", phoneNumber, countryCode)) || "";
exports.parsePhoneNumber = parsePhoneNumber;

const parsePhoneDisplay = (phoneNumber, countryCode = null) => (phoneNumber && isString(phoneNumber) && parsePhone("NATIONAL", phoneNumber, countryCode)) || "";
exports.parsePhoneDisplay = parsePhoneDisplay;

const validatePhoneNumber = (phoneNumber, countryCode = null) => {
  if (!phoneNumber) return "Phone number missing";
  if (!isString(phoneNumber)) return "Invalid mobile phone number value";
  const parsedNumber = parsePhoneNumber(phoneNumber, countryCode);
  if (!parsedNumber) return "Invalid mobile phone number";
  return "";
};
exports.validatePhoneNumber = validatePhoneNumber;

const parsePhone = (format, phoneNumber, countryCode) => {
  if (!phoneNumber || !isString(phoneNumber)) return "";
  if (VALID_TEST_NUMBERS.includes(phoneNumber)) return parsePhoneNumberFromString(phoneNumber)?.format?.(format) || phoneNumber;
  let parsedPhoneNumber = countryCode ? parsePhoneNumberFromString(phoneNumber, countryCode) : parsePhoneNumberFromString(phoneNumber);
  let isValid = isValidPhoneNumberObj(parsedPhoneNumber);
  if (!isValid) parsedPhoneNumber = countryCode ? parsePhoneNumberFromString(phoneNumber) : parsePhoneNumberFromString(phoneNumber, "US");
  isValid = isValidPhoneNumberObj(parsedPhoneNumber);
  if (!isValid && !phoneNumber?.startsWith("+")) parsedPhoneNumber = parsePhoneNumberFromString(`+${phoneNumber}`);
  isValid = isValidPhoneNumberObj(parsedPhoneNumber);
  const formattedPhoneNumber = isValid ? parsedPhoneNumber?.format?.(format) : "";
  return formattedPhoneNumber || "";
};

const isValidPhoneNumberObj = (phoneNumObj) => !!(phoneNumObj?.isPossible?.() && isValidType(phoneNumObj?.getType?.()));

// Using == for equality is important here
const isValidType = (type) => VALID_PHONE_NUMBER_TYPES.some((validType) => validType == type);

const parsePhoneInput = (prevInput, newInput, countryCode = null) => {
  const prevInputLength = stringLength(prevInput);
  const newInputLength = stringLength(newInput);

  if (!isString(newInput) || !newInputLength) return { input: "", display: "", number: "" };

  const number = parseIncompletePhoneNumber(newInput) || "";
  const display = formatIncompletePhoneNumber(number, countryCode || "US") || "";
  const input = newInputLength < prevInputLength || (!display && newInput) ? newInput : display;

  return { input, display, number };
};
exports.parsePhoneInput = parsePhoneInput;
