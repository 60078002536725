import { useQueries, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { getEvent, getEventBookOdds } from "@wagerlab/admin/src/utils/events/database";

const EVENT_STALE_MINUTES = 15;
const EVENT_STALE_MS = EVENT_STALE_MINUTES * 60 * 1000;
const EVENT_AUTOREFRESH_MINUTES = 20;
const EVENT_AUTOREFRESH_MS = EVENT_AUTOREFRESH_MINUTES * 60 * 1000;

export const useEventsQuery = (eventID) => {
  const [currentEventID, setCurrentEventID] = useState(eventID || "");
  useEffect(() => {
    setCurrentEventID(eventID);
  }, [eventID]);
  const [{ data: fetchedEvent, isLoading: isEventLoading, isFetching: isEventFetching }, { data: fetchedEventBookOdds, isLoading: isEventBookOddsLoading, isFetching: isEventBookOddsFetching }] =
    useQueries({
      queries: [
        {
          queryKey: ["event", currentEventID],
          queryFn: () => getEvent(currentEventID),
          staleTime: EVENT_STALE_MS,
          refetchOnWindowFocus: true,
          refetchOnReconnect: true,
          refetchOnMount: true,
          refetchInterval: EVENT_AUTOREFRESH_MS,
          refetchIntervalInBackground: false,
        },
        {
          queryKey: ["eventBookOdds", currentEventID],
          queryFn: () => getEventBookOdds(currentEventID),
          staleTime: EVENT_STALE_MS,
          refetchOnWindowFocus: true,
          refetchOnReconnect: true,
          refetchOnMount: true,
          refetchInterval: EVENT_AUTOREFRESH_MS,
          refetchIntervalInBackground: false,
        },
      ],
    });

  const isLoading = isEventLoading || isEventBookOddsLoading;
  const isFetching = isEventFetching || isEventBookOddsFetching;
  const loadingEvent = isLoading || isFetching;
  return { fetchedEvent, fetchedEventBookOdds, loadingEvent };
};
