const { logError, logRead, logWrite, logCounter } = require("@wagerlab/utils/logging");
const { isString, isInteger, isArray, isObject, isPositiveInteger } = require("@wagerlab/utils/data/types");
const { getSizeOfDocument } = require("@wagerlab/utils/data/size");
const { getFirebaseModule } = require("@wagerlab/utils/initialize");

const initRequest = (operationType, refType, ...requestArgs) => {
  let refArgs = [];
  if (refType === "collection-group") refArgs = (requestArgs || []).slice(0, 1);
  if (refType === "collection") refArgs = (requestArgs || []).slice(0, 1);
  if (refType === "collection-doc") refArgs = (requestArgs || []).slice(0, 2);
  if (refType === "sub-collection") refArgs = (requestArgs || []).slice(0, 3);
  if (refType === "sub-collection-doc") refArgs = (requestArgs || []).slice(0, 4);

  const ref = refType === "collection-group" ? collectionGroupReference(...refArgs) : reference(...refArgs);

  const argsString = (refArgs || []).filter((refArg) => refArg && isString(refArg)).join(" -> ");
  let logName = `${operationType || "UNKNOWN_OPERATION_TYPE"}-${refType || "UNKNOWN_REF_TYPE"} @ ${argsString?.length ? argsString : "NO_REF"}`;

  if (!ref || !logName || requestArgs.some((arg) => !arg)) {
    invalid(logName, ...requestArgs);
    return { invalidParams: true };
  }

  return { ref, logName, invalidParams: false };
};

const initQueryRequest = (operationType, refType, queryParts, ...requestArgs) => {
  let { ref, logName, invalidParams } = initRequest(operationType, refType, ...requestArgs);
  if (invalidParams) return { invalidParams: true };
  const queryLogName = (queryParts || [])
    .map((queryPart) => {
      const { key, operator, value } = queryPart || {};
      return `(${key || "NO_KEY"} ${operator || "NO_OPERATOR"} ${value})`;
    })
    .join(" && ");
  logName = `${logName} ${queryLogName}`;
  return { ref, logName, invalidParams: false };
};

exports.getCollectionDoc = async (collectionName, collectionID) => {
  const { logName, ref, invalidParams } = initRequest("get", "collection-doc", collectionName, collectionID);
  if (invalidParams) return null;
  return ref
    .get()
    .then((docSnap) => {
      return success(docSnap, "get", logName);
    })
    .catch((err) => {
      return failure(err, "get", logName);
    });
};

exports.getSubCollectionDoc = async (collectionName, collectionID, subCollectionName, subCollectionID) => {
  const { logName, ref, invalidParams } = initRequest("get", "sub-collection-doc", collectionName, collectionID, subCollectionName, subCollectionID);
  if (invalidParams) return null;
  return ref
    .get()
    .then((docSnap) => success(docSnap, "get", logName))
    .catch((err) => failure(err, "get", logName));
};

exports.getAllCollectionDocs = async (collectionName) => {
  const { logName, ref, invalidParams } = initRequest("get-all", "collection", collectionName);
  if (invalidParams) return null;
  return ref
    .get()
    .then((querySnap) => success(querySnap, "get-all", logName))
    .catch((err) => failure(err, "get-all", logName));
};

exports.setCollectionDoc = async (collectionName, collectionID, value, merge = false) => {
  const { logName, ref, invalidParams } = initRequest("set", "collection-doc", collectionName, collectionID, value);
  if (invalidParams) return null;
  return ref
    .set(value, { merge: !!merge })
    .then((r) => success(r, "set", logName, value))
    .catch((err) => failure(err, "set", logName, value));
};

exports.setSubCollectionDoc = async (collectionName, collectionID, subCollectionName, subCollectionID, value, merge = false) => {
  const { logName, ref, invalidParams } = initRequest("set", "sub-collection-doc", collectionName, collectionID, subCollectionName, subCollectionID, value);
  if (invalidParams) return null;
  return ref
    .set(value, { merge: !!merge })
    .then((r) => success(r, "set", logName, value))
    .catch((err) => failure(err, "set", logName, value));
};

exports.updateCollectionDoc = async (collectionName, collectionID, updates) => {
  const { logName, ref, invalidParams } = initRequest("update", "collection-doc", collectionName, collectionID, updates);
  if (invalidParams) return null;
  return ref
    .update(updates)
    .then((r) => success(r, "update", logName, updates))
    .catch((err) => failure(err, "update", logName, updates));
};

exports.updateSubCollectionDoc = async (collectionName, collectionID, subCollectionName, subCollectionID, updates) => {
  const { logName, ref, invalidParams } = initRequest("update", "sub-collection-doc", collectionName, collectionID, subCollectionName, subCollectionID, updates);
  if (invalidParams) return null;
  return ref
    .update(updates)
    .then((r) => success(r, "update", logName, updates))
    .catch((err) => failure(err, "update", logName, updates));
};

exports.deleteCollectionDoc = async (collectionName, collectionID) => {
  const { logName, ref, invalidParams } = initRequest("delete", "collection-doc", collectionName, collectionID);
  if (invalidParams) return null;
  return ref
    .delete()
    .then((r) => success(r, "delete", logName))
    .catch((err) => failure(err, "delete", logName));
};

exports.deleteSubCollectionDoc = async (collectionName, collectionID, subCollectionName, subCollectionID) => {
  const { logName, ref, invalidParams } = initRequest("delete", "sub-collection-doc", collectionName, collectionID, subCollectionName, subCollectionID);
  if (invalidParams) return null;
  return ref
    .delete()
    .then((r) => success(r, "delete", logName))
    .catch((err) => failure(err, "delete", logName));
};

exports.queryCollection = async (collectionName, queryKey, queryOperator, queryValue, orderByField = "", orderByType = "asc", limit = 0, selectList = []) => {
  const queryParts = [{ key: queryKey, operator: queryOperator, value: queryValue }];
  const { logName, ref, invalidParams } = initQueryRequest("query", "collection", queryParts, collectionName);
  if (invalidParams) return null;
  return runQuery(ref, queryParts, orderByField, orderByType, limit, null, false, logName, null, selectList);
};

exports.querySubCollection = async (collectionName, collectionID, subCollectionName, queryKey, queryOperator, queryValue, orderByField = "", orderByType = "asc", limit = 0) => {
  const queryParts = [{ key: queryKey, operator: queryOperator, value: queryValue }];
  const { logName, ref, invalidParams } = initQueryRequest("query", "sub-collection", queryParts, collectionName, collectionID, subCollectionName);
  if (invalidParams) return null;
  return runQuery(ref, queryParts, orderByField, orderByType, limit, null, false, logName);
};

exports.deepQueryCollection = async (collectionName, queryParts, orderByField = "", orderByType = "asc", limit = 0) => {
  const { logName, ref, invalidParams } = initQueryRequest("query", "collection", queryParts, collectionName);
  if (invalidParams) return null;
  return runQuery(ref, queryParts, orderByField, orderByType, limit, null, false, logName);
};

exports.queryCollectionGroup = async (collectionGroupName, queryKey, queryOperator, queryValue, orderByField = "", orderByType = "asc", limit = 0) => {
  const queryParts = [{ key: queryKey, operator: queryOperator, value: queryValue }];
  const { logName, ref, invalidParams } = initQueryRequest("query", "collection-group", queryParts, collectionGroupName);
  if (invalidParams) return null;
  return runQuery(ref, queryParts, orderByField, orderByType, limit, null, false, logName);
};

exports.queryCollectionCursor = async (collectionName, queryKey, queryOperator, queryValue, startAfterSnap, orderByField = "", orderByType = "asc", limit = 0) => {
  const queryParts = [{ key: queryKey, operator: queryOperator, value: queryValue }];
  const { logName, ref, invalidParams } = initQueryRequest("cursor-query", "collection-group", queryParts, collectionName);
  if (invalidParams) return null;
  return runQuery(ref, queryParts, orderByField, orderByType, limit, startAfterSnap, true, logName);
};

exports.querySubCollectionCursor = async (collectionName, collectionID, subCollectionName, queryKey, queryOperator, queryValue, startAfterSnap, orderByField = "", orderByType = "asc", limit = 0) => {
  const queryParts = [{ key: queryKey, operator: queryOperator, value: queryValue }];
  const { logName, ref, invalidParams } = initQueryRequest("cursor-query", "sub-collection", queryParts, collectionName, collectionID, subCollectionName);
  if (invalidParams) return null;
  return runQuery(ref, queryParts, orderByField, orderByType, limit, startAfterSnap, true, logName);
};

exports.deepQueryCollectionCursor = async (collectionName, queryParts, startAfter = null, orderByField = "", orderByType = "asc", limit = 0, endBefore = null, selectList = []) => {
  const { logName, ref, invalidParams } = initQueryRequest("cursor-query", "collection", queryParts, collectionName);
  if (invalidParams) return null;
  return runQuery(ref, queryParts, orderByField, orderByType, limit, startAfter, true, logName, endBefore, selectList);
};

exports.queryCollectionGroupCursor = async (collectionGroupName, queryKey, queryOperator, queryValue, startAfterSnap, orderByField = "", orderByType = "asc", limit = 0) => {
  const queryParts = [{ key: queryKey, operator: queryOperator, value: queryValue }];
  const { logName, ref, invalidParams } = initQueryRequest("cursor-query", "collection-group", queryParts, collectionGroupName);
  if (invalidParams) return null;
  return runQuery(ref, queryParts, orderByField, orderByType, limit, startAfterSnap, true, logName);
};

exports.customQueryCollection = async (collectionName, customQuery) => {
  const { logName, ref, invalidParams } = initRequest("query", "collection", collectionName);
  if (invalidParams) return null;
  return ref
    .where(customQuery)
    .get()
    .then((querySnap) => success(querySnap, "query", logName))
    .catch((err) => failure(err, "query", logName));
};

const PRIMARY_ORDER_OPERATORS = ["<", "<=", ">", ">=", "!="];
const runQuery = async (queryRef, queryParts, orderByField, orderByType, limit, startAfter, useCursor, logName, endBefore, selectList) => {
  if (!queryParts?.length || queryParts.some((part) => !part?.key || !part?.operator || part?.value == null)) return invalid(logName, queryParts);
  if (useCursor && !orderByField) return invalid(logName, useCursor, orderByField);

  let query = queryParts.reduce((combinedQuery, queryPart) => combinedQuery.where(queryPart.key, queryPart.operator, queryPart.value), queryRef);

  const primaryOrderField = queryParts.find(({ operator }) => PRIMARY_ORDER_OPERATORS.includes(operator))?.key || "";
  const orderByArray = isArray(orderByField) ? orderByField : orderByField ? [orderByField] : [];
  let hasCheckedPrimaryOrder = false;
  orderByArray.forEach((orderByFieldName, i) => {
    if (!orderByFieldName) return;
    const orderByTypeItem = isArray(orderByType) ? orderByType[i] : orderByType;
    const orderByDirection = orderByTypeItem === "asc" || orderByTypeItem === "desc" ? orderByTypeItem : "asc";
    if (!hasCheckedPrimaryOrder && orderByFieldName && primaryOrderField && primaryOrderField !== orderByFieldName) query = query.orderBy(primaryOrderField, orderByDirection);
    hasCheckedPrimaryOrder = true;
    query = query.orderBy(orderByFieldName, orderByDirection);
  });

  let useLimitToLast = false;
  if (useCursor && hasCheckedPrimaryOrder) {
    if (startAfter) {
      query = isArray(startAfter) ? query.startAfter(...startAfter) : query.startAfter(startAfter);
    } else if (endBefore) {
      query = isArray(endBefore) ? query.endBefore(...endBefore) : query.endBefore(endBefore);
      useLimitToLast = true;
    }
  }

  if (isPositiveInteger(limit)) query = useLimitToLast ? query.limitToLast(limit) : query.limit(limit);

  const actionType = useCursor ? "cursor-query" : "query";
  return selectList?.length ?
    query
      .select(...selectList)
      .get()
      .then((querySnap) => success(querySnap, actionType, logName))
      .catch((err) => failure(err, actionType, logName)) :
    query
      .get()
      .then((querySnap) => success(querySnap, actionType, logName))
      .catch((err) => failure(err, actionType, logName));
};

const MAX_BATCH_SIZE_ITEMS = Math.floor(500 * 0.75);
const MAX_BATCH_SIZE_BYTES = Math.floor(1048576 * 0.75);
exports.writeBatch = async (batchList, multiBatchStrategy = "PARALLEL") => {
  if (!batchList?.length) {
    invalid("write-batch", batchList);
    return { allSuccess: false, someSuccess: false, results: [] };
  }
  const batchListChunks = [];
  let currentChunk = [];
  let currentChunkSizeItems = 0;
  let currentChunkSizeBytes = 0;
  for (let i = 0; i < batchList.length; i++) {
    const batchItem = batchList[i] || {};
    let { method, collectionName, collectionID, subCollectionName, subCollectionID, payload } = batchItem;
    const sizeOfItem = getSizeOfDocument(payload, collectionName, collectionID, subCollectionName, subCollectionID);
    const sizeItemsIfAdded = currentChunkSizeItems + 1;
    const sizeBytesIfAdded = currentChunkSizeBytes + sizeOfItem;

    const shouldAdd = sizeItemsIfAdded <= MAX_BATCH_SIZE_ITEMS && sizeBytesIfAdded <= MAX_BATCH_SIZE_BYTES;
    if (shouldAdd) {
      currentChunk.push(batchItem);
      currentChunkSizeItems = sizeItemsIfAdded;
      currentChunkSizeBytes = sizeBytesIfAdded;
    } else {
      if (currentChunk.length) batchListChunks.push(currentChunk);
      currentChunk = [batchItem];
      currentChunkSizeItems = 1;
      currentChunkSizeBytes = sizeOfItem;
    }
  }
  if (currentChunk.length) batchListChunks.push(currentChunk);

  let batchWriteResult = [];
  if (multiBatchStrategy === "SEQUENTIAL_ALL" || multiBatchStrategy === "SEQUENTIAL_STOP_ON_ERROR") {
    let hasFailure = false;
    for (let batchListChunkIndex = 0; batchListChunkIndex < batchListChunks.length; batchListChunkIndex++) {
      if (hasFailure && multiBatchStrategy === "SEQUENTIAL_STOP_ON_ERROR") {
        batchWriteResult.push(false);
        continue;
      }
      const batchListChunk = batchListChunks[batchListChunkIndex];
      const isSoloBatch = batchListChunks.length === 1 && batchListChunkIndex === 0;
      const retries = isSoloBatch ? 0 : 3;
      const chunkResult = await writeSingleBatch(batchListChunk, isSoloBatch, retries);
      hasFailure = hasFailure || !chunkResult;
      batchWriteResult.push(chunkResult);
    }
  } else {
    const batchChunkPromises = batchListChunks.map((batchListChunk, batchListChunkIndex) => {
      const isSoloBatch = batchListChunks.length === 1 && batchListChunkIndex === 0;
      const retries = isSoloBatch ? 0 : 3;
      return writeSingleBatch(batchListChunk, isSoloBatch, retries);
    });
    batchWriteResult = await Promise.all(batchChunkPromises);
  }

  let hasFailure = false;
  let hasSuccess = false;
  let results = [];
  batchWriteResult.forEach((batchChunkSuccess, chunkIndex) => {
    if (batchChunkSuccess) {
      hasSuccess = true;
    } else {
      hasFailure = true;
    }
    const chunkList = batchListChunks[chunkIndex] || [];
    results.push(...chunkList.map((batchItem) => !!batchChunkSuccess));
  });

  return { allSuccess: !!hasSuccess && !hasFailure, someSuccess: !!hasSuccess, results };
};
const writeSingleBatch = async (batchList, isSingleBatch, retries) => {
  const firebase = getFirebaseModule();
  const batchWriter = firebase.firestore().batch();
  let hasError = false;
  let numWrites = 0;
  (batchList || []).forEach((batchItem) => {
    let { method, collectionName, collectionID, subCollectionName, subCollectionID, payload } = batchItem || {};

    if (method !== "create" && method !== "set" && method !== "update" && method !== "delete") method = "";
    if (method === "create" && subCollectionName) subCollectionID = reference(collectionName, collectionID, subCollectionName)?.doc?.()?.id;
    if (method === "create" && !subCollectionName) collectionID = reference(collectionName)?.doc?.()?.id;
    if (method === "delete") payload = true;

    const refType = subCollectionName || subCollectionID ? "sub-collection-doc" : "collection-doc";
    const refArgs = refType === "sub-collection-doc" ? [collectionName, collectionID, subCollectionName, subCollectionID] : [collectionName, collectionID];

    const { logName, ref, invalidParams } = initRequest(`batch-write-${method || "?"}`, refType, ...refArgs, method, payload);

    if (invalidParams) {
      hasError = true;
      return invalid(logName, batchItem);
    }

    if (method === "set" || method === "create") {
      batchWriter.set(ref, payload);
      numWrites++;
    } else if (method === "delete") {
      batchWriter.delete(ref);
      numWrites++;
    } else if (method === "update") {
      batchWriter.update(ref, payload);
      numWrites++;
    }
  });
  // if (hasError && isSingleBatch) return null;
  if (hasError) return null;
  return batchWriter
    .commit()
    .then((r) => {
      success(r, "batch-write", numWrites, batchList);
      return true;
    })
    .catch((err) => {
      if (retries > 0) return writeSingleBatch(batchList, isSingleBatch, retries - 1);
      failure(err, "batch-write", numWrites, batchList);
      return false;
    });
};

const MAX_READ_BATCH_SIZE_ITEMS = Math.floor(500 * 0.75);
exports.readBatch = async (collectionName, queryParts) => {
  const { logName, ref, invalidParams } = initQueryRequest("read-batch", "collection", queryParts, collectionName);
  if (invalidParams) return null;
  const orderByField = queryParts[0]?.key;
  return await readNextBatch(collectionName, queryParts, orderByField, ref, logName);
};
const readNextBatch = async (collectionName, queryParts, orderByField, ref, logName, prevItems = [], prevSnap = null, batchIndex = 0) => {
  if (!prevSnap && batchIndex > 0) return prevItems;
  const batchResponse = await runQuery(ref, queryParts, orderByField, "", MAX_READ_BATCH_SIZE_ITEMS, prevSnap, true, logName);
  if (!batchResponse) return null;
  const { values, lastSnap } = batchResponse;
  const valuesToAdd = isArray(values) ? values : [];
  const newPrevSnap = valuesToAdd.length < MAX_READ_BATCH_SIZE_ITEMS ? null : lastSnap;
  const newPrevItems = [...prevItems, ...valuesToAdd];
  return await readNextBatch(collectionName, queryParts, orderByField, ref, logName, newPrevItems, newPrevSnap, batchIndex + 1);
};

exports.setWithSWID = (setValue) => {
  if (!isObject(setValue)) return setValue;
  const swid = (setValue.swid || 0) + 1;
  return { ...setValue, swid };
};

exports.updateWithSWID = (updateValue) => {
  if (!isObject(updateValue)) return updateValue;
  const firebase = getFirebaseModule();
  updateValue.swid = firebase.firestore.FieldValue.increment(1);
  return updateValue;
};

exports.deleteValue = () => {
  const firebase = getFirebaseModule();
  return firebase.firestore.FieldValue.delete();
};

exports.serverTimeValue = () => {
  const firebase = getFirebaseModule();
  return firebase.firestore.FieldValue.serverTimestamp();
};

exports.incrementValue = (incrementBy) => {
  const firebase = getFirebaseModule();
  return firebase.firestore.FieldValue.increment(incrementBy);
};

exports.ref = (collectionName, collectionID = "", subCollectionName = "", subCollectionID = "") => {
  return reference(collectionName, collectionID, subCollectionName, subCollectionID);
};

exports.docListener = (ref, onValue, onError) => {
  const onParsedValue = (docSnap) => onValue(parseDocument(docSnap));
  return ref?.onSnapshot?.(onParsedValue, onError);
};

exports.queryListener = (query, onValue, onError) => {
  const onParsedValue = (querySnap) => onValue(parseQuery(querySnap));
  return query?.onSnapshot?.(onParsedValue, onError);
};

exports.newID = (collectionName, collectionID = "", subCollectionName = "") => {
  const ref = collectionID || subCollectionName ? reference(collectionName, collectionID, subCollectionName) : reference(collectionName);
  return ref?.doc?.()?.id || null;
};

// Helpers

const reference = (collectionName, collectionID, subCollectionName, subCollectionID) => {
  const firebase = getFirebaseModule();
  let ref = null;
  if (collectionName) ref = firebase?.firestore().collection(collectionName);
  if (collectionName && collectionID) ref = ref?.doc(collectionID);
  if (collectionName && collectionID && subCollectionName) ref = ref.collection(subCollectionName);
  if (collectionName && collectionID && subCollectionName && subCollectionID) ref = ref.doc(subCollectionID);
  return ref;
};

const collectionGroupReference = (collectionGroupName) => {
  if (!collectionGroupName) return null;
  const firebase = getFirebaseModule();
  return firebase.firestore().collectionGroup(collectionGroupName);
};

const parseDocument = (docSnap) => {
  if (!docSnap?.exists || !docSnap?.id) return null;
  const docData = docSnap?.data?.() ?? null;
  return convertTimestampsToDates(docData);
};
exports.parseDocument = parseDocument;

const parseQuery = (querySnap) => {
  if (!querySnap || querySnap.empty || !querySnap.forEach) return [];
  const items = [];
  querySnap.forEach((docSnap) => {
    const parsedDocument = parseDocument(docSnap);
    if (parsedDocument != null) items.push(parsedDocument);
  });
  return items;
};
exports.parseQuery = parseQuery;

const parseCursorQuery = (querySnap) => {
  if (!querySnap?.forEach || querySnap.empty) return { values: [], lastSnap: null };
  const values = [];
  let lastSnap = null;
  querySnap.forEach((docSnap) => {
    const parsedDocument = parseDocument(docSnap);
    values.push(parsedDocument);
    lastSnap = docSnap;
  });
  return { values, lastSnap };
};
exports.parseCursorQuery = parseCursorQuery;

const success = (response, actionType, logName, ...requestData) => {
  let parsedResponse = null;
  let numOps = 1;
  if (actionType === "set" || actionType === "update" || actionType === "delete") {
    parsedResponse = true;
    numOps = 1;
  } else if (actionType === "batch-write") {
    parsedResponse = true;
    numOps = parseInt(logName) || 1;
  } else if (actionType === "get") {
    parsedResponse = parseDocument(response);
    numOps = 1;
  } else if (actionType === "query" || actionType === "get-all") {
    parsedResponse = parseQuery(response);
    numOps = parsedResponse?.length || 1;
  } else if (actionType === "cursor-query") {
    parsedResponse = parseCursorQuery(response);
    numOps = parsedResponse?.values?.length || 1;
  }

  const isWrite = actionType === "set" || actionType === "update" || actionType === "delete" || actionType === "batch-write";

  let successMessage = `Successful ${actionType}`;
  if (isArray(parsedResponse) && parsedResponse.length === 0) successMessage = "No docs returned";
  if (actionType === "batch-write") successMessage = `Docs written in batch`;
  const logMessage = `${successMessage}: ${logName}`;

  if (isWrite) {
    logWrite(logMessage, parsedResponse, ...requestData);
    logCounter("FIRESTORE_WRITE", numOps);
  } else {
    logRead(logMessage, parsedResponse, ...requestData);
    logCounter("FIRESTORE_READ", numOps);
  }

  return parsedResponse;
};

// Failures always return null
const failure = (err, actionType, logName, ...requestData) => {
  logError(`Failed ${actionType}: ${logName} FIRESTORE`, err, ...requestData);
  return null;
};

const invalid = (logName, ...requestData) => {
  logError(`Invalid params: ${logName}`, ...requestData);
  return null;
};

const isFirestoreTimestamp = (item) => typeof item?.toDate === "function";

const convertTimestampsToDates = (data) => {
  if (!data) return data;
  if (isFirestoreTimestamp(data)) return data.toDate();
  if (isArray(data)) {
    data = data.map((item) => convertTimestampsToDates(item));
  }
  if (isObject(data)) {
    Object.keys(data).forEach((key) => {
      data[key] = convertTimestampsToDates(data[key]);
    });
  }
  return data;
};
