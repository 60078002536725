const { LEAGUE_CONFIG } = require("@wagerlab/utils/sports/leagueConfig");
const { setObjVal } = require("@wagerlab/utils/data/mutations");
const { isArray, isString } = require("@wagerlab/utils/data/types");
const { getTeamDisplay, getPlayerDisplay } = require("@wagerlab/utils/displays");
const _ = require("lodash");

const formatLookups = (lookupData) => {
  Object.entries(lookupData?.overrides || {}).forEach(([overriddenTerm, overriddenID]) => {
    if (!overriddenTerm) {
      _.unset(lookupData, `overrides.${overriddenTerm}`);
      return;
    }
    if (overriddenID && lookupData?.searches?.[overriddenTerm] !== overriddenID) _.set(lookupData, `searches.${overriddenTerm}`, overriddenID);
    if (!overriddenID && lookupData?.searches?.[overriddenTerm]) _.unset(lookupData, `searches.${overriddenTerm}`);
    if (lookupData?.clashes?.[overriddenTerm]) _.unset(lookupData, `clashes.${overriddenTerm}`);
  });
  Object.keys(lookupData?.clashes || {}).forEach((clashingTerm) => {
    if (!clashingTerm) {
      _.unset(lookupData, `clashes.${clashingTerm}`);
      return;
    }
    if (!isArray(lookupData?.clashes?.[clashingTerm])) _.set(lookupData, `clashes.${clashingTerm}`, []);
    const singleClashID = lookupData.clashes[clashingTerm].length === 1 ? lookupData?.clashes?.[clashingTerm][0] : null;
    const searchID = lookupData?.searches?.[clashingTerm];
    if (searchID && !lookupData.clashes[clashingTerm].includes(searchID)) _.set(lookupData, `clashes.${clashingTerm}`, [...lookupData.clashes[clashingTerm], searchID]);
    const shouldSetSingle = singleClashID && (!searchID || singleClashID === searchID);
    if (shouldSetSingle) {
      _.unset(lookupData, `clashes.${clashingTerm}`);
      _.set(lookupData, `searches.${clashingTerm}`, singleClashID);
      return;
    } else if (!lookupData?.clashes?.[clashingTerm]?.length) {
      _.unset(lookupData, `clashes.${clashingTerm}`);
    } else {
      _.unset(lookupData, `searches.${clashingTerm}`);
    }
  });
  return { searches: lookupData?.searches || {}, clashes: lookupData?.clashes || {}, overrides: lookupData?.overrides || {} };
};
exports.formatLookups = formatLookups;

exports.setOverrideLookup = (lookupData, lookupStringRaw, lookupID) => {
  const lookupString = getSearchableString(lookupStringRaw);
  if (!lookupString || !lookupID) return lookupData;
  _.unset(lookupData, `clashes.${lookupString}`);
  _.unset(lookupData, `searches.${lookupString}`);
  setObjVal(lookupData, `overrides.${lookupString}`, lookupID);
  return lookupData;
};

const setSearchLookup = (lookupData, lookupStringRaw, lookupID) => {
  const lookupString = getSearchableString(lookupStringRaw);
  if (!lookupString || !lookupID) return lookupData;
  const overrideID = lookupData?.overrides?.[lookupString];

  // If an override is set (even a blank one) do nothing
  if (isString(overrideID)) return lookupData;

  const searchID = lookupData?.searches?.[lookupString];
  const clashIDs = lookupData?.clashes?.[lookupString] || [];
  const isClash = clashIDs?.length > 1 || (searchID && searchID !== lookupID);
  if (isClash) {
    if (!clashIDs.includes(searchID)) clashIDs.push(searchID);
    if (!clashIDs.includes(lookupID)) clashIDs.push(lookupID);
    setObjVal(lookupData, `clashes.${lookupString}`, clashIDs);
    if (searchID) _.unset(lookupData, `searches.${lookupString}`);
    return lookupData;
  } else {
    if (searchID === lookupID) return lookupData;
    setObjVal(lookupData, `searches.${lookupString}`, lookupID);
  }

  return lookupData;
};
exports.setSearchLookup = setSearchLookup;

exports.getSearchLookup = (lookupData, lookupStringRaw) => {
  const lookupString = getSearchableString(lookupStringRaw);
  if (!lookupString || !lookupData) return null;
  if (isString(lookupData?.overrides?.[lookupString])) return lookupData?.overrides?.[lookupString] || null;
  if (isString(lookupData?.searches?.[lookupString])) return lookupData?.searches?.[lookupString] || null;
  return null;
};

exports.getAllLookupEntityIDs = (lookupData) => {
  const searchesIDs = Object.values(lookupData?.searches || {}).filter((id) => id);
  const overrideIDs = Object.values(lookupData?.overrides || {}).filter((id) => id);
  const clashIDs = Object.values(lookupData?.clashes || {}).reduce((acc, ids) => acc.concat(ids), []);
  return [...new Set([...searchesIDs, ...clashIDs, ...overrideIDs])];
};

exports.getTeamName = (teamData) => getTeamDisplay("name_long", teamData);

exports.getPlayerName = (playerData) => getPlayerDisplay("name_full", playerData);

exports.getPlayerSortName = (playerData) => {
  if (playerData?.lastName && playerData?.firstName) return `${playerData.lastName} ${playerData.firstName}`;
  if (playerData?.name) {
    const [first, second, ...rest] = playerData.name.split(" ");
    if (first && second) return [second, ...rest, first].join(" ");
  }
  return getPlayerDisplay("name_full", playerData);
};

const getUnknownTeamKey = (unknownTeam) => {
  const { teamData, teamNameLookups, teamNameLookupsOrig, sourceTeamID, sourceID } = unknownTeam || {};
  if (!sourceID) return "";
  //Replace all . with {dot} to avoid issues when putting this into an object as keys using setObjVal
  const sourceTeamIDEscaped = `${sourceTeamID || ""}`.replace(/\./g, "{dot}").trim();
  if (sourceTeamIDEscaped) return `${sourceID}_${sourceTeamIDEscaped}`;

  const lookupsKey = [...(teamNameLookups || [])].sort().join("_");
  if (lookupsKey) return `${sourceID}_${lookupsKey}`;

  return "";
};
exports.getUnknownTeamKey = getUnknownTeamKey;

const getUnknownPlayerKey = (unknownPlayer) => {
  const { playerData, playerNameLookups, playerNameLookupsOrig, sourcePlayerID, sourceID } = unknownPlayer || {};
  if (!sourceID) return "";
  const sourcePlayerIDEscaped = `${sourcePlayerID || ""}`.replace(/\./g, "{dot}").trim();
  if (sourcePlayerIDEscaped) return `${sourceID}_${sourcePlayerIDEscaped}`;

  const lookupsKey = [...(playerNameLookups || [])].sort().join("_");
  if (lookupsKey) return `${sourceID}_${lookupsKey}`;

  return "";
};
exports.getUnknownPlayerKey = getUnknownPlayerKey;

const getSearchableLookupsList = (lookupsOrigList) => {
  const searchableLookupsMap = (lookupsOrigList || []).reduce((acc, lookupName) => {
    const searchableLookup = getSearchableString(lookupName);
    if (searchableLookup) acc[searchableLookup] = true;
    return acc;
  }, {});
  return Object.keys(searchableLookupsMap);
};
exports.getSearchableLookupsList = getSearchableLookupsList;

const getNormalizedString = (str) =>
  str
    ?.trim?.()
    ?.normalize?.("NFD")
    ?.replace?.(/[\u0300-\u036f]/g, "") || "";
exports.getNormalizedString = getNormalizedString;

const getSearchableString = (str) => getNormalizedString(str?.toLowerCase?.())?.replace?.(/[^a-z0-9]+/g, "") || "";
exports.getSearchableString = getSearchableString;

const getIDString = (str) =>
  getNormalizedString(str)
    .replace(/\s+/g, " ")
    .trim()
    .toUpperCase()
    .replaceAll?.(" ", "_")
    .replace(/[^A-Z0-9_]+/g, "");
exports.getIDString = getIDString;

const formatPotentialTeamID = (teamNameLong, leagueID) => {
  console.log("formatPotentialTeamID", teamNameLong, leagueID);
  const teamNameID = getIDString(teamNameLong);
  if (!teamNameID || !LEAGUE_CONFIG[leagueID]?.leagueID) return "";
  return `${teamNameID}_${leagueID}`;
};
exports.formatPotentialTeamID = formatPotentialTeamID;

const formatPotentialPlayerID = (playerNameFull, teamID) => {
  const playerNameID = getIDString(playerNameFull);
  if (playerNameID && teamID) return `${playerNameID}_${teamID}`;
  return "";
};
exports.formatPotentialPlayerID = formatPotentialPlayerID;
