import { getCollectionDoc, queryCollection, setCollectionDoc, updateCollectionDoc } from "@wagerlab/utils/database/firestore";
import { mergeData } from "@wagerlab/utils/data/mutations";

export const getLeague = async (leagueID) => {
  return await getCollectionDoc("Leagues", leagueID);
};

export const getTeamsForLeague = async (leagueID) => {
  return await queryCollection("Teams", "leagueID", "==", leagueID);
};

export const updateLeagueBatch = (leagueID, updates) => {
  return { method: "update", collectionName: "Leagues", collectionID: leagueID, payload: updates };
};

export const setAggregatorQueryTeamLookups = async (leagueID) => {
  try {
    return await updateCollectionDoc("Config", "aggregator", { "runner.queryTeamLookups": leagueID });
  } catch (e) {
    console.error("Error setting aggregator config to query team lookups:", e);
    return false;
  }
};

export const updateAggregatorTeamLookupsBatch = (leagueID, ranAt, data) => {
  return {
    method: "update",
    collectionName: "AggregatorTeamLookups",
    collectionID: leagueID,
    payload: {
      ranAt,
      manualUpdateAt: new Date(),
      unknownTeams: data,
    },
  };
};

export const getTeam = async (teamID) => {
  return await getCollectionDoc("Teams", teamID);
};

export const updateTeamDataBatch = async (teamID, data) => {
  try {
    const teamData = await getCollectionDoc("Teams", teamID);

    let updatedData = { ...data, teamID };
    if (teamData) {
      updatedData = teamData;
      updatedData.sourceContext = mergeData(teamData?.sourceContext || {}, data?.sourceContext || {}, { defaultStrategy: "existing_unlessEmpty" });
    }

    return { method: "set", collectionName: "Teams", collectionID: teamID, payload: updatedData };
  } catch (e) {
    return null;
  }
};

export const getUnknownTeamLookups = async (leagueID) => {
  try {
    return (await getCollectionDoc("AggregatorTeamLookups", leagueID)) || {};
  } catch (e) {
    console.error("Error getting aggregator unknown lookups:", e);
    return false;
  }
};
