const { setObjVal } = require("@wagerlab/utils/data/mutations");
const { isNumber } = require("@wagerlab/utils/data/types");
const { EVENT_TYPES } = require("@wagerlab/utils/events/enums");

exports.refreshEventScores = (eventData) => {
  const eventType = eventData?.type;
  if (eventType === EVENT_TYPES.prop) return eventData;

  Object.keys(eventData?.teams || {}).forEach((teamStatEntityID) => {
    let teamScore = eventData?.results?.game?.[teamStatEntityID]?.points;
    if (!eventData?.teams?.[teamStatEntityID]?.teamID || !isNumber(teamScore)) teamScore = null;
    setObjVal(eventData, `teams.${teamStatEntityID}.score`, teamScore);
  });

  if (eventType === EVENT_TYPES.match) {
    if (!isNumber(eventData?.teams?.home?.score) || !isNumber(eventData?.teams?.away?.score)) {
      setObjVal(eventData, "teams.home.score", null);
      setObjVal(eventData, "teams.away.score", null);
    }
  }

  return eventData;
};
