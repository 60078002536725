// Right now this is managed at the event-level. In the future we may want to manage this at the odd level
// in which case all calls to this function will need to be changed to possibly use a new odds-level version
const eventRequiresManualScoring = (eventData) => {
  return eventData?.manual && eventData?.type === "prop";
};
exports.eventRequiresManualScoring = eventRequiresManualScoring;

const hasAnyAutomatedScoring = (eventData) => {
  if (!eventRequiresManualScoring(eventData)) return true;
  return Object.values(eventData?.sourceContext || {}).some((singleSourceContext) => {
    return singleSourceContext?.supportsEventScores || singleSourceContext?.supportsEventStats || singleSourceContext?.supportsPlayerStats;
  });
};
exports.hasAnyAutomatedScoring = hasAnyAutomatedScoring;
