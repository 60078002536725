const { setSearchLookup, formatLookups } = require("@wagerlab/utils/aggregator/lookups");
const { copyData, mergeData } = require("@wagerlab/utils/data/mutations");

const mergePlayerNames = (existingPlayerData, playerDataUpdates) => {
  const { names: existingNames, variations: existingVariations } = getPlayerNamesWithVariations(existingPlayerData);
  const { names: updatedNames, variations: updatedVariations } = getPlayerNamesWithVariations(playerDataUpdates);

  const preferExisting = !!existingNames?.firstName && !!existingNames?.lastName && (!updatedNames?.firstName || !updatedNames?.lastName);
  return mergeData(existingNames, updatedNames, preferExisting);
};
exports.mergePlayerNames = mergePlayerNames;

const generatePlayerSearchLookups = (teamData) => {
  let generatedPlayerLookups = {
    searches: {},
    clashes: copyData(teamData?.playerLookups?.clashes || {}),
    overrides: copyData(teamData?.playerLookups?.overrides || {}),
  };
  Object.entries(teamData?.players || {}).forEach(([playerID, playerData]) => {
    if (!playerID) return;
    const playerNameLookups = expandPlayerNameLookups(playerData, {}, [], []);
    generatedPlayerLookups = playerNameLookups.reduce((lookupDataObj, variationName) => setSearchLookup(lookupDataObj, variationName, playerID), generatedPlayerLookups);
  });

  return generatedPlayerLookups?.searches || {};
};
exports.generatePlayerSearchLookups = generatePlayerSearchLookups;

const expandPlayerNameLookups = (mergedPlayerData, playerDataUpdates, existingLookupsList = [], additionalLookupsList = []) => {
  const { names: existingNames, variations: existingVariations } = getPlayerNamesWithVariations(mergedPlayerData);
  const { names: updatedNames, variations: updatedVariations } = getPlayerNamesWithVariations(playerDataUpdates);

  const allVariationsSet = new Set([...existingVariations, ...updatedVariations, ...(existingLookupsList || []), ...(additionalLookupsList || [])]);
  return [...allVariationsSet];
};
exports.expandPlayerNameLookups = expandPlayerNameLookups;

const isUniqueNickname = (nickname, firstName, lastName) => {
  if (!nickname) return false;
  const nicknameLower = nickname.toLowerCase();
  const firstNameLower = firstName.toLowerCase();
  const lastNameLower = lastName.toLowerCase();
  if (firstNameLower && (nicknameLower.startsWith(firstNameLower) || nicknameLower.endsWith(firstNameLower))) return false;
  if (lastNameLower && (nicknameLower.startsWith(lastNameLower) || nicknameLower.endsWith(lastNameLower))) return false;

  if (!firstName || !lastName) {
    const likelyFirstNameLastInitialWithPeriod = nickname.length > 3 && nickname.charAt(nickname.length - 1) === "." && nickname.charAt(nickname.length - 3) === " ";
    const likelyFirstNameLastInitialWithoutPeriod = nickname.length > 2 && nickname.charAt(nickname.length - 2) === " ";
    const likelyFirstInitialLastNameWithPeriod = nickname.length > 3 && nickname.charAt(1) === "." && nickname.charAt(2) === " ";
    const likelyFirstInitialLastNameWithoutPeriod = nickname.length > 2 && nickname.charAt(1) === " ";
    return !likelyFirstNameLastInitialWithPeriod && !likelyFirstNameLastInitialWithoutPeriod && !likelyFirstInitialLastNameWithPeriod && !likelyFirstInitialLastNameWithoutPeriod;
  }
  return true;
};

const getPlayerNamesWithVariations = (playerData) => {
  let fullName = playerData?.name?.trim?.() || "";
  let firstName = playerData?.firstName?.trim?.() || "";
  let lastName = playerData?.lastName?.trim?.() || "";
  let nickname = playerData?.nickname?.trim?.() || "";

  if (!isUniqueNickname(nickname, firstName, lastName)) nickname = "";

  const nameVariantsSet = new Set();

  if (lastName) nameVariantsSet.add(lastName);

  if (firstName && lastName) {
    if (!fullName) fullName = `${firstName} ${lastName}`;
    nameVariantsSet.add(`${firstName} ${lastName}`);
    nameVariantsSet.add(`${lastName}, ${firstName}`);
    const firstNameFirstChar = firstName.charAt(0);
    const lastNameFirstChar = lastName.charAt(0);
    nameVariantsSet.add(`${firstNameFirstChar}. ${lastName}`);
    nameVariantsSet.add(`${firstName} ${lastNameFirstChar}.`);
  }

  if (firstName && lastName && nickname) {
    nameVariantsSet.add(`${firstName} ${nickname} ${lastName}`);
    nameVariantsSet.add(`${firstName} "${nickname}" ${lastName}`);
    nameVariantsSet.add(`${firstName} '${nickname}' ${lastName}`);
    nameVariantsSet.add(`${firstName} (${nickname}) ${lastName}`);
  }

  if (nickname) nameVariantsSet.add(nickname);
  if (fullName) nameVariantsSet.add(fullName);

  return {
    names: { firstName: firstName || null, lastName: lastName || null, nickname: nickname || null, name: fullName || null },
    variations: [...nameVariantsSet],
  };
};

const expandAllPlayerLookups = (teamData) => {
  let expandedLookupData = copyData(teamData?.playerLookups || {});
  expandedLookupData.searches = {};
  Object.entries(teamData?.players || {}).forEach(([playerID, playerData]) => {
    if (!playerID) return;
    const playerNameLookups = expandPlayerNameLookups(playerData, {}, [], []);
    expandedLookupData = playerNameLookups.reduce((lookupDataObj, variationName) => setSearchLookup(lookupDataObj, variationName, playerID), expandedLookupData);
  });
  expandedLookupData.searches = { ...expandedLookupData.searches, ...(teamData?.playerLookups?.searches || {}) };
  return formatLookups(expandedLookupData);
};
exports.expandAllPlayerLookups = expandAllPlayerLookups;
