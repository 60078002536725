const { isDate, isNumber, isString } = require("@wagerlab/utils/data/types");

const resolveDate = (dateObject, dateParseable) => {
  if (isDate(dateObject)) return new Date(dateObject);
  if (isString(dateParseable) || isNumber(dateParseable)) {
    const parsedDate = new Date(dateParseable);
    if (isDate(parsedDate)) return parsedDate;
  }
  return null;
};
exports.resolveDate = resolveDate;

const parseDate = (dateLike, defaultsTo = "zeroDate") => {
  if (isDate(dateLike)) return new Date(dateLike);
  const parseableIsStringInt = isString(dateLike) && dateLike?.length && /^\d+$/.test(dateLike);
  const parseableValue = parseableIsStringInt ? parseInt(dateLike) : dateLike;
  const parsedDate = isNumber(parseableValue) || isString(parseableValue) ? new Date(parseableValue) : null;
  if (isDate(parsedDate)) return parsedDate;
  if (!defaultsTo) return null;
  if (isDate(defaultsTo)) return new Date(defaultsTo);
  if (defaultsTo === "now") return new Date();
  if (defaultsTo === "future") return new Date(Date.now() + 1000 * 60 * 60 * 24 * 365 * 100);
  return new Date(0);
};
exports.parseDate = parseDate;

const parseTimestamp = (dateLike, defaultsTo = "zeroDate") => {
  const parsedDate = parseDate(dateLike, defaultsTo);
  return parsedDate?.getTime?.() || 0;
};
exports.parseTimestamp = parseTimestamp;

const cooldownExpired = (lastEventDate, cooldownDays, nowDate = null) => {
  const datePlusCooldown = parseDate(lastEventDate, "zeroDate");
  datePlusCooldown.setDate(datePlusCooldown.getDate() + (cooldownDays || 0));
  const currentDate = isDate(nowDate) ? nowDate : new Date();
  const datePlusCooldownTimestamp = datePlusCooldown?.getTime?.() || 0;
  const currentDateTimestamp = currentDate?.getTime?.() || 0;
  return currentDateTimestamp >= datePlusCooldownTimestamp;
};
exports.cooldownExpired = cooldownExpired;

const cooldownMsRemaining = (lastEventDate, cooldownDays, nowDate = null) => {
  const datePlusCooldown = parseDate(lastEventDate, "zeroDate");
  datePlusCooldown.setDate(datePlusCooldown.getDate() + (cooldownDays || 0));
  const currentDate = isDate(nowDate) ? nowDate : new Date();
  const datePlusCooldownTimestamp = datePlusCooldown?.getTime?.() || 0;
  const currentDateTimestamp = currentDate?.getTime?.() || 0;
  return Math.max(datePlusCooldownTimestamp - currentDateTimestamp, 0);
};
exports.cooldownMsRemaining = cooldownMsRemaining;

const cooldownExpiredMinutes = (lastEventDate, cooldownMinutes, nowDate = null) => {
  const datePlusCooldown = parseDate(lastEventDate, "zeroDate");
  datePlusCooldown.setMinutes(datePlusCooldown.getMinutes() + (cooldownMinutes || 0));
  const currentDate = isDate(nowDate) ? nowDate : new Date();
  const datePlusCooldownTimestamp = datePlusCooldown?.getTime?.() || 0;
  const currentDateTimestamp = currentDate?.getTime?.() || 0;
  return currentDateTimestamp >= datePlusCooldownTimestamp;
};
exports.cooldownExpiredMinutes = cooldownExpiredMinutes;

const cooldownExpiredMs = (lastEventDate, cooldownMs, nowDate = null) => {
  const datePlusCooldown = parseDate(lastEventDate, "zeroDate");
  datePlusCooldown.setMilliseconds(datePlusCooldown.getMilliseconds() + (cooldownMs || 0));
  const currentDate = isDate(nowDate) ? nowDate : new Date();
  const datePlusCooldownTimestamp = datePlusCooldown?.getTime?.() || 0;
  const currentDateTimestamp = currentDate?.getTime?.() || 0;
  return currentDateTimestamp >= datePlusCooldownTimestamp;
};
exports.cooldownExpiredMs = cooldownExpiredMs;
