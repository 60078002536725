const { setObjVal } = require("@wagerlab/utils/data/mutations");
const { parseNumber } = require("@wagerlab/utils/data/numbers");
const _ = require("lodash");

/*
* Notes for setting configs
- statID should never contain the symbols: . [ ]
- Try to re-use the same statID across sports if possible (ie. if it makes sense logically)
- All sports must have a points statID
- The points statID should never define an aggregationMethod (ie it should always use the default which is sum)
- Fields you can set:
  - isScoreStat
  - canDecrease - whether the stat can decrease during the event/game under normal circumstances (not including stat corrections and things like that)
  - lowerScoreWins - whether the lowest score is "better" for grading purposes
  - yesNoBets - whether yes/no bets make sense on this stat. defaults to true.
  - display()
  - description
  - supportedLevels -> all, team, player
  - displays -> short, long
  - units -> short.singular, short.plural, long.singular, long.plural
  - type
  - aggregationMethod -> 'sum' (default), 'percent', 'first', 'last', 'min', 'max', 'ratio', 'none' (none means never aggregate) - this affects whether/how we aggregate across periodIDs and home+away=all
  - binary - whether this stat will take on a binary value (ie. 0 or 1)
  - calculate - an array of calculation objects used to calculate the value of this stat based on other stats. Items are evaluated in order
  - calculate.performCalc - function(targetEntityStatVals, otherEntitiesVals) - ex ({ rebounds, assists }, { home, away }) rebounds maps to target entity rebounds.  home.assists would map to home statEntityID assists...performCalc() is is not called recursively. Therefore you should include all possible calculation variations. (FYI it does recursively manage periodIDs for you but that's abstracted away already)
  - calculate.dependencies - array - list of statIDs that the target statEntityID needs to have to calculate this stat. All statIDs listed in dependencies must have a non-null value for the calculation to be performed. One one needs to be non-null if defaultDependencyValue is provided
  - calculate.otherEntityDependencies.<statEntityID> - obj - list of statIDs that entity needs to have to calculate this stat (statEntityID should be static/not the target)
  - calculate.defaultDependencyValue - number - If you define a defaultDependencyValue then the stat will take on a value if at least one dependency has a value with the rest defaulting to this value
  - calculate.canCalc - function(oddData, eventData) - Whether the calculation can be performed. If not provided, it defaults to () => true
*/

// TODO: some statID should never use sumParts - for example free throw % shouldn't be Q1 + Q2 + Q3 + Q4 values. Need a flag for this
// TODO overall we need to rethink some of the flags and stuff as they're used to filter out incoherent/special/unsupported odds

const STAT_CONFIG_DEFAULTS = {
  type: "number",
  yesNoBets: true,
  aggregationMethod: "sum",
};

const SPORT_STAT_CONFIG = {
  BASEBALL: {
    "points": {
      statID: "points",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: {
        short: "Runs",
        long: "Runs",
      },
      units: {
        short: { singular: "run", plural: "runs" },
        long: { singular: "run", plural: "runs" },
      },
      description: "Runs scored",
      isScoreStat: true,
    },
    "batting_singles": {
      statID: "batting_singles",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: {
        short: "1Bs",
        long: "Singles",
      },
      description: "Singles",
    },
    "batting_doubles": {
      statID: "batting_doubles",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: {
        short: "2Bs",
        long: "Doubles",
      },
      description: "Doubles",
    },
    "batting_triples": {
      statID: "batting_triples",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: {
        short: "3Bs",
        long: "Triples",
      },
      description: "Triples",
    },
    "batting_homeRuns": {
      statID: "batting_homeRuns",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: {
        short: "HRs",
        long: "Home Runs",
      },
      description: "Home runs",
    },
    "batting_strikeouts": {
      statID: "batting_strikeouts",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: {
        short: "Ks",
        long: "Strikeouts",
      },
      description: "Strike outs",
    },
    "batting_basesOnBalls": {
      statID: "batting_basesOnBalls",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: {
        short: "BOBs",
        long: "Walks",
      },
      description: "Number of times the batter was walked (bases on balls)",
    },
    "batting_hits": {
      statID: "batting_hits",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Hits", long: "Hits" },
      description: "Hits",
    },
    "batting_battingAvg": {
      statID: "batting_battingAvg",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "AVG", long: "Batting Average" },
      description: "Batting average",
      canDecrease: true,
      yesNoBets: false,
      aggregationMethod: "percent",
    },
    "batting_onBasePercent": {
      statID: "batting_onBasePercent",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "OBP", long: "On-Base %" },
      description: "On-base percentage",
      canDecrease: true,
      yesNoBets: false,
      aggregationMethod: "percent",
    },
    "batting_sluggingPercent": {
      statID: "batting_sluggingPercent",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "SLG %", long: "Slugging %" },
      description: "Slugging percentage",
      canDecrease: true,
      yesNoBets: false,
      aggregationMethod: "percent",
    },
    "batting_OPS": {
      statID: "batting_OPS",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "OPS", long: "On-Base + Slugging %" },
      description: "On-base plus slugging",
      canDecrease: true,
      yesNoBets: false,
      aggregationMethod: "percent",
    },
    "batting_RBI": {
      statID: "batting_RBI",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "RBIs", long: "Runs Batted In" },
      description: "Runs batted in",
    },
    "batting_atBats": {
      statID: "batting_atBats",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "At Bats", long: "At Bats" },
      description: "At bats",
    },
    "batting_leftOnBase": {
      statID: "batting_leftOnBase",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "LOB", long: "Left on Base" },
      description: "Left on base",
    },
    "batting_flyOuts": {
      statID: "batting_flyOuts",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Fly Outs", long: "Fly Outs" },
      description: "Fly outs",
    },
    "batting_groundOuts": {
      statID: "batting_groundOuts",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Ground Outs", long: "Ground Outs" },
      description: "Ground outs",
    },
    "batting_hitByPitch": {
      statID: "batting_hitByPitch",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "HBP", long: "Hit by Pitch" },
      description: "Hit by pitch",
    },
    "batting_caughtStealing": {
      statID: "batting_caughtStealing",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Caught #", long: "Caught Stealing" },
      description: "Caught stealing",
    },
    "batting_stolenBases": {
      statID: "batting_stolenBases",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "SBs", long: "Stolen Bases" },
      description: "Stolen bases",
    },
    "batting_sacrificeBunts": {
      statID: "batting_sacrificeBunts",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Bunts", long: "Sacrifice Bunts" },
      description: "Sacrifice bunts",
    },
    "batting_sacrificeFlies": {
      statID: "batting_sacrificeFlies",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Flies", long: "Sacrifice Flies" },
      description: "Sacrifice flies",
    },
    "batting_hits+runs+rbi": {
      statID: "batting_hits+runs+rbi",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "H+R+RBIs", long: "Hits + Runs + RBIs" },
      description: "Hits + Runs + RBIs",
      calculate: [
        {
          dependencies: ["batting_hits", "points", "batting_RBI"],
          defaultDependencyValue: 0,
          performCalc: ({ batting_hits, points, batting_RBI }) => batting_hits + points + batting_RBI,
        },
      ],
    },
    "batting_runs+rbi": {
      statID: "batting_runs+rbi",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "R+RBIs", long: "Runs + RBIs" },
      description: "Runs + RBIs",
      calculate: [
        {
          dependencies: ["points", "batting_RBI"],
          defaultDependencyValue: 0,
          performCalc: ({ points, batting_RBI }) => points + batting_RBI,
        },
      ],
    },
    "batting_bases": {
      statID: "batting_bases",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "B", long: "Bases" },
      description: "Bases reached",
    },
    "pitching_win": {
      statID: "pitching_win",
      supportedLevels: {
        all: false,
        team: false,
        player: true,
      },
      displays: { short: "Pitch Win", long: "Pitching Win" },
      description: "Pitcher recorded a win",
      binary: true,
      aggregationMethod: "none",
    },
    "pitching_runsAllowed": {
      statID: "pitching_runsAllowed",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Runs Allowed", long: "Runs Allowed" },
      description: "Runs allowed",
    },
    "pitching_homeRunsAllowed": {
      statID: "pitching_homeRunsAllowed",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "HRs Allowed", long: "Home Runs Allowed" },
      description: "Home runs allowed",
    },
    "pitching_strikeouts": {
      statID: "pitching_strikeouts",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Ks", long: "Strikeouts" },
      description: "Strikeouts",
    },
    "pitching_basesOnBalls": {
      statID: "pitching_basesOnBalls",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "BoBs", long: "Walks" },
      description: "Walks allowed",
    },
    "pitching_hits": {
      statID: "pitching_hits",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Hits", long: "Hits Allowed" },
      description: "Hits allowed",
    },
    "pitching_ERA": {
      statID: "pitching_ERA",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "ERA", long: "ERA" },
      description: "Earned run average",
      canDecrease: true,
      yesNoBets: false,
      aggregationMethod: "percent",
    },
    "pitching_inningsPitched": {
      statID: "pitching_inningsPitched",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "IP", long: "Innings Pitched" },
      description: "Innings pitched",
    },
    "pitching_earnedRuns": {
      statID: "pitching_earnedRuns",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "ERs", long: "Earned Runs" },
      description: "Earned runs allowed",
    },
    "pitching_battersFaced": {
      statID: "pitching_battersFaced",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Batters", long: "Batters Faced" },
      description: "Batters faced",
    },
    "pitching_outs": {
      statID: "pitching_outs",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Outs", long: "Outs" },
      description: "Outs recorded",
    },
    "pitching_pitchesThrown": {
      statID: "pitching_pitchesThrown",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Pitches", long: "Pitches Thrown" },
      description: "Pitches thrown",
    },
    "pitching_strikesThrown": {
      statID: "pitching_strikesThrown",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Strikes", long: "Strikes Thrown" },
      description: "Strikes thrown",
    },
    "fielding_errors": {
      statID: "fielding_errors",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Errors", long: "Errors" },
      description: "Errors",
    },
    "fielding_assists": {
      statID: "fielding_assists",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Assists", long: "Assists" },
      description: "Assists",
    },
    "fielding_putOuts": {
      statID: "fielding_putOuts",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Put Outs", long: "Put Outs" },
      description: "Put outs",
    },
    "batting_firstHomeRun": {
      statID: "batting_firstHomeRun",
      supportedLevels: {
        all: false,
        team: true,
        player: true,
      },
      displays: { short: "First HR", long: "First Home Run" },
      description: "First player or team to hit a home run",
      binary: true,
      aggregationMethod: "first",
    },
    "firstToScore": {
      statID: "firstToScore",
      supportedLevels: {
        all: false,
        team: true,
        player: true,
      },
      displays: { short: "First Run", long: "First Run" },
      description: "First player or team to score a run",
      binary: true,
      aggregationMethod: "first",
    },
    "lastToScore": {
      statID: "lastToScore",
      supportedLevels: {
        all: false,
        team: true,
        player: true,
      },
      displays: { short: "Last Run", long: "Last Run" },
      description: "Last player or team to score a run",
      binary: true,
      aggregationMethod: "last",
    },
    // Team-level stats
    "largestLead": {
      statID: "largestLead",
      supportedLevels: {
        all: true,
        team: true,
        player: false,
      },
      displays: { short: "Largest Lead", long: "Largest Lead" },
      description: "Largest lead held during the game",
      yesNoBets: false,
      aggregationMethod: "max",
    },
    "inningsInLead": {
      statID: "inningsInLead",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Innings in Lead", long: "Innings in Lead" },
      description: "Number of innings the team was in the lead",
      // TODO: thought: perhaps we define a period type that this (and things like it) have a single value-per
    },
    // Game-level stats
    "timesTied": {
      statID: "timesTied",
      supportedLevels: {
        all: true,
        team: false,
        player: false,
      },
      displays: { short: "Times Tied", long: "Times Tied" },
      description: "Number of times the game was tied (excluding the start)",
    },
    "leadChanges": {
      statID: "leadChanges",
      supportedLevels: {
        all: true,
        team: false,
        player: false,
      },
      displays: { short: "Lead Changes", long: "Lead Changes" },
      description: "Number of times the winning team changed",
    },
  },
  FOOTBALL: {
    // Combined Stats
    "points": {
      statID: "points",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Pts", long: "Points" },
      units: {
        short: { singular: "pt", plural: "pts" },
        long: { singular: "point", plural: "points" },
      },
      description: "Points scored",
      isScoreStat: true,
    },
    "touchdowns": {
      statID: "touchdowns",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "TDs", long: "Touchdowns" },
      description: "Total Touchdowns",
    },
    "yards": {
      statID: "yards",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Yds", long: "Yards" },
      description: "Total Yards (net)",
      canDecrease: true,
    },
    "rushing+receiving_yards": {
      statID: "rushing+receiving_yards",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Rush+Rec. Yds", long: "Rushing + Receiving Yards" },
      description: "Rushing + Receiving Yards",
      calculate: [
        {
          dependencies: ["rushing_yards", "receiving_yards"],
          defaultDependencyValue: 0,
          performCalc: ({ rushing_yards, receiving_yards }) => rushing_yards + receiving_yards,
        },
      ],
    },
    "passing+rushing_yards": {
      statID: "passing+rushing_yards",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Pass+Rush. Yds", long: "Passing + Rushing Yards" },
      description: "Passing + Rushing Yards",
      calculate: [
        {
          dependencies: ["passing_yards", "rushing_yards"],
          defaultDependencyValue: 0,
          performCalc: ({ passing_yards, rushing_yards }) => passing_yards + rushing_yards,
        },
      ],
    },
    "firstDowns": {
      statID: "firstDowns",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "1st Downs", long: "First Downs" },
      description: "Total First Downs",
    },
    "turnovers": {
      statID: "turnovers",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "TOs", long: "Turnovers" },
      description: "Total Turnovers",
    },
    "fumbles": {
      statID: "fumbles",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Fumbles", long: "Fumbles" },
      description: "Number of fumbles committed by the offense and special teams",
    },
    "fumblesLost": {
      statID: "fumblesLost",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Fumbles Lost", long: "Fumbles Lost" },
      description: "Fumbles lost",
    },
    "firstToScore": {
      statID: "firstToScore",
      supportedLevels: {
        all: false,
        team: true,
        player: true,
      },
      displays: { short: "First Score", long: "First Score" },
      description: "First team to score",
      binary: true,
      aggregationMethod: "first",
    },
    "firstTouchdown": {
      statID: "firstTouchdown",
      supportedLevels: {
        all: false,
        team: true,
        player: true,
      },
      displays: { short: "First Tchdwn", long: "First Touchdown" },
      description: "First player or team to record a touchdown",
      binary: true,
      aggregationMethod: "first",
    },
    "lastTouchdown": {
      statID: "lastTouchdown",
      supportedLevels: {
        all: false,
        team: true,
        player: true,
      },
      displays: { short: "Last TD", long: "Last Touchdown" },
      description: "Last player or team to record a touchdown",
      binary: true,
      aggregationMethod: "last",
    },
    // Penalties
    "penalty_count": {
      statID: "penalty_count",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Penalties", long: "Penalties" },
      description: "Number of penalties incurred",
    },
    "penalty_yards": {
      statID: "penalty_yards",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Penalty Yds", long: "Penalty Yards" },
      description: "Number of yards penalized",
    },
    "penalty_firstDowns": {
      statID: "penalty_firstDowns",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Penalty 1st Downs", long: "Penalty First Downs" },
      description: "Number of first downs achieved by penalty",
    },
    // Blocks
    "fieldGoalBlocks": {
      statID: "fieldGoalBlocks",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "FG Blocks", long: "Field Goal Blocks" },
      description: "Number of field goal attempts successfully blocked",
    },
    "puntBlocks": {
      statID: "puntBlocks",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Punt Blocks", long: "Punt Blocks" },
      description: "Number of punt attempts successfully blocked",
    },
    // Misc offense
    "offense_redZoneTrips": {
      statID: "offense_redZoneTrips",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Red Zone Trips", long: "Red Zone Trips" },
      description: "Number of times the offense has entered the red zone",
    },
    "offense_redZoneTouchdowns": {
      statID: "offense_redZoneTouchdowns",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Red Zone TDs", long: "Red Zone Touchdowns" },
      description: "Number of times the offense has scored a touchdown in the red zone",
    },
    "offense_drives": {
      statID: "offense_drives",
      supportedLevels: {
        all: true,
        team: true,
        player: false,
      },
      displays: { short: "Drives", long: "Drives" },
      description: "Number of offensive drives",
    },
    "offense_plays": {
      statID: "offense_plays",
      supportedLevels: {
        all: true,
        team: true,
        player: false,
      },
      displays: { short: "Plays", long: "Plays" },
      description: "Number of offensive plays ran",
    },
    "offense_yardsPerPlay": {
      statID: "offense_yardsPerPlay",
      supportedLevels: {
        all: true,
        team: true,
        player: false,
      },
      displays: { short: "Yds/Play", long: "Yards Per Play" },
      description: "Average net yards gained per offensive play",
      canDecrease: true,
      yesNoBets: false,
      aggregationMethod: "ratio",
    },
    "offense_secondsOfPossession": {
      statID: "offense_secondsOfPossession",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Sec. of Poss", long: "Time of Possession (Sec.)" },
      description: "Time of possession (seconds)",
      calculate: [
        {
          dependencies: ["offense_minutesOfPossession"],
          performCalc: ({ offense_minutesOfPossession }) => offense_minutesOfPossession * 60,
        },
      ],
      yesNoBets: false,
    },
    "offense_minutesOfPossession": {
      statID: "offense_minutesOfPossession",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Min. of Poss.", long: "Time of Possession (Min.)" },
      description: "Time of possession (minutes)",
      calculate: [
        {
          dependencies: ["offense_secondsOfPossession"],
          performCalc: ({ offense_secondsOfPossession }) => offense_secondsOfPossession / 60,
        },
      ],
      yesNoBets: false,
    },
    "offense_thirdDownAttempts": {
      statID: "offense_thirdDownAttempts",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "3rd Down Att.", long: "Third Down Attempts" },
      description: "Number of third down attempts",
      yesNoBets: false,
    },
    "offense_thirdDownConversions": {
      statID: "offense_thirdDownConversions",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "3rd Down Conv.", long: "Third Down Conversions" },
      description: "Number of third down conversions",
    },
    "offense_thirdDownEfficiency": {
      statID: "offense_thirdDownEfficiency",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "3rd Down Eff.", long: "Third Down Efficiency" },
      description: "Third down efficiency",
      yesNoBets: false,
      aggregationMethod: "percent",
    },
    "offense_fourthDownAttempts": {
      statID: "offense_fourthDownAttempts",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "4th Down Att.", long: "Fourth Down Attempts" },
      description: "Number of fourth down attempts",
    },
    "offense_fourthDownConversions": {
      statID: "offense_fourthDownConversions",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "4th Down Conv.", long: "Fourth Down Conversions" },
      description: "Number of fourth down conversions",
    },
    "offense_fourthDownEfficiency": {
      statID: "offense_fourthDownEfficiency",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "4th Down Eff.", long: "Fourth Down Efficiency" },
      description: "Fourth down efficiency",
      yesNoBets: false,
      aggregationMethod: "percent",
    },
    //Receiving
    "receiving_touchdowns": {
      statID: "receiving_touchdowns",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Rec. TDs", long: "Receiving Touchdowns" },
      description: "Receiving touchdowns",
    },
    "receiving_yards": {
      statID: "receiving_yards",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Rec. Yds", long: "Receiving Yards" },
      description: "Receiving yards",
      canDecrease: true,
    },
    "receiving_receptions": {
      statID: "receiving_receptions",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Rec.", long: "Receptions" },
      description: "Receptions",
    },
    "receiving_targets": {
      statID: "receiving_targets",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Tgts.", long: "Targets" },
      description: "Targets",
    },
    "receiving_yardsAfterCatch": {
      statID: "receiving_yardsAfterCatch",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "YAC", long: "Yards After Catch" },
      description: "Yards after catch",
    },
    "receiving_firstDowns": {
      statID: "receiving_firstDowns",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Rec. 1st Downs", long: "Receiving First Downs" },
      description: "Receiving first downs",
    },
    "receiving_longestReception": {
      statID: "receiving_longestReception",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Longest Rec.", long: "Longest Reception" },
      description: "Longest reception",
      yesNoBets: false,
      aggregationMethod: "max",
    },
    "receiving_yardsPerReception": {
      statID: "receiving_yardsPerReception",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Yds/Rec.", long: "Yards Per Reception" },
      description: "Yards per reception",
      canDecrease: true,
      yesNoBets: false,
      aggregationMethod: "ratio",
    },
    "receiving_twoPointConversions": {
      statID: "receiving_twoPointConversions",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "2Pt. Conv.", long: "Two Point Conversions" },
      description: "Two point conversions",
    },
    // Rushes
    "rushing_touchdowns": {
      statID: "rushing_touchdowns",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Rush TDs", long: "Rushing Touchdowns" },
      description: "Rushing touchdowns",
    },
    "rushing_yards": {
      statID: "rushing_yards",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Rush Yds", long: "Rushing Yards" },
      description: "Rushing yards",
      canDecrease: true,
    },
    "rushing_attempts": {
      statID: "rushing_attempts",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Rush Att.", long: "Rushing Attempts" },
      description: "Rushing attempts",
    },
    "rushing_firstDowns": {
      statID: "rushing_firstDowns",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Rush 1st Downs", long: "Rushing First Downs" },
      description: "Rushing first downs",
    },
    "rushing_longestRush": {
      statID: "rushing_longestRush",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Longest Rush", long: "Longest Rush" },
      description: "Longest rush",
      yesNoBets: false,
      aggregationMethod: "max",
    },
    "rushing_yardsPerAttempt": {
      statID: "rushing_yardsPerAttempt",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Rush Yds/Att.", long: "Rush Yards Per Attempt" },
      description: "Yards per attempt",
      canDecrease: true,
      yesNoBets: false,
      aggregationMethod: "ratio",
    },
    "rushing_yardsAfterContact": {
      statID: "rushing_yardsAfterContact",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Rush Yds After Cont.", long: "Rush Yards After Contact" },
      description: "Yards after contact",
    },
    "rushing_twoPointConversions": {
      statID: "rushing_twoPointConversions",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Rush 2Pt. Conv.", long: "Rushing Two Point Conversions" },
      description: "Two point conversions (rushing)",
    },
    // Passing
    "passing_attempts": {
      statID: "passing_attempts",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Pass Att.", long: "Passing Attempts" },
      description: "Passing attempts",
    },
    "passing_completions": {
      statID: "passing_completions",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Pass Comp.", long: "Passing Completions" },
      description: "Passing completions",
    },
    "passing_yards": {
      statID: "passing_yards",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Pass Yds", long: "Passing Yards" },
      description: "Passing yards",
      canDecrease: true,
    },
    "passing_firstDowns": {
      statID: "passing_firstDowns",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Pass 1st Downs", long: "Passing First Downs" },
      description: "Passing first downs",
    },
    "passing_sackYards": {
      statID: "passing_sackYards",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Sack Yds", long: "Sack Yards Lost" },
      description: "Yards lost due to sacks",
    },
    "passing_netYards": {
      statID: "passing_netYards",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Net Yds", long: "Net Yards" },
      description: "Net passing yards (yards - sack yards)",
      canDecrease: true,
    },
    "passing_interceptions": {
      statID: "passing_interceptions",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "INTs", long: "Interceptions" },
      description: "Number of interceptions thrown",
    },
    "passing_longestCompletion": {
      statID: "passing_longestCompletion",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Longest Comp.", long: "Longest Completion" },
      description: "Longest completion",
      yesNoBets: false,
      aggregationMethod: "max",
    },
    "passing_sacksTaken": {
      statID: "passing_sacksTaken",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Sacks Taken", long: "Sacks Taken" },
      description: "Sacks taken",
    },
    "passing_touchdowns": {
      statID: "passing_touchdowns",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Pass TDs", long: "Passing Touchdowns" },
      description: "Touchdowns thrown",
    },
    "passing_yardsPerAttempt": {
      statID: "passing_yardsPerAttempt",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Yds/Att.", long: "Yards Per Attempt" },
      description: "Yards per attempt",
      canDecrease: true,
      yesNoBets: false,
      aggregationMethod: "ratio",
    },
    "passing_yardsPerCompletion": {
      statID: "passing_yardsPerCompletion",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Yds/Comp.", long: "Yards Per Completion" },
      description: "Yards per completion",
      canDecrease: true,
      yesNoBets: false,
      aggregationMethod: "ratio",
    },
    "passing_passerRating": {
      statID: "passing_passerRating",
      supportedLevels: {
        all: false,
        team: false,
        player: true,
      },
      displays: { short: "QBR", long: "Passer Rating" },
      description: "Passer rating",
      canDecrease: true,
      yesNoBets: false,
      aggregationMethod: "none",
    },
    "passing_twoPointConversions": {
      statID: "passing_twoPointConversions",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Pass 2Pt. Conv.", long: "Pass Two Point Conversions" },
      description: "Two point conversions (passing)",
    },
    // All Defense
    "defense_tackles": {
      statID: "defense_tackles",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Tackles", long: "Tackles" },
      description: "Tackles (half point for assisted)",
    },
    "defense_combinedTackles": {
      statID: "defense_combinedTackles",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Comb. Tackles", long: "Combined Tackles + Assists" },
      description: "Number of combined (solo + assisted) tackles. Each counts as 1.",
    },
    "defense_soloTackles": {
      statID: "defense_soloTackles",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Solo Tackles", long: "Solo Tackles" },
      description: "Number of solo (unassisted) tackles",
    },
    "defense_assistedTackles": {
      statID: "defense_assistedTackles",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Astd. Tackles", long: "Assisted Tackles" },
      description: "Number of assisted tackles",
    },
    "defense_sacks": {
      statID: "defense_sacks",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Sacks", long: "Sacks" },
      description: "Sacks",
    },
    "defense_tacklesForLoss": {
      statID: "defense_tacklesForLoss",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "TFLs", long: "Tackles for Loss" },
      description: "Tackles for loss",
    },
    "defense_passesDefended": {
      statID: "defense_passesDefended",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Passes Def.", long: "Passes Defended" },
      description: "Passes defended",
    },
    "defense_qbHits": {
      statID: "defense_qbHits",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "QB Hits", long: "QB Hits" },
      description: "Quarterback hits",
    },
    "defense_interceptions": {
      statID: "defense_interceptions",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "INTs", long: "Interceptions" },
      description: "Interceptions recovered",
    },
    "defense_pickSixes": {
      statID: "defense_pickSixes",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Pick Sixes", long: "Pick Sixes" },
      description: "Interceptions returned for touchdowns",
    },
    "defense_fumblesForced": {
      statID: "defense_fumblesForced",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Fumbles Forced", long: "Fumbles Forced" },
      description: "Fumbles forced",
    },
    "defense_fumblesRecovered": {
      statID: "defense_fumblesRecovered",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Fumbles Rec.", long: "Fumbles Recovered" },
      description: "Fumbles recovered",
    },
    "defense_scoopAndScores": {
      statID: "defense_scoopAndScores",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Scoop & Scores", long: "Scoop and Scores" },
      description: "Fumbles returned for touchdowns",
    },
    "defense_safeties": {
      statID: "defense_safeties",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Safeties", long: "Defensive Safeties" },
      description: "Safeties",
    },
    // Kickoff Returns
    "kickoffReturn_numReturns": {
      statID: "kickoffReturn_numReturns",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Kickoff Returns", long: "Kickoff Returns" },
      description: "Kickoffs returned",
    },
    "kickoffReturn_yards": {
      statID: "kickoffReturn_yards",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Kickoff Ret. Yds", long: "Kickoff Return Yards" },
      description: "Kickoff return yards",
    },
    "kickoffReturn_touchdowns": {
      statID: "kickoffReturn_touchdowns",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Kickoff Ret. TDs", long: "Kickoff Return Touchdowns" },
      description: "Kickoff return touchdowns",
    },
    "kickoffReturn_longestReturn": {
      statID: "kickoffReturn_longestReturn",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Longest Kickoff Ret.", long: "Longest Kickoff Return" },
      description: "Longest kickoff return",
      aggregationMethod: "max",
    },
    "kickOffReturn_yardsPerReturn": {
      statID: "kickOffReturn_yardsPerReturn",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Kickoff Ret. Yds/Ret.", long: "Kickoff Return Yards Per Return" },
      description: "Yards per kickoff return",
      canDecrease: true,
      yesNoBets: false,
      aggregationMethod: "ratio",
    },
    // Punt Returns
    "puntReturn_numReturns": {
      statID: "puntReturn_numReturns",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Punt Returns", long: "Punt Returns" },
      description: "Punts returned",
    },
    "puntReturn_yards": {
      statID: "puntReturn_yards",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Punt Ret. Yds", long: "Punt Return Yards" },
      description: "Punt return yards",
    },
    "puntReturn_touchdowns": {
      statID: "puntReturn_touchdowns",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Punt Ret. TDs", long: "Punt Return Touchdowns" },
      description: "Punt return touchdowns",
    },
    "puntReturn_longestReturn": {
      statID: "puntReturn_longestReturn",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Longest Punt Ret.", long: "Longest Punt Return" },
      description: "Longest punt return",
      yesNoBets: false,
      aggregationMethod: "max",
    },
    "puntReturn_yardsPerReturn": {
      statID: "puntReturn_yardsPerReturn",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Punt Ret. Yds/Ret.", long: "Punt Return Yards Per Return" },
      description: "Yards per punt return",
      canDecrease: true,
      yesNoBets: false,
      aggregationMethod: "ratio",
    },
    // Punting (NOT returning)
    "punting_numPunts": {
      statID: "punting_numPunts",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Punts", long: "Punts" },
      description: "Number of punts",
    },
    "punting_yards": {
      statID: "punting_yards",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Punt Yds", long: "Punting Yards" },
      description: "Total punting yards (gross)",
    },
    "punting_netYards": {
      statID: "punting_netYards",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Net Punt Yds", long: "Net Punting Yards" },
      description: "Net punting yards (after returns)",
    },
    "punting_yardsPerPunt": {
      statID: "punting_yardsPerPunt",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Punt Yds/Punt", long: "Punting Yards Per Punt" },
      description: "Average yards per punt",
      canDecrease: true,
      yesNoBets: false,
      aggregationMethod: "ratio",
    },
    "punting_longestPunt": {
      statID: "punting_longestPunt",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Longest Punt", long: "Longest Punt" },
      description: "Longest punt",
      yesNoBets: false,
      aggregationMethod: "max",
    },
    "punting_puntsInside20": {
      statID: "punting_puntsInside20",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Punts In 20", long: "Punts Inside 20" },
      description: "Punts inside the 20 yard line",
    },
    "punting_puntsForTouchback": {
      statID: "punting_puntsForTouchback",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "TB Punts", long: "Punts for Touchback" },
      description: "Punts for touchback",
    },
    "punting_puntsForFairCatch": {
      statID: "punting_puntsForFairCatch",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "FC Punts", long: "Punts for Fair Catch" },
      description: "Punts for fair catch",
    },
    "punting_puntsBlocked": {
      statID: "punting_puntsBlocked",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Blockled Punts", long: "Blocked Punts" },
      description: "Number of punt attempts blocked by the other team",
    },
    // Field Goals (Kicking)
    "fieldGoals_attempts": {
      statID: "fieldGoals_attempts",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "FG Att", long: "Field Goal Attempts" },
      description: "Field goal attempts",
    },
    "fieldGoals_made": {
      statID: "fieldGoals_made",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "FG Made", long: "Field Goals Made" },
      description: "Field goals made",
    },
    "fieldGoals_percentMade": {
      statID: "fieldGoals_percentMade",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "FG %", long: "Field Goal Percentage" },
      description: "Field goal percentage",
      canDecrease: true,
      yesNoBets: false,
      aggregationMethod: "percent",
    },
    "fieldGoals_longestMade": {
      statID: "fieldGoals_longestMade",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Longest FGM", long: "Longest Field Goal Made" },
      description: "Longest field goal made",
      yesNoBets: false,
      aggregationMethod: "max",
    },
    "fieldGoals_longestAttempt": {
      statID: "fieldGoals_longestAttempt",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Longest FGA", long: "Longest Field Goal Attempt" },
      description: "Longest field goal attempt",
      yesNoBets: false,
      aggregationMethod: "max",
    },
    "fieldGoals_1to19YardsMade": {
      statID: "fieldGoals_1to19YardsMade",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "1-19 Yd FGM", long: "1-19 Yard FGs Made" },
      description: "Number of 1-19 yard field goals made",
    },
    "fieldGoals_20to29YardsMade": {
      statID: "fieldGoals_20to29YardsMade",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "20-29 Yd FGM", long: "20-29 Yard FGs Made" },
      description: "Number of 20-29 yard field goals made",
    },
    "fieldGoals_30to39YardsMade": {
      statID: "fieldGoals_30to39YardsMade",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "30-39 Yd FGM", long: "30-39 Yard FGs Made" },
      description: "Number of 30-39 yard field goals made",
    },
    "fieldGoals_40to49YardsMade": {
      statID: "fieldGoals_40to49YardsMade",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      description: "Number of 40-49 yard field goals made",
    },
    "fieldGoals_50PlusYardsMade": {
      statID: "fieldGoals_50PlusYardsMade",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "50+ Yd FGM", long: "50+ Yard FGs Made" },
      description: "Number of 50+ yard field goals made",
    },
    "fieldGoals_attemptsBlocked": {
      statID: "fieldGoals_attemptsBlocked",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Blocked FGAs", long: "Field Goal Attempts Blocked" },
      description: "Number of field goal attempts blocked by the other team",
    },
    // Extra Points (Kicking)
    "extraPoints_kicksMade": {
      statID: "extraPoints_kicksMade",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "XP Made", long: "Extra Points Made" },
      description: "Extra points made",
    },
    "extraPoints_kickAttempts": {
      statID: "extraPoints_kickAttempts",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "XP Att", long: "Extra Point Attempts" },
      description: "Extra point attempts",
    },
    // Kicking (Totals)
    "kicking_totalPoints": {
      statID: "kicking_totalPoints",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Kicking Pts", long: "Kicking Total Points" },
      description: "Kicking total points",
      calculate: [
        {
          dependencies: ["fieldGoals_made", "extraPoints_kicksMade"],
          defaultDependencyValue: 0,
          performCalc: ({ fieldGoals_made, extraPoints_kicksMade }) => (fieldGoals_made * 3) + extraPoints_kicksMade,
        },
      ],
    },
    // Team-level stats
    "largestLead": {
      statID: "largestLead",
      supportedLevels: {
        all: true,
        team: true,
        player: false,
      },
      displays: { short: "Largest Lead", long: "Largest Lead" },
      description: "Largest lead held during the game",
      yesNoBets: false,
      aggregationMethod: "max",
    },
    "secondsInLead": {
      statID: "secondsInLead",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Sec. Leading", long: "Seconds In Lead" },
      description: "Number of game clock seconds team was winning",
      calculate: [
        {
          dependencies: ["minutesInLead"],
          performCalc: ({ minutesInLead }) => minutesInLead * 60,
        },
      ],
    },
    "minutesInLead": {
      statID: "minutesInLead",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      description: "Number of game clock minutes team was winning",
      displays: { short: "Min. Leading", long: "Minutes In Lead" },
      calculate: [
        {
          dependencies: ["secondsInLead"],
          performCalc: ({ secondsInLead }) => secondsInLead / 60,
        },
      ],
    },
    "longestScoringRun": {
      statID: "longestScoringRun",
      supportedLevels: {
        all: true,
        team: true,
        player: false,
      },
      displays: { short: "Lng Pts Run", long: "Longest Scoring Run" },
      description: "Longest run of unanswered points",
      yesNoBets: false,
      aggregationMethod: "max",
    },
    // Game-level stats
    "timesTied": {
      statID: "timesTied",
      supportedLevels: {
        all: true,
        team: false,
        player: false,
      },
      displays: { short: "Times Tied", long: "Times Tied" },
      description: "Number of times the game was tied (excluding the start)",
    },
    "leadChanges": {
      statID: "leadChanges",
      supportedLevels: {
        all: true,
        team: false,
        player: false,
      },
      displays: { short: "Lead Changes", long: "Lead Changes" },
      description: "Number of times the winning team changed",
    },
  },
  BASKETBALL: {
    "points": {
      statID: "points",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Pts", long: "Points" },
      units: {
        short: { singular: "pt", plural: "pts" },
        long: { singular: "point", plural: "points" },
      },
      description: "Points",
      isScoreStat: true,
    },
    "blocks": {
      statID: "blocks",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Blks", long: "Blocks" },
      description: "Blocks",
    },
    "steals": {
      statID: "steals",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Stls", long: "Steals" },
      description: "Steals",
    },
    "assists": {
      statID: "assists",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Asts", long: "Assists" },
      description: "Assists",
    },
    "rebounds": {
      statID: "rebounds",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Rebs", long: "Rebounds" },
      description: "Rebounds",
    },
    "offensiveRebounds": {
      statID: "offensiveRebounds",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Off Rebs", long: "Offensive Rebounds" },
      description: "Offensive rebounds",
    },
    "defensiveRebounds": {
      statID: "defensiveRebounds",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Def Rebs", long: "Defensive Rebounds" },
      description: "Defensive rebounds",
    },
    "turnovers": {
      statID: "turnovers",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "TOs", long: "Turnovers" },
      description: "Turnovers committed",
    },
    "fouls": {
      statID: "fouls",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Fouls", long: "Fouls" },
      description: "Fouls committed",
    },
    "fieldGoalsMade": {
      statID: "fieldGoalsMade",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "FGs", long: "Field Goals Made" },
      description: "Field goals made",
    },
    "fieldGoalsAttempted": {
      statID: "fieldGoalsAttempted",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "FG Att", long: "Field Goals Attempted" },
      description: "Field goals attempted",
    },
    "fieldGoalPercent": {
      statID: "fieldGoalPercent",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "FG %", long: "Field Goal Percentage" },
      description: "Field goal shooting percentage",
      canDecrease: true,
      yesNoBets: false,
      aggregationMethod: "percent",
    },
    "threePointersMade": {
      statID: "threePointersMade",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "3PTs", long: "Three Pointers Made" },
      description: "Three pointers made",
    },
    "threePointersAttempted": {
      statID: "threePointersAttempted",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "3PT Att", long: "Three Pointers Attempted" },
      description: "Three pointers attempted",
    },
    "threePointerPercent": {
      statID: "threePointerPercent",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "3PT %", long: "Three Pointer Percentage" },
      description: "Three pointer shooting percentage",
      canDecrease: true,
      yesNoBets: false,
      aggregationMethod: "percent",
    },
    "twoPointersAttempted": {
      statID: "twoPointersAttempted",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "2PT Att", long: "Two Pointers Attempted" },
      description: "Two pointers attempted",
    },
    "twoPointersMade": {
      statID: "twoPointersMade",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "2PTs", long: "Two Pointers Made" },
      description: "Two pointers made",
    },
    "twoPointerPercent": {
      statID: "twoPointerPercent",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "2PT %", long: "Two Pointer Percentage" },
      description: "Two pointer percentage",
      canDecrease: true,
      yesNoBets: false,
      aggregationMethod: "percent",
    },
    "freeThrowsMade": {
      statID: "freeThrowsMade",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "FTs", long: "Free Throws Made" },
      description: "Free throws made",
    },
    "freeThrowsAttempted": {
      statID: "freeThrowsAttempted",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "FT Att", long: "Free Throws Attempted" },
      description: "Free throws attempted",
    },
    "freeThrowPercent": {
      statID: "freeThrowPercent",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "FT %", long: "Free Throw Percentage" },
      description: "Free throw percentage",
      canDecrease: true,
      yesNoBets: false,
      aggregationMethod: "percent",
    },
    "pointsInPaint": {
      statID: "pointsInPaint",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Pts In Paint", long: "Points In The Paint" },
      description: "Points in the paint",
    },
    "pointsOffTurnovers": {
      statID: "pointsOffTurnovers",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Pts Off TOs", long: "Points Off Turnovers" },
      description: "Points off of turnovers",
    },
    "secondChancePoints": {
      statID: "secondChancePoints",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "2nd Chance Pts", long: "Second Chance Points" },
      description: "Points scored after an offensive rebound",
    },
    "fastBreakPoints": {
      statID: "fastBreakPoints",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Fast Break Pts", long: "Fast Break Points" },
      description: "Fast break points",
    },
    "points+rebounds+assists": {
      statID: "points+rebounds+assists",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Pts + Rebs + Asts", long: "Points + Rebounds + Assists" },
      description: "Points + Rebounds + Assists",
      calculate: [
        {
          dependencies: ["points", "rebounds", "assists"],
          defaultDependencyValue: 0,
          performCalc: ({ points, rebounds, assists }) => points + rebounds + assists,
        },
      ],
    },
    "points+rebounds": {
      statID: "points+rebounds",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Pts + Rebs", long: "Points + Rebounds" },
      description: "Points + Rebounds",
      calculate: [
        {
          dependencies: ["points", "rebounds"],
          defaultDependencyValue: 0,
          performCalc: ({ points, rebounds }) => points + rebounds,
        },
      ],
    },
    "points+assists": {
      statID: "points+assists",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Pts + Asts", long: "Points + Assists" },
      description: "Points + Assists",
      calculate: [
        {
          dependencies: ["points", "assists"],
          defaultDependencyValue: 0,
          performCalc: ({ points, assists }) => points + assists,
        },
      ],
    },
    "rebounds+assists": {
      statID: "rebounds+assists",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Rebs + Asts", long: "Rebounds + Assists" },
      description: "Rebounds + Assists",
      calculate: [
        {
          dependencies: ["rebounds", "assists"],
          defaultDependencyValue: 0,
          performCalc: ({ rebounds, assists }) => rebounds + assists,
        },
      ],
    },
    "blocks+steals": {
      statID: "blocks+steals",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Blks + Stls", long: "Blocks + Steals" },
      description: "Blocks + Steals",
      calculate: [
        {
          dependencies: ["blocks", "steals"],
          defaultDependencyValue: 0,
          performCalc: ({ blocks, steals }) => blocks + steals,
        },
      ],
    },
    "doubleDouble": {
      statID: "doubleDouble",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Dbl-Dbl", long: "Double-Double" },
      description: "10+ in at least two of the following: points, rebounds, assists, steals, blocks",
      calculate: [
        {
          dependencies: ["points", "rebounds", "assists", "steals", "blocks"],
          defaultDependencyValue: 0,
          performCalc: ({ points, rebounds, assists, blocks, steals }) => ([points, rebounds, assists, blocks, steals].filter((stat) => stat >= 10).length >= 2 ? 1 : 0),
        },
      ],
      binary: true,
    },
    "tripleDouble": {
      statID: "tripleDouble",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Trpl-Dbl", long: "Triple-Double" },
      description: "10+ in at least three of the following: points, rebounds, assists, steals, blocks",
      calculate: [
        {
          dependencies: ["points", "rebounds", "assists", "steals", "blocks"],
          defaultDependencyValue: 0,
          performCalc: ({ points, rebounds, assists, blocks, steals }) => ([points, rebounds, assists, blocks, steals].filter((stat) => stat >= 10).length >= 3 ? 1 : 0),
        },
      ],
      binary: true,
    },
    "firstBasket": {
      statID: "firstBasket",
      supportedLevels: {
        all: false,
        team: true,
        player: true,
      },
      displays: { short: "First Bskt", long: "First Basket" },
      description: "First player or team to score a basket",
      binary: true,
      aggregationMethod: "first",
    },
    "firstToScore": {
      statID: "firstToScore",
      supportedLevels: {
        all: false,
        team: true,
        player: true,
      },
      displays: { short: "First Score", long: "First Score" },
      description: "First player or team to score",
      binary: true,
      aggregationMethod: "first",
    },
    // Player stats
    "secondsPlayed": {
      statID: "secondsPlayed",
      supportedLevels: {
        all: false,
        team: false,
        player: true,
      },
      displays: { short: "Sec. Played", long: "Seconds Played" },
      description: "Seconds played",
      calculate: [
        {
          dependencies: ["minutesPlayed"],
          performCalc: ({ minutesPlayed }) => minutesPlayed * 60,
        },
      ],
    },
    "minutesPlayed": {
      statID: "minutesPlayed",
      supportedLevels: {
        all: false,
        team: false,
        player: true,
      },
      displays: { short: "Min. Played", long: "Minutes Played" },
      description: "Minutes played",
      calculate: [
        {
          dependencies: ["secondsPlayed"],
          performCalc: ({ secondsPlayed }) => secondsPlayed / 60,
        },
      ],
    },
    "plusMinus": {
      statID: "plusMinus",
      supportedLevels: {
        all: false,
        team: false,
        player: true,
      },
      displays: { short: "+/-", long: "Plus/Minus" },
      description: "Net score change while on the court",
      canDecrease: true,
      yesNoBets: false,
    },
    // Team-level stats
    "largestLead": {
      statID: "largestLead",
      supportedLevels: {
        all: true,
        team: true,
        player: false,
      },
      displays: { short: "Largest Lead", long: "Largest Lead" },
      description: "Largest lead held during the game",
      yesNoBets: false,
      aggregationMethod: "max",
    },
    "secondsInLead": {
      statID: "secondsInLead",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Sec. Leading", long: "Seconds In Lead" },
      description: "Number of game clock seconds team was winning",
      calculate: [
        {
          dependencies: ["minutesInLead"],
          performCalc: ({ minutesInLead }) => minutesInLead * 60,
        },
      ],
    },
    "minutesInLead": {
      statID: "minutesInLead",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Min. Leading", long: "Minutes In Lead" },
      description: "Number of game clock minutes team was winning",
      calculate: [
        {
          dependencies: ["secondsInLead"],
          performCalc: ({ secondsInLead }) => secondsInLead / 60,
        },
      ],
    },
    "longestScoringRun": {
      statID: "longestScoringRun",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Lng Pts Run", long: "Longest Scoring Run" },
      description: "Longest run of unanswered points",
      yesNoBets: false,
      aggregationMethod: "max",
    },
    // Game-level stats
    "timesTied": {
      statID: "timesTied",
      supportedLevels: {
        all: true,
        team: false,
        player: false,
      },
      displays: { short: "Times Tied", long: "Times Tied" },
      description: "Number of times the game was tied (excluding the start)",
    },
    "leadChanges": {
      statID: "leadChanges",
      supportedLevels: {
        all: true,
        team: false,
        player: false,
      },
      displays: { short: "Lead Changes", long: "Lead Changes" },
      description: "Number of times the winning team changed",
    },
  },
  HOCKEY: {
    "points": {
      statID: "points",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Goals", long: "Goals" },
      units: {
        short: { singular: "goal", plural: "goals" },
        long: { singular: "goal", plural: "goals" },
      },
      description: "Goals scored",
      isScoreStat: true,
    },
    "firstToScore": {
      statID: "firstToScore",
      supportedLevels: {
        all: false,
        team: true,
        player: true,
      },
      displays: { short: "First Goal", long: "First Goal" },
      description: "First Goal by player or team",
      binary: true,
      aggregationMethod: "first",
    },
    "lastToScore": {
      statID: "lastToScore",
      supportedLevels: {
        all: false,
        team: true,
        player: true,
      },
      displays: { short: "Last Goal", long: "Last Goal" },
      description: "Last Goal by player or team",
      binary: true,
      aggregationMethod: "last",
    },
    "goals+assists": {
      statID: "goals+assists",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Points", long: "Points (Goals + Assists)" },
      description: "Hockey Points (Goals + Assists)",
      calculate: [
        {
          dependencies: ["points", "assists"],
          defaultDependencyValue: 0,
          performCalc: ({ points, assists }) => points + assists,
        },
      ],
    },
    "assists": {
      statID: "assists",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Asts", long: "Assists" },
      description: "Assists",
    },
    "shots": {
      statID: "shots",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Shots", long: "Shots" },
      description: "Shots taken",
    },
    "shots_onGoal": {
      statID: "shots_onGoal",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Shots On Goal", long: "Shots On Goal" },
      description: "Shots on goal",
    },
    "shots_blocked": {
      statID: "shots_blocked",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Shots Blocked", long: "Shots Blocked" },
      description: "Number of shots taken that were blocked by the opposing team",
    },
    "hits": {
      statID: "hits",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Hits", long: "Hits" },
      description: "Hits",
    },
    "blocks": {
      statID: "blocks",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Blks", long: "Blocked Shots" },
      description: "Number of shots (taken by the opposing team) that were blocked",
    },
    "penaltySeconds": {
      statID: "penaltySeconds",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Penalty Secs", long: "Penalty Seconds" },
      description: "Number of seconds spent in the penalty box",
      calculate: [
        {
          dependencies: ["penaltyMinutes"],
          performCalc: ({ penaltyMinutes }) => penaltyMinutes * 60,
        },
      ],
    },
    "penaltyMinutes": {
      statID: "penaltyMinutes",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Penalty Mins", long: "Penalty Minutes" },
      description: "Number of minutes spent in the penalty box",
      calculate: [
        {
          dependencies: ["penaltySeconds"],
          performCalc: ({ penaltySeconds }) => penaltySeconds / 60,
        },
      ],
    },
    "giveaways": {
      statID: "giveaways",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Giveaways", long: "Giveaways" },
      description: "Giveaways",
    },
    "takeaways": {
      statID: "takeaways",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Takeaways", long: "Takeaways" },
      description: "Takeaways",
    },
    // Hockey - Relative strength stats
    "powerPlay_count": {
      statID: "powerPlay_count",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "PP Count", long: "Power Play Count" },
      description: "Power play count",
    },
    "shortHanded_count": {
      statID: "shortHanded_count",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "SH Count", long: "Short Handed Count" },
      description: "Short handed count",
    },
    "powerPlay_goals": {
      statID: "powerPlay_goals",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "PP Goals", long: "Power-Play Goals" },
      description: "Goals scored during Power-Plays",
    },
    "powerPlay_assists": {
      statID: "powerPlay_assists",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "PP Asts", long: "Power-Play Assists" },
      description: "Assists during Power-Plays",
    },
    "powerPlay_goals+assists": {
      statID: "powerPlay_goals+assists",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "PP Points", long: "Power-Play Points" },
      description: "Goals + Assists during Power-Plays",
      calculate: [
        {
          dependencies: ["powerPlay_goals", "powerPlay_assists"],
          defaultDependencyValue: 0,
          performCalc: ({ powerPlay_goals, powerPlay_assists }) => powerPlay_goals + powerPlay_assists,
        },
      ],
    },
    "shortHanded_goals": {
      statID: "shortHanded_goals",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "SH Goals", long: "Short-Handed Goals" },
      description: "Goals scored while short-handed",
    },
    "shortHanded_assists": {
      statID: "shortHanded_assists",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "SH Asts", long: "Short-Handed Assists" },
      description: "Assists while short-handed",
    },
    "shortHanded_goals+assists": {
      statID: "shortHanded_goals+assists",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "SH Points", long: "Short-Handed Points" },
      description: "Goals + Assists while Short-Handed",
      calculate: [
        {
          dependencies: ["shortHanded_goals", "shortHanded_assists"],
          defaultDependencyValue: 0,
          performCalc: ({ shortHanded_goals, shortHanded_assists }) => shortHanded_goals + shortHanded_assists,
        },
      ],
    },
    "evenStrength_goals": {
      statID: "evenStrength_goals",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "ES Goals", long: "Even Strength Goals" },
      description: "Goals scored while at Even Strength",
    },
    "evenStrength_assists": {
      statID: "evenStrength_assists",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "ES Asts", long: "Even-Strength Assists" },
      description: "Assists while at Even Strength",
    },
    "evenStrength_goals+assists": {
      statID: "evenStrength_goals+assists",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "ES Points", long: "Even-Strength Points" },
      description: "Goals + Assists while at Even Strength",
      calculate: [
        {
          dependencies: ["evenStrength_goals", "evenStrength_assists"],
          defaultDependencyValue: 0,
          performCalc: ({ evenStrength_goals, evenStrength_assists }) => evenStrength_goals + evenStrength_assists,
        },
      ],
    },
    // Hockey - Faceoff Stats
    "faceOffs_won": {
      statID: "faceOffs_won",
      supportedLevels: {
        all: false,
        team: true,
        player: true,
      },
      displays: { short: "FO Won", long: "Faceoffs Won" },
      description: "Faceoffs won",
    },
    "faceOffs_lost": {
      statID: "faceOffs_lost",
      supportedLevels: {
        all: false,
        team: true,
        player: true,
      },
      displays: { short: "FO Lost", long: "Faceoffs Lost" },
      description: "Faceoffs lost",
    },
    "faceOffs_taken": {
      statID: "faceOffs_taken",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "FO Taken", long: "Faceoffs Taken" },
      description: "Faceoffs taken",
    },
    // Hockey - Goalie Stats
    "goalie_goalsAgainst": {
      statID: "goalie_goalsAgainst",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Goals", long: "Goals Against" },
      description: "Goals against",
    },
    "goalie_saves": {
      statID: "goalie_saves",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Saves", long: "Saves" },
      description: "Saves",
    },
    "goalie_shortHanded_saves": {
      statID: "goalie_shortHanded_saves",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "SH Saves", long: "Short Handed Saves" },
      description: "Short handed saves",
    },
    "goalie_evenStrength_saves": {
      statID: "goalie_evenStrength_saves",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "ES Saves", long: "Even Strength Saves" },
      description: "Even strength saves",
    },
    "goalie_powerPlay_saves": {
      statID: "goalie_powerPlay_saves",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "PP Saves", long: "Power Play Saves" },
      description: "Power play saves",
    },
    "goalie_shotsAgainst": {
      statID: "goalie_shotsAgainst",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Shots Against", long: "Shots Against" },
      description: "Shots against",
    },
    "goalie_shortHanded_shotsAgainst": {
      statID: "goalie_shortHanded_shotsAgainst",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "SH Shots Against", long: "Short Handed Shots Against" },
      description: "Short handed shots against",
    },
    "goalie_evenStrength_shotsAgainst": {
      statID: "goalie_evenStrength_shotsAgainst",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "ES Shots Against", long: "Even Strength Shots Against" },
      description: "Even strength shots against",
    },
    "goalie_powerPlay_shotsAgainst": {
      statID: "goalie_powerPlay_shotsAgainst",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "PP Shots Against", long: "Power Play Shots Against" },
      description: "Power play shots against",
    },
    "goalie_savePercent": {
      statID: "goalie_savePercent",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Save %", long: "Save Percentage" },
      description: "Save percentage",
      canDecrease: true,
      yesNoBets: false,
      aggregationMethod: "percent",
    },
    // Player stats
    "secondsPlayed": {
      statID: "secondsPlayed",
      supportedLevels: {
        all: false,
        team: false,
        player: true,
      },
      displays: { short: "Sec. Played", long: "Seconds Played" },
      description: "Seconds played",
      calculate: [
        {
          dependencies: ["minutesPlayed"],
          performCalc: ({ minutesPlayed }) => minutesPlayed * 60,
        },
      ],
    },
    "minutesPlayed": {
      statID: "minutesPlayed",
      supportedLevels: {
        all: false,
        team: false,
        player: true,
      },
      displays: { short: "Min. Played", long: "Minutes Played" },
      description: "Minutes played",
      calculate: [
        {
          dependencies: ["secondsPlayed"],
          performCalc: ({ secondsPlayed }) => secondsPlayed / 60,
        },
      ],
    },
    "plusMinus": {
      statID: "plusMinus",
      supportedLevels: {
        all: false,
        team: false,
        player: true,
      },
      displays: { short: "+/-", long: "Plus/Minus" },
      description: "Net score change while player was playing",
      canDecrease: true,
      yesNoBets: false,
    },
    // Team-level stats
    "largestLead": {
      statID: "largestLead",
      supportedLevels: {
        all: true,
        team: true,
        player: false,
      },
      displays: { short: "Largest Lead", long: "Largest Lead" },
      description: "Largest lead held during the game",
      yesNoBets: false,
      aggregationMethod: "max",
    },
    "secondsInLead": {
      statID: "secondsInLead",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Sec. Leading", long: "Seconds In Lead" },
      description: "Number of game clock seconds team was winning",
      calculate: [
        {
          dependencies: ["minutesInLead"],
          performCalc: ({ minutesInLead }) => minutesInLead * 60,
        },
      ],
    },
    "minutesInLead": {
      statID: "minutesInLead",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Min. Leading", long: "Minutes In Lead" },
      description: "Number of game clock minutes team was winning",
      calculate: [
        {
          dependencies: ["secondsInLead"],
          performCalc: ({ secondsInLead }) => secondsInLead / 60,
        },
      ],
    },
    "longestScoringRun": {
      statID: "longestScoringRun",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Longest Run", long: "Longest Scoring Run" },
      description: "Longest run of unanswered goals",
      yesNoBets: false,
      aggregationMethod: "max",
    },
    // Game-level stats
    "timesTied": {
      statID: "timesTied",
      supportedLevels: {
        all: true,
        team: false,
        player: false,
      },
      displays: { short: "Times Tied", long: "Times Tied" },
      description: "Number of times the game was tied (excluding the start)",
    },
    "leadChanges": {
      statID: "leadChanges",
      supportedLevels: {
        all: true,
        team: false,
        player: false,
      },
      displays: { short: "Lead Changes", long: "Lead Changes" },
      description: "Number of times the winning team changed",
    },
  },
  SOCCER: {
    "points": {
      statID: "points",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Goals", long: "Goals" },
      units: {
        short: { singular: "goal", plural: "goals" },
        long: { singular: "goal", plural: "goals" },
      },
      description: "Goals scored",
      isScoreStat: true,
    },
    "firstToScore": {
      statID: "firstToScore",
      supportedLevels: {
        all: false,
        team: true,
        player: true,
      },
      displays: { short: "First Goal", long: "First Goal" },
      description: "First Goal by player or team",
      binary: true,
      aggregationMethod: "first",
    },
    "lastToScore": {
      statID: "lastToScore",
      supportedLevels: {
        all: false,
        team: true,
        player: true,
      },
      displays: { short: "Last Goal", long: "Last Goal" },
      description: "Last Goal by player or team",
      binary: true,
      aggregationMethod: "last",
    },
    "goals+assists": {
      statID: "goals+assists",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Goals + Assists", long: "Goals + Assists" },
      description: "Soccer (Goals + Assists)",
      calculate: [
        {
          dependencies: ["points", "assists"],
          defaultDependencyValue: 0,
          performCalc: ({ points, assists }) => points + assists,
        },
      ],
    },
    "assists": {
      statID: "assists",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Asts", long: "Assists" },
      description: "Assists",
    },
    "shots_assisted": {
      statID: "shots_assisted",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Shots Assisted", long: "Shots Assisted" },
      description: "Shots Assisted",
    },
    "shots": {
      statID: "shots",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Shots", long: "Shots" },
      description: "Shots",
    },
    "shots_onGoal": {
      statID: "shots_onGoal",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Shots On Goal", long: "Shots On Goal" },
      description: "Shots on goal",
    },
    "shots_offGoal": {
      statID: "shots_offGoal",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Shots Off Goal", long: "Shots Off Goal" },
      description: "Shots off goal",
    },
    "shots_hitCrossbar": {
      statID: "shots_hitCrossbar",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Shots Hit Crossbar", long: "Shots Hit Crossbar" },
      description: "Shots that hit the crossbar",
    },
    "shots_insideBox": {
      statID: "shots_insideBox",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Shots Inside Box", long: "Shots Inside Box" },
      description: "Shots inside box",
    },
    "shots_outsideBox": {
      statID: "shots_outsideBox",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Shots Outside Box", long: "Shots Outside Box" },
      description: "Shots outside box",
    },
    "shots_blocked": {
      statID: "shots_blocked",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Shots Blocked", long: "Shots Blocked" },
      description: "Shots that were blocked by the opposing team",
    },
    "touches": {
      statID: "touches",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Touches", long: "Touches" },
      description: "Touches",
    },
    "clearances": {
      statID: "clearances",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Clearances", long: "Clearances" },
      description: "Clearances",
    },
    "cornerKicks": {
      statID: "cornerKicks",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Corner Kicks", long: "Corner Kicks" },
      description: "Corner kicks",
    },
    "throwIns": {
      statID: "throwIns",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Throw Ins", long: "Throw Ins" },
      description: "Throw-ins",
    },
    // Passing
    "passes_attempted": {
      statID: "passes_attempted",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Passes Att", long: "Passes Attempted" },
      description: "Passes attempted",
    },
    "passes_accurate": {
      statID: "passes_accurate",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Passes Acc", long: "Passes Accurate" },
      description: "Passes accurate",
    },
    "passes_inaccurate": {
      statID: "passes_inaccurate",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Passes Inacc", long: "Passes Inaccurate" },
      description: "Passes inaccurate",
    },
    "passes_percent": {
      statID: "passes_percent",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Pass %", long: "Pass Accuracy" },
      description: "Pass accuracy percentage",
      canDecrease: true,
      yesNoBets: false,
      aggregationMethod: "percent",
    },
    // Long balls
    "longBalls_attempted": {
      statID: "longBalls_attempted",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Long Balls Att", long: "Long Balls Attempted" },
      description: "Long balls attempted",
    },
    "longBalls_accurate": {
      statID: "longBalls_accurate",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Long Balls Acc", long: "Accurate Long Balls" },
      description: "Number of accurate long balls",
    },
    "longBalls_inaccurate": {
      statID: "longBalls_inaccurate",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Long Balls Inacc", long: "Inaccurate Long Balls" },
      description: "Number of inaccurate long balls",
    },
    "longBalls_percent": {
      statID: "longBalls_percent",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Long Balls %", long: "Long Ball Accuracy" },
      description: "Long ball accuracy percentage",
      canDecrease: true,
      yesNoBets: false,
      aggregationMethod: "percent",
    },
    "crosses_attempted": {
      statID: "crosses_attempted",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Cross Att", long: "Crosses Attempted" },
      description: "Crosses attempted",
    },
    "crosses_accurate": {
      statID: "crosses_accurate",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Cross Acc", long: "Accurate Crosses" },
      description: "Number of accurate crosses",
    },
    "crosses_inaccurate": {
      statID: "crosses_inaccurate",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Cross Inacc", long: "Inaccurate Crosses" },
      description: "Number of inaccurate crosses",
    },
    "crosses_percent": {
      statID: "crosses_percent",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Cross %", long: "Cross Accuracy" },
      description: "Cross accuracy percentage",
      canDecrease: true,
      yesNoBets: false,
      aggregationMethod: "percent",
    },
    //Duels
    "duels_attempted": {
      statID: "duels_attempted",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Duels Att", long: "Duels Attempted" },
      description: "Number of duels attempted",
    },
    "duels_won": {
      statID: "duels_won",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Duels Won", long: "Duels Won" },
      description: "Number of duels won",
    },
    "duels_lost": {
      statID: "duels_lost",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Duels Lost", long: "Duels Lost" },
      description: "Number of duels lost",
    },
    "duels_percent": {
      statID: "duels_percent",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Duel %", long: "Duel Win Percentage" },
      description: "Duel win percentage",
      canDecrease: true,
      yesNoBets: false,
      aggregationMethod: "percent",
    },
    // Aerials
    "aerials_attempted": {
      statID: "aerials_attempted",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Aerials Att", long: "Aerials Attempted" },
      description: "Number of aerials attempted",
    },
    "aerials_won": {
      statID: "aerials_won",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Aerials Won", long: "Aerials Won" },
      description: "Number of aerials won",
    },
    "aerials_lost": {
      statID: "aerials_lost",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Aerials Lost", long: "Aerials Lost" },
      description: "Number of aerials lost",
    },
    "aerials_percent": {
      statID: "aerials_percent",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Aerial %", long: "Aerial Win Percentage" },
      description: "Aerial win percentage",
      canDecrease: true,
      yesNoBets: false,
      aggregationMethod: "percent",
    },
    // Offensive dribbles
    "dribbles_attempted": {
      statID: "dribbles_attempted",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Dribbles Att", long: "Dribbles Attempted" },
      description: "Number of dribbles/take-ons attempted (offense)",
    },
    "dribbles_won": {
      statID: "dribbles_won",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Dribbles Won", long: "Dribbles Won" },
      description: "Number of dribbles/take-ons won (offense)",
    },
    "dribbles_lost": {
      statID: "dribbles_lost",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Dribbles Lost", long: "Dribbles Lost" },
      description: "Number of dribbles/take-ons lost (offense)",
    },
    "dribbles_percent": {
      statID: "dribbles_percent",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Dribble %", long: "Dribble Win Percentage" },
      description: "Dribble/take-on win percentage (offense)",
      canDecrease: true,
      yesNoBets: false,
      aggregationMethod: "percent",
    },
    // Defending dribbles
    "defense_dribbles_contested": {
      statID: "defense_dribbles_contested",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Def. Dribbles Contested", long: "Defensive Dribbles Contested" },
      description: "Number of dribbles/take-ons contested (defense)",
    },
    "defense_dribbles_won": {
      statID: "defense_dribbles_won",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Def. Dribbles Won", long: "Defensive Dribbles Won" },
      description: "Number of dribbles/take-ons won (defense)",
    },
    "defense_dribbles_lost": {
      statID: "defense_dribbles_lost",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Def. Dribbles Lost", long: "Defensive Dribbles Lost" },
      description: "Number of dribbles/take-ons lost (defense)",
    },
    "defense_dribbles_percent": {
      statID: "defense_dribbles_percent",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Def. Dribble %", long: "Defensive Dribble Win Percentage (Defense)" },
      description: "Dribble/take-on win percentage (defense)",
      canDecrease: true,
      yesNoBets: false,
      aggregationMethod: "percent",
    },
    // Defending
    "tackles": {
      statID: "tackles",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Tackles", long: "Tackles" },
      description: "Number of tackles",
    },
    "interceptions": {
      statID: "interceptions",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Interceptions", long: "Interceptions" },
      description: "Number of interceptions",
    },
    "blocks": {
      statID: "blocks",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Blocks", long: "Shots Blocked" },
      description: "Number of shots (taken by the opposing team) that were blocked",
    },
    "disposessed": {
      statID: "disposessed",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Dispossessed", long: "Dispossessed" },
      description: "Number of times dispossessed",
    },
    // Infractions
    "foulsDrawn": {
      statID: "foulsDrawn",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Fouls Drawn", long: "Fouls Drawn" },
      description: "Number of fouls drawn",
    },
    "fouls": {
      statID: "fouls",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Fouls", long: "Fouls Committed" },
      description: "Number of fouls committed",
    },
    "offsides": {
      statID: "offsides",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Offsides", long: "Offsides" },
      description: "Number of times called for offsides",
    },
    "freeKicks": {
      statID: "freeKicks",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Free Kicks", long: "Free Kicks" },
      description: "Number of free kicks",
    },
    "combinedCards": {
      statID: "combinedCards",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Cards", long: "Cards" },
      description: "Number of cards received (yellow + red). Both count as 1",
      calculate: [
        {
          dependencies: ["yellowCards", "redCards"],
          defaultDependencyValue: 0,
          performCalc: ({ yellowCards, redCards }) => yellowCards + redCards,
        },
      ],
    },
    "weightedCards": {
      statID: "weightedCards",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Cards (Adj)", long: "Cards (Weighted)" },
      description: "Number of cards where yellow cards count as 1 and red cards count as 2",
      calculate: [
        {
          dependencies: ["yellowCards", "redCards"],
          defaultDependencyValue: 0,
          performCalc: ({ yellowCards, redCards }) => yellowCards + redCards * 2,
        },
      ],
    },
    "cardIndex": {
      statID: "cardIndex",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Card Indx.", long: "Card Index" },
      description: "Weighted index score of cards received. Yellow cards count as 10. Red cards count as 25.",
      calculate: [
        {
          dependencies: ["yellowCards", "redCards"],
          defaultDependencyValue: 0,
          performCalc: ({ yellowCards, redCards }) => yellowCards * 10 + redCards * 25,
        },
      ],
    },
    "yellowCards": {
      statID: "yellowCards",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Yellow Cards", long: "Yellow Cards" },
      description: "Number of yellow cards received",
    },
    "redCards": {
      statID: "redCards",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Red Cards", long: "Red Cards" },
      description: "Number of red cards received",
    },
    "penaltiesCommitted": {
      statID: "penaltiesCommitted",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Penalties Committed", long: "Penalties Committed" },
      description: "Number of penalties committed (for penalty kicks)",
    },
    // Penalty kicks
    "penaltyKicks_taken": {
      statID: "penaltyKicks_taken",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "PK Taken", long: "Penalty Kicks Taken" },
      description: "Number of penalty kicks taken",
    },
    "penaltyKicks_made": {
      statID: "penaltyKicks_made",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "PK Made", long: "Penalty Kicks Made" },
      description: "Number of penalty kicks made",
    },
    "penaltyKicks_missed": {
      statID: "penaltyKicks_missed",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "PK Missed", long: "Penalty Kicks Missed" },
      description: "Number of penalty kicks missed",
    },
    "penaltyKicks_percent": {
      statID: "penaltyKicks_percent",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "PK %", long: "Penalty Kick Accuracy" },
      description: "Penalty kick accuracy percentage",
      canDecrease: true,
      yesNoBets: false,
      aggregationMethod: "percent",
    },
    // Soccer - Goalie Stats
    "goalie_goalsAgainst": {
      statID: "goalie_goalsAgainst",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "GA", long: "Goals Against" },
      description: "Number of goals allowed",
    },
    "goalie_saves": {
      statID: "goalie_saves",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Saves", long: "Saves" },
      description: "Number of saves",
    },
    "goalie_shotsAgainst": {
      statID: "goalie_shotsAgainst",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Shots Against", long: "Shots Against" },
      description: "Number of shots against",
    },
    "goalie_savePercent": {
      statID: "goalie_savePercent",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Save %", long: "Save Percentage" },
      description: "Goalie save percentage",
      canDecrease: true,
      yesNoBets: false,
      aggregationMethod: "percent",
    },
    "goalie_penaltyKicksSaved": {
      statID: "goalie_penaltyKicksSaved",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "PK Saves", long: "Penalty Kicks Saved" },
      description: "Number of penalty kicks saved",
    },
    "goalie_insideBox_saves": {
      statID: "goalie_insideBox_saves",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Box Saves", long: "Inside Box Saves" },
      description: "Number of saves inside the box",
    },
    "goalie_goalKicks": {
      statID: "goalie_goalKicks",
      supportedLevels: {
        all: true,
        team: true,
        player: true,
      },
      displays: { short: "Goal Kicks", long: "Goal Kicks" },
      description: "Number of goal kicks",
    },
    // Player stats
    "secondsPlayed": {
      statID: "secondsPlayed",
      supportedLevels: {
        all: false,
        team: false,
        player: true,
      },
      displays: { short: "Sec. Played", long: "Seconds Played" },
      description: "Seconds played",
      calculate: [
        {
          dependencies: ["minutesPlayed"],
          performCalc: ({ minutesPlayed }) => minutesPlayed * 60,
        },
      ],
    },
    "minutesPlayed": {
      statID: "minutesPlayed",
      supportedLevels: {
        all: false,
        team: false,
        player: true,
      },
      displays: { short: "Min. Played", long: "Minutes Played" },
      description: "Minutes played",
      calculate: [
        {
          dependencies: ["secondsPlayed"],
          performCalc: ({ secondsPlayed }) => secondsPlayed / 60,
        },
      ],
    },
    "plusMinus": {
      statID: "plusMinus",
      supportedLevels: {
        all: false,
        team: false,
        player: true,
      },
      displays: { short: "+/-", long: "Plus/Minus" },
      description: "Net score change while player was playing",
      canDecrease: true,
      yesNoBets: false,
    },
    "playerRating": {
      statID: "playerRating",
      supportedLevels: {
        all: false,
        team: false,
        player: true,
      },
      displays: { short: "Rating", long: "Player Rating" },
      description: "Player rating",
      canDecrease: true,
      yesNoBets: false,
      aggregationMethod: "none",
    },
    // Team-level stats
    "largestLead": {
      statID: "largestLead",
      supportedLevels: {
        all: true,
        team: true,
        player: false,
      },
      displays: { short: "Largest Lead", long: "Largest Lead" },
      description: "Largest lead held during the game",
      yesNoBets: false,
      aggregationMethod: "max",
    },
    "secondsInLead": {
      statID: "secondsInLead",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Sec. Leading", long: "Seconds In Lead" },
      description: "Number of game clock seconds team was winning",
      calculate: [
        {
          dependencies: ["minutesInLead"],
          performCalc: ({ minutesInLead }) => minutesInLead * 60,
        },
      ],
    },
    "minutesInLead": {
      statID: "minutesInLead",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Min. Leading", long: "Minutes In Lead" },
      description: "Number of game clock minutes team was winning",
      calculate: [
        {
          dependencies: ["secondsInLead"],
          performCalc: ({ secondsInLead }) => secondsInLead / 60,
        },
      ],
    },
    "possessionPercent": {
      statID: "possessionPercent",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Poss. %", long: "Possession Percentage" },
      description: "Percentage of time the team had possession of the ball",
      canDecrease: true,
      yesNoBets: false,
      aggregationMethod: "percent",
    },
    "longestScoringRun": {
      statID: "longestScoringRun",
      supportedLevels: {
        all: true,
        team: true,
        player: false,
      },
      displays: { short: "Longest Run", long: "Longest Scoring Run" },
      description: "Longest run of unanswered goals",
      yesNoBets: false,
      aggregationMethod: "max",
    },
    // Game-level stats
    "timesTied": {
      statID: "timesTied",
      supportedLevels: {
        all: true,
        team: false,
        player: false,
      },
      displays: { short: "Times Tied", long: "Times Tied" },
      description: "Number of times the game was tied (excluding the start)",
    },
    "leadChanges": {
      statID: "leadChanges",
      supportedLevels: {
        all: true,
        team: false,
        player: false,
      },
      displays: { short: "Lead Changes", long: "Lead Changes" },
      description: "Number of times the winning team changed",
    },
    "bothTeamsScored": {
      statID: "bothTeamsScored",
      supportedLevels: {
        all: true,
        team: false,
        player: false,
      },
      // Right now the defaultEntity is only used in special cases for yn betTypeIDs where statEntityID="all" but it could be used on other statEntityIDs if we wanted to change that
      displays: { short: "BTTS", long: "Both Teams Scored", infinitive: "To Score", defaultEntity: "Both Teams" },
      description: "Whether both teams scored a goal.",
      calculate: [
        {
          canCalc: (oddData, eventData) => oddData?.statEntityID === "all", // If not provided this defaults to true
          otherEntityDependencies: {
            home: ["points"],
            away: ["points"],
          },
          dependencies: [],
          defaultDependencyValue: 0,
          performCalc: (x, { home, away }) => (home?.points > 0 && away?.points > 0 ? 1 : 0),
        },
      ],
      binary: true,
      aggregationMethod: "none",
    },
  },
  HANDBALL: {
    points: {
      statID: "points",
      supportedLevels: {
        all: true,
        team: true,
        player: false,
      },
      displays: { short: "Goals", long: "Goals" },
      units: {
        short: { singular: "goal", plural: "goals" },
        long: { singular: "goal", plural: "goals" },
      },
      description: "Goals scored",
      isScoreStat: true,
    },
  },
  TENNIS: {
    points: {
      statID: "points",
      supportedLevels: {
        all: true,
        team: true,
        player: false,
      },
      displays: { short: "Sets", long: "Sets Won" },
      units: {
        short: { singular: "set", plural: "sets" },
        long: { singular: "set", plural: "sets" },
      },
      description: "Sets won",
      isScoreStat: true,
      fixedTotalValuePerPeriod: true,
    },
    games: {
      statID: "games",
      supportedLevels: {
        all: true,
        team: true,
        player: false,
      },
      displays: { short: "Games", long: "Games Won" },
      units: {
        short: { singular: "game", plural: "games" },
        long: { singular: "game", plural: "games" },
      },
      description: "Games won",
      isScoreStat: true,
    },
  },
  GOLF: {
    points: {
      statID: "points",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "To Par", long: "To Par" },
      units: {
        short: { singular: "stroke", plural: "strokes" },
        long: { singular: "stroke", plural: "strokes" },
      },
      description: "To Par Score",
      isScoreStat: true,
      canDecrease: true,
      lowerScoreWins: true,
      display: (val) => {
        const intVal = parseNumber(parseInt(val), 0, "integer");
        return intVal > 0 ? `+${intVal}` : intVal === 0 ? "E" : `${intVal}`;
      },
      yesNoBets: false,
    },
  },
  MMA: {
    points: {
      statID: "points",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Win", long: "Fight Winner" },
      units: {
        short: { singular: "win", plural: "win" },
        long: { singular: "win", plural: "win" },
      },
      description: "Fight Winner",
      isScoreStat: true,
    },
    strikes: {
      statID: "strikes",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Strikes", long: "Strikes" },
      units: {
        short: { singular: "strike", plural: "strikes" },
        long: { singular: "strike", plural: "strikes" },
      },
      description: "Total Strikes",
      calculate: [
        {
          dependencies: ["strikes_head", "strikes_body", "strikes_leg"],
          defaultDependencyValue: 0,
          performCalc: ({ strikes_head, strikes_body, strikes_leg }) => strikes_head + strikes_body + strikes_leg,
        },
      ],
    },
    significant_strikes: {
      statID: "significant_strikes",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Significant Strikes", long: "Significant Strikes" },
      units: {
        short: { singular: "strike", plural: "strikes" },
        long: { singular: "strike", plural: "strikes" },
      },
      description: "Total Significant Strikes",
    },
    strikes_received: {
      statID: "strikes_received",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Strikes Received", long: "Strikes Received" },
      units: {
        short: { singular: "strike", plural: "strikes" },
        long: { singular: "strike", plural: "strikes" },
      },
      description: "Total Strikes Received",
    },
    strikes_distance: {
      statID: "strikes_distance",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Strikes from Dist.", long: "Strikes from Distance" },
      units: {
        short: { singular: "strike", plural: "strikes" },
        long: { singular: "strike", plural: "strikes" },
      },
      description: "Total Strikes from Distance",
    },
    strikes_ground: {
      statID: "strikes_ground",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Strikes on Ground", long: "Strikes on the Ground" },
      units: {
        short: { singular: "strike", plural: "strikes" },
        long: { singular: "strike", plural: "strikes" },
      },
      description: "Total Strikes on the Ground",
    },
    strikes_clinch: {
      statID: "strikes_clinch",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Strikes in Clinch", long: "Strikes in the Clinch" },
      units: {
        short: { singular: "strike", plural: "strikes" },
        long: { singular: "strike", plural: "strikes" },
      },
      description: "Total Strikes in the Clinch",
    },
    strikes_head: {
      statID: "strikes_head",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Head Strikes", long: "Strikes (Head)" },
      units: {
        short: { singular: "strike", plural: "strikes" },
        long: { singular: "strike", plural: "strikes" },
      },
      description: "Total Strikes (Head)",
    },
    strikes_body: {
      statID: "strikes_body",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Body Strikes", long: "Strikes (Body)" },
      units: {
        short: { singular: "strike", plural: "strikes" },
        long: { singular: "strike", plural: "strikes" },
      },
      description: "Total Strikes (Body)",
    },
    strikes_leg: {
      statID: "strikes_leg",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Leg Strikes", long: "Strikes (Leg)" },
      units: {
        short: { singular: "strike", plural: "strikes" },
        long: { singular: "strike", plural: "strikes" },
      },
      description: "Total Strikes (Leg)",
    },
    power_strikes: {
      statID: "power_strikes",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Power Strikes", long: "Power Strikes" },
      units: {
        short: { singular: "strike", plural: "strikes" },
        long: { singular: "strike", plural: "strikes" },
      },
      description: "Total Power Strikes",
      calculate: [
        {
          dependencies: ["power_strikes_head", "power_strikes_body", "power_strikes_leg"],
          defaultDependencyValue: 0,
          performCalc: ({ power_strikes_head, power_strikes_body, power_strikes_leg }) => power_strikes_head + power_strikes_body + power_strikes_leg,
        },
      ],
    },
    power_strikes_head: {
      statID: "power_strikes_head",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Power Strikes (Head)", long: "Power Strikes (Head)" },
      units: {
        short: { singular: "strike", plural: "strikes" },
        long: { singular: "strike", plural: "strikes" },
      },
      description: "Total Power Strikes (Head)",
    },
    power_strikes_body: {
      statID: "power_strikes_body",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Power Strikes (Body)", long: "Power Strikes (Body)" },
      units: {
        short: { singular: "strike", plural: "strikes" },
        long: { singular: "strike", plural: "strikes" },
      },
      description: "Total Power Strikes (Body)",
    },
    power_strikes_leg: {
      statID: "power_strikes_leg",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Power Strikes (Leg)", long: "Power Strikes (Leg)" },
      units: {
        short: { singular: "strike", plural: "strikes" },
        long: { singular: "strike", plural: "strikes" },
      },
      description: "Total Power Strikes (Leg)",
    },
    submissions: {
      statID: "submissions",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Submissions", long: "Submissions" },
      units: {
        short: { singular: "submission", plural: "submissions" },
        long: { singular: "submission", plural: "submissions" },
      },
      description: "Total Submissions",
    },
    knockdowns: {
      statID: "knockdowns",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Knockdowns", long: "Knockdowns" },
      units: {
        short: { singular: "knockdown", plural: "knockdowns" },
        long: { singular: "knockdown", plural: "knockdowns" },
      },
      description: "Total Knockdowns",
    },
    groundTime_seconds: {
      statID: "groundTime_seconds",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Ground Time (sec)", long: "Ground Time (seconds)" },
      units: {
        short: { singular: "second", plural: "seconds" },
        long: { singular: "second", plural: "seconds" },
      },
      description: "Total Ground Time (seconds)",
      calculate: [
        {
          dependencies: ["groundTime_minutes"],
          performCalc: ({ groundTime_minutes }) => groundTime_minutes * 60,
        },
      ],
    },
    groundTime_minutes: {
      statID: "groundTime_minutes",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Ground Time (min)", long: "Ground Time (Minutes)" },
      units: {
        short: { singular: "minute", plural: "minutes" },
        long: { singular: "minute", plural: "minutes" },
      },
      description: "Total Ground Time (minutes)",
      calculate: [
        {
          dependencies: ["groundTime_seconds"],
          performCalc: ({ groundTime_seconds }) => groundTime_seconds / 60,
        },
      ],
    },
    clinchTime_seconds: {
      statID: "clinchTime_seconds",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Clinch Time (sec)", long: "Clinch Time (seconds)" },
      units: {
        short: { singular: "second", plural: "seconds" },
        long: { singular: "second", plural: "seconds" },
      },
      description: "Total Clinch Time (seconds)",
      calculate: [
        {
          dependencies: ["clinchTime_minutes"],
          performCalc: ({ clinchTime_minutes }) => clinchTime_minutes * 60,
        },
      ],
    },
    clinchTime_minutes: {
      statID: "clinchTime_minutes",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Clinch Time (min)", long: "Clinch Time (Minutes)" },
      units: {
        short: { singular: "minute", plural: "minutes" },
        long: { singular: "minute", plural: "minutes" },
      },
      description: "Total Clinch Time (minutes)",
      calculate: [
        {
          dependencies: ["clinchTime_seconds"],
          performCalc: ({ clinchTime_seconds }) => clinchTime_seconds / 60,
        },
      ],
    },
    controlTime_seconds: {
      statID: "controlTime_seconds",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Control Time (sec)", long: "Control Time (seconds)" },
      units: {
        short: { singular: "second", plural: "seconds" },
        long: { singular: "second", plural: "seconds" },
      },
      description: "Total Ground Control Time (seconds)",
      calculate: [
        {
          dependencies: ["controlTime_minutes"],
          performCalc: ({ controlTime_minutes }) => controlTime_minutes * 60,
        },
      ],
    },
    controlTime_minutes: {
      statID: "controlTime_minutes",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Control Time (min)", long: "Control Time (Minutes)" },
      units: {
        short: { singular: "minute", plural: "minutes" },
        long: { singular: "minute", plural: "minutes" },
      },
      description: "Total Ground Control Time (minutes)",
      calculate: [
        {
          dependencies: ["controlTime_seconds"],
          performCalc: ({ controlTime_seconds }) => controlTime_seconds / 60,
        },
      ],
    },
    clinchControlTime_seconds: {
      statID: "clinchControlTime_seconds",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Clinch Control Time (sec)", long: "Clinch Control Time (seconds)" },
      units: {
        short: { singular: "second", plural: "seconds" },
        long: { singular: "second", plural: "seconds" },
      },
      description: "Total Control Time (seconds)",
      calculate: [
        {
          dependencies: ["clinchControlTime_minutes"],
          performCalc: ({ clinchControlTime_minutes }) => clinchControlTime_minutes * 60,
        },
      ],
    },
    clinchControlTime_minutes: {
      statID: "clinchControlTime_minutes",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Clinch Control Time (min)", long: "Clinch Control Time (Minutes)" },
      units: {
        short: { singular: "minute", plural: "minutes" },
        long: { singular: "minute", plural: "minutes" },
      },
      description: "Total Clinch Control Time (minutes)",
      calculate: [
        {
          dependencies: ["clinchControlTime_seconds"],
          performCalc: ({ clinchControlTime_seconds }) => clinchControlTime_seconds / 60,
        },
      ],
    },
    takedown_attempts: {
      statID: "takedown_attempts",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Takedown Attempts", long: "Takedown Attempts" },
      units: {
        short: { singular: "attempt", plural: "attempts" },
        long: { singular: "attempt", plural: "attempts" },
      },
      description: "Total Takedown Attempts",
    },
    takedowns_landed: {
      statID: "takedowns_landed",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Takedowns Landed", long: "Takedowns Landed" },
      units: {
        short: { singular: "landed", plural: "landed" },
        long: { singular: "landed", plural: "landed" },
      },
      description: "Total Takedowns Landed",
    },
    takedowns_received: {
      statID: "takedowns_received",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Takedowns Received", long: "Takedowns Received" },
      units: {
        short: { singular: "received", plural: "received" },
        long: { singular: "received", plural: "received" },
      },
      description: "Total Takedowns Received",
    },
    takedowns_defended: {
      statID: "takedowns_defended",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Takedowns Defended", long: "Takedowns Defended" },
      units: {
        short: { singular: "defended", plural: "defended" },
        long: { singular: "defended", plural: "defended" },
      },
      description: "Total Takedowns Defended",
    },
    submissions_attempted: {
      statID: "submissions_attempted",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Submissions Attempted", long: "Submissions Attempted" },
      units: {
        short: { singular: "attempt", plural: "attempts" },
        long: { singular: "attempt", plural: "attempts" },
      },
      description: "Total Submissions Attempted",
    },
    submissions_succeeded: {
      statID: "submissions_succeeded",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Submissions Succeeded", long: "Submissions Succeeded" },
      units: {
        short: { singular: "success", plural: "succeeded" },
        long: { singular: "success", plural: "succeeded" },
      },
      description: "Total Submissions Succeeded",
    },
    submissions_received: {
      statID: "submissions_received",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Submissions Received", long: "Submissions Received" },
      units: {
        short: { singular: "received", plural: "received" },
        long: { singular: "received", plural: "received" },
      },
      description: "Total Submissions Received",
    },
    submissions_defended: {
      statID: "submissions_defended",
      supportedLevels: {
        all: false,
        team: true,
        player: false,
      },
      displays: { short: "Submissions Defended", long: "Submissions Defended" },
      units: {
        short: { singular: "defended", plural: "defended" },
        long: { singular: "defended", plural: "defended" },
      },
      description: "Total Submissions Defended",
    },
  },
};
exports.SPORT_STAT_CONFIG = SPORT_STAT_CONFIG;

const ALL_STATS_CONFIG = Object.entries(SPORT_STAT_CONFIG).reduce((allStatsConfig, [sportID, sportStatConfig]) => {
  Object.entries(sportStatConfig || {}).forEach(([statID, statConfig]) => {
    if (!statID) return;
    allStatsConfig[statID] = allStatsConfig[statID] || { ...statConfig };
    const statDisplayName = statConfig?.displays?.long || statConfig?.displays?.short || statID;
    setObjVal(allStatsConfig, `${statID}.supportedSports.${sportID}`, statDisplayName);
  });
  return allStatsConfig;
}, {});
exports.ALL_STATS_CONFIG = ALL_STATS_CONFIG;

// If we want to change a statID, this is the best way to do so
const SPORT_MIGRATED_STAT_IDS = {
  HOCKEY: {
    shotsOnGoal: "shots_onGoal",
    blockedShots: "blocks",
  },
};
exports.SPORT_MIGRATED_STAT_IDS = SPORT_MIGRATED_STAT_IDS;
const ALL_MIGRATED_STAT_IDS = Object.entries(SPORT_MIGRATED_STAT_IDS).reduce((allMigratedStatIDs, [sportID, sportMigratedStatIDs]) => {
  return { ...allMigratedStatIDs, ...sportMigratedStatIDs };
}, {});
exports.ALL_MIGRATED_STAT_IDS = ALL_MIGRATED_STAT_IDS;

const STAT_LEVELS_ENUM = {
  ALL: "all",
  TEAM: "team",
  PLAYER: "player",
};
exports.STAT_LEVELS_ENUM = STAT_LEVELS_ENUM;

const STAT_LEVELS_LIST = Object.values(STAT_LEVELS_ENUM);
exports.STAT_LEVELS_LIST = STAT_LEVELS_LIST;

const lookupStatConfig = (statID, sportID = "") => {
  if (!statID) return null;
  const directLookup = sportID ? SPORT_STAT_CONFIG?.[sportID]?.[statID] : ALL_STATS_CONFIG?.[statID];
  if (directLookup?.statID) return directLookup;
  const migratedToStatID = sportID ? SPORT_MIGRATED_STAT_IDS?.[sportID]?.[statID] : ALL_MIGRATED_STAT_IDS?.[statID];
  if (!migratedToStatID) return null;
  const migratedLookup = migratedToStatID ? SPORT_STAT_CONFIG?.[sportID]?.[migratedToStatID] : ALL_STATS_CONFIG?.[migratedToStatID];
  if (migratedLookup?.statID) return { ...migratedLookup, statID };
  return null;
};

const getStatConfig = (statID, sportID, sportOptional = false, removeInternal = false) => {
  if (!statID) return null;
  if (!sportID && !sportOptional) return null;
  const baseStatConfig = lookupStatConfig(statID, sportID);
  if (!baseStatConfig?.statID) return null;
  const statConfig = { ...STAT_CONFIG_DEFAULTS, ...baseStatConfig };
  if (removeInternal) {
    const { display, binary, yesNoBets, calculate, fixedTotalValuePerPeriod, aggregationMethod, ...rest } = statConfig;
    return rest;
  }
  return statConfig;
};
exports.getStatConfig = getStatConfig;

const getStatConfigFromEventOdds = (eventData, oddData) => getStatConfig(oddData?.statID, eventData?.sportID);
exports.getStatConfigFromEventOdds = getStatConfigFromEventOdds;

const getStatLevel = (statEntityID, playerID = "", teamID = "") => {
  if (statEntityID === "all") return "all";
  if (statEntityID === "home" || statEntityID === "away") return "team";
  if (teamID && statEntityID === teamID) return "team";
  if (playerID && statEntityID === playerID) return "player";
  return "";
};
exports.getStatLevel = getStatLevel;

const getStatLevelFromOddsData = (oddsData) => getStatLevel(oddsData?.statEntityID, oddsData?.playerID, oddsData?.teamID);
exports.getStatLevelFromOddsData = getStatLevelFromOddsData;

// Note: This requires the relevant teamID or playerID to be included in the Event.teams/Event.players data (if applicable)
const checkStatIsSupportedByEvent = (statID, statEntityID, eventData) => {
  if (!statID || !statEntityID) return false;
  if ((statEntityID === "side1" || statEntityID === "side2") && statID.startsWith("prop")) return true;
  let statLevel = "";

  if (statEntityID === "all") statLevel = "all";
  else if (statEntityID === "home" || statEntityID === "away") statLevel = "team";
  else if (eventData?.teams?.[statEntityID]?.teamID) statLevel = "team";
  else if (eventData?.players?.[statEntityID]?.playerID) statLevel = "player";

  if (!statLevel) return false;
  const statConfig = lookupStatConfig(statID, eventData?.sportID);
  if (!statConfig?.statID) return false;
  if (!statConfig?.supportedLevels?.[statLevel]) return false;

  return true;
};
exports.checkStatIsSupportedByEvent = checkStatIsSupportedByEvent;

// Note: This requires the teamID or playerID to be included only in the oddsData (if applicable)
const checkStatIsSupportedByOdds = (oddData, sportID) => {
  const statLevel = getStatLevelFromOddsData(oddData);
  if (!statLevel) return false;
  const statConfig = getStatConfig(oddData?.statID, sportID);
  if (!statConfig?.statID) return false;
  if (!statConfig?.supportedLevels?.[statLevel]) return false;
  return true;
};
exports.checkStatIsSupportedByOdds = checkStatIsSupportedByOdds;

const getStatIDList = (sportID = "", statLevel = "") => {
  const statConfigObj = sportID ? SPORT_STAT_CONFIG?.[sportID] || {} : ALL_STATS_CONFIG || {};
  const statOverridesObj = sportID ? SPORT_MIGRATED_STAT_IDS?.[sportID] || {} : ALL_MIGRATED_STAT_IDS || {};
  const statIDList = Object.keys({ ...statConfigObj, ...statOverridesObj });
  if (!statLevel) return statIDList;
  return statIDList.filter((statID) => lookupStatConfig(statID, sportID)?.supportedLevels?.[statLevel]);
};
exports.getStatIDList = getStatIDList;

const getStatConfigList = (sportID = "", statLevel = "") => getStatIDList(sportID, statLevel).map((statID) => getStatConfig(statID, sportID, true, true));
exports.getStatConfigList = getStatConfigList;
