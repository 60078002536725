import React from "react";
import { Button, ButtonGroup, Tooltip } from "@mui/material";
import _ from "lodash";

export const HeaderButtons = ({ loading, loadData, refreshData, deselectAll, upsertEmbeddings, fetchAISuggestions, handleSubmit, entityType }) => {
  return (
    <ButtonGroup variant="contained" sx={{ marginTop: "10px" }}>
      <LoadButton loading={loading} loadData={loadData} entityType={entityType} />
      <RefreshButton loading={loading} refreshData={refreshData} entityType={entityType} />
      <DeselectButton loading={loading} deselectAll={deselectAll} entityType={entityType} />
      <UpsertButton loading={loading} upsertEmbeddings={upsertEmbeddings} entityType={entityType} />
      <AISuggestButton loading={loading} fetchAISuggestions={fetchAISuggestions} entityType={entityType} />
      <SubmitChangesButton loading={loading} handleSubmit={handleSubmit} entityType={entityType} />
    </ButtonGroup>
  );
};

const LoadButton = ({ loading, loadData, entityType }) => {
  return (
    <Tooltip title={`Load ${entityType} lookup data for the selected LEAGUE`}>
      <Button disabled={loading} variant="contained" color="primary" onClick={loadData}>
        Load {_.capitalize(entityType)} Data
      </Button>
    </Tooltip>
  );
};

const RefreshButton = ({ loading, refreshData, entityType }) => {
  return (
    <Tooltip title="Set aggregator to refresh lookups. this data on its next cycle. This is an async operation and will take several minutes to complete.">
      <Button disabled={loading} variant="contained" color="primary" onClick={refreshData}>
        Refresh Data
      </Button>
    </Tooltip>
  );
};

const DeselectButton = ({ loading, deselectAll, entityType }) => {
  return (
    <Tooltip title={`De-selects all lookup entries that have no ${entityType || ""}ID assigned`}>
      <Button disabled={loading} variant="contained" color="primary" onClick={deselectAll}>
        De-Select All Empty
      </Button>
    </Tooltip>
  );
};

const UpsertButton = ({ loading, upsertEmbeddings, entityType }) => {
  return (
    <Tooltip title={`Upsert all ${entityType}IDs in the Pinecone Vector DB. This is done for you after saving data.`}>
      <Button disabled={loading} variant="contained" color="primary" onClick={upsertEmbeddings}>
        Upsert Vector Embeddings
      </Button>
    </Tooltip>
  );
};

const AISuggestButton = ({ loading, fetchAISuggestions, entityType }) => {
  return (
    <Tooltip
      title={`Query vector database for selected ${entityType} lookups. Get ${entityType}ID suggestions. Results will appear in the autocomplete suggestions for the applicable lookup entries. Max 50 entries at once.`}
    >
      <Button disabled={loading} variant="contained" color="primary" onClick={fetchAISuggestions}>
        AI Suggest Selected
      </Button>
    </Tooltip>
  );
};

const SubmitChangesButton = ({ loading, handleSubmit, entityType }) => {
  return (
    <Tooltip title="Submits lookup mappings as override lookups for this team, creating new teams if necessary.">
      <Button disabled={loading} variant="contained" color="success" onClick={handleSubmit}>
        Submit Changes
      </Button>
    </Tooltip>
  );
};
