import { copyData, setObjVal, stripNullValues } from "@wagerlab/utils/data/mutations";
import { isNumber } from "@wagerlab/utils/data/types";
import { refreshEvent } from "@wagerlab/utils/events/refresh";
import * as Yup from "yup";
import moment from "moment-timezone";
import { oddsSetSorter, oddsSorter } from "@wagerlab/utils/events/oddOrdering";
import { getOddScore, isSpecialCaseScoring } from "@wagerlab/utils/odds/scoring";

export const initializeFormData = (eventData, eventBookOddsData) => {
  if (!eventData?.eventID || eventData?.type !== "match") return null;

  let resultsSummaries = [];
  const pathsAddedMap = {};

  resultsSummaries.push({ periodID: "game", statID: "points", statEntityID: "home", score: eventData?.results?.game?.home?.points });
  resultsSummaries.push({ periodID: "game", statID: "points", statEntityID: "away", score: eventData?.results?.game?.away?.points });
  pathsAddedMap["game.home.points"] = true;
  pathsAddedMap["game.away.points"] = true;

  let uncancelledPaths = {};
  let oddIDGroups = {};
  Object.entries(eventData?.odds || {}).forEach(([oddID, oddData]) => {
    const { periodID, statID, statEntityID, cancelled, oddID: oid } = oddData || {};
    if(isSpecialCaseScoring(oddData, eventData)) return;
    const score = getOddScore(oddData, eventData);
    if (!periodID || !statID || !statEntityID || !oddID || oddID !== oid) return;
    if (!cancelled) uncancelledPaths[`${periodID}.${statEntityID}.${statID}`] = true;
    const oddIDGroup = oddIDGroups[`${periodID}.${statEntityID}.${statID}`] || [];
    if (!oddIDGroup.includes(oddID)) oddIDGroup.push(oddID);
    oddIDGroups[`${periodID}.${statEntityID}.${statID}`] = oddIDGroup;
    if (pathsAddedMap[`${periodID}.${statEntityID}.${statID}`]) return;
    pathsAddedMap[`${periodID}.${statEntityID}.${statID}`] = true;
    resultsSummaries.push({ periodID, statID, statEntityID, score });
  });

  resultsSummaries = resultsSummaries
    .map((resultSummary) => {
      const { periodID, statID, statEntityID, score } = resultSummary || {};
      const cancelled = !uncancelledPaths[`${periodID}.${statEntityID}.${statID}`];
      const oddIDs = (oddIDGroups[`${periodID}.${statEntityID}.${statID}`] || []).sort();
      return { ...resultSummary, cancelled, oddIDs };
    })
    .sort((a, b) => oddsSetSorter(eventData?.odds[a?.oddIDs?.[0]], eventData?.odds[b?.oddIDs?.[0]]));

  return {
    eventID: eventData?.eventID,
    ended: true,
    started: eventData?.status?.started,
    cancelled: eventData?.status?.cancelled,
    displayShort: eventData?.status?.displayShort,
    displayLong: eventData?.status?.displayLong,
    resultsSummaries,
  };
};

export const validateFormData = (eventForm, prevEvent, prevEventBookOdds) => {
  if (!eventForm?.eventID) return "Event ID is missing";

  return "";
};

export const buildEventFromFormData = (eventForm, prevEvent, prevEventBookOdds) => {
  if (!eventForm?.eventID || !prevEvent?.eventID || eventForm.eventID !== prevEvent.eventID) return { event: null, eventBookOdds: null };

  let event = copyData(prevEvent);
  let eventBookOdds = copyData(prevEventBookOdds);
  ({ event, eventBookOdds } = refreshEvent(event, eventBookOdds));

  setObjVal(event, "status.reGrade", true);
  setObjVal(event, "status.displayShort", eventForm.displayShort);
  setObjVal(event, "status.displayLong", eventForm.displayLong);
  setObjVal(event, "status.cancelled", eventForm.cancelled);
  setObjVal(event, "status.started", eventForm.started);
  setObjVal(event, "status.ended", eventForm.ended);
  setObjVal(event, "status.completed", eventForm.ended && !eventForm.cancelled);
  // setObjVal(event, "inactiveSourceContext", prevEvent?.sourceContext || prevEvent?.inactiveSourceContext || null);
  // setObjVal(event, "sourceContext", null);
  setObjVal(event, "manual", true);
  setObjVal(event, "status.delayed", false);
  setObjVal(event, "status.finalized", false);
  setObjVal(event, "status.live", false);
  setObjVal(event, "status.nextUpdateAt", new Date());

  (eventForm.resultsSummaries || []).forEach((resultSummary) => {
    const { periodID, statID, statEntityID, cancelled, score, oddIDs } = resultSummary || {};
    if (!periodID || !statID || !statEntityID || (!isNumber(score) && !cancelled)) return;
    if (cancelled) {
      setObjVal(event, `results.${periodID}.${statEntityID}.${statID}`, null);
      Object.entries(event?.odds || {}).forEach(([oddID, oddData]) => {
        if (oddData?.statID === statID && oddData?.periodID === periodID && oddData?.statEntityID === statEntityID) {
          setObjVal(event, `odds.${oddID}.cancelled`, true);
          setObjVal(eventBookOdds, `odds.${oddID}.cancelled`, true);
        }
      });
    } else {
      setObjVal(event, `results.${periodID}.${statEntityID}.${statID}`, score);
    }
  });

  ({ event, eventBookOdds } = refreshEvent(event, eventBookOdds));
  return { event, eventBookOdds };
};

export const formFieldValidation = Yup.object().shape({
  eventID: Yup.string().required("Event ID is missing"),
  displayShort: Yup.string(),
  displayLong: Yup.string(),
  ended: Yup.boolean().required("Event ended must be Yes or No"),
  cancelled: Yup.boolean().required("Event cancelled must be Yes or No"),
  started: Yup.boolean().required("Event started must be Yes or No"),
  resultsSummaries: Yup.array()
    .of(
      Yup.object({
        periodID: Yup.string().required("periodID is required"),
        statID: Yup.string().required("statID is required"),
        statEntityID: Yup.string().required("statEntityID is required"),
        cancelled: Yup.boolean().required("Result cancelled is required"),
        score: Yup.mixed().when("cancelled", {
          is: (isCancelled) => !isCancelled,
          then: () => Yup.number().required("score is required"),
          otherwise: () => Yup.mixed().nullable(),
        }),
      })
    )
    .required("results are required")
    .min(1, "At least one result/odd item is required"),
});
