const { WARNING_COLOR, DARK_CONTENT_COLOR, SUCCESS_COLOR, ERROR_COLOR } = require("@wagerlab/utils/styles/colors");
const { formatTime } = require("./displayUtils");
const { UserDisplays } = require("./UserDisplays");
const { WagerDisplays } = require("./WagerDisplays");

// These are only applied to parlayWrap wagers (not parlayLegs)

class ParlayDisplays {
  constructor(wagerData) {
    this.parlayWrap = wagerData;
    this.parlayLegs = wagerData?.client?.parlayLegs;
    this.odds = wagerData?.odds;
    this.proposerDisplays = new UserDisplays(wagerData?.proposer);
    this.recipientDisplays = new UserDisplays(wagerData?.recipient);
  }

  PARLAY_DISPLAY_OVERRIDES = {
    vsOrAt_suffix_proposer: (wager) => "vs",
    vsOrAt_suffix_recipient: (wager) => "vs",

    createTime_local_long: (wager) => {
      return this.getCreateTime("long");
    },
    responseTime_local_long: (wager) => {
      return this.getResponseTime("long");
    },
    withdrawnTime_local_long: (wager) => {
      return this.getWithdrawnTime("long");
    },
    expiration_local_long: (wager) => {
      return this.getExpirationTime("long");
    },
    displayMedium_recipient: (wager) => {
      return this.recipientDisplays.getDisplayString("displayMedium");
    },
    displayMedium_proposer: (wager) => {
      return this.proposerDisplays.getDisplayString("displayMedium");
    },
  };

  getCreateTime(length, timezone = null) {
    if (!this?.parlayWrap?.status?.createdAt) return "";
    return formatTime(this.parlayWrap.status.createdAt, length, timezone) || "";
  }

  getExpirationTime(length, timezone = null) {
    if (!this?.parlayWrap?.status?.expiresAt) return "";
    return formatTime(this.parlayWrap?.status?.expiresAt, length, timezone) || "";
  }

  getResponseTime(length, timezone = null) {
    if (!this?.parlayWrap?.status?.responded || !this?.parlayWrap?.status?.respondedAt) return "";
    return formatTime(this.parlayWrap.status.respondedAt, length, timezone) || "";
  }

  getGradedTime(length, timezone = null) {
    if (!this?.parlayWrap?.status?.graded || !this?.parlayWrap?.status?.gradedAt) return "";
    return formatTime(this.parlayWrap.status.gradedAt, length, timezone) || "";
  }

  getWithdrawnTime(length, timezone = null) {
    if (!this?.parlayWrap?.status?.withdrawn || !this?.parlayWrap?.status?.withdrawnAt) return "";
    return formatTime(this.parlayWrap.status.withdrawnAt, length, timezone) || "";
  }

  getParlayTitle(length, role) {
    const numLegs = this?.parlayLegs?.length || Object.keys(this.parlayWrap?.parlayLegStatuses || {})?.length || 0;
    const parlayType = role === "recipient" ? "Reverse Parlay" : "Parlay";
    const odds = role === "recipient" ? this.parlayWrap?.odds?.recipient?.odds : this.parlayWrap?.odds?.proposer?.odds;

    let oddsSection = "";
    if (length === "long" && odds) {
      oddsSection = `@ ${odds}`;
    } else if (length === "medium" && odds) {
      oddsSection = `${odds}`;
    }
    return `${numLegs}-Leg ${parlayType} ${oddsSection}`.trim();
  }

  getParlayPicksList(length, role) {
    const legWagerIDList = Object.keys(this.parlayWrap?.parlayLegStatuses || {}).sort((wagerID_A, wagerID_B) => {
      const startsAt_A = this?.parlayWrap?.parlayLegStatuses?.[wagerID_A]?.startsAt?.getTime?.() || 0;
      const startsAt_B = this?.parlayWrap?.parlayLegStatuses?.[wagerID_B]?.startsAt?.getTime?.() || 0;
      if (startsAt_A !== startsAt_B) return startsAt_A - startsAt_B;
      return wagerID_A.localeCompare(wagerID_B);
    });

    return legWagerIDList.reduce((parlayPicksList, legWagerID, i) => {
      if (!legWagerID) return parlayPicksList;
      const { graded, winner, loser, wash, cancelled } = this?.parlayWrap?.parlayLegStatuses?.[legWagerID] || {};
      const legWager = this.parlayLegs.find((w) => w?.wagerID === legWagerID);

      let title = `Leg ${i + 1}`;
      if (legWager?.wagerID) {
        const legWagerDisplays = new WagerDisplays(legWager);
        title = legWagerDisplays.getPickTitle(length, role) || title;
      }

      let icon = "";
      let color = DARK_CONTENT_COLOR || "";
      if (cancelled) {
        icon = "🔄";
        color = WARNING_COLOR;
      } else if (graded) {
        if (graded && winner === role) {
          icon = "✅";
          color = SUCCESS_COLOR;
        } else if (graded && loser === role) {
          icon = "❌";
          color = ERROR_COLOR;
        } else {
          icon = "🔄";
          color = WARNING_COLOR;
        }
      }

      parlayPicksList.push({ title, icon, color });
      return parlayPicksList;
    }, []);
  }

  getDisplayString(displayType) {
    if (displayType === "parlayPicksList_proposer_long") return this.getParlayPicksList("long", "proposer");
    if (displayType === "parlayPicksList_proposer_medium") return this.getParlayPicksList("medium", "proposer");
    if (displayType === "parlayPicksList_proposer_short") return this.getParlayPicksList("short", "proposer");
    if (displayType === "parlayPicksList_recipient_long") return this.getParlayPicksList("long", "recipient");
    if (displayType === "parlayPicksList_recipient_medium") return this.getParlayPicksList("medium", "recipient");
    if (displayType === "parlayPicksList_recipient_short") return this.getParlayPicksList("short", "recipient");

    // For parlayWraps the pickTitle is the same as the wagerTitle
    if (displayType === "wagerTitle_proposer_long") return this.getParlayTitle("long", "proposer");
    if (displayType === "wagerTitle_proposer_medium") return this.getParlayTitle("medium", "proposer");
    if (displayType === "wagerTitle_proposer_short") return this.getParlayTitle("short", "proposer");
    if (displayType === "wagerTitle_recipient_long") return this.getParlayTitle("long", "recipient");
    if (displayType === "wagerTitle_recipient_medium") return this.getParlayTitle("medium", "recipient");
    if (displayType === "wagerTitle_recipient_short") return this.getParlayTitle("short", "recipient");
    if (displayType === "pickTitle_long_proposer") return this.getParlayTitle("long", "proposer");
    if (displayType === "pickTitle_medium_proposer") return this.getParlayTitle("medium", "proposer");
    if (displayType === "pickTitle_short_proposer") return this.getParlayTitle("short", "proposer");
    if (displayType === "pickTitle_long_recipient") return this.getParlayTitle("long", "recipient");
    if (displayType === "pickTitle_medium_recipient") return this.getParlayTitle("medium", "recipient");
    if (displayType === "pickTitle_short_recipient") return this.getParlayTitle("short", "recipient");

    const USES_WAGER_DISPLAY = [
      "expirationTime_local_short",
      "expirationTime_local_medium",
      "expirationTime_local_long",
      "expirationTime_ET_short",
      "expirationTime_ET_medium",
      "expirationTime_ET_long",
      "responseTime_local_short",
      "responseTime_local_medium",
      "responseTime_local_long",
      "responseTime_ET_short",
      "responseTime_ET_medium",
      "responseTime_ET_long",
      "gradedTime_local_short",
      "gradedTime_local_medium",
      "gradedTime_local_long",
      "gradedTime_ET_short",
      "gradedTime_ET_medium",
      "gradedTime_ET_long",
      "withdrawnTime_local_short",
      "withdrawnTime_local_medium",
      "withdrawnTime_local_long",
      "withdrawnTime_ET_short",
      "withdrawnTime_ET_medium",
      "withdrawnTime_ET_long",
      "createTime_local_short",
      "createTime_local_medium",
      "createTime_local_long",
      "createTime_ET_short",
      "createTime_ET_medium",
      "createTime_ET_long",
      "oddsAppliedNotice",
    ];

    if (USES_WAGER_DISPLAY.includes(displayType)) {
      const singleWagerDisplays = new WagerDisplays(this.parlayWrap);
      return singleWagerDisplays.getDisplayString(displayType);
    }

    const override = this.PARLAY_DISPLAY_OVERRIDES[displayType];
    if (override) return override(this.parlayWrap);

    const result = this?.parlayLegs?.reduce((acc, leg) => {
      const wagerDisplays = new WagerDisplays(leg);
      const display = wagerDisplays?.getDisplayString?.(displayType);
      if (display?.length) {
        acc.push(display);
      }

      return acc;
    }, []);

    return result?.every((w) => typeof w === "string") ? result?.join("\n") : result?.flat();
  }
}
exports.ParlayDisplays = ParlayDisplays;
