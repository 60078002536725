import { FormControlLabel, Stack, Switch } from "@mui/material";
import { useFormikContext } from "formik";

export const ToggledInput = ({ toggleName, InputComponent, ...inputProps }) => {
  const { values, setFieldValue } = useFormikContext();

  const hasToggle = !!toggleName;
  const isToggled = !!values?.[toggleName];

  if (!hasToggle) return <InputComponent {...inputProps} />;

  if (!InputComponent || !inputProps?.name) return null;

  const handleToggleChange = (event) => setFieldValue(toggleName, event.target.checked);

  return (
    <Stack direction="row" spacing={2}>
      <Switch checked={isToggled} onChange={handleToggleChange} name={toggleName} />
      <InputComponent {...inputProps} disabled={hasToggle ? !isToggled : inputProps?.disabled} />
    </Stack>
  );
};
