import React, { useEffect, useMemo, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Typography, MenuItem, Select } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { formatLookups, setOverrideLookup } from "@wagerlab/utils/aggregator/lookups";
import _ from "lodash";
import { copyData } from "@wagerlab/utils/data/mutations";
import { SelectMenu } from "@wagerlab/admin/src/components/inputs/SelectMenu";

export const ManageExistingLookups = ({ loading, lookupData, handleSubmit, handleRefresh }) => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [newLookupData, setNewLookupData] = useState(copyData(lookupData || {}));

  useMemo(() => {
    setNewLookupData(copyData(lookupData || {}));
  }, [lookupData]);

  const { overrideList, clashList, searchList, idOptionsList, idOptionsMap } = useMemo(() => {
    const idOptionsMapping = {};
    const overridesArr = [];
    const searchesArr = [];
    const clashesArr = [];
    Object.entries(newLookupData.overrides || {}).forEach(([lookupString, lookupID]) => {
      overridesArr.push({ lookupString, lookupID, lookupIDDisplay: lookupID });
      idOptionsMapping[lookupID] = lookupID; // Set the value to something else to adjust the displays
    });
    Object.entries(newLookupData.clashes || {}).forEach(([lookupString, lookupIDs]) => {
      clashesArr.push({ lookupString, lookupIDs, lookupIDDisplay: lookupIDs.join(", ") });
      lookupIDs.forEach((lookupID) => (idOptionsMapping[lookupID] = lookupID)); // Set the value to something else to adjust the displays
    });
    Object.entries(newLookupData.searches || {}).forEach(([lookupString, lookupID]) => {
      searchesArr.push({ lookupString, lookupID, lookupIDDisplay: lookupID });
      idOptionsMapping[lookupID] = lookupID; // Set the value to something else to adjust the displays
    });
    const idOptionsArr = Object.entries(idOptionsMapping).map(([lookupID, lookupIDDisplay]) => ({ lookupID, lookupIDDisplay }));
    idOptionsArr.sort((a, b) => a.lookupIDDisplay.localeCompare(b.lookupIDDisplay));
    overridesArr.sort((a, b) => a.lookupString.localeCompare(b.lookupString));
    clashesArr.sort((a, b) => a.lookupString.localeCompare(b.lookupString));
    searchesArr.sort((a, b) => a.lookupString.localeCompare(b.lookupString));

    return { overrideList: overridesArr, clashList: clashesArr, searchList: searchesArr, idOptionsList: idOptionsArr, idOptionsMap: idOptionsMapping };
  }, [newLookupData]);

  useEffect(() => {
    if (error) setTimeout(() => setError(""), 15000);
  }, [error]);
  useEffect(() => {
    if (success) setTimeout(() => setSuccess(""), 15000);
  }, [success]);

  const handleAddNewLookup = (newLookupString, newLookupID) => {
    if (!newLookupString || !newLookupID || !idOptionsMap[newLookupID]) {
      setError("Invalid lookup");
      return false;
    }
    const updatedLookupData = setOverrideLookup(copyData(newLookupData), newLookupString, newLookupID);
    setNewLookupData(updatedLookupData);
    return true;
  };

  const handleDeleteLookup = (lookupString, lookupType) => {
    const lookupTypeLower = lookupType?.toLowerCase?.() || "";
    if (!["searches", "overrides", "clashes"].includes(lookupTypeLower)) {
      setError("Invalid lookup type");
      return;
    }
    const updatedLookupData = copyData(newLookupData);
    if (lookupTypeLower === "searches") {
      _.unset(updatedLookupData, `searches.${lookupString}`);
    } else if (lookupTypeLower === "overrides") {
      _.unset(updatedLookupData, `overrides.${lookupString}`);
    } else if (lookupTypeLower === "clashes") {
      _.unset(updatedLookupData, `clashes.${lookupString}`);
    }
    setNewLookupData(updatedLookupData);
  };

  const handleSubmitLookups = async () => {
    setSuccess("");
    setError("");
    const formattedNewLookups = formatLookups(newLookupData);
    const submitError = await handleSubmit(formattedNewLookups);
    if (submitError) {
      setError(submitError);
      return;
    } else {
      setSuccess("Lookups saved successfully");
    }
  };

  //      {info && <Typography sx={{ color: "green", fontSize: 20, margin: "10px" }}>{info}</Typography>}
  if (!lookupData) {
    return (
      <TableContainer component={Paper} style={{ padding: 20 }}>
        <Typography sx={{ color: "red", fontSize: 20, margin: "10px" }}>No lookup data loaded</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Button sx={{ marginLeft: "20px" }} disabled={loading} variant="contained" color="secondary" onClick={handleRefresh}>
                  Load Data
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    );
  }

  return (
    <TableContainer component={Paper} style={{ padding: 20 }}>
      {error && <Typography sx={{ color: "red", fontSize: 20, margin: "10px" }}>{error}</Typography>}
      {success && <Typography sx={{ color: "green", fontSize: 20, margin: "10px" }}>{success}</Typography>}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "40%" }}>
              <Button sx={{}} disabled={loading} variant="contained" color="primary" onClick={handleRefresh} fullWidth>
                Reload Data
              </Button>
            </TableCell>
            <TableCell style={{ width: "40%" }}>
              <Button sx={{}} disabled={loading} variant="contained" color="success" onClick={handleSubmitLookups} fullWidth>
                Submit Changes
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>

      <AddNewLookup idOptionsList={idOptionsList} handleAdd={handleAddNewLookup} loading={loading} />

      <LookupTypeTable type="overrides" lookupList={overrideList} loading={loading} handleDelete={handleDeleteLookup} />
      <LookupTypeTable type="clashes" lookupList={clashList} loading={loading} handleDelete={handleDeleteLookup} />
      <LookupTypeTable type="searches" lookupList={searchList} loading={loading} handleDelete={handleDeleteLookup} />
    </TableContainer>
  );
};

const LookupTypeTable = ({ type, lookupList, handleDelete, loading }) => {
  return (
    <Table sx={{ m: 2 }}>
      <TableHead>
        <TableRow style={{ fontSize: 28, fontWeight: "bold" }}>{type?.toUpperCase?.() || "UNKNOWN LOOKUP TYPE ERROR"}</TableRow>
      </TableHead>
      <TableBody>
        {!lookupList?.length ? <TableRow style={{ fontSize: 12 }}>No values configured</TableRow> : null}
        {(lookupList || []).map(({ lookupString, lookupIDDisplay }) => {
          return (
            <TableRow key={lookupString}>
              <TableCell style={{ width: "35%" }}>{lookupString || "ERROR"}</TableCell>
              <TableCell style={{ width: "5%", fontSize: 24, fontWeight: "bold" }}>→</TableCell>
              <TableCell style={{ width: "35%" }}>{lookupIDDisplay || "ERROR"}</TableCell>
              <TableCell style={{ width: "25%" }}>
                <Button disabled={loading} onClick={() => handleDelete(lookupString, type)}>
                  <DeleteIcon color="error" />
                </Button>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const AddNewLookup = ({ idOptionsList, handleAdd, loading }) => {
  const [newLookupString, setNewLookupString] = useState("");
  const [newLookupID, setNewLookupID] = useState("");
  const addDisabled = loading || !newLookupString || !newLookupID;

  const handleSubmit = () => {
    const success = handleAdd(newLookupString, newLookupID);
    if (success) {
      setNewLookupString("");
      setNewLookupID("");
    }
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{ width: "35%" }}>
            <TextField disabled={loading} value={newLookupString || ""} onChange={(event) => setNewLookupString(event?.target?.value || "")} fullWidth />
          </TableCell>
          <TableCell style={{ width: "5%", fontSize: 24, fontWeight: "bold" }}>→</TableCell>
          <TableCell style={{ width: "35%" }}>
            <Select value={newLookupID || ""} onChange={(event) => setNewLookupID(event?.target?.value || "")} disabled={loading} fullWidth>
              {idOptionsList.map(({ lookupID, lookupIDDisplay }) => (
                <MenuItem key={lookupID} value={lookupID}>
                  {lookupIDDisplay}
                </MenuItem>
              ))}
            </Select>
          </TableCell>

          <TableCell style={{ width: "25%" }}>
            <Button disabled={addDisabled} onClick={handleSubmit} fullWidth variant="contained" color="primary">
              Add Override
            </Button>
          </TableCell>
        </TableRow>
      </TableHead>
    </Table>
  );
};
