import React from "react";
import { IconButton, TextField as MaterialTextField, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useField, useFormikContext } from "formik";
import { isString } from "@wagerlab/utils/data/types";

export const TextField = ({ name, tooltip, disabled = false, ...otherProps }) => {
  const { isSubmitting } = useFormikContext();
  const [field, { touched, error }, { setValue }] = useField(name);

  const isDisabled = !!(isSubmitting || disabled);
  const hasErrors = !!(touched && error && isString(error));

  const inputProps = tooltip
    ? {
        endAdornment: (
          <Tooltip title={tooltip} placement="top">
            <IconButton color="primary" aria-label="help icon" component="span" sx={{ mr: 2 }}>
              <InfoOutlinedIcon sx={{ color: "lightblue" }} />
            </IconButton>
          </Tooltip>
        ),
      }
    : null;

  return <MaterialTextField {...field} {...otherProps} disabled={isDisabled} fullWidth={true} variant={"outlined"} error={hasErrors} helperText={hasErrors ? error : null} InputProps={inputProps} />;
};
