import { useFormikContext } from "formik";
import { useEffect } from "react";

export const EventFormDependentFields = ({}) => {
  const { values, setFieldValue } = useFormikContext();
  const { eventID, oddsCloseType, oddsCloseDate, started, ended, cancelled, delayed, propOddsList } = values || {};

  const allOddsCancelled = propOddsList.every((po) => po?.cancelled);
  const allCancelled = allOddsCancelled || cancelled;
  const allEnded = allCancelled || ended;

  useEffect(() => {
    if (allCancelled && !cancelled) setFieldValue("cancelled", true);
    if (allEnded && !ended) setFieldValue("ended", true);

    propOddsList.forEach((propOdd, index) => {
      if (allCancelled && !propOdd?.cancelled) setFieldValue(`propOddsList[${index}].cancelled`, true);
      if (allEnded && propOdd?.available) setFieldValue(`propOddsList[${index}].available`, false);
      if (allEnded && propOdd?.delayed) setFieldValue(`propOddsList[${index}].delayed`, false);
    });
  }, [allOddsCancelled, allCancelled, allEnded]);

  return null;
};
