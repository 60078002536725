import React from "react";
import { Box, Grid } from "@mui/material";
import { Form } from "formik";
import { SubmitButton } from "@wagerlab/admin/src/components/inputs/SubmitButton";
import { TextField } from "@wagerlab/admin/src/components/inputs/TextField";
import { SPORT_CONFIG } from "@wagerlab/utils/sports/sportConfig";
import { LEAGUE_CONFIG, SPORT_ALL_LEAGUES } from "@wagerlab/utils/sports/leagueConfig";
import { useFormikContext } from "formik";
import { SelectMenu } from "@wagerlab/admin/src/components/inputs/SelectMenu";
import { SelectBoolean } from "@wagerlab/admin/src/components/inputs/SelectBoolean";
import { DateTimePicker } from "@wagerlab/admin/src/components/inputs/DateTimePicker";
import { EVENT_TYPES } from "@wagerlab/admin/src/utils/events/constants";
import { ToggledInput } from "@wagerlab/admin/src/components/inputs/ToggledInput";

const sportOptions = Object.keys(SPORT_CONFIG).reduce((sportOpts, sportID) => ({ ...sportOpts, [sportID]: SPORT_CONFIG?.[sportID]?.name || SPORT_CONFIG?.[sportID]?.shortName || sportID }), {});

export const EventQueryForm = () => {
  const { values } = useFormikContext();

  const selectedSportID = values?.sportID;
  const leageIDOptions = SPORT_ALL_LEAGUES?.[selectedSportID] || Object.keys(LEAGUE_CONFIG);
  const leagueOptions = leageIDOptions.reduce((leagueOpts, leagueID) => ({ ...leagueOpts, [leagueID]: LEAGUE_CONFIG?.[leagueID]?.name || LEAGUE_CONFIG?.[leagueID]?.shortName || leagueID }), {});

  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <SelectMenu name="type" label="Event Type" options={{ [EVENT_TYPES.PROP]: "prop - Custom", [EVENT_TYPES.MATCH]: "match - Auto-Generated" }} required />
        </Grid>
        <Grid item xs={12} md={6}>
          <ToggledInput toggleName={"eventIDIncluded"} InputComponent={TextField} name="eventID" label="Event ID" />
        </Grid>
        <Grid item xs={12} md={6}>
          <ToggledInput toggleName={"eventNameIncluded"} InputComponent={TextField} name="eventName" label="Event Name" />
        </Grid>
        <Grid item xs={12} md={6}>
          <ToggledInput toggleName={"sportIDIncluded"} InputComponent={SelectMenu} name="sportID" label="Sport ID" options={sportOptions} />
        </Grid>
        <Grid item xs={12} md={6}>
          <ToggledInput toggleName={"leagueIDIncluded"} InputComponent={SelectMenu} name="leagueID" label="League ID" options={leagueOptions} />
        </Grid>

        <Grid item xs={12} md={6}>
          <ToggledInput toggleName={"homeTeamIDIncluded"} InputComponent={TextField} name="homeTeamID" label="Home Team ID" tooltip="ex. LOS_ANGELES_LAKERS_NBA" />
        </Grid>

        <Grid item xs={12} md={6}>
          <ToggledInput toggleName={"awayTeamIDIncluded"} InputComponent={TextField} name="awayTeamID" label="Away Team ID" tooltip="ex. LOS_ANGELES_LAKERS_NBA" />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ToggledInput toggleName={"startsAtIncluded"} InputComponent={DateTimePicker} name="startsAt" label="Start Time" required />
        </Grid>

        <Grid item xs={6} sm={4}>
          <ToggledInput toggleName={"anyOddsAvailableIncluded"} InputComponent={SelectBoolean} name="anyOddsAvailable" label="Any Odds Available" />
        </Grid>
        <Grid item xs={6} sm={4}>
          <ToggledInput toggleName={"marketOddsAvailableIncluded"} InputComponent={SelectBoolean} name="marketOddsAvailable" label="Market Odds Available" />
        </Grid>
        <Grid item xs={6} sm={4}>
          <ToggledInput toggleName={"startedIncluded"} InputComponent={SelectBoolean} name="started" label="Started" />
        </Grid>
        <Grid item xs={6} sm={4}>
          <ToggledInput toggleName={"endedIncluded"} InputComponent={SelectBoolean} name="ended" label="Completed" />
        </Grid>
        <Grid item xs={6} sm={4}>
          <ToggledInput toggleName={"cancelledIncluded"} InputComponent={SelectBoolean} name="cancelled" label="Cancelled" />
        </Grid>
        <Grid item xs={6} sm={4}>
          <ToggledInput toggleName={"delayedIncluded"} InputComponent={SelectBoolean} name="delayed" label="Delayed" />
        </Grid>
        <Grid item xs={6} sm={4}>
          <ToggledInput toggleName={"finalizedIncluded"} InputComponent={SelectBoolean} name="finalized" label="Finalized" />
        </Grid>
      </Grid>
      <Box
        sx={{
          marginTop: 8,
          marginBottom: 8,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <SubmitButton type="submit" size="medium" sx={{ width: 200 }} text={"Run Query"} />
      </Box>
    </Form>
  );
};
