import { Box, Typography } from "@mui/material";
import React from "react";

const ColorLegend = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "20px" }}>
      <Typography variant="h6" component="div" gutterBottom>
        Suggestion Color Codes
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ display: "flex", alignItems: "center", marginRight: "20px" }}>
          <Box sx={{ width: "20px", height: "20px", backgroundColor: "#FFC107", marginRight: "10px" }} />
          <Box sx={{ width: "20px", height: "20px", backgroundColor: "#FFF150", marginRight: "10px" }} />
          <Typography>Best to Worst Match (AI)</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", marginRight: "20px" }}>
          <Box sx={{ width: "20px", height: "20px", backgroundColor: "#AED581", marginRight: "10px" }} />
          <Box sx={{ width: "20px", height: "20px", backgroundColor: "#C5E1A5", marginRight: "10px" }} />
          <Box sx={{ width: "20px", height: "20px", backgroundColor: "#D5E8D5", marginRight: "10px" }} />
          <Box sx={{ width: "20px", height: "20px", backgroundColor: "#E5F0E5", marginRight: "10px" }} />
          <Typography>Best to Worst Match (ID String Compare)</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ width: "20px", height: "20px", backgroundColor: "#FF7F7F", marginRight: "10px" }} />
          <Typography>Poor Match</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ColorLegend;
