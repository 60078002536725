import { setObjVal } from "@wagerlab/utils/data/mutations";
import { isEmptyObject } from "@wagerlab/utils/data/types";
import * as Yup from "yup";

export const initializeFormData = (configData) => {
  return Object.entries(configData?.affiliateConfig?.affiliates || {})
    .reduce((affiliatesList, [affiliateID, affiliateConfig]) => {
      if (!affiliateID || affiliateID !== affiliateConfig?.affiliateID) return affiliatesList;
      affiliatesList.push({
        ...affiliateConfig,
      });
      return affiliatesList;
    }, [])
    .sort((a, b) => a?.name?.localeCompare?.(b?.name) || 0);
};

export const validateFormData = (affiliatesListFormData, prevConfigData) => {
  return "";
};

export const buildConfigFromFormData = (affiliatesListFormData, prevConfigData, newConfigData) => {
  if (!newConfigData || !prevConfigData || !affiliatesListFormData?.length) return newConfigData;

  const newAffiliates = affiliatesListFormData.reduce((affiliates, affiliate) => {
    if (!affiliate?.affiliateID) return affiliates;
    affiliates[affiliate.affiliateID] = { ...affiliate, version: 2 };
    return affiliates;
  }, {});
  if (!isEmptyObject(newAffiliates)) setObjVal(newConfigData, "affiliateConfig.affiliates", newAffiliates);

  return newConfigData;
};

const uniqueAffiliateIDTest = {
  name: "unique-affiliateID",
  message: "Each affiliate ID must be unique",
  test: function (value) {
    const ids = value.map((v) => v?.affiliateID || "");
    const uniqueIDs = new Set(ids);
    return ids.length === uniqueIDs.size;
  },
};

export const formFieldValidation = Yup.array()
  .of(
    Yup.object({
      affiliateID: Yup.string().required("Affiliate ID is required"),
      enabled: Yup.boolean().required("Enabled flag is required"),
      name: Yup.string().required("Name is required"),
      title: Yup.string().required("Title is required"),
      cta: Yup.string().required("CTA is required"),
      bannerImg: Yup.string().url("Must be a valid URL").required("Banner image URL is required"),
      defaultLanding: Yup.string().url("Must be a valid URL").required("Default landing URL is required"),
      darkColor: Yup.string()
        .matches(/^#([0-9A-F]{3}){1,2}$/i, "Must be a valid hex color")
        .required("Dark color is required"),
      accentColor: Yup.string()
        .matches(/^#([0-9A-F]{3}){1,2}$/i, "Must be a valid hex color")
        .required("Accent color is required"),
      lightColor: Yup.string()
        .matches(/^#([0-9A-F]{3}){1,2}$/i, "Must be a valid hex color")
        .required("Light color is required"),
      whiteLogoImg: Yup.string().url("Must be a valid URL").required("White logo image URL is required"),
      colorLogoImg: Yup.string().url("Must be a valid URL").required("Color logo image URL is required"),
      wannaBetPromptText: Yup.string().required("Wanna bet prompt text is required"),
      wannaBetButtonText: Yup.string().required("Wanna bet button text is required"),
      bottomSheetTitleText: Yup.string().required("Bottom sheet title text is required"),
      bottomSheetSubTitleText: Yup.string().required("Bottom sheet subtitle text is required"),
      // Optional fields
      promoCode: Yup.string(),
      jsInjections: Yup.array().of(
        Yup.object().shape({
          elementID: Yup.string().required("Element ID is required"),
          injectedPlaceholder: Yup.string(),
          injectedValue: Yup.string(),
        })
      ),
      successUrlContains: Yup.string(),
    })
  )
  .required("Affiliates list is missing")
  .min(1, "At least one affiliate is required")
  .test(uniqueAffiliateIDTest);
