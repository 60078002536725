import React from "react";
import { Grid, Typography } from "@mui/material";
import { SelectBoolean } from "@wagerlab/admin/src/components/inputs/SelectBoolean";

export const EventFormStatusStates = ({ isNew }) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">Status</Typography>
      </Grid>
      <Grid item xs={6} sm={3}>
        <SelectBoolean name="started" label="Started" />
      </Grid>
      <Grid item xs={6} sm={3}>
        <SelectBoolean name="delayed" label="Delayed" />
      </Grid>
    </>
  );
};
