import { Button, Grid, Typography } from "@mui/material";
import { TextField } from "@wagerlab/admin/src/components/inputs/TextField";
import { SelectMenu } from "@wagerlab/admin/src/components/inputs/SelectMenu";
import { useState, Fragment } from "react";
import { useFormikContext, FieldArray } from "formik";
import { NumberSlider } from "@wagerlab/admin/src/components/inputs/NumberSlider";
import { getOdds_fromDirectionValue } from "@wagerlab/admin/src/utils/events/eventForm";
import { SelectBoolean } from "@wagerlab/admin/src/components/inputs/SelectBoolean";

export const EventFormPropOdds = ({ isNew }) => {
  const { values, initialValues } = useFormikContext();
  const prevEnded = initialValues?.ended || initialValues?.cancelled;
  const newEnded = prevEnded || values?.ended || values?.cancelled;

  const propOddsList = values?.propOddsList || [];

  return (
    <>
      <FieldArray
        name="propOddsList"
        render={(arrayHelpers) => (
          <>
            <Grid item xs={12}>
              <Typography variant="h5">Bets & Odds</Typography>
            </Grid>
            {propOddsList.map((item, index) => {
              return (
                <Fragment key={item?.propStatID ?? index}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h5">{item?.propStatID?.toUpperCase?.() || "???"}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField name={`propOddsList[${index}].propTitle`} label="Prop Title" required />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField name={`propOddsList[${index}].propDetails`} label="Prop Details" />
                    </Grid>

                    <Grid item xs={4}>
                      <SelectBoolean name={`propOddsList[${index}].available`} label="Available" required disabled={newEnded} />
                    </Grid>

                    <Grid item xs={4}>
                      <SelectBoolean name={`propOddsList[${index}].numericScoring`} label="Numeric Scoring" disabled />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography>{`Side 1`}</Typography>
                      <TextField name={`propOddsList[${index}].side1Display`} label="Side 1 Display Name" required />
                      <Typography>{`Side 1 Odds: ${getOdds_fromDirectionValue(item?.side1OddsDirection, item?.side1OddsValue)}`}</Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography>{`Side 2`}</Typography>
                      <TextField name={`propOddsList[${index}].side2Display`} label="Side 2 Display Name" required />
                      <Typography>{`Side 2 Odds: ${getOdds_fromDirectionValue(item?.side1OddsDirection === "pos" ? "neg" : "pos", item?.side1OddsValue)}`}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography>{`Set Odds (Basis is Side 1)`}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <SelectMenu name={`propOddsList[${index}].side1OddsDirection`} label="Direction" options={{ pos: "+", neg: "-" }} disabled={newEnded} />
                    </Grid>
                    <Grid item xs={8} sx={{ marginLeft: 2, marginTop: 2 }}>
                      <NumberSlider name={`propOddsList[${index}].side1OddsValue`} defaultValue={100} step={10} min={100} max={1000} valueLabelDisplay="on" disabled={newEnded} />
                    </Grid>
                  </Grid>
                </Fragment>
              );
            })}

            {isNew || newEnded ? null : (
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (propOddsList.some((o) => o?.propStatID === `prop${propOddsList.length}`)) return;
                    arrayHelpers.push({
                      propIndex: propOddsList.length,
                      propStatID: `prop${propOddsList.length}`,
                      propTitle: "",
                      propDetails: "",
                      numericScoring: false,
                      side1Display: "",
                      side2Display: "",
                      available: true,
                      side1OddsDirection: "pos",
                      side1OddsValue: 100,
                    });
                  }}
                >
                  Add Odds
                </Button>
              </Grid>
            )}
          </>
        )}
      />
    </>
  );
};
