const { stripEmptyValues, setObjVal } = require("@wagerlab/utils/data/mutations");

exports.validateEvent = (event, eventBookOdds) => {
  const { eventID: eventID_ebo, sportID: sportID_ebo, leagueID: leagueID_ebo, type: type_ebo } = eventBookOdds || {};
  const { eventID: eventID_e, sportID: sportID_e, leagueID: leagueID_e, type: type_e } = event || {};

  const eventMetaIsValid = !!(eventID_e && sportID_e && leagueID_e && type_e);
  const eventBookOddsMetaIsValid = !!(eventID_ebo && sportID_ebo && leagueID_ebo && type_ebo);

  const finalizedMatches = !!event?.status?.finalized === !!eventBookOdds?.status?.finalized;
  const eventEndedIfFinalized = event?.status?.finalized ? event?.status?.ended : true;
  const eventBookOddsEndedIfFinalized = eventBookOdds?.status?.finalized ? eventBookOdds?.status?.ended : true;

  const eventIDsMatch = eventID_e === eventID_ebo;

  const eventBookOddsHasOdds = Object.keys(eventBookOdds?.odds || {}).length > 0;

  const eventIsValid = eventMetaIsValid && eventEndedIfFinalized;
  const eventBookOddsIsValid = eventIDsMatch && eventBookOddsMetaIsValid && eventBookOddsHasOdds && finalizedMatches && eventBookOddsEndedIfFinalized;

  const hasMarketOdds = !!eventBookOddsIsValid;
  const hasAnyOdds = !!(hasMarketOdds || Object.keys(event?.odds || {}).length > 0);
  if (eventIsValid && hasMarketOdds !== event?.status?.hasMarketOdds) setObjVal(event, "status.hasMarketOdds", hasMarketOdds);
  if (eventIsValid && hasAnyOdds !== event?.status?.hasAnyOdds) setObjVal(event, "status.hasAnyOdds", hasAnyOdds);

  return {
    event: eventIsValid ? stripEmptyValues(event) : null,
    eventBookOdds: eventBookOddsIsValid ? stripEmptyValues(eventBookOdds) : null,
  };
};
