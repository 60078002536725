import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { initializeFormData, buildEventFromFormData, formFieldValidation, validateFormData } from "@wagerlab/admin/src/utils/events/eventForm";
import { createEvent, createEventShort, saveEventChanges } from "@wagerlab/admin/src/utils/events/database";
import { EventFormIdentifiers } from "@wagerlab/admin/src/components/events/EventFormIdentifiers";
import { EventFormStatusTiming } from "@wagerlab/admin/src/components/events/EventFormStatusTiming";
import { EventFormStatusDisplays } from "@wagerlab/admin/src/components/events/EventFormStatusDisplays";
import { EventFormWrapper } from "@wagerlab/admin/src/components/events/EventFormWrapper";
import { useParams } from "react-router-dom";
import { EventFormPropOdds } from "@wagerlab/admin/src/components/events/EventFormPropOdds";

export const CreateEvent = () => {
  const [initialEventForm, setInitialEventForm] = useState(initializeFormData(null, null));
  const queryClient = useQueryClient();
  const { eventType } = useParams();

  const handleSubmit = async (formData, { resetForm }) => {
    const validationError = validateFormData(formData, null, null);
    if (validationError) return validationError;
    const prevToNew = ({ event: prevEvent, eventBookOdds: prevEventBookOdds }) => buildEventFromFormData(formData, prevEvent, prevEventBookOdds);
    const saveError = await saveEventChanges(null, null, prevToNew, true);
    if (saveError) return saveError;
    queryClient.invalidateQueries({ queryKey: ["events"] });
    const nextEventForm = initializeFormData(null, null);
    resetForm({ values: nextEventForm });
    setInitialEventForm(nextEventForm);
    return "";
  };

  if (eventType !== "prop") {
    return (
      <Box sx={{ width: "100%", height: 200, display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Typography variant="h4">Only prop events are currently supported</Typography>
      </Box>
    );
  }

  return (
    <EventFormWrapper
      title={"New Event"}
      loading={false}
      eventData={null}
      eventBookOddsData={null}
      formData={initialEventForm}
      formValidation={formFieldValidation}
      handleSubmit={handleSubmit}
      submitButtonText={"Create Event"}
    >
      <Grid container spacing={2}>
        <EventFormIdentifiers isNew={true} />
        <EventFormStatusTiming isNew={true} />
        <EventFormStatusDisplays isNew={true} />
        <EventFormPropOdds isNew={true} />
      </Grid>
    </EventFormWrapper>
  );
};
