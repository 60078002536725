import React from "react";
import { TableCell } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ellipsisStyle } from "@wagerlab/admin/src/components/lookups/shared/add/styles";

export const NameCell = ({ sourceID, sourceEntityID, name, unknownEntity, handleOpenModal, lookupKey }) => {
  const sourceDisplay = sourceID && sourceEntityID ? `${sourceID}: ${sourceEntityID}` : sourceID || "";

  return (
    <TableCell sx={{ verticalAlign: "top" }}>
      <div style={{ ...ellipsisStyle, fontWeight: "bold", fontSize: 18 }} title={name}>
        {name}
      </div>
      {sourceDisplay ? (
        <div style={ellipsisStyle} title={sourceDisplay}>
          {sourceDisplay}
        </div>
      ) : null}
      <IconButton onClick={(event) => handleOpenModal(event, unknownEntity, lookupKey)}>
        <VisibilityIcon />
      </IconButton>
    </TableCell>
  );
};
