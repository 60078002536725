import { Box, Grid, Stack, Typography } from "@mui/material";
import { isNumber } from "@wagerlab/utils/data/types";
import { SelectBoolean } from "@wagerlab/admin/src/components/inputs/SelectBoolean";
import { useFormikContext } from "formik";
import { TextField } from "@wagerlab/admin/src/components/inputs/TextField";
import { getOddsDisplay } from "@wagerlab/utils/displays";
import { SubmitButton } from "@wagerlab/admin/src/components/inputs/SubmitButton";

export const EventReGraderForm = ({ prevEvent }) => {
  const { values, setFieldValue } = useFormikContext();

  const resultsSummariesList = values?.resultsSummaries || [];
  const allCancelled = !!values?.cancelled;

  const onCancelledChange = (newCancelled) => {
    const newResultsSummaries = resultsSummariesList.map((rs) => ({ ...rs, cancelled: !!newCancelled }));
    setFieldValue("resultsSummaries", newResultsSummaries);
  };

  return (
    <>
      <Grid container xs={12} spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField name="eventID" label="Event ID" disabled />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectBoolean
            name="cancelled"
            label="Cancelled"
            onChange={onCancelledChange}
            tooltip="Whether ALL odds/bets should be set to cancelled. Be careful setting this as it will automatically set all odds' cancelled states to this value"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField name="displayShort" label="Short Update" tooltip="ex: G-1 or C-EX" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField name="displayLong" label="Long Update" tooltip="One sentence description: Graded as a win for side 1." />
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        {resultsSummariesList.map((item, index) => {
          const { periodID, statID, statEntityID, score, cancelled, oddIDs } = item || {};
          const prevSavedScore = isNumber(prevEvent?.results?.[periodID]?.[statEntityID]?.[statID]) ? prevEvent?.results?.[periodID]?.[statEntityID]?.[statID] : "<NONE>";
          const firstOddData = prevEvent?.odds?.[oddIDs?.[0]];
          const fallbackDisplayName = `${periodID} ${statEntityID} ${statID}`;
          const displayNameLong = getOddsDisplay("resultStatName_long", firstOddData, prevEvent) || fallbackDisplayName;
          const displayNameShort = getOddsDisplay("resultStatName_short", firstOddData, prevEvent) || fallbackDisplayName;
          const prevScoreContext = `prev: ${prevSavedScore}`;

          return (
            <Grid item xs={12} md={6} key={index}>
              <Stack direction="row" spacing={2}>
                <Typography variant="subtitle2" sx={{ width: "66%" }}>
                  {displayNameLong}
                </Typography>
                <Typography variant="body2" sx={{ width: "34%" }}>
                  {prevScoreContext}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2}>
                <TextField name={`resultsSummaries[${index}].score`} label={displayNameShort} type={"number"} fullWidth sx={{ width: "66%" }} disabled={cancelled} />
                <SelectBoolean name={`resultsSummaries[${index}].cancelled`} label="Cancelled" required sx={{ width: "34%" }} disabled={allCancelled} />
              </Stack>
            </Grid>
          );
        })}
      </Grid>

      <Box
        sx={{
          marginTop: 8,
          marginBottom: 8,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <SubmitButton type="submit" size="medium" sx={{ width: 200 }} text={"Save Event"} />
      </Box>
    </>
  );
};
