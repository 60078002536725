const { setObjVal } = require("@wagerlab/utils/data/mutations");
const { isPositiveInteger } = require("@wagerlab/utils/data/types");
const { getPeriodConfig, PERIOD_ID_CONFIG, PERIOD_UNIT_TYPES, getBasePeriodIDs, sortPeriods, getExtraPeriodIDs } = require("@wagerlab/utils/sports/periods");

const CONSIDER_PERIOD_STARTED_WHEN_PREV_PERIOD_ENDED = false;

const getPeriodsStartedEndedMap = (periodsStarted, periodsEnded, sportID, leagueID) => {
  let periodStatusMap = { started: {}, ended: {}, visitedStarted: {}, visitedEnded: {} };

  (periodsEnded || []).forEach((endedPeriodID) => {
    periodStatusMap = flagPeriodAsEnded(endedPeriodID, sportID, leagueID, periodStatusMap);
  });
  (periodsStarted || []).forEach((startedPeriodID) => {
    periodStatusMap = flagPeriodAsStarted(startedPeriodID, sportID, leagueID, periodStatusMap);
  });

  return { started: periodStatusMap.started, ended: periodStatusMap.ended };
};
exports.getPeriodsStartedEndedMap = getPeriodsStartedEndedMap;

const lookupPeriodStartedEnded = (periodID, periodsStarted, periodsEnded, sportID, leagueID) => {
  const periodsStartedEndedMap = getPeriodsStartedEndedMap(periodsStarted, periodsEnded, sportID, leagueID);
  return getPeriodStartedEnded(periodID, periodsStartedEndedMap);
};
exports.lookupPeriodStartedEnded = lookupPeriodStartedEnded;

const getPeriodStartedEnded = (periodID, periodsStartedEndedMap) => {
  const ended = !!periodsStartedEndedMap?.ended?.[periodID];
  const started = ended || !!periodsStartedEndedMap?.started?.[periodID];

  return { started, ended };
};
exports.getPeriodStartedEnded = getPeriodStartedEnded;

const getLatestBasePeriodIDs = (periodsStartedEndedMap, sportID, leagueID) => {
  const basePeriodIDs = getBasePeriodIDs(sportID, leagueID);
  if (!sportID || !leagueID || !basePeriodIDs?.length) return { started: { periodID: null, index: -1 }, ended: { periodID: null, index: -1 } };
  let latestStarted = { periodID: null, index: -1 };
  let latestEnded = { periodID: null, index: -1 };
  for (let i = basePeriodIDs.length - 1; i >= 0; i--) {
    const periodID = basePeriodIDs[i];
    const { started, ended } = getPeriodStartedEnded(periodID, periodsStartedEndedMap);
    if (!latestStarted.periodID && started) latestStarted = { periodID, index: i };
    if (!latestEnded.periodID && ended) latestEnded = { periodID, index: i };
    if (latestEnded.periodID && latestStarted.periodID) break;
  }
  return { started: latestStarted, ended: latestEnded };
};
exports.getLatestBasePeriodIDs = getLatestBasePeriodIDs;

const lookupLatestBasePeriodIDs = (periodsStarted, periodsEnded, sportID, leagueID) => {
  const periodsStartedEndedMap = getPeriodsStartedEndedMap(periodsStarted, periodsEnded, sportID, leagueID);
  return getLatestBasePeriodIDs(periodsStartedEndedMap, sportID, leagueID);
};
exports.lookupLatestBasePeriodIDs = lookupLatestBasePeriodIDs;

const expandPeriodsStartedEndedLists = (periodsStarted, periodsEnded, eventStarted, eventCompleted, currentPeriodID, previousPeriodID, periodsWithScores, sportID, leagueID) => {
  const basePeriodIDs = getBasePeriodIDs(sportID, leagueID);
  const existingPeriodsStarted = [...(periodsStarted || [])];
  const existingPeriodsEnded = [...(periodsEnded || [])];
  if (!sportID || !leagueID || !basePeriodIDs?.length) return { periodsStarted: existingPeriodsStarted, periodsEnded: existingPeriodsEnded };

  const combinedPeriodsEndedList = [...existingPeriodsEnded, previousPeriodID];
  const combinedPeriodsStartedList = [...existingPeriodsStarted, ...periodsWithScores, currentPeriodID];
  const defineablePeriodsList = [...basePeriodIDs, "game", "reg", ...combinedPeriodsStartedList, ...combinedPeriodsEndedList];
  const defineablePeriodsMap = defineablePeriodsList.reduce((periodMap, pid) => {
    if (pid) periodMap[pid] = true;
    return periodMap;
  }, {});

  if (eventCompleted) combinedPeriodsEndedList.push("game", "reg");
  else if (eventStarted) combinedPeriodsStartedList.push("game", "reg");

  const periodsStartedEndedMap = getPeriodsStartedEndedMap(combinedPeriodsStartedList, combinedPeriodsEndedList, sportID, leagueID);

  const { expandedPeriodsStarted, expandedPeriodsEnded } = Object.keys(defineablePeriodsMap).reduce(
    (acc, pid) => {
      if (!pid) return acc;
      const { started, ended } = getPeriodStartedEnded(pid, periodsStartedEndedMap);
      if (started) acc.expandedPeriodsStarted.push(pid);
      if (ended) acc.expandedPeriodsEnded.push(pid);
      return acc;
    },
    { expandedPeriodsStarted: [], expandedPeriodsEnded: [] }
  );

  return {
    periodsStarted: sortPeriods(expandedPeriodsStarted, basePeriodIDs),
    periodsEnded: sortPeriods(expandedPeriodsEnded, basePeriodIDs),
  };
};
exports.expandPeriodsStartedEndedLists = expandPeriodsStartedEndedLists;

const PERIOD_IDS_STARTING_AT_EVENT_START = Object.keys(PERIOD_ID_CONFIG).filter((periodID) => PERIOD_ID_CONFIG[periodID]?.startsAtEventStart);
const TIME_BASED_PERIOD_IDS = Object.keys(PERIOD_ID_CONFIG).filter((periodID) => PERIOD_ID_CONFIG[periodID]?.unit === PERIOD_UNIT_TYPES.MINUTES);

const getEndOrder = (periodConfig) => (isPositiveInteger(periodConfig?.order) && isPositiveInteger(periodConfig?.multiple) ? periodConfig.order + periodConfig.multiple - 1 : null);
const getStartOrder = (periodConfig) => (isPositiveInteger(periodConfig?.order) ? periodConfig.order : null);
const getUnitOrderKey = (unit, order) => unit && order && `${unit}-${order}`;
const PERIOD_IDS_BY_START_ORDER = Object.entries(PERIOD_ID_CONFIG).reduce((byStartOrder, [periodID, periodConfig]) => {
  const unitOrderKey = getUnitOrderKey(periodConfig?.unit, getStartOrder(periodConfig));
  if (!periodID || !unitOrderKey) return byStartOrder;
  byStartOrder[unitOrderKey] = byStartOrder[unitOrderKey] || [];
  byStartOrder[unitOrderKey].push(periodID);
  return byStartOrder;
}, {});
const PERIOD_IDS_BY_END_ORDER = Object.entries(PERIOD_ID_CONFIG).reduce((byEndOrder, [periodID, periodConfig]) => {
  const endOrder = getEndOrder(periodConfig);
  const unitOrderKey = getUnitOrderKey(periodConfig?.unit, endOrder);
  if (!periodID || !unitOrderKey) return byEndOrder;
  byEndOrder[unitOrderKey] = byEndOrder[unitOrderKey] || [];
  byEndOrder[unitOrderKey].push(periodID);
  return byEndOrder;
}, {});
const getPeriodIDsAtStartOrder = (unit, order) => PERIOD_IDS_BY_START_ORDER[getUnitOrderKey(unit, order)] || [];
const getPeriodIDsAtEndOrder = (unit, order) => PERIOD_IDS_BY_END_ORDER[getUnitOrderKey(unit, order)] || [];

const flagPeriodAsEnded = (endedPeriodID, sportID, leagueID, periodStatusMap) => {
  if (!endedPeriodID || periodStatusMap?.visitedEnded?.[endedPeriodID]) return periodStatusMap;
  setObjVal(periodStatusMap, `visitedEnded.${endedPeriodID}`, true);
  setObjVal(periodStatusMap, `visitedStarted.${endedPeriodID}`, true);
  const endedPeriodConfig = getPeriodConfig(endedPeriodID);
  if (!endedPeriodConfig) return periodStatusMap;
  const { sumParts, unit } = endedPeriodConfig;
  setObjVal(periodStatusMap, `ended.${endedPeriodID}`, true);
  setObjVal(periodStatusMap, `started.${endedPeriodID}`, true);
  (sumParts || []).forEach((sumPartsList) => {
    (sumPartsList || []).forEach((sumPartPeriodID) => {
      periodStatusMap = flagPeriodAsEnded(sumPartPeriodID, sportID, leagueID, periodStatusMap);
    });
  });

  // Flag any periods sharing the same unit and ending at the same time or before as ended
  if (unit) {
    const endOrder = getEndOrder(endedPeriodConfig);
    for (let currentEndOrder = 1; currentEndOrder <= endOrder || 0; currentEndOrder++) {
      const periodIDsAtEndOrder = getPeriodIDsAtEndOrder(unit, currentEndOrder);
      periodIDsAtEndOrder.forEach((pid) => {
        periodStatusMap = flagPeriodAsEnded(pid, sportID, leagueID, periodStatusMap);
      });
    }
  }

  if (CONSIDER_PERIOD_STARTED_WHEN_PREV_PERIOD_ENDED) {
    const baseOnlyPeriodIDs = getBasePeriodIDs(sportID, leagueID, false);
    const basePeriodIndex = baseOnlyPeriodIDs.indexOf(endedPeriodID);
    if (basePeriodIndex > 0 && basePeriodIndex < baseOnlyPeriodIDs.length - 1) {
      const nextPeriodID = baseOnlyPeriodIDs[basePeriodIndex + 1];
      periodStatusMap = flagPeriodAsStarted(nextPeriodID, sportID, leagueID, periodStatusMap);
    }
  }

  if (!periodStatusMap.hasEndedTimeBasedPeriods && unit !== PERIOD_UNIT_TYPES.MINUTES) {
    periodStatusMap.hasEndedTimeBasedPeriods = true;
    TIME_BASED_PERIOD_IDS.forEach((pid) => {
      periodStatusMap = flagPeriodAsEnded(pid, sportID, leagueID, periodStatusMap);
    });
  }

  periodStatusMap = flagEventStartPeriods(sportID, leagueID, periodStatusMap);

  periodStatusMap = flagParentPeriods(endedPeriodID, sportID, leagueID, periodStatusMap);

  return periodStatusMap;
};

const flagPeriodAsStarted = (startedPeriodID, sportID, leagueID, periodStatusMap) => {
  if (!startedPeriodID || periodStatusMap?.visitedEnded?.[startedPeriodID] || periodStatusMap?.visitedStarted?.[startedPeriodID]) return periodStatusMap;

  setObjVal(periodStatusMap, `visitedStarted.${startedPeriodID}`, true);
  const startedPeriodConfig = getPeriodConfig(startedPeriodID);
  if (!startedPeriodConfig) return periodStatusMap;
  const { sumParts, unit } = startedPeriodConfig;
  setObjVal(periodStatusMap, `started.${startedPeriodID}`, true);

  // Flag the first sumPart item as started
  (sumParts || []).forEach((sumPartsList) => {
    (sumPartsList || []).forEach((sumPartPeriodID) => {
      const firstPeriodID = sumPartsList[0];
      periodStatusMap = flagPeriodAsStarted(firstPeriodID, sportID, leagueID, periodStatusMap);
    });
  });

  if (unit) {
    const startOrder = getStartOrder(startedPeriodConfig) || 0;
    const endOrder = startOrder > 1 ? startOrder - 1 : 0;
    // Flag any periods sharing the same unit which end BEFORE this period starts as ENDED
    for (let currentEndOrder = 1; currentEndOrder <= endOrder; currentEndOrder++) {
      const periodIDsAtEndOrder = getPeriodIDsAtEndOrder(unit, currentEndOrder);
      periodIDsAtEndOrder.forEach((pid) => {
        periodStatusMap = flagPeriodAsEnded(pid, sportID, leagueID, periodStatusMap);
      });
    }
    // Flag any periods sharing the same unit which start AT OR BEFORE this period starts as STARTED
    for (let currentStartOrder = 1; currentStartOrder <= startOrder; currentStartOrder++) {
      const periodIDsAtStartOrder = getPeriodIDsAtStartOrder(unit, currentStartOrder);
      periodIDsAtStartOrder.forEach((pid) => {
        periodStatusMap = flagPeriodAsStarted(pid, sportID, leagueID, periodStatusMap);
      });
    }
  }

  periodStatusMap = flagEventStartPeriods(sportID, leagueID, periodStatusMap);

  periodStatusMap = flagParentPeriods(startedPeriodID, sportID, leagueID, periodStatusMap);

  return periodStatusMap;
};

const flagEventStartPeriods = (sportID, leagueID, periodStatusMap) => {
  if (!periodStatusMap.hasFlaggedEventStarts) {
    periodStatusMap.hasFlaggedEventStarts = true;
    PERIOD_IDS_STARTING_AT_EVENT_START.forEach((periodID) => {
      periodStatusMap = flagPeriodAsStarted(periodID, sportID, leagueID, periodStatusMap);
    });
  }
  return periodStatusMap;
};

const flagParentPeriods = (periodID, sportID, leagueID, periodStatusMap) => {
  const subsetOfPeriods = getPeriodConfig(periodID)?.isSubsetOf || [];
  subsetOfPeriods.forEach((parentPeriodID) => {
    const { sumParts: parentSumParts } = getPeriodConfig(parentPeriodID) || {};
    if (!parentSumParts?.length || parentPeriodID === "game" || periodStatusMap?.visitedEnded?.[parentPeriodID]) return;
    let parentPeriodStarted = false;
    for (let i = 0; i < parentSumParts.length || 0; i++) {
      const sumPartsList = parentSumParts[i];
      const firstSumPartPeriodID = sumPartsList[0];
      const lastSumPartPeriodID = sumPartsList[sumPartsList.length - 1];
      if (periodStatusMap.ended[lastSumPartPeriodID]) {
        periodStatusMap = flagPeriodAsEnded(parentPeriodID, sportID, leagueID, periodStatusMap);
        return;
      }
      if (periodStatusMap.started[firstSumPartPeriodID]) parentPeriodStarted = true;
    }
    if (parentPeriodStarted) periodStatusMap = flagPeriodAsStarted(parentPeriodID, sportID, leagueID, periodStatusMap);
  });

  const baseOnlyPeriodIDs = getBasePeriodIDs(sportID, leagueID, false);
  const extraPeriodIDs = getExtraPeriodIDs(sportID, leagueID);
  const allBasePeriodIDs = [...baseOnlyPeriodIDs, ...extraPeriodIDs];
  const lastPeriodID = allBasePeriodIDs[allBasePeriodIDs.length - 1];
  if (periodStatusMap.ended[lastPeriodID]) periodStatusMap = flagPeriodAsEnded("game", sportID, leagueID, periodStatusMap);

  let remainingPeriodsEnded = false;
  for (let i = extraPeriodIDs.length - 1; i >= 0; i--) {
    const periodID = extraPeriodIDs[i];
    if (remainingPeriodsEnded && !periodStatusMap.ended[periodID]) periodStatusMap = flagPeriodAsEnded(periodID, sportID, leagueID, periodStatusMap);
    if (periodStatusMap.ended[periodID] || periodStatusMap.started[periodID]) remainingPeriodsEnded = true;
  }

  const lastBaseOnlyPeriodID = baseOnlyPeriodIDs[baseOnlyPeriodIDs.length - 1];
  if (remainingPeriodsEnded) periodStatusMap = flagPeriodAsEnded(lastBaseOnlyPeriodID, sportID, leagueID, periodStatusMap);
  if (periodStatusMap.ended[lastBaseOnlyPeriodID]) periodStatusMap = flagPeriodAsEnded("reg", sportID, leagueID, periodStatusMap);

  return periodStatusMap;
};
