import _ from "lodash";
import { SPORT_CONFIG } from "@wagerlab/utils/sports/sportConfig";
import { LEAGUE_CONFIG } from "@wagerlab/utils/sports/leagueConfig";
import { isString } from "@wagerlab/utils/data/types";

export const generateEventSearchIndexMap = (events) => {
  const indexMap = {};
  (events || []).forEach((event) => {
    if (!event?.eventID) return;
    indexMap[event.eventID] = indexStringsForEvent(event);
  });
  return indexMap;
};

export const filterEvents = (events, searchIndexMap, searchQuery) => {
  const searchQueryNormalized = searchQuery?.toLowerCase?.() || "";
  if (!searchQueryNormalized) return events || [];
  return (events || []).filter((event) => {
    const searchIndex = event?.eventID && searchIndexMap?.[event.eventID];
    if (!searchIndex) return false;
    return searchIndex.includes(searchQueryNormalized);
  });
};

//Fields that end up indexed:  sport name, league name, event name, team names, player names, source IDs

const DEFAULT_INDEXED_PATHS = [
  "eventName",
  "teams.home.teamID",
  "teams.home.names.short",
  "teams.home.names.medium",
  "teams.home.names.long",
  "teams.away.teamID",
  "teams.away.names.short",
  "teams.away.names.medium",
  "teams.away.names.long",
  "sportID",
  "leagueID",
];

export const indexStringsForEvent = (event) => {
  const indexedPaths = [...DEFAULT_INDEXED_PATHS];

  Object.keys(event?.players || {}).forEach((playerID) => {
    if (!playerID) return;
    indexedPaths.push(`players.${playerID}.playerID`);
    indexedPaths.push(`players.${playerID}.firstName`);
    indexedPaths.push(`players.${playerID}.lastName`);
    indexedPaths.push(`players.${playerID}.name`);
    indexedPaths.push(`players.${playerID}.nickname`);
  });

  Object.keys(event?.props || {}).forEach((propStatID) => {
    if (!propStatID) return;
    indexedPaths.push(`props.${propStatID}.statID`);
    indexedPaths.push(`props.${propStatID}.propTitle`);
    indexedPaths.push(`props.${propStatID}.propDetails`);
    indexedPaths.push(`props.${propStatID}.sides.side1.sideDisplay`);
    indexedPaths.push(`props.${propStatID}.sides.side2.sideDisplay`);
  });

  const indexedPathValues = indexedPaths.map((path) => _.get(event, path));

  const additionalIndexedValues = [
    SPORT_CONFIG?.[event?.sportID]?.name,
    SPORT_CONFIG?.[event?.sportID]?.shortName,
    LEAGUE_CONFIG?.[event?.leagueID]?.name,
    LEAGUE_CONFIG?.[event?.leagueID]?.shortName,
    ...Object.keys(event?.sourceContext || {}),
  ];

  return [...indexedPathValues, ...additionalIndexedValues]
    .map((value) => {
      if (!value || !isString(value)) return "";
      return (
        value
          ?.trim?.()
          ?.normalize?.("NFD")
          ?.replace?.(/[\u0300-\u036f]/g, "")
          ?.toLowerCase?.() || ""
      );
    })
    .filter((value) => value)
    .join(" ");
};
