import React from "react";
import { IconButton, MenuItem, TextField, Tooltip } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { isString } from "@wagerlab/utils/data/types";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const SelectBoolean = ({ options, name, disabled, onChange, tooltip, ...otherProps }) => {
  const { isSubmitting } = useFormikContext();
  const [field, { touched, error }, { setValue }] = useField(name);

  const isDisabled = !!(isSubmitting || disabled);
  const hasErrors = !!(touched && error && isString(error));
  const selectOptions = options || { true: "Yes", false: "No" };

  const handleBooleanChange = (e) => {
    const rawValue = e?.target?.value;
    const booleanValue = rawValue === "false" || !rawValue ? false : true;
    setValue(booleanValue);
    onChange?.(booleanValue);
  };

  const inputProps = tooltip
    ? {
        endAdornment: (
          <Tooltip title={tooltip} placement="right-start">
            <IconButton color="primary" aria-label="help icon" component="span" sx={{ mr: 2 }}>
              <InfoOutlinedIcon sx={{ color: "lightblue" }} />
            </IconButton>
          </Tooltip>
        ),
      }
    : null;

  return (
    <TextField
      {...field}
      {...otherProps}
      disabled={isDisabled}
      select={true}
      variant={"outlined"}
      fullWidth={true}
      onChange={handleBooleanChange}
      error={hasErrors}
      helperText={hasErrors ? error : null}
      InputProps={inputProps}
    >
      {Object.entries(selectOptions || {}).map(([value, display], index) => {
        return (
          <MenuItem key={index} value={value}>
            {display}
          </MenuItem>
        );
      })}
    </TextField>
  );
};
