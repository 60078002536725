const { DEFAULT_WAGER_UNIT } = require("@wagerlab/utils/constants");
const { isNumber, isPositiveNumber } = require("@wagerlab/utils/data/types");
const { asMoneylineInt } = require("@wagerlab/utils/odds/converters");
const { iReceivedWager, iProposedWager, iWonWager, iLostWager } = require("@wagerlab/utils/wagers/user-wager");
const { parseNumber } = require("@wagerlab/utils/data/numbers");

const calculateToWin = (odds, riskAmount, decimals = 0) => {
  const oddsInt = asMoneylineInt(odds);
  const riskAmountInt = riskAmount && parseInt(riskAmount);
  if (!oddsInt || !riskAmountInt || !isPositiveNumber(riskAmountInt)) return 0;
  const toWinUnrounded = oddsInt < 0 ? riskAmountInt / ((-1 * oddsInt) / 100) : riskAmountInt * (oddsInt / 100);
  return parseNumber(toWinUnrounded?.toFixed?.(decimals));
};
exports.calculateToWin = calculateToWin;

const calculateMinRisk = (odds) => {
  const oddsInt = asMoneylineInt(odds);
  if (!oddsInt) return 5;
  if (oddsInt >= 150 && oddsInt < 160) return 5;
  if (oddsInt >= 160 && oddsInt < 175) return 3;
  if (oddsInt >= 250 && oddsInt < 260) return 3;
  if (oddsInt >= 260 && oddsInt < 270) return 2;
  if (oddsInt > 0) return 1;

  const minRiskSigned = Math.ceil(-0.01 * oddsInt);
  return Math.max(minRiskSigned, 1);
};
exports.calculateMinRisk = calculateMinRisk;

const getProposerRisk = (wager) => wager?.amount?.proposerRisk || 0;
exports.getProposerRisk = getProposerRisk;

const getProposerToWin = (wager) => wager?.amount?.recipientRisk || 0;
exports.getProposerToWin = getProposerToWin;

const getRecipientRisk = (wager) => wager?.amount?.recipientRisk || 0;
exports.getRecipientRisk = getRecipientRisk;

const getRecipientToWin = (wager) => wager?.amount?.proposerRisk || 0;
exports.getRecipientToWin = getRecipientToWin;

const getUserRisk = (wager, userID) => (iProposedWager(wager, userID) ? getProposerRisk(wager) : iReceivedWager(wager, userID) ? getRecipientRisk(wager) : 0);
exports.getUserRisk = getUserRisk;

const getUserToWin = (wager, userID) => (iProposedWager(wager, userID) ? getProposerToWin(wager) : iReceivedWager(wager, userID) ? getRecipientToWin(wager) : 0);
exports.getUserToWin = getUserToWin;

const getOpponentRisk = (wager, userID) => (iProposedWager(wager, userID) ? getRecipientRisk(wager) : iReceivedWager(wager, userID) ? getProposerRisk(wager) : 0);
exports.getOpponentRisk = getOpponentRisk;

const getOpponentToWin = (wager, userID) => (iProposedWager(wager, userID) ? getRecipientToWin(wager) : iReceivedWager(wager, userID) ? getProposerToWin(wager) : 0);
exports.getOpponentToWin = getOpponentToWin;

const getWagerAmountUnit = (wager) => wager?.amount?.unit || DEFAULT_WAGER_UNIT;
exports.getWagerAmountUnit = getWagerAmountUnit;

const getUserWagerBalanceChange = (wager, userID) => {
  if (iWonWager(wager, userID)) return getUserToWin(wager, userID);
  if (iLostWager(wager, userID)) return -1 * getUserRisk(wager, userID);
  return 0;
};
exports.getUserWagerBalanceChange = getUserWagerBalanceChange;

const getLegacyWagerAmounts = (wager) => {
  let proposerRisk = wager?.amount && parseInt(wager.amount);
  if (!proposerRisk || !isNumber(proposerRisk)) return null;
  let recipientToWin = proposerRisk;

  let recipientRisk = wager?.recipientAmount && parseInt(wager.recipientAmount);
  let proposerToWin = recipientRisk;
  if (!recipientRisk || !isNumber(recipientRisk)) {
    proposerToWin = calculateToWin(wager?.proposerMoneyline, proposerRisk);
    recipientRisk = proposerToWin;
  }
  if (!recipientRisk || !isNumber(recipientRisk)) return null;

  const isUneven = proposerRisk !== proposerToWin || recipientRisk !== recipientToWin;
  return {
    isUneven,
    proposerRisk,
    proposerToWin,
    recipientRisk,
    recipientToWin,
    amount: proposerRisk,
    unit: wager?.unit || DEFAULT_WAGER_UNIT,
  };
};
exports.getLegacyWagerAmounts = getLegacyWagerAmounts;

const getWagerAmounts = (wager) => {
  let proposerRisk = wager?.amount?.proposerRisk && parseInt(wager.amount?.proposerRisk);
  if (!proposerRisk || !isNumber(proposerRisk)) return null;
  let recipientToWin = proposerRisk;

  let recipientRisk = wager?.amount?.recipientRisk && parseInt(wager?.amount?.recipientRisk);
  let proposerToWin = recipientRisk;
  if (!recipientRisk || !isNumber(recipientRisk)) {
    proposerToWin = calculateToWin(wager?.odds?.proposer?.odds, proposerRisk);
    recipientRisk = proposerToWin;
  }
  if (!recipientRisk || !isNumber(recipientRisk)) return null;

  const isUneven = proposerRisk !== proposerToWin || recipientRisk !== recipientToWin;
  return {
    isUneven,
    proposerRisk,
    proposerToWin,
    recipientRisk,
    recipientToWin,
    amount: proposerRisk,
    unit: wager?.amount?.unit || DEFAULT_WAGER_UNIT,
  };
};
exports.getWagerAmounts = getWagerAmounts;