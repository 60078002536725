import OpenAI from "openai";
import { Pinecone } from '@pinecone-database/pinecone';

const openaiClient = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_SECRET_KEY,
  organization: "org-ixA70HofPJunZxN5jaDI8tw6",
  dangerouslyAllowBrowser: true
});

const pineconeClient = new Pinecone({
  apiKey: process.env.REACT_APP_PINECONE_API_KEY,
});

export const upsertPineconeIndexData = async (index, namespace, vectors) => {
  const pineconeIndex = pineconeClient.index(index);
  return await pineconeIndex.namespace(namespace).upsert(vectors);
}

export const queryPineconeIndex = async (index, namespace, vector, topK) => {
  const pineconeIndex = pineconeClient.index(index);
  const query = {
    vector,
    topK,
    includeValues: false,
    includeMetadata: false
  };
  return await pineconeIndex.namespace(namespace).query(query);
}

// TODO: make this paramater an object of id: metadata if we need to add metadata to the embeddings
export const generateEmbeddings = async (values) => {
  try {
    const embeddings = await openaiClient.embeddings.create({
      model: "text-embedding-ada-002",
      input: values,
    });

    if (!embeddings?.data)
      return false;

    return embeddings.data.map((e, i) => {
      const valueKey = values?.[i];
      const embedding = e.embedding;
      if (!valueKey || !embedding)
        return null;

      return {
        id: valueKey,
        values: embedding,
      }
    }).filter((v) => !!v);
  } catch (error) {
    console.error('Error generating embeddings:', error);
    return null;
  }
}