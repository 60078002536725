const { isNumber } = require("@wagerlab/utils/data/types");
const { CLOCK_TYPES } = require("@wagerlab/utils/sports/gameClock");

const SPORT_CONFIG = {
  BASKETBALL: {
    sportID: "BASKETBALL",
    enabled: true,
    name: "Basketball",
    shortName: "Basketball",
    hasMeaningfulHomeAway: true,
    pointWord: {
      short: { singular: "pt", plural: "pts" },
      long: { singular: "point", plural: "points" },
    },
    eventWord: {
      short: { singular: "game", plural: "games" },
      long: { singular: "game", plural: "games" },
    },
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASKETBALL_imageicon.png?alt=media&token=d8fff787-34b2-4a6c-b0cd-d758a58a1087",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASKETBALL_squareImage.jpg?alt=media&token=0e30ce03-b382-42fb-b1bf-a0f2adc72fea",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASKETBALL_backgroundImage.jpg?alt=media&token=a04ba9e7-6f96-4d94-9f64-48fed55a89f1",
    defaultPopularityScore: 5.0,
    clockType: CLOCK_TYPES.COUNTS_DOWN, // Some exceptions may exist
    basePeriods: ["1q", "2q", "3q", "4q"],
    extraPeriods: ["ot"],
  },
  HOCKEY: {
    sportID: "HOCKEY",
    enabled: true,
    hasMeaningfulHomeAway: true,
    name: "Hockey",
    shortName: "Hockey",
    pointWord: {
      short: { singular: "goal", plural: "goals" },
      long: { singular: "goal", plural: "goals" },
    },
    eventWord: {
      short: { singular: "game", plural: "games" },
      long: { singular: "game", plural: "games" },
    },
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_HOCKEY_imageicon.png?alt=media&token=fbc6cc0d-139f-46d4-99a8-0d2a91aa6462",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_HOCKEY_squareImage.jpg?alt=media&token=dc3235dd-dd79-48ef-a266-4a28fbf3d11d",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_HOCKEY_backgroundImage.jpg?alt=media&token=75587b97-577c-466a-b2a8-4d0de3797050",
    defaultPopularityScore: 5.0,
    clockType: CLOCK_TYPES.COUNTS_DOWN,
    basePeriods: ["1p", "2p", "3p"],
    extraPeriods: ["ot", "so"],
  },
  FOOTBALL: {
    sportID: "FOOTBALL",
    enabled: true,
    hasMeaningfulHomeAway: true,
    name: "Football",
    shortName: "Football",
    pointWord: {
      short: { singular: "pt", plural: "pts" },
      long: { singular: "point", plural: "points" },
    },
    eventWord: {
      short: { singular: "game", plural: "games" },
      long: { singular: "game", plural: "games" },
    },
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_FOOTBALL_imageicon.png?alt=media&token=90476918-cc97-44da-ab6b-c0c92963fbba",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_FOOTBALL_squareImage.jpg?alt=media&token=7f6d0b40-97aa-4bd7-8be6-b4ab38aed5a7",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_FOOTBALL_backgroundImage.jpg?alt=media&token=f45229ba-09f7-450d-9727-9a5be40aef07",
    defaultPopularityScore: 7.0,
    clockType: CLOCK_TYPES.COUNTS_DOWN,
    basePeriods: ["1q", "2q", "3q", "4q"],
    extraPeriods: ["ot"],
  },
  BASEBALL: {
    sportID: "BASEBALL",
    enabled: true,
    hasMeaningfulHomeAway: true,
    name: "Baseball",
    shortName: "Baseball",
    pointWord: {
      short: { singular: "run", plural: "runs" },
      long: { singular: "run", plural: "runs" },
    },
    eventWord: {
      short: { singular: "game", plural: "games" },
      long: { singular: "game", plural: "games" },
    },
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASEBALL_imageicon.png?alt=media&token=53f343ee-c10b-47cd-af4a-beef6ee0cd5f",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASEBALL_squareImage.jpg?alt=media&token=0affd222-2c6e-48c1-b6f3-c6f6492eb594",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASEBALL_backgroundImage.jpg?alt=media&token=c2c1f4cc-6920-40c5-a61b-30fd353fcf3d",
    defaultPopularityScore: 7.0,
    clockType: CLOCK_TYPES.NO_CLOCK,
    basePeriods: ["1i", "2i", "3i", "4i", "5i", "6i", "7i", "8i", "9i"],
    extraPeriods: ["ot"], // TODO: Consider adding extra innings up to a certain number and make them all a sumParts of ot
  },
  SOCCER: {
    sportID: "SOCCER",
    enabled: true,
    hasMeaningfulHomeAway: false,
    prefers3WayMoneyline: true,
    name: "Soccer",
    shortName: "Soccer",
    pointWord: {
      short: { singular: "goal", plural: "goals" },
      long: { singular: "goal", plural: "goals" },
    },
    eventWord: {
      short: { singular: "match", plural: "matches" },
      long: { singular: "match", plural: "matches" },
    },
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_imageicon.png?alt=media&token=03efe8ba-0a30-4ca4-8fa9-80e272ca1828",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_squareImage.jpg?alt=media&token=9b2ff9ac-3e9d-4965-979c-fc1a0d873baa",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_backgroundImage.jpg?alt=media&token=9c7e2109-12a1-400b-aa5f-90e369ac282d",
    defaultPopularityScore: 8.0,
    clockType: CLOCK_TYPES.COUNTS_UP,
    basePeriods: ["1h", "2h"],
    extraPeriods: ["ot", "so"],
  },
  TENNIS: {
    sportID: "TENNIS",
    enabled: true,
    hasMeaningfulHomeAway: false,
    name: "Tennis",
    shortName: "Tennis",
    pointWord: {
      short: { singular: "game", plural: "games" },
      long: { singular: "game", plural: "games" },
    },
    eventWord: {
      short: { singular: "match", plural: "matches" },
      long: { singular: "match", plural: "matches" },
    },
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FTennis_ImageIcon.png?alt=media&token=39d5bcfb-156b-4761-bdb1-916aa0285e79",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FTENNIS_squareImage.jpg?alt=media&token=9f1b8280-404a-451b-bd8f-1210206d1207",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FTENNIS_backgroundImage.jpg?alt=media&token=13b97567-77f9-48d8-8681-75f3734016f8",
    defaultPopularityScore: 5.0,
    clockType: CLOCK_TYPES.NO_CLOCK,
    basePeriods: ["1s", "2s", "3s", "4s", "5s"], // Note: When configuring sources, ensure tiebreaks are considered in period-status and score calculations. We don't define them as their own period.
  },
  HANDBALL: {
    sportID: "HANDBALL",
    enabled: true,
    hasMeaningfulHomeAway: false,
    prefers3WayMoneyline: true,
    name: "Handball",
    shortName: "Handball",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FHANDBALL_imageicon.png?alt=media&token=cd2302b4-4d33-4f9b-aa32-6c58784be30b",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FHANDBALL_squareImage.jpg?alt=media&token=b9117d62-c85b-42d2-b06a-a87561b209d5",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FHANDBALL_backgroundImage.jpg?alt=media&token=09281591-62c3-480c-bb61-91eb1bbea957",
    pointWord: {
      short: { singular: "goal", plural: "goals" },
      long: { singular: "goal", plural: "goals" },
    },
    eventWord: {
      short: { singular: "game", plural: "games" },
      long: { singular: "game", plural: "games" },
    },
    clockType: CLOCK_TYPES.COUNTS_DOWN, // Exceptions: Olympics
    basePeriods: ["1h", "2h"],
    extraPeriods: ["ot", "so"],
  },
  FLOORBALL: {
    sportID: "FLOORBALL",
    enabled: false,
    hasMeaningfulHomeAway: false,
    name: "Floorball",
    shortName: "Floorball",
    pointWord: {
      short: { singular: "goal", plural: "goals" },
      long: { singular: "goal", plural: "goals" },
    },
    eventWord: {
      short: { singular: "game", plural: "games" },
      long: { singular: "game", plural: "games" },
    },
    clockType: CLOCK_TYPES.COUNTS_DOWN,
    basePeriods: ["1p", "2p", "3p"],
    extraPeriods: ["ot", "so"],
  },
  MOTORSPORTS: {
    sportID: "MOTORSPORTS",
    enabled: false,
    hasMeaningfulHomeAway: false,
    name: "Motorsports",
    shortName: "Racing",
    pointWord: {
      short: { singular: "lap", plural: "laps" },
      long: { singular: "lap", plural: "laps" },
    },
    eventWord: {
      short: { singular: "race", plural: "races" },
      long: { singular: "race", plural: "races" },
    },
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FMOTORSPORTS_imageicon.png?alt=media&token=a38df1e0-7c5a-4199-a5f1-d0ea42bb25f8",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FMOTORSPORTS_squareImage.jpg?alt=media&token=0abbf887-1275-45e5-82b3-42318259613e",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FMOTORSPORTS_backgroundImage.jpg?alt=media&token=bcee14fd-d037-45bd-a977-ec432a708eba",
    defaultPopularityScore: 6.0,
    clockType: CLOCK_TYPES.NO_CLOCK,
    //TODO: periods -> 1st to 100th lap? No extras? That could get long. Do we integrate that into the clock instead?
  },
  RUGBY: {
    sportID: "RUGBY",
    enabled: false,
    hasMeaningfulHomeAway: false,
    prefers3WayMoneyline: true,
    name: "Rugby",
    shortName: "Rugby",
    pointWord: {
      short: { singular: "pt", plural: "pts" },
      long: { singular: "point", plural: "points" },
    },
    eventWord: {
      short: { singular: "match", plural: "matches" },
      long: { singular: "match", plural: "matches" },
    },
    clockType: CLOCK_TYPES.VARIABLE, //Typically counts down. Some count up
    basePeriods: ["1h", "2h"],
    extraPeriods: ["ot"],
  },
  AUSSIE_RULES_FOOTBALL: {
    sportID: "AUSSIE_RULES_FOOTBALL",
    enabled: false,
    hasMeaningfulHomeAway: false,
    name: "Aussie Rules",
    shortName: "Aussie Rules",
    pointWord: {
      short: { singular: "pt", plural: "pts" },
      long: { singular: "point", plural: "points" },
    },
    eventWord: {
      short: { singular: "match", plural: "matches" },
      long: { singular: "match", plural: "matches" },
    },
    clockType: CLOCK_TYPES.VARIABLE, // Typically counts down
    basePeriods: ["1q", "2q", "3q", "4q"],
    extraPeriods: ["ot"],
  },
  BANDY: {
    sportID: "BANDY",
    enabled: false,
    hasMeaningfulHomeAway: false,
    name: "Bandy",
    shortName: "Bandy",
    pointWord: {
      short: { singular: "goal", plural: "goals" },
      long: { singular: "goal", plural: "goals" },
    },
    eventWord: {
      short: { singular: "game", plural: "games" },
      long: { singular: "game", plural: "games" },
    },
    clockType: CLOCK_TYPES.COUNTS_DOWN,
    basePeriods: ["1h", "2h"],
    extraPeriods: ["ot", "so"],
  },
  SNOOKER: {
    sportID: "SNOOKER",
    enabled: false,
    hasMeaningfulHomeAway: false,
    name: "Snooker",
    shortName: "Snooker",
    pointWord: {
      short: { singular: "pt", plural: "pts" },
      long: { singular: "point", plural: "points" },
    },
    eventWord: {
      short: { singular: "frame", plural: "frames" },
      long: { singular: "frame", plural: "frames" },
    },
    clockType: CLOCK_TYPES.NO_CLOCK,
    //TODO: periods -> 1st Frame to 17th? 35th? frame? No extras
  },
  TABLE_TENNIS: {
    sportID: "TABLE_TENNIS",
    enabled: false,
    hasMeaningfulHomeAway: false,
    name: "Table Tennis",
    shortName: "Table Tennis",
    pointWord: {
      short: { singular: "pt", plural: "pts" },
      long: { singular: "point", plural: "points" },
    },
    eventWord: {
      short: { singular: "match", plural: "matches" },
      long: { singular: "match", plural: "matches" },
    },
    clockType: CLOCK_TYPES.NO_CLOCK,
    //TODO periods: 1st to 7th game? No extras
  },
  DARTS: {
    sportID: "DARTS",
    enabled: false,
    hasMeaningfulHomeAway: false,
    name: "Darts",
    shortName: "Darts",
    pointWord: {
      short: { singular: "pt", plural: "pts" },
      long: { singular: "point", plural: "points" },
    },
    eventWord: {
      short: { singular: "leg", plural: "legs" },
      long: { singular: "leg", plural: "legs" },
    },
    clockType: CLOCK_TYPES.NO_CLOCK,
    // TODO periods: legs or sets? 5, 7, 9, 13? No extras
  },
  VOLLEYBALL: {
    sportID: "VOLLEYBALL",
    enabled: false,
    hasMeaningfulHomeAway: false,
    name: "Volleyball",
    shortName: "Volleyball",
    pointWord: {
      short: { singular: "pt", plural: "pts" },
      long: { singular: "point", plural: "points" },
    },
    eventWord: {
      short: { singular: "match", plural: "matches" },
      long: { singular: "match", plural: "matches" },
    },
    clockType: CLOCK_TYPES.NO_CLOCK,
    basePeriods: ["1s", "2s", "3s", "4s", "5s"],
  },
  CRICKET: {
    sportID: "CRICKET",
    enabled: false,
    hasMeaningfulHomeAway: false,
    prefers3WayMoneyline: true,
    name: "Cricket",
    shortName: "Cricket",
    pointWord: {
      short: { singular: "run", plural: "runs" },
      long: { singular: "run", plural: "runs" },
    },
    eventWord: {
      short: { singular: "match", plural: "matches" },
      long: { singular: "match", plural: "matches" },
    },
    clockType: CLOCK_TYPES.NO_CLOCK,
    basePeriods: ["1i", "2i", "3i", "4i", "5i"], // TODO: double check this
    extraPeriods: ["ot"], //TODO: Double check this
  },
  WATER_POLO: {
    sportID: "WATER_POLO",
    enabled: false,
    hasMeaningfulHomeAway: false,
    prefers3WayMoneyline: true,
    name: "Water Polo",
    shortName: "Water Polo",
    pointWord: {
      short: { singular: "goal", plural: "goals" },
      long: { singular: "goal", plural: "goals" },
    },
    eventWord: {
      short: { singular: "game", plural: "games" },
      long: { singular: "game", plural: "games" },
    },
    clockType: CLOCK_TYPES.COUNTS_DOWN, // Some exceptions exist
    basePeriods: ["1q", "2q", "3q", "4q"],
    extraPeriods: ["ot", "so"],
  },
  FUTSAL: {
    sportID: "FUTSAL",
    enabled: false,
    hasMeaningfulHomeAway: false,
    name: "Futsal",
    shortName: "Futsal",
    pointWord: {
      short: { singular: "goal", plural: "goals" },
      long: { singular: "goal", plural: "goals" },
    },
    eventWord: {
      short: { singular: "match", plural: "matches" },
      long: { singular: "match", plural: "matches" },
    },
    clockType: CLOCK_TYPES.COUNTS_DOWN, // Some exceptions exist
    basePeriods: ["1h", "2h"],
    extraPeriods: ["ot", "so"],
  },
  BADMINTON: {
    sportID: "BADMINTON",
    enabled: false,
    hasMeaningfulHomeAway: false,
    name: "Badminton",
    shortName: "Badminton",
    pointWord: {
      short: { singular: "pt", plural: "pts" },
      long: { singular: "point", plural: "points" },
    },
    eventWord: {
      short: { singular: "match", plural: "matches" },
      long: { singular: "match", plural: "matches" },
    },
    clockType: CLOCK_TYPES.NO_CLOCK,
    //TODO periods: 1st to 3rd game/set? No extras
  },
  BEACH_VOLLEYBALL: {
    sportID: "BEACH_VOLLEYBALL",
    enabled: false,
    hasMeaningfulHomeAway: false,
    name: "Beach Volleyball",
    shortName: "Beach Volleyball",
    pointWord: {
      short: { singular: "pt", plural: "pts" },
      long: { singular: "point", plural: "points" },
    },
    eventWord: {
      short: { singular: "match", plural: "matches" },
      long: { singular: "match", plural: "matches" },
    },
    clockType: CLOCK_TYPES.NO_CLOCK,
    basePeriods: ["1s", "2s", "3s"],
  },
  ESPORTS: {
    sportID: "ESPORTS",
    enabled: false,
    hasMeaningfulHomeAway: false,
    name: "ESports",
    shortName: "ESports",
    pointWord: {
      short: { singular: "pt", plural: "pts" },
      long: { singular: "point", plural: "points" },
    },
    eventWord: {
      short: { singular: "match", plural: "matches" },
      long: { singular: "match", plural: "matches" },
    },
    clockType: CLOCK_TYPES.VARIABLE,
    // TODO: periods -> 1st to 5th game? No extras?
  },
  LACROSSE: {
    sportID: "LACROSSE",
    enabled: false,
    hasMeaningfulHomeAway: true,
    name: "Lacrosse",
    shortName: "Lacrosse",
    pointWord: {
      short: { singular: "goal", plural: "goals" },
      long: { singular: "goal", plural: "goals" },
    },
    eventWord: {
      short: { singular: "game", plural: "games" },
      long: { singular: "game", plural: "games" },
    },
    clockType: CLOCK_TYPES.COUNTS_DOWN, // Some exceptions exist
    basePeriods: ["1q", "2q", "3q", "4q"],
    extraPeriods: ["ot", "so"],
  },
  GOLF: {
    sportID: "GOLF",
    enabled: true,
    hasMeaningfulHomeAway: false,
    name: "Golf",
    shortName: "Golf",
    pointWord: {
      short: { singular: "strk", plural: "strks" },
      long: { singular: "stroke", plural: "strokes" },
    },
    eventWord: {
      short: { singular: "tourney", plural: "tourneys" },
      long: { singular: "tournament", plural: "tournaments" },
    },
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FGOLF_imageicon.png?alt=media&token=3bd873bc-bf22-4e85-9d8c-242af468549b",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FGOLF_squareImage.jpg?alt=media&token=4c4f3ebd-2b45-4184-87ab-6a24d4050cb2",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FGOLF_backgroundImage.jpg?alt=media&token=aa7de464-56a5-49a9-87cd-390221035834",
    defaultPopularityScore: 7.0,
    clockType: CLOCK_TYPES.NO_CLOCK,
    basePeriods: ["1r", "2r", "3r", "4r"],
    extraPeriods: ["5r"], // TODO: Consider either making this a new periodID, part of ot, or (probably not) a new periodID for each extra hole
    unsupportedPeriods: ["reg"],
  },
  MMA: {
    sportID: "MMA",
    enabled: true,
    hasMeaningfulHomeAway: false,
    name: "MMA",
    shortName: "MMA",
    pointWord: {
      short: { singular: "rnd", plural: "rnds" },
      long: { singular: "round", plural: "rounds" },
    },
    eventWord: {
      short: { singular: "fight", plural: "fights" },
      long: { singular: "fight", plural: "fights" },
    },
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FMMA_imageicon.png?alt=media&token=1492fefa-1547-43df-a5a5-4ed81f8ffe65",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FMMA_squareImage.jpg?alt=media&token=10c02ea6-bfc8-4995-b9f1-b0653f0d37e8",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2Fmma_backgroundImage.jpg?alt=media&token=2dbae8d6-4c16-41f2-b088-40cc565b07fc",
    defaultPopularityScore: 7.0,
    clockType: CLOCK_TYPES.COUNTS_DOWN, // Also uses rounds
    basePeriods: ["1r", "2r", "3r", "4r", "5r"],
  },
  BOXING: {
    sportID: "BOXING",
    enabled: false,
    hasMeaningfulHomeAway: false,
    name: "Boxing",
    shortName: "Boxing",
    pointWord: {
      short: { singular: "rnd", plural: "rnds" },
      long: { singular: "round", plural: "rounds" },
    },
    eventWord: {
      short: { singular: "fight", plural: "fights" },
      long: { singular: "fight", plural: "fights" },
    },
    clockType: CLOCK_TYPES.COUNTS_DOWN, // Also uses rounds
    basePeriods: ["1r", "2r", "3r", "4r", "5r", "6r", "7r", "8r", "9r", "10r", "11r", "12r"],
  },
  HORSE_RACING: {
    sportID: "HORSE_RACING",
    enabled: true,
    hasMeaningfulHomeAway: false,
    name: "Horse Racing",
    shortName: "Horse Racing",
    pointWord: {
      short: { singular: "place", plural: "places" },
      long: { singular: "placement", plural: "placements" },
    },
    eventWord: {
      short: { singular: "race", plural: "races" },
      long: { singular: "race", plural: "races" },
    },
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FHORSE_RACING_imageIcon.png?alt=media&token=b5be95cd-afcc-486b-9d9b-cdb98dee8813",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FHORSE_RACING_squareImage.jpg?alt=media&token=4019abd8-7ab9-402c-96ac-94b91a3caf59",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FHORSE_RACING_backgroundImage.jpg?alt=media&token=b8b8aede-3438-4fc1-92f6-862b28f38568",
    defaultPopularityScore: 6.0,
    clockType: CLOCK_TYPES.NO_CLOCK,
  },
  NON_SPORTS: {
    sportID: "NON_SPORTS",
    enabled: true,
    hasMeaningfulHomeAway: false,
    name: "Non-Sports",
    shortName: "Non-Sports",
    pointWord: {
      short: { singular: "pt", plural: "pts" },
      long: { singular: "point", plural: "points" },
    },
    eventWord: {
      short: { singular: "event", plural: "events" },
      long: { singular: "event", plural: "events" },
    },
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FENTMT_imageicon.png?alt=media&token=63f0deb2-32fa-4943-ab70-4dfb32138d68",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FENTMT_squareImage.jpg?alt=media&token=e6db1fe3-5c3a-41b4-95fc-465fed819323",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FEPL_backgroundImage.jpg?alt=media&token=9433368c-5322-44a6-b9ba-bdf18c833533",
    defaultPopularityScore: 6.0,
    clockType: CLOCK_TYPES.NO_CLOCK,
  },
};
exports.SPORT_CONFIG = SPORT_CONFIG;

const ENABLED_SPORTS = Object.values(SPORT_CONFIG).filter((sportConfig) => sportConfig?.sportID && sportConfig?.enabled);
exports.ENABLED_SPORTS = ENABLED_SPORTS;

const ENABLED_SPORT_IDS = ENABLED_SPORTS.map((sportConfig) => sportConfig?.sportID);
exports.ENABLED_SPORT_IDS = ENABLED_SPORT_IDS;

const getWordForEvent = (sportID, number, length) => {
  let numInt = parseInt(number);
  if (!isNumber(numInt)) numInt = 0;
  const useSingular = numInt <= 1 && numInt >= -1 && numInt !== 0;
  const { singular, plural } = SPORT_CONFIG[sportID]?.eventWord?.[length] || SPORT_CONFIG[sportID]?.eventWord?.long || {};
  return (useSingular ? singular : plural) || "";
};
exports.getWordForEvent = getWordForEvent;

const isSportEnabled = (sportID) => SPORT_CONFIG[sportID]?.enabled || false;
exports.isSportEnabled = isSportEnabled;

const SPORTS_BY_NAME = [...ENABLED_SPORTS].sort((a, b) => {
  if (!a?.name && !b?.name) return 0;
  if (!a?.name) return 1;
  if (!b?.name) return -1;
  return a.name.localeCompare(b.name);
});
exports.SPORTS_BY_NAME = SPORTS_BY_NAME;

const SPORTS_BY_DEFAULT_POPULARITY = [...ENABLED_SPORTS].sort((a, b) => (b?.defaultPopularityScore || 0) - (a?.defaultPopularityScore || 0));
exports.SPORTS_BY_DEFAULT_POPULARITY = SPORTS_BY_DEFAULT_POPULARITY;
