const { meaningfulDataChanged } = require("@wagerlab/utils/data/mutations");

const eventHasMeaningfulChanges = (prevEvent, newEvent, defaultMeaningful = true, meaningfulDefinition = null) => {
  const allResults = meaningfulDefinition?.allResults ?? !!defaultMeaningful;
  const primaryResults = meaningfulDefinition?.primaryResults ?? !!defaultMeaningful;
  const allOdds = meaningfulDefinition?.allOdds ?? !!defaultMeaningful;
  const closeOdds = meaningfulDefinition?.closeOdds ?? !!defaultMeaningful;
  const oddValues = meaningfulDefinition?.oddValues ?? !!defaultMeaningful;
  const oddScores = meaningfulDefinition?.oddScores ?? !!defaultMeaningful;
  const oddStatuses = meaningfulDefinition?.oddStatuses ?? !!defaultMeaningful;
  const allStatus = meaningfulDefinition?.allStatus ?? !!defaultMeaningful;
  const statusOdds = meaningfulDefinition?.statusOdds ?? !!defaultMeaningful;
  const statusBooleans = meaningfulDefinition?.statusBooleans ?? !!defaultMeaningful;
  const statusDisplays = meaningfulDefinition?.statusDisplays ?? !!defaultMeaningful;
  const statusOther = meaningfulDefinition?.statusOther ?? !!defaultMeaningful;
  const allPlayers = meaningfulDefinition?.allPlayers ?? !!defaultMeaningful;
  const allTeams = meaningfulDefinition?.allTeams ?? !!defaultMeaningful;
  const allInfo = meaningfulDefinition?.allInfo ?? !!defaultMeaningful;
  const allProps = meaningfulDefinition?.allProps ?? !!defaultMeaningful;
  const leaderboard = meaningfulDefinition?.leaderboard ?? !!defaultMeaningful;

  const changeConfig = {
    ignorePaths: {},
    defaultPaths: {},
    onlyCommonPaths: false,
    treatEmptyValuesSame: true,
  };

  changeConfig.ignorePaths["sourceContext"] = true;

  if (!allResults || !primaryResults) {
    changeConfig.ignorePaths["results"] = true;
    if (primaryResults) {
      changeConfig.defaultPaths["results.game.home.points"] = 0;
      changeConfig.defaultPaths["results.game.away.points"] = 0;
    }
  }

  if (!allStatus) {
    changeConfig.ignorePaths["status"] = true;
    if (statusOdds) {
      changeConfig.defaultPaths["status.anyOddsAvailable"] = false;
      changeConfig.defaultPaths["status.marketOddsAvailable"] = false;
      changeConfig.defaultPaths["status.oddsCloseAtStart"] = false;
      changeConfig.defaultPaths["status.oddsCloseAtDate"] = false;
      changeConfig.defaultPaths["status.oddsOverview"] = false;
    }
    if (statusBooleans) {
      changeConfig.defaultPaths["status.started"] = false;
      changeConfig.defaultPaths["status.finalized"] = false;
      changeConfig.defaultPaths["status.cancelled"] = false;
      changeConfig.defaultPaths["status.ended"] = false;
      changeConfig.defaultPaths["status.live"] = false;
      changeConfig.defaultPaths["status.delayed"] = false;
      changeConfig.defaultPaths["status.inBreak"] = false;
    }
    if (statusDisplays) {
      changeConfig.defaultPaths["status.currentPeriodID"] = "";
      changeConfig.defaultPaths["status.previousPeriodID"] = "";
      changeConfig.defaultPaths["status.clock"] = "";
    }
    if (statusOther) {
      changeConfig.defaultPaths["status.startsAt"] = null;
      changeConfig.defaultPaths["status.hardStart"] = false;
    }
  } else {
    changeConfig.ignorePaths["status.previousStartsAt"] = true;
    changeConfig.ignorePaths["status.nextUpdateAt"] = true;
    changeConfig.ignorePaths["status.periods"] = true;
    if (!statusOdds) {
      changeConfig.ignorePaths["status.anyOddsAvailable"] = true;
      changeConfig.ignorePaths["status.marketOddsAvailable"] = true;
      changeConfig.ignorePaths["status.oddsCloseAtStart"] = true;
      changeConfig.ignorePaths["status.oddsCloseAtDate"] = true;
      changeConfig.ignorePaths["status.oddsOverview"] = true;
    }
    if (!statusBooleans) {
      changeConfig.ignorePaths["status.started"] = true;
      changeConfig.ignorePaths["status.finalized"] = true;
      changeConfig.ignorePaths["status.cancelled"] = true;
      changeConfig.ignorePaths["status.ended"] = true;
      changeConfig.ignorePaths["status.live"] = true;
      changeConfig.ignorePaths["status.delayed"] = true;
      changeConfig.ignorePaths["status.inBreak"] = true;
    }
    if (!statusDisplays) {
      changeConfig.ignorePaths["status.currentPeriodID"] = true;
      changeConfig.ignorePaths["status.previousPeriodID"] = true;
      changeConfig.ignorePaths["status.clock"] = true;
    }
    if (!statusOther) {
      changeConfig.ignorePaths["status.startsAt"] = true;
      changeConfig.ignorePaths["status.hardStart"] = true;
    }
  }

  if (!allOdds) {
    changeConfig.ignorePaths["odds"] = true;
    if (closeOdds) {
      changeConfig.defaultPaths["odds.*.closeSpread"] = "";
      changeConfig.defaultPaths["odds.*.closeOverUnder"] = "";
      changeConfig.defaultPaths["odds.*.closeOdds"] = "";
    }
    if (oddValues) {
      changeConfig.defaultPaths["odds.*.spread"] = "";
      changeConfig.defaultPaths["odds.*.overUnder"] = "";
      changeConfig.defaultPaths["odds.*.odds"] = "";
    }
    if (oddScores) {
      changeConfig.defaultPaths["odds.*.score"] = 0;
    }
    if (oddStatuses) {
      changeConfig.defaultPaths["odds.*.started"] = false;
      changeConfig.defaultPaths["odds.*.ended"] = false;
      changeConfig.defaultPaths["odds.*.cancelled"] = false;
      changeConfig.defaultPaths["odds.*.available"] = false;
    }
  } else {
    changeConfig.ignorePaths["odds.*.bookSpread"] = true;
    changeConfig.ignorePaths["odds.*.bookOverUnder"] = true;
    changeConfig.ignorePaths["odds.*.bookOdds"] = true;
    changeConfig.ignorePaths["odds.*.closeBookOverUnder"] = true;
    changeConfig.ignorePaths["odds.*.closeBookSpread"] = true;
    changeConfig.ignorePaths["odds.*.closeBookOdds"] = true;
    changeConfig.ignorePaths["odds.*.sourceContext"] = true;
    if (!closeOdds) {
      changeConfig.ignorePaths["odds.*.closeSpread"] = true;
      changeConfig.ignorePaths["odds.*.closeOverUnder"] = true;
      changeConfig.ignorePaths["odds.*.closeOdds"] = true;
    }
    if (!oddValues) {
      changeConfig.ignorePaths["odds.*.spread"] = true;
      changeConfig.ignorePaths["odds.*.overUnder"] = true;
      changeConfig.ignorePaths["odds.*.odds"] = true;
    }
    if (!oddScores) {
      changeConfig.ignorePaths["odds.*.score"] = true;
    }
    if (!oddStatuses) {
      changeConfig.ignorePaths["odds.*.started"] = true;
      changeConfig.ignorePaths["odds.*.ended"] = true;
      changeConfig.ignorePaths["odds.*.cancelled"] = true;
      changeConfig.ignorePaths["odds.*.available"] = true;
    }
  }

  if (!allPlayers) {
    changeConfig.ignorePaths["players"] = true;
  } else {
    changeConfig.ignorePaths["players.*.sourceContext"] = true;
  }
  if (!allTeams) {
    changeConfig.ignorePaths["teams"] = true;
  } else {
    changeConfig.ignorePaths["teams.*.sourceContext"] = true;
  }
  if (!allInfo) {
    changeConfig.ignorePaths["info"] = true;
  }
  if (!allProps) {
    changeConfig.ignorePaths["props"] = true;
  }
  if (!leaderboard) {
    changeConfig.ignorePaths["leaderboard"] = true;
  }

  return meaningfulDataChanged(prevEvent, newEvent, changeConfig);
};
exports.eventHasMeaningfulChanges = eventHasMeaningfulChanges;

const oddsHaveMeaningfulChanges = (prevOddData, newOddData, defaultMeaningful = true, meaningfulDefinition = null) => {
  const closeOdds = meaningfulDefinition?.closeOdds ?? !!defaultMeaningful;
  const oddValues = meaningfulDefinition?.oddValues ?? !!defaultMeaningful;
  const oddScores = meaningfulDefinition?.oddScores ?? !!defaultMeaningful;
  const oddStatuses = meaningfulDefinition?.oddStatuses ?? !!defaultMeaningful;

  const changeConfig = {
    ignorePaths: {},
    defaultPaths: {},
    onlyCommonPaths: false,
    treatEmptyValuesSame: true,
  };

  changeConfig.ignorePaths["bookSpread"] = true;
  changeConfig.ignorePaths["bookOverUnder"] = true;
  changeConfig.ignorePaths["bookOdds"] = true;
  changeConfig.ignorePaths["closeBookOverUnder"] = true;
  changeConfig.ignorePaths["closeBookSpread"] = true;
  changeConfig.ignorePaths["closeBookOdds"] = true;
  changeConfig.ignorePaths["sourceContext"] = true;

  if (!closeOdds) {
    changeConfig.ignorePaths[`closeSpread`] = true;
    changeConfig.ignorePaths[`closeOverUnder`] = true;
    changeConfig.ignorePaths[`closeOdds`] = true;
  }

  if (!oddValues) {
    changeConfig.ignorePaths[`spread`] = true;
    changeConfig.ignorePaths[`overUnder`] = true;
    changeConfig.ignorePaths[`odds`] = true;
  }

  if (!oddScores) {
    changeConfig.ignorePaths[`score`] = true;
  }

  if (!oddStatuses) {
    changeConfig.ignorePaths[`started`] = true;
    changeConfig.ignorePaths[`ended`] = true;
    changeConfig.ignorePaths[`cancelled`] = true;
    changeConfig.ignorePaths[`available`] = true;
  }

  return meaningfulDataChanged(prevOddData || {}, newOddData || {}, changeConfig);
};
exports.oddsHaveMeaningfulChanges = oddsHaveMeaningfulChanges;
