const { WagerDisplays } = require("@wagerlab/utils/displays/WagerDisplays");
const { EventDisplays } = require("@wagerlab/utils/displays/EventDisplays");
const { EventShortDisplays } = require("@wagerlab/utils/displays/EventShortDisplays");
const { UserDisplays } = require("@wagerlab/utils/displays/UserDisplays");
const { ParlayDisplays } = require("@wagerlab/utils/displays/ParlayDisplays");
const { OddsDisplays } = require("@wagerlab/utils/displays/OddsDisplays");
const { TeamDisplays } = require("@wagerlab/utils/displays/TeamDisplays");
const { PlayerDisplays } = require("@wagerlab/utils/displays/PlayerDisplays");

const getDisplay = (dataType, dataDisplayType, ...data) => {
  if (dataType === "wager") return getWagerDisplay(dataDisplayType, ...data);
  if (dataType === "team") return getTeamDisplay(dataDisplayType, ...data);
  if (dataType === "event") return getEventDisplay(dataDisplayType, ...data);
  if (dataType === "user") return getUserDisplay(dataDisplayType, ...data);
  if (dataType === "eventShort") return getEventShortDisplay(dataDisplayType, ...data);
  if (dataType === "odds") return getOddsDisplay(dataDisplayType, ...data);
  if (dataType === "player") return getPlayerDisplay(dataDisplayType, ...data);
  return "";
};
exports.getDisplay = getDisplay;

const getWagerDisplay = (displayType, wager) => {
  const wagerDisplays = wager?.type === "parlayWrap" ? new ParlayDisplays(wager) : new WagerDisplays(wager);
  return wagerDisplays?.getDisplayString?.(displayType) || "";
};
exports.getWagerDisplay = getWagerDisplay;

const getTeamDisplay = (displayType, team) => {
  const teamDisplays = new TeamDisplays(team);
  return teamDisplays?.getDisplayString?.(displayType) || "";
};
exports.getTeamDisplay = getTeamDisplay;

const getEventDisplay = (displayType, event) => {
  const eventDisplays = new EventDisplays(event);
  return eventDisplays?.getDisplayString?.(displayType) || "";
};
exports.getEventDisplay = getEventDisplay;

const getEventShortDisplay = (displayType, eventShort) => {
  const eventShortDisplays = new EventShortDisplays(eventShort);
  return eventShortDisplays?.getDisplayString?.(displayType) || "";
};
exports.getEventShortDisplay = getEventShortDisplay;

const getOddsDisplay = (displayType, oddData, eventData) => {
  const oddsDisplays = new OddsDisplays(oddData, eventData);
  return oddsDisplays?.getDisplayString?.(displayType) || "";
};
exports.getOddsDisplay = getOddsDisplay;

const getUserDisplay = (displayType, user) => {
  const userDisplays = new UserDisplays(user);
  return userDisplays?.getDisplayString?.(displayType) || "";
};
exports.getUserDisplay = getUserDisplay;

const getPlayerDisplay = (displayType, player) => {
  const playerDisplays = new PlayerDisplays(player);
  return playerDisplays?.getDisplayString?.(displayType) || "";
};
exports.getPlayerDisplay = getPlayerDisplay;
