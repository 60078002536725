import React, { useEffect, useMemo, useState } from "react";
import { Paper, TableContainer, TextField, Typography } from "@mui/material";
import _ from "lodash";
import { getTeam, getUnknownPlayerLookups, setAggregatorQueryPlayerLookups } from "@wagerlab/admin/src/utils/playerLookups/database";
import { expandAllPlayerLookups } from "@wagerlab/utils/aggregator/playerNames";
import { formatLookups, getAllLookupEntityIDs, getPlayerName, getSearchLookup, setOverrideLookup } from "@wagerlab/utils/aggregator/lookups";
import { generateEmbeddings, queryPineconeIndex, upsertPineconeIndexData } from "@wagerlab/admin/src/utils/ai/ai";
import { deleteValue, writeBatch } from "@wagerlab/utils/database/firestore";
import { copyData, dataAdditions, dataChanged, dataUpdates, mergeData, setObjVal } from "@wagerlab/utils/data/mutations";
import { getDataUpdatedColor } from "@wagerlab/admin/src/components/lookups/shared/add/utils";
import ColorLegend from "@wagerlab/admin/src/components/lookups/shared/add/ColorLegend";
import { HeaderButtons } from "@wagerlab/admin/src/components/lookups/shared/add/HeaderButtons";
import { OverridesTable } from "@wagerlab/admin/src/components/lookups/shared/add/OverridesTable";
import { UnknownsTable } from "@wagerlab/admin/src/components/lookups/shared/add/UnknownsTable";
import { formatModalData, ObjectDataDialog } from "@wagerlab/admin/src/components/lookups/shared/add/ObjectDataDialog";
import * as playerGetters from "@wagerlab/admin/src/components/lookups/players/add/getters";
import { isEmptyObject } from "@wagerlab/utils/data/types";

const validateNewPlayerID = (playerID, teamID) => {
  if (!playerID?.length) return `Player ID have a value`;
  const playerIDRegex = new RegExp(`^[A-Z0-9_]+_${teamID}$`);
  if (!playerIDRegex.test(playerID)) return `Player ID must contain only capital letters, numbers, and underscores and end with _${teamID}`;
  return "";
};

export const AddNewPlayerIds = ({ teamID, leagueID }) => {
  const [dataUpdatedAt, setDataUpdatedAt] = useState();
  const [originalUnknownPlayers, setOriginalUnknownPlayers] = useState({});
  const [unknownPlayers, setUnknownPlayers] = useState({});
  const [playerLookupOverrides, setPlayerLookupOverrides] = useState({});
  const [playerLookupErrors, setPlayerLookupErrors] = useState({});
  const [aiSuggestions, setAISuggestions] = useState({});

  const [searchName, setSearchName] = useState("");
  const [teamData, setTeamData] = useState(null);
  const [expandedPlayerLookups, setExpandedPlayerLookups] = useState(null);

  const [error, setError] = useState(null);
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const [modalData, setModalData] = useState(null);

  const unknownPlayerSearchPatterns = useMemo(() => {
    return Object.entries(originalUnknownPlayers || {}).reduce((searchPatternsMap, [lookupKey, unknownPlayer]) => {
      if (!lookupKey) return searchPatternsMap;
      const rawTermsList = [getPlayerName(unknownPlayer?.playerData), ...(unknownPlayer?.playerNameLookups || []), ...(unknownPlayer?.playerNameLookupsOrig || [])];
      const searchableTermsList = rawTermsList.map((term) => (term || "").toLowerCase().replace(/\s/g, ""));
      searchPatternsMap[lookupKey] = searchableTermsList.join("");
      return searchPatternsMap;
    }, {});
  }, [originalUnknownPlayers]);

  useEffect(() => {
    reset();
  }, [teamID]);

  useEffect(() => {
    validateAllEntries();
  }, [playerLookupOverrides]);

  const handleOpenModal = (event, modalUnknownPlayer, modalLookupKey) => {
    event.stopPropagation();
    setModalData(formatModalData(modalUnknownPlayer, modalLookupKey));
  };

  const reset = () => {
    setDataUpdatedAt(null);
    setOriginalUnknownPlayers({});
    setUnknownPlayers({});
    setPlayerLookupOverrides({});
    setSearchName("");
    setTeamData(null);
    setExpandedPlayerLookups(null);
    setPlayerLookupErrors({});
  };

  const loadData = async () => {
    if (!teamID) return;
    setLoading(true);
    reset();

    const unknownPlayersPromise = getUnknownPlayerLookups(teamID);
    const teamPromise = getTeam(teamID);

    const [unknownPlayersResponse, team] = await Promise.all([unknownPlayersPromise, teamPromise]);

    setExpandedPlayerLookups(expandAllPlayerLookups(team));
    setDataUpdatedAt(unknownPlayersResponse?.ranAt);
    setUnknownPlayers(copyData(unknownPlayersResponse?.unknownPlayers || {}));
    setOriginalUnknownPlayers(unknownPlayersResponse?.unknownPlayers || {});
    setTeamData(team);
    setLoading(false);
  };

  const setErrorMsg = (msg) => {
    setError(msg);
    setInfo(null);
    setTimeout(() => setError(null), 10000);
  };

  const setInfoMsg = (msg) => {
    setInfo(msg);
    setError(null);
    setTimeout(() => setInfo(null), 10000);
  };

  const refreshData = async () => {
    if (!leagueID) {
      setErrorMsg(`Must set leagueID before refreshing data!`);
      return;
    }

    setLoading(true);

    const result = await setAggregatorQueryPlayerLookups(leagueID);
    if (!result) {
      setErrorMsg(`Error setting aggregator to refresh ${leagueID} data!`);
      setLoading(false);
      return;
    }

    setLoading(false);
    setInfoMsg(
      `Refreshing ${leagueID} data, this can take a few minutes. Press "Load Data" after a few minutes to pull the updated data. The "ranAt" timestamp should update once the data is up to date.`
    );
  };

  const deselectAll = () => {
    const { newOverrides, newUnknowns } = Object.entries(playerLookupOverrides || {}).reduce(
      (acc, [lookupKey, assignedPlayerID]) => {
        if (!assignedPlayerID) {
          _.unset(acc.newOverrides, lookupKey);
          setObjVal(acc.newUnknowns, lookupKey, originalUnknownPlayers[lookupKey]);
        }
        return acc;
      },
      { newOverrides: { ...playerLookupOverrides }, newUnknowns: { ...unknownPlayers } }
    );

    setUnknownPlayers(newUnknowns);
    setPlayerLookupOverrides(newOverrides);
    setPlayerLookupErrors({});
  };

  const upsertTeamEmbeddings = async () => {
    setLoading(true);
    if (!expandedPlayerLookups) {
      setErrorMsg("There is no existing team data for AI to compare names against!");
      setLoading(false);
      return;
    }

    const lookupData = getAllLookupEntityIDs(expandedPlayerLookups);
    const vectors = await generateEmbeddings(lookupData);
    await upsertPineconeIndexData("player-lookups", teamID, vectors);

    setInfoMsg("Successfully updated team embeddings!");
    setLoading(false);
  };

  const fetchAISuggestions = async () => {
    setLoading(true);
    if (!playerLookupOverrides || !expandedPlayerLookups) {
      setErrorMsg("There is no existing league data for AI to compare names against!");
      setLoading(false);
      return;
    }

    const overrideKeys = Object.keys(playerLookupOverrides).filter((key) => playerLookupOverrides[key]?.length === 0);
    if (overrideKeys.length > 50) {
      setErrorMsg("AI suggestions are limited to 50 lookup names at a time!");
      setLoading(false);
      return;
    }

    if (overrideKeys.length === 0) {
      setErrorMsg("No empty entries to get AI suggestions for!");
      setLoading(false);
      return;
    }

    try {
      const playerNames = overrideKeys.map((key) => getPlayerName(originalUnknownPlayers[key]));
      const lookupVectors = await generateEmbeddings(playerNames);
      const pineconePromises = (lookupVectors || []).map((vector) => queryPineconeIndex("player-lookups", teamID, vector.values, 3));
      const queryResults = await Promise.all(pineconePromises);
      const aiResults = queryResults.reduce((results, queryResult, index) => {
        const matches = queryResult?.matches?.slice(0, 3)?.map((match) => ({ id: match.id, score: match.score }));
        if (matches) {
          matches.sort((a, b) => b.score - a.score);
          _.set(results, overrideKeys[index], matches);
        }
        return results;
      }, {});

      setAISuggestions(aiResults);
    } catch (e) {
      setLoading(false);
      console.error("Error fetching AI suggestion data:", e);
      setErrorMsg("Error fetching AI suggestion data!");
      return;
    }

    setInfoMsg("Successfully got AI suggestions for selected team lookups!");
    setLoading(false);
  };

  const handleAdd = (lookupKey) => {
    if (!lookupKey) return;
    setPlayerLookupOverrides({ ...playerLookupOverrides, [lookupKey]: "" });
    const newUnknownTeamLookups = { ...unknownPlayers };
    _.unset(newUnknownTeamLookups, lookupKey);
    setUnknownPlayers(newUnknownTeamLookups);
  };

  const handleDelete = (lookupKey) => {
    if (!lookupKey) return;
    const newUnknownTeams = { ...unknownPlayers, [lookupKey]: { ...originalUnknownPlayers[lookupKey] } };
    const newTeamLookupOverrides = { ...playerLookupOverrides };
    const newTeamLookupErrors = { ...playerLookupErrors };
    _.unset(newTeamLookupOverrides, lookupKey);
    _.unset(newTeamLookupErrors, lookupKey);

    setPlayerLookupOverrides(newTeamLookupOverrides);
    setUnknownPlayers(newUnknownTeams);
    setPlayerLookupErrors(newTeamLookupErrors);
  };

  const handleSearchChange = (event) => setSearchName(event?.target?.value || "");

  const checkShouldContinue = (confirmMessage) => {
    const shouldContinue = window.confirm(confirmMessage);
    if (!shouldContinue) setLoading(false);
    return !!shouldContinue;
  };

  const handleSubmit = async () => {
    if (!teamID || teamData?.teamID !== teamID) return;
    setLoading(true);

    const overrideEntries = Object.entries(playerLookupOverrides || {});
    for (let i = 0; i < overrideEntries.length; i++) {
      const [lookupKey, playerID] = overrideEntries[i];
      const playerIDValidationError = validateNewPlayerID(playerID, teamID);
      if (playerIDValidationError) {
        const playerIDValidationErrorMessage = `Error in playerID for ${lookupKey}: ${playerIDValidationError}`;
        setErrorMsg(playerIDValidationErrorMessage);
        setLoading(false);
        return;
      }
    }

    if (Object.values(playerLookupErrors || {}).some((e) => e?.error)) {
      const shouldContinue = checkShouldContinue("There are errors in the data you provided. Are you sure you want to submit changes?");
      if (!shouldContinue) return;
    }

    const dbTeamData = await getTeam(teamID);
    const didChange = dataChanged(teamData, dbTeamData);
    if (didChange) {
      const shouldContinue = checkShouldContinue("Team data has changed since you loaded it. Are you sure you want to submit changes?");
      if (!shouldContinue) return;
    }

    const { playersToWrite, lookupKeysToWrite, newUnknownPlayers } = Object.entries(playerLookupOverrides || {}).reduce(
      (acc, [lookupKey, playerID]) => {
        const playerData = { ...(originalUnknownPlayers?.[lookupKey]?.playerData || {}), playerID };
        if (!playerData?.playerID || !playerData.playerID.endsWith(`_${teamID}`)) return acc;

        // If we're writing multiple players to the same playerID, merge the results.
        const samePlayerToWriteIndex = acc.playersToWrite.findIndex((p) => p?.playerID === playerID);
        if (samePlayerToWriteIndex >= 0) {
          const mergedPlayer = mergeData(acc.playersToWrite[samePlayerToWriteIndex], playerData);
          acc.playersToWrite[samePlayerToWriteIndex] = mergedPlayer;
        } else {
          acc.playersToWrite.push(playerData);
        }

        acc.lookupKeysToWrite.push(lookupKey);
        _.unset(acc.newUnknownPlayers, lookupKey);
        return acc;
      },
      { playersToWrite: [], lookupKeysToWrite: [], newUnknownPlayers: { ...unknownPlayers } }
    );

    const batchWrites = [];

    let newTeamPlayerLookups = copyData(teamData?.playerLookups || {});
    lookupKeysToWrite.forEach((lookupKey) => {
      const playerNameLookupsToAdd = originalUnknownPlayers[lookupKey].playerNameLookups || [];
      const playerID = playerLookupOverrides[lookupKey];
      if (!playerID || !playerNameLookupsToAdd || !playersToWrite.some((player) => player.playerID === playerID)) return;
      playerNameLookupsToAdd.forEach((newLookupString) => {
        const remoteExistingLookup = getSearchLookup(expandedPlayerLookups, newLookupString);
        if (remoteExistingLookup && remoteExistingLookup !== playerID) {
          const shouldContinue = window.confirm(`Is it ok to change the remote lookup ${newLookupString} from ${remoteExistingLookup} to ${playerID}`);
          if (!shouldContinue) return;
        }
        const localExistingLookup = getSearchLookup(newTeamPlayerLookups, newLookupString);
        //if (localExistingLookup && localExistingLookup !== playerID && localExistingLookup !== remoteExistingLookup) {
        if (localExistingLookup && localExistingLookup !== playerID) {
          const shouldContinue = window.confirm(`This lookup was changed multiple times: ${newLookupString} Do you NOT want to map to ${localExistingLookup} and instead MAP TO ${playerID}`);
          if (!shouldContinue) return;
        }
        newTeamPlayerLookups = setOverrideLookup(newTeamPlayerLookups, newLookupString, playerID);
      });
    });

    const formattedTeamPlayerLookups = formatLookups(newTeamPlayerLookups);
    const lookupDataChanges = dataUpdates({ playerLookups: teamData?.playerLookups || {} }, { playerLookups: formattedTeamPlayerLookups }, deleteValue());
    const newPlayerIDs = [];
    let teamUpdates = lookupDataChanges || {};
    playersToWrite.forEach((playerData, index) => {
      const existingPlayerData = teamData?.players?.[playerData.playerID];
      if (existingPlayerData) {
        const dataAdditionUpdates = dataAdditions(existingPlayerData, playerData, ["sourceContext"], `players.${playerData.playerID}`);
        if (dataAdditionUpdates) teamUpdates = { ...teamUpdates, ...dataAdditionUpdates };
      } else {
        newPlayerIDs.push(playerData.playerID);
        teamUpdates = { ...teamUpdates, [`players.${playerData.playerID}`]: playerData };
      }
    });
    if (!isEmptyObject(teamUpdates)) batchWrites.push({ method: "update", collectionName: "Teams", collectionID: teamID, payload: teamUpdates });

    if (!batchWrites.length) {
      setInfoMsg(`Nothing to update for ${teamID} player lookups!`);
      setLoading(false);
      return;
    }

    batchWrites.push({
      method: "update",
      collectionName: "AggregatorPlayerLookups",
      collectionID: teamID,
      payload: {
        manualUpdateAt: new Date(),
        unknownPlayers: newUnknownPlayers,
      },
    });

    batchWrites.push({ method: "update", collectionName: "Config", collectionID: "aggregator", payload: { "runner.cacheInvalidated": true } });
    let result = await writeBatch(batchWrites);
    if (!result) {
      setErrorMsg(`Error writing batched data, changes will not apply!`);
      setLoading(false);
      return;
    }

    // If we created new TeamIDs, upsert them in the Pinecone DB
    if (newPlayerIDs.length > 0) {
      const vectors = await generateEmbeddings(newPlayerIDs);
      await upsertPineconeIndexData("player-lookups", teamID, vectors);
    }

    reset();
    await loadData();

    setInfoMsg(`Successfully updated player lookups for ${teamID}!`);
    setLoading(false);
  };

  const validateAllEntries = () => {
    const newErrors = {};

    Object.entries(playerLookupOverrides).forEach(([lookupKey, playerID]) => {
      const error = getPlayerIDFieldError(lookupKey, playerID);
      const notice = getPlayerIDFieldNotice(lookupKey, playerID);
      if (error) {
        newErrors[lookupKey] = {
          error: true,
          helperText: error,
        };
      } else if (notice) {
        newErrors[lookupKey] = {
          error: false,
          helperText: notice,
        };
      }
    });

    setPlayerLookupErrors(newErrors);
  };

  const getPlayerIDFieldError = (lookupKey, playerID) => {
    if (!playerID?.length) return null;

    const playerIDError = validateNewPlayerID(playerID, teamData?.teamID);
    if (playerIDError) return playerIDError;

    const originalUnknownPlayer = originalUnknownPlayers[lookupKey];
    const unknownPlayerLookupNames = originalUnknownPlayer.playerNameLookups;
    for (let i = 0; i < unknownPlayerLookupNames.length; i++) {
      const lookupString = unknownPlayerLookupNames[i];
      const existingSearchLookup = expandedPlayerLookups?.searches?.[lookupString];
      if (existingSearchLookup && existingSearchLookup !== playerID) return `Assigning player to this playerID causes search clash...lookup:${lookupString}  clashing playerID:${existingSearchLookup}`;
      const existingOverrideLookup = expandedPlayerLookups?.overrides?.[lookupString];
      if (existingOverrideLookup && existingOverrideLookup !== playerID)
        return `Assigning player to this playerID causes override clash...lookup:${lookupString}  clashing playerID:${existingOverrideLookup}`;
      const existingClashLookupsStr = (expandedPlayerLookups?.clashes?.[lookupString] || []).filter((id) => id && id !== playerID).join(", ");
      if (existingClashLookupsStr) return `Assigning player to this playerID adds to existing clash...lookup:${lookupString}  clashing playerIDs:${existingClashLookupsStr}`;
    }

    return null;
  };

  const getPlayerIDFieldNotice = (lookupKey, playerID) => {
    if (!playerID || !playerID.length) return null;
    const countPlayerID = Object.values(playerLookupOverrides).filter((id) => id === playerID).length;
    if (countPlayerID > 1) return `This PlayerID being assigned to ${countPlayerID} unknown players`;
    return null;
  };

  const searchPlayerFilter = (lookupKey) => {
    const playerSearchPattern = unknownPlayerSearchPatterns?.[lookupKey] || "";
    const inputSearchPattern = searchName.toLowerCase().replace(/\s/g, "");
    return !inputSearchPattern?.length || playerSearchPattern.includes(inputSearchPattern);
  };

  return (
    <TableContainer component={Paper} style={{ padding: 20 }}>
      <ColorLegend />
      {error && <Typography sx={{ color: "red", fontSize: 20, margin: "10px" }}>{error}</Typography>}
      {info && <Typography sx={{ color: "green", fontSize: 20, margin: "10px" }}>{info}</Typography>}
      <TextField value={searchName} onChange={(event) => handleSearchChange(event)} placeholder={"Search for player name..."} />
      <HeaderButtons
        loading={loading}
        loadData={loadData}
        refreshData={refreshData}
        deselectAll={deselectAll}
        upsertEmbeddings={upsertTeamEmbeddings}
        fetchAISuggestions={fetchAISuggestions}
        handleSubmit={handleSubmit}
        entityType={"player"}
      />
      {dataUpdatedAt && (
        <Typography sx={{ fontSize: 20, color: getDataUpdatedColor(dataUpdatedAt), margin: "10px" }}>Data last updated at: {dataUpdatedAt?.toLocaleString?.() || "Unknown"}</Typography>
      )}
      <OverridesTable
        searchFilter={searchPlayerFilter}
        originalUnknownEntities={originalUnknownPlayers}
        parentLookupsObj={expandedPlayerLookups}
        parentID={teamData?.teamID}
        entityLookupOverrides={playerLookupOverrides}
        setEntityLookupOverrides={setPlayerLookupOverrides}
        entityLookupErrors={playerLookupErrors}
        aiSuggestions={aiSuggestions}
        handleOpenModal={handleOpenModal}
        handleDelete={handleDelete}
        getters={playerGetters}
      />
      <UnknownsTable unknownEntities={unknownPlayers} searchFilter={searchPlayerFilter} handleAdd={handleAdd} handleOpenModal={handleOpenModal} getters={playerGetters} />
      <ObjectDataDialog data={modalData} setData={setModalData} setOriginalUnknownEntities={setOriginalUnknownPlayers} dataPath="playerData" namesPath="playerData" lookupsPath="playerNameLookups" />
    </TableContainer>
  );
};
