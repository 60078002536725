import { setObjVal } from "@wagerlab/utils/data/mutations";
import * as Yup from "yup";

export const initializeFormData = (configData) => {
  if (!configData) return null;

  const affiliateIDs = Object.keys(configData?.affiliateConfig?.affiliates || {}).filter((id) => id);

  const usStates = Object.entries(configData?.affiliateConfig?.locales?.US || {})
    .reduce((usStatesList, [stateCode, stateAffiliateList]) => {
      if (!stateCode) return usStatesList;
      usStatesList.push({
        code: stateCode,
        affiliateIDs: stateAffiliateList || [],
      });
      return usStatesList;
    }, [])
    .sort(localeListSorter);

  const caStates = Object.entries(configData?.affiliateConfig?.locales?.CA || {})
    .reduce((caStatesList, [stateCode, stateAffiliateList]) => {
      if (!stateCode) return caStatesList;
      caStatesList.push({
        code: stateCode,
        affiliateIDs: stateAffiliateList || [],
      });
      return caStatesList;
    }, [])
    .sort(localeListSorter);

  const countries = Object.entries(configData?.affiliateConfig?.locales || {})
    .reduce((countryList, [countryCode, countryAffiliateList]) => {
      if (!countryCode || countryCode === "US" || countryCode === "CA") return countryList;
      countryList.push({
        code: countryCode,
        affiliateIDs: countryAffiliateList || [],
      });
      return countryList;
    }, [])
    .sort(localeListSorter);

  return { usStates, caStates, countries, affiliateIDs };
};

const localeListSorter = (localeItemA, localeItemB) => {
  if (localeItemA?.code === "default") return -1;
  if (localeItemB?.code === "default") return 1;
  return (localeItemA?.code || "").localeCompare(localeItemB?.code || "");
};

export const validateFormData = (affiliateLocalesFormData, prevConfigData) => {
  const { usStates, caStates, countries } = affiliateLocalesFormData || {};
  if (!usStates?.length) return "US States list is empty";
  if (!caStates?.length) return "CA States list is empty";
  if (!countries?.length) return "Countries list is empty";
  if (!usStates?.some((state) => state?.code === "default")) return "US States list is missing default";
  if (!caStates?.some((state) => state?.code === "default")) return "CA States list is missing default";
  if (!countries?.some((country) => country?.code === "default")) return "Countries list is missing default";

  return "";
};

export const buildConfigFromFormData = (affiliateLocalesFormData, prevConfigData, newConfigData) => {
  if (!newConfigData || !prevConfigData) return newConfigData;
  const { usStates, caStates, countries } = affiliateLocalesFormData || {};

  const affiliateLocales = countries.reduce((locales, country) => {
    if (!country?.code) return locales;
    locales[country.code] = country.affiliateIDs || [];
    return locales;
  }, {});

  affiliateLocales.US = usStates.reduce((states, state) => {
    if (!state?.code) return states;
    states[state.code] = state.affiliateIDs;
    return states;
  }, {});

  affiliateLocales.CA = caStates.reduce((states, state) => {
    if (!state?.code) return states;
    states[state.code] = state.affiliateIDs;
    return states;
  }, {});

  setObjVal(newConfigData, "affiliateConfig.locales", affiliateLocales);
  return newConfigData;
};

const uniqueAffiliateIDTest = {
  name: "unique-affiliateID",
  message: "Each affiliate ID must be unique",
  test: function (ids) {
    const uniqueIDs = new Set(ids || []);
    return ids?.length === uniqueIDs.size;
  },
};

const uniqueLocaleCodesTest = {
  name: "unique-localeCodes",
  message: "Each locale code must be unique",
  test: function (value) {
    const codes = value.map((v) => v?.code || "");
    const uniqueCodes = new Set(codes);
    return codes.length === uniqueCodes.size;
  },
};

const defaultCodeProvidedTest = {
  name: "provided-defaultCode",
  message: "'default' code must be provided for US, CA, and rest",
  test: function (value) {
    return value.some((v) => v?.code === "default");
  },
};

const defaultOrAllCapsCodeTest = {
  name: "default-or-all-caps-code",
  message: "Code must be 'default' or a valid country/state code",
  test: (v) => v === "default" || (v === v?.toUpperCase?.() && v?.length <= 3 && v === v?.trim?.()),
};

const affiliateLocaleListValidation = Yup.array()
  .of(
    Yup.object({
      code: Yup.string().required("Country/state code is required").test(defaultOrAllCapsCodeTest),
      affiliateIDs: Yup.array().of(Yup.string().required("Affiliate ID is required")).required("Affiliate IDs list is missing").test(uniqueAffiliateIDTest),
    })
  )
  .required("Affiliates list is missing")
  .test(uniqueLocaleCodesTest)
  .test(defaultCodeProvidedTest);

export const formFieldValidation = Yup.object({
  usStates: affiliateLocaleListValidation,
  caStates: affiliateLocaleListValidation,
  countries: affiliateLocaleListValidation,
});
