import React, { useState } from "react";
import { Alert, Box, Container, Grid, Snackbar, Typography } from "@mui/material";
import { Formik, Form } from "formik";
import { EventQueryForm } from "@wagerlab/admin/src/components/events/EventQueryForm";
import { queryEvents } from "@wagerlab/admin/src/utils/events/database";

const QUERY_FORM_CONFIG = {
  type: {
    defaultValue: "prop",
    key: "type",
    includedKey: null,
    eventPath: "type",
    required: true,
  },
  eventID: {
    defaultValue: "",
    key: "eventID",
    includedKey: "eventIDIncluded",
    eventPath: "eventID",
  },
  eventName: {
    defaultValue: "",
    key: "eventName",
    includedKey: "eventNameIncluded",
    eventPath: "eventName",
  },
  sportID: {
    defaultValue: "",
    key: "sportID",
    includedKey: "sportIDIncluded",
    eventPath: "sportID",
  },
  leagueID: {
    defaultValue: "",
    key: "leagueID",
    includedKey: "leagueIDIncluded",
    eventPath: "leagueID",
  },
  homeTeamID: {
    defaultValue: "",
    key: "homeTeamID",
    includedKey: "homeTeamIDIncluded",
    eventPath: "teams.home.teamID",
  },
  awayTeamID: {
    defaultValue: "",
    key: "awayTeamID",
    includedKey: "awayTeamIDIncluded",
    eventPath: "teams.away.teamID",
  },
  startsAt: {
    defaultValue: null,
    key: "startsAt",
    includedKey: "startsAtIncluded",
    eventPath: "status.startsAt",
    transformer: (momentDate) => momentDate?.toDate?.(),
  },
  anyOddsAvailable: {
    defaultValue: false,
    key: "anyOddsAvailable",
    includedKey: "anyOddsAvailableIncluded",
    eventPath: "status.anyOddsAvailable",
  },
  marketOddsAvailable: {
    defaultValue: false,
    key: "marketOddsAvailable",
    includedKey: "marketOddsAvailableIncluded",
    eventPath: "status.marketOddsAvailable",
  },
  started: {
    defaultValue: false,
    key: "started",
    includedKey: "startedIncluded",
    eventPath: "status.started",
  },
  ended: {
    defaultValue: false,
    key: "ended",
    includedKey: "endedIncluded",
    eventPath: "status.ended",
  },
  cancelled: {
    defaultValue: false,
    key: "cancelled",
    includedKey: "cancelledIncluded",
    eventPath: "status.cancelled",
  },
  delayed: {
    defaultValue: false,
    key: "delayed",
    includedKey: "delayedIncluded",
    eventPath: "status.delayed",
  },
  finalized: {
    defaultValue: false,
    key: "finalized",
    includedKey: "finalizedIncluded",
    eventPath: "status.finalized",
  },
};

const DEFAULT_FORM_VALUES = Object.values(QUERY_FORM_CONFIG).reduce((defaultFormVals, formConfigItem) => {
  if (!formConfigItem?.key) return defaultFormVals;
  defaultFormVals[formConfigItem.key] = formConfigItem.defaultValue;
  if (formConfigItem?.includedKey) defaultFormVals[formConfigItem.includedKey] = false;
  return defaultFormVals;
}, {});

export const EventQueryBuilder = ({ handleQueryResult }) => {
  const [message, setMessage] = useState("");

  const handleFormSubmit = async (formData, formikBag) => {
    setMessage("");
    const eventQueryParts = Object.values(QUERY_FORM_CONFIG).reduce((queryParts, queryConfigItem) => {
      const { includedKey, key, required, eventPath, transformer } = queryConfigItem || {};
      if (!key || !eventPath) return queryParts;
      const shouldInclude = required || !includedKey || formData?.[includedKey];
      if (!shouldInclude) return queryParts;
      const value = transformer ? transformer(formData[key]) : formData[key];
      if (value == null) return queryParts;
      queryParts.push({ key: eventPath, operator: "==", value: value });
      return queryParts;
    }, []);

    if (!eventQueryParts.length) return setMessage("Include at least 1 item in your query");
    const queryResult = await queryEvents(eventQueryParts);

    if (!queryResult) return setMessage("Error querying events");
    if (!queryResult.length) return setMessage("No events found");

    handleQueryResult(queryResult);
  };

  return (
    <>
      <Grid item xs={12}>
        <Container maxWidth="xl">
          <Formik initialValues={{ ...DEFAULT_FORM_VALUES }} onSubmit={handleFormSubmit}>
            <EventQueryForm />
          </Formik>
        </Container>
      </Grid>

      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={!!message} onClose={() => setMessage("")} autoHideDuration={10000}>
        <Alert onClose={() => setMessage("")} severity={"info"} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};
