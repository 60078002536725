import React from "react";
import { MenuItem, Slider, TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { isString } from "@wagerlab/utils/data/types";

export const NumberSlider = ({ name, disabled, ...otherProps }) => {
  const { isSubmitting } = useFormikContext();
  const [field, { touched, error }, { setValue }] = useField(name);

  const isDisabled = !!(isSubmitting || disabled);
  const hasErrors = !!(touched && error && isString(error));

  return <Slider {...field} {...otherProps} disabled={isDisabled} error={hasErrors ? error : ""} helpertext={hasErrors ? error : null} />;
};
