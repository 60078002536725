import React from "react";
import { Outlet } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AppBar, Box, Button, Stack, Toolbar, Typography } from "@mui/material";
import firebase from "firebase/compat/app";
import { useNavigate } from "react-router-dom";

const defaultTheme = createTheme();

export const AppLayout = () => {
  const navigate = useNavigate();

  const handleSignOut = () => {
    firebase.auth().signOut();
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main">
        <CssBaseline />
        <AppBar>
          <Toolbar>
            <Box sx={{ flexGrow: 1 }}>
              <Stack direction="row" spacing={2}>
                <Typography variant="h6" component="div">
                  WagerLab Admin
                </Typography>
                <Button onClick={() => navigate("/")} color="inherit">
                  Home
                </Button>
              </Stack>
            </Box>

            <Stack direction="row" spacing={2}>
              <Button color="inherit" onClick={handleSignOut}>
                Log Out
              </Button>
            </Stack>
          </Toolbar>
        </AppBar>
        <Outlet />
      </Container>
    </ThemeProvider>
  );
};
