const _ = require("lodash");
const wagerIsValid = (wager) => !!wager?.wagerID;
exports.wagerIsValid = wagerIsValid;

const wagerSelf = (wager, userID) => {
  if (!isMyWager(wager, userID)) return null;
  return iProposedWager(wager, userID) ? wager?.proposer : wager?.recipient;
};
exports.wagerSelf = wagerSelf;

const wagerSelfString = (wager, userID) => {
  if (!isMyWager(wager, userID)) return null;
  return iProposedWager(wager, userID) ? "proposer" : "recipient";
};
exports.wagerSelfString = wagerSelfString;

const wagerOpponent = (wager, userID) => {
  if (!isMyWager(wager, userID)) return null;
  return iProposedWager(wager, userID) ? wager?.recipient : wager?.proposer;
};
exports.wagerOpponent = wagerOpponent;

const iProposedWager = (wager, userID) => !!userID && wager?.proposer?.userID === userID;
exports.iProposedWager = iProposedWager;

const iReceivedWager = (wager, userID) => !!userID && wager?.recipient?.userID === userID;
exports.iReceivedWager = iReceivedWager;

const isMyWager = (wager, userID) => iProposedWager(wager, userID) || iReceivedWager(wager, userID);
exports.isMyWager = isMyWager;

const iArchivedWager = (wager, userID) => (iProposedWager(wager, userID) && !!wager?.status?.archived?.proposer) || (iReceivedWager(wager, userID) && !!wager?.status?.archived?.recipient);
exports.iArchivedWager = iArchivedWager;

const wagerIsGraded = (wager) => !!wager?.status?.graded;
exports.wagerIsGraded = wagerIsGraded;

const wagerIsGradedPush = (wager) => wagerIsGraded(wager) && !wager?.status?.winner && !wager?.status?.loser;
exports.wagerIsGradedPush = wagerIsGradedPush;

const iWonWager = (wager, userID) => (iProposedWager(wager, userID) && wager?.status?.winner == "proposer") || (iReceivedWager(wager, userID) && wager?.status?.winner == "recipient");
exports.iWonWager = iWonWager;

const iLostWager = (wager, userID) => (iProposedWager(wager, userID) && wager?.status?.loser == "proposer") || (iReceivedWager(wager, userID) && wager?.status?.loser == "recipient");
exports.iLostWager = iLostWager;

const iPushedWager = (wager, userID) => isMyWager(wager, userID) && wagerIsGraded(wager) && !iWonWager(wager, userID) && !iLostWager(wager, userID);
exports.iPushedWager = iPushedWager;

const iSeeAGradedWager = (wager, userID) => !isMyWager(wager, userID) && wagerIsGraded(wager);
exports.iSeeAGradedWager = iSeeAGradedWager;

const wagerAccepted = (wager) => !!wager?.status?.responded && !!wager?.status?.accepted; // Whether the wager has been accepted by the recipient
exports.wagerAccepted = wagerAccepted;

const wagerDeclined = (wager) => !!wager?.status?.responded && !wager?.status?.accepted; // Whether the wager has been declined by the recipient
exports.wagerDeclined = wagerDeclined;

const wagerEventOddsCancelled = (wager) => !!wager?.status?.cancelled && !wagerDeclined(wager); // Whether the underlying event or odds were cancelled (when the wager has been otherwise active)
exports.wagerEventOddsCancelled = wagerEventOddsCancelled;

const wagerWithdrawn = (wager) => !!wager?.status?.withdrawn && !wagerDeclined(wager); // Whether the wager was withdrawn by the proposer. (when the wager has been otherwise active)
exports.wagerWithdrawn = wagerWithdrawn;

const wagerExpired = (wager) => !!wager?.status?.expired && !wagerDeclined(wager); // Whether the wager expired before being accepted/declined. (when the wager has been otherwise active)
exports.wagerExpired = wagerExpired;

const wagerVoided = (wager) => !!wager?.status?.voided; //The voided field tracks cancelled=true OR withdrawn=true OR expired=true (This helper doesn't care about whether the wager was active when it was cancelled/withdrawn/expired)
exports.wagerVoided = wagerVoided;

const wagerHasAction = (wager) => !!wager?.status?.hasAction; // Whether the wager will/does count to the users total. This is true only if responded=true AND accepted=true AND voided=false
exports.wagerHasAction = wagerHasAction;

const wagerCanAttachRecipient = (wager) => !!wager?.attachLink && !wager?.recipient?.userID && wagerPendingResponse(wager); // Whether the wager can still have a recipient attached via the attachLink
exports.wagerCanAttachRecipient = wagerCanAttachRecipient;

const wagerPendingResponse = (wager) => !wagerVoided(wager) && !wager?.status?.responded; // Whether the wager has not yet been accepted or declined
exports.wagerPendingResponse = wagerPendingResponse;

const wagerInProgress = (wager) => wagerHasAction(wager) && !!wager?.status?.started && !wager?.status?.ended; // Whether the wager has action and is currently in progress
exports.wagerInProgress = wagerInProgress;

const wagerInAcceptedState = (wager) => wagerAccepted(wager) && !wager?.status?.started && !wager?.status?.ended; // Whether the wager has been accepted but not yet started
exports.wagerInAcceptedState = wagerInAcceptedState;

const wagerInDeclinedState = (wager) => wagerDeclined(wager); // Whether the wager has been declined but not yet started
exports.wagerInDeclinedState = wagerInDeclinedState;

const wagerInPendingGradingState = (wager) => wagerHasAction(wager) && !!wager?.status?.started && !!wager?.status?.ended && !wager?.status?.graded; // Whether the wager has action and has ended but not yet been graded
exports.wagerInPendingGradingState = wagerInPendingGradingState;

const iCanWithdrawWager = (wager, userID) => iProposedWager(wager, userID) && wagerPendingResponse(wager); // Whether the user can withdraw the wager
exports.iCanWithdrawWager = iCanWithdrawWager;

const iCanRespondToWager = (wager, userID) => iReceivedWager(wager, userID) && wagerPendingResponse(wager); // Whether the user can respond to the wager
exports.iCanRespondToWager = iCanRespondToWager;

const wagerCountsTowardsTotal = (wager) => wagerIsGraded(wager) && wagerHasAction(wager); // Whether the wager counts towards the users' total
exports.wagerCountsTowardsTotal = wagerCountsTowardsTotal;

const wagerEventIsLive = (wager) => {
  if (wager?.type === "parlayWrap") {
    if (wager?.client?.parlayLegs?.length)
      return wager.client.parlayLegs.some((legWager) => legWager?.event?.type === "match" && legWager?.event?.status?.live && legWager?.event?.status?.hardStart !== false);
    const legStatusList = Object.values(wager?.parlayLegStatuses || {});
    return legStatusList.length ? legStatusList.some((legStatus) => legStatus?.started && !legStatus?.ended) : false;
  }
  return wager?.event?.type === "match" && wager?.event?.status?.live && wager?.event?.status?.hardStart !== false;
};
exports.wagerEventIsLive = wagerEventIsLive;

const wagerEventStarted = (wager) => {
  if (wager?.type === "parlayWrap") {
    if (wager?.client?.parlayLegs?.length) return wager.client.parlayLegs.every((legWager) => legWager?.event?.status?.started);
    const legStatusList = Object.values(wager?.parlayLegStatuses || {});
    return legStatusList.length ? legStatusList.every((legStatus) => legStatus?.started) : false;
  }
  return !!wager.event?.status?.started;
};
exports.wagerEventStarted = wagerEventStarted;

const wagerEventEnded = (wager) => {
  if (wager?.type === "parlayWrap") {
    if (wager?.client?.parlayLegs?.length) return wager.client.parlayLegs.every((legWager) => legWager?.event?.status?.ended);
    const legStatusList = Object.values(wager?.parlayLegStatuses || {});
    return legStatusList?.length ? legStatusList.every((legStatus) => legStatus?.ended) : false;
  }
  return !!wager.event?.status?.ended;
};
exports.wagerEventEnded = wagerEventEnded;

const wagerEventStartsAt = (wager) => {
  if (wager?.type === "parlayWrap") return wager?.status?.firstLegStartsAt;
  return wager?.event?.status?.startsAt;
};
exports.wagerEventStartsAt = wagerEventStartsAt;

const wagerSportMatches = (wager, sportID) => {
  if (!sportID) return false;
  if (wager?.type === "parlayWrap" && wager?.client?.parlayLegs?.length) return wager?.client?.parlayLegs.some((legWager) => legWager?.event?.sportID === sportID);
  return sportID === wager?.event?.sportID;
};
exports.wagerSportMatches = wagerSportMatches;

const wagerLeagueMatches = (wager, leagueID) => {
  if (!leagueID) return false;
  if (wager?.type === "parlayWrap" && wager?.client?.parlayLegs?.length) return wager?.client?.parlayLegs.some((legWager) => legWager?.event?.leagueID === leagueID);
  return leagueID === wager?.event?.leagueID;
};
exports.wagerLeagueMatches = wagerLeagueMatches;

const wagerOdds = (wager, userID) => {
  return _.get(wager, `odds.${wagerSelfString(wager, userID)}`, {});
};
exports.wagerOdds = wagerOdds;
