const { stripEmptyValues } = require("@wagerlab/utils/data/mutations");
const { EVENT_TYPES } = require("@wagerlab/utils/events/enums");
const { getOddGroups } = require("@wagerlab/utils/events/oddOrdering");
const { getCategoryIDs } = require("@wagerlab/utils/sports/categoryConfig");
const _ = require("lodash");

const ALLOW_LIVE_EVENTS_WITHOUT_ODDS = false;
const ALLOW_NON_LIVE_EVENTS_WITHOUT_ODDS = false;

const eventToEventShort = (eventData) => {
  const { eventID, sportID, leagueID, type, info, activity, names, eventName, finalized } = eventData || {};
  if (!eventID) return null;
  const { displayShort, displayLong, clock, oddsOverview, marketOddsAvailable, anyOddsAvailable, started, ended, live, startsAt, delayed, inBreak, currentPeriodID, hardStart } =
    eventData?.status || {};
  if (!eventID || !sportID || !leagueID || !type || ended || finalized) return null;

  // TODO to fully allow live (or even non-live) events without odds, we need to make it so odd groups can return "deactivated" odds
  const oddGroups = formatOddsGroupingsForEventShort(eventData);
  const hasOdds = !!(anyOddsAvailable && oddGroups?.length);
  if (live && !ALLOW_LIVE_EVENTS_WITHOUT_ODDS && !hasOdds) return null;
  if (!live && !ALLOW_NON_LIVE_EVENTS_WITHOUT_ODDS && !hasOdds) return null;

  const teams = formatTeamsForEventShort(eventData);
  const props = formatPropsForEventShort(eventData?.props, oddGroups);
  const numAdditionalOdds = countNumAdditionalOdds(eventData, oddGroups);
  const categoryIDs = getCategoryIDs(leagueID);

  if (type === EVENT_TYPES.match) {
    if (!teams) return null;
  } else if (type === EVENT_TYPES.tournament) {
    if (!teams) return null;
  } else if (type === EVENT_TYPES.prop) {
    if (!props) return null;
  } else {
    return null;
  }

  const eventShort = {
    eventID,
    sportID,
    leagueID,
    categoryIDs,
    type,
    names,
    teams,
    props,
    eventName,
    activity,
    info,
    oddGroups: oddGroups || [],
    numAdditionalOdds,
    status: { displayShort, displayLong, clock, oddsOverview, marketOddsAvailable, anyOddsAvailable, started, live, startsAt, delayed, inBreak, currentPeriodID, hardStart },
  };

  return stripEmptyValues(eventShort);
};
exports.eventToEventShort = eventToEventShort;

const formatTeamsForEventShort = (eventData) => {
  const eventType = eventData?.type;
  if (eventType === EVENT_TYPES.prop) return null;
  const teamStatEntityIDs = eventType === EVENT_TYPES.match ? ["home", "away"] : eventType === EVENT_TYPES.tournament ? Object.keys(eventData?.teams || {}) : [];

  const teams = teamStatEntityIDs.reduce((formattedTeams, teamStatEntityID) => {
    const formattedTeam = formatSingleTeamForEventShort(eventData, teamStatEntityID);
    if (formattedTeam?.teamID) formattedTeams[teamStatEntityID] = formattedTeam;
    return formattedTeams;
  }, {});

  if (eventType === EVENT_TYPES.match && (!teams?.home || !teams?.away)) return null;
  if (eventType === EVENT_TYPES.tournament && !Object.keys(teams || {}).length) return null;
  return teams;
};

const formatSingleTeamForEventShort = (eventData, teamStatEntityID) => {
  if (!teamStatEntityID) return null;
  const { names, logo, colors, score, teamID } = eventData?.teams?.[teamStatEntityID] || {};
  const { short, medium, long } = names || {};
  if (!short && !medium && !long) return null;
  const formattedNames = { short, medium, long };
  return { names: formattedNames, logo, colors, score, teamID };
};

const formatPropsForEventShort = (props, oddGroups) => {
  let formattedProps = {};
  let hasProps = false;
  (oddGroups || []).forEach((oddGroup) => {
    oddGroup?.rows?.forEach?.((oddRow) => {
      oddRow?.odds?.forEach?.((odd) => {
        const { betTypeID, statID } = odd || {};
        if (!statID || betTypeID !== "prop" || !props?.[statID]?.statID || formattedProps[statID]) return;
        formattedProps[statID] = props[statID];
        hasProps = true;
      });
    });
  });
  if (!hasProps) return null;
  return formattedProps;
};

const formatOddsGroupingsForEventShort = (eventData) => {
  const oddGroups = getOddGroups(eventData) || [];
  if (!oddGroups.length || !oddGroups[0]?.rows?.length) return [];
  return [oddGroups[0]];
};

const countNumAdditionalOdds = (eventData, oddGroups) => {
  const totalOddsCount = Object.values(eventData?.odds || {}).filter((o) => o?.oddID && o?.available).length || 0;
  const totalOddPairs = Math.round(totalOddsCount / 2);
  const oddPairsInGroups = (oddGroups || []).reduce((total, oddGroup) => total + (oddGroup?.rows?.length || 0), 0);
  return totalOddPairs - oddPairsInGroups;
};

const isEventShort = (eventData) => !eventData?.odds && !!eventData?.oddGroups;
exports.isEventShort = isEventShort;

const isEventFull = (eventData) => !!eventData?.odds || !eventData?.oddGroups;
exports.isEventFull = isEventFull;
