import React from "react";
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { isArray, isDate, isObject } from "@wagerlab/utils/data/types";

export const EventTableRowSummary = ({ event }) => {
  const navigate = useNavigate();

  const { type, status } = event || {};
  const { finalized, ended, cancelled } = status || {};

  const canShowReGrader = finalized;
  const canShowGrader = !finalized;
  const canShowEditor = !ended && !cancelled && !finalized;

  return (
    <Box sx={{ margin: 1 }}>
      <Typography variant="h6" gutterBottom component="div">
        Event Summary
      </Typography>
      {canShowReGrader ? (
        <Button onClick={() => navigate(`/events/regrade/${event?.eventID}`)} variant="contained" sx={{ marginRight: 4 }}>
          Re-Grade
        </Button>
      ) : null}
      {canShowGrader ? (
        <Button onClick={() => navigate(`/events/grade/${event?.eventID}`)} variant="contained" sx={{ marginLeft: 16, marginRight: 4 }}>
          Grade
        </Button>
      ) : null}
      {canShowEditor ? (
        <Button onClick={() => navigate(`/events/edit/${event?.eventID}`)} variant="contained" sx={{ marginRight: 4 }}>
          Edit
        </Button>
      ) : null}

      <TableContainer sx={{ marginTop: 2 }} component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Property</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderObjectFields(event)}</TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const renderObjectFields = (dataToRender, path = "") => {
  if (isArray(dataToRender)) return dataToRender.map((item, index) => renderObjectFields(item, `${path || ""}${path ? "." : ""}[${index}]`));
  if (isObject(dataToRender)) return Object.entries(dataToRender).map(([key, value]) => renderObjectFields(value, `${path || ""}${path ? "." : ""}${key}`));

  const displayValue = isDate(dataToRender) ? dataToRender.toLocaleString() : `${dataToRender}`;

  return (
    <TableRow key={path}>
      <TableCell>{path || "-"}</TableCell>
      <TableCell>{displayValue}</TableCell>
    </TableRow>
  );
};
