import { Button, Box, Typography, IconButton, Grid } from "@mui/material";
import { TextField } from "@wagerlab/admin/src/components/inputs/TextField";
import { SelectMenu } from "@wagerlab/admin/src/components/inputs/SelectMenu";
import { SelectBoolean } from "@wagerlab/admin/src/components/inputs/SelectBoolean";
import { useFormikContext, FieldArray } from "formik";
import _ from "lodash";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useMemo } from "react";
import Divider from "@mui/material/Divider";

export const AffiliateLocaleGroup = ({ groupKey, groupDisplaySingular, title, prevConfig }) => {
  const { values, errors, touched, initialValues } = useFormikContext();

  const affiliateIDOptions = useMemo(
    () =>
      (values?.affiliateLocales?.affiliateIDs || []).reduce((affOptions, affID) => {
        const displayValue = prevConfig?.affiliateConfig?.affiliates?.[affID]?.name || affID;
        if (!displayValue || !affID) return affOptions;
        return { ...affOptions, [affID]: displayValue };
      }, {}),
    [values?.affiliateLocales?.affiliateIDs]
  );

  if (!["usStates", "caStates", "countries"].includes(groupKey)) return null;

  return (
    <Box sx={{ marginBottom: 5, border: "1px solid #ccc", padding: 1 }}>
      <FieldArray name={`affiliateLocales.${groupKey}`}>
        {({ push, remove }) => (
          <>
            <Grid container sx={{ marginBottom: 2 }}>
              <Grid item xs={6}>
                <Typography variant="h4" sx={{ textTransform: "uppercase", marginBottom: 1 }}>
                  {title}
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ justifyContent: "flex-end", alignItems: "center", display: "flex" }}>
                <Button startIcon={<AddCircleOutlineIcon />} onClick={() => push({ code: "", affiliateIDs: [] })} size="large" color={"success"}>
                  Add {groupDisplaySingular}
                </Button>
              </Grid>
            </Grid>
            {(values.affiliateLocales[groupKey] || []).map((localeCodeAndList, index) => (
              <Box key={index}>
                <Grid container spacing={2} key={index} sx={{ marginVertical: 5 }}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Country/State Code"
                      name={`affiliateLocales.${groupKey}[${index}].code`}
                      error={touched.affiliateLocales?.[groupKey]?.[index]?.code && errors.affiliateLocales?.[groupKey]?.[index]?.code}
                      required
                    />
                    <Button startIcon={<RemoveCircleOutlineIcon />} color="error" onClick={() => remove(index)}>
                      Remove {groupDisplaySingular}
                    </Button>
                  </Grid>

                  <Grid item xs={6}>
                    <FieldArray name={`affiliateLocales.${groupKey}[${index}].affiliateIDs`}>
                      {({ push: pushAffiliateID, remove: removeAffiliateID }) => (
                        <>
                          {localeCodeAndList.affiliateIDs.map((affiliateID, affiliateIDIndex) => (
                            <Box key={affiliateIDIndex} sx={{ display: "flex", alignItems: "center", gap: 2, marginBottom: 2 }}>
                              <Typography variant="h6">#{affiliateIDIndex + 1}</Typography>
                              <SelectMenu
                                name={`affiliateLocales.${groupKey}[${index}].affiliateIDs[${affiliateIDIndex}]`}
                                options={affiliateIDOptions}
                                error={
                                  touched.affiliateLocales?.[groupKey]?.[index]?.affiliateIDs?.[affiliateIDIndex] && errors.affiliateLocales?.[groupKey]?.[index]?.affiliateIDs?.[affiliateIDIndex]
                                }
                                required
                              />

                              <IconButton onClick={() => removeAffiliateID(affiliateIDIndex)} color={"error"}>
                                <RemoveCircleOutlineIcon />
                              </IconButton>
                            </Box>
                          ))}
                          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                            <Button startIcon={<AddCircleOutlineIcon />} onClick={() => pushAffiliateID("")}>
                              Add Affiliate
                            </Button>
                          </Box>
                        </>
                      )}
                    </FieldArray>
                  </Grid>
                </Grid>
                <Divider sx={{ marginTop: 2, marginBottom: 5 }} />
              </Box>
            ))}
          </>
        )}
      </FieldArray>
    </Box>
  );
};
