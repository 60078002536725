const { refreshAndSyncOdds } = require("@wagerlab/utils/events/refresh/refreshAndSyncOdds");
const { refreshEventFinalized } = require("@wagerlab/utils/events/refresh/refreshEventFinalized");
const { refreshEventScores } = require("@wagerlab/utils/events/refresh/refreshEventScores");
const { refreshEventStatus } = require("@wagerlab/utils/events/refresh/refreshEventStatus");
const { refreshOddsOverview } = require("@wagerlab/utils/events/refresh/refreshOddsOverview");
const { refreshAndSyncMetadata } = require("@wagerlab/utils/events/refresh/refreshAndSyncMetadata");
const { syncEventBookOddsStatus } = require("@wagerlab/utils/events/refresh/syncEventBookOddsStatus");
const { validateEvent } = require("@wagerlab/utils/events/refresh/validateEvent");

const refreshEvent = (event, eventBookOdds, sourceMap = null, canAutoFinalize = false) => {
  if (!event?.eventID) return { event: null, eventBookOdds: null };
  if (eventBookOdds?.eventID && eventBookOdds?.eventID !== event?.eventID) return { event: null, eventBookOdds: null };

  // The eventBookOdds object passed in can be..
  // 1. null - we build it from scratch based on event.odds data
  // 2. a full/valid eventBookOdds object - we merge them
  // 3. a partial eventBookOdds object with only eventID and odds - we merge them

  ({ event, eventBookOdds } = refreshAndSyncMetadata(event, eventBookOdds));
  event = refreshEventStatus(event); // Must come after: refreshAndSyncMetadata
  event = refreshEventScores(event); // Must come after: refreshAndSyncMetadata

  ({ event, eventBookOdds } = refreshAndSyncOdds(event, eventBookOdds, sourceMap)); // Must come after: refreshAndSyncMetadata, refreshEventStatus
  ({ event, eventBookOdds } = refreshEventFinalized(event, eventBookOdds, canAutoFinalize)); // Must come after refreshAndSyncMetadata, refreshEventStatus, refreshAndSyncOdds
  ({ event, eventBookOdds } = refreshOddsOverview(event, eventBookOdds)); // Must come after: refreshAndSyncOdds & refreshEventStatus
  eventBookOdds = syncEventBookOddsStatus(event, eventBookOdds); // Must come after: refreshEventStatus & refreshEventFinalized && refreshOddsOverview
  ({ event, eventBookOdds } = validateEvent(event, eventBookOdds)); // Must come after: refreshAndSyncMetadata & refreshAndSyncOdds (both must run. changes needed to run this without running both)

  return { event, eventBookOdds };
};
exports.refreshEvent = refreshEvent;
