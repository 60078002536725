const { BET_TYPE_CONFIG, ALL_SIDE_IDS } = require("@wagerlab/utils/odds/betTypes");
const { getStatConfig } = require("@wagerlab/utils/sports/stats");
const { PERIOD_ID_CONFIG, periodIsSupportedByLeague } = require("@wagerlab/utils/sports/periods");
const { isString } = require("@wagerlab/utils/data/types");
const { getInverseSpread, getInverseOdds, asMoneylineString, asSpreadString } = require("@wagerlab/utils/odds/converters");
const { SPORT_CONFIG } = require("@wagerlab/utils/sports/sportConfig");

// OUTRIGHTS  Might take the form of something like this:
// betTypeID=      outright  (or outright/champ/mltournament/mlt/mltourney/tml/tournamentml/winner)
// statEntityID =  teamID_1,
// sideID=         team    field   (or something similar. it depends on how we want to set up outright overunders. ex: o/u for tournament type event or for team wins in an NFL season. we could have a separate betTypeID or use the same one)

const getOddID = (oddData, skipStatEntityIDRefresh = false) => {
  const { statID, periodID, betTypeID, sideID } = oddData || {};
  const statEntityID = skipStatEntityIDRefresh ? oddData?.statEntityID : refreshStatEntityID(oddData);
  if (!betTypeID || !sideID || !periodID || !statID || !statEntityID) return null;
  return `${statID}-${statEntityID}-${periodID}-${betTypeID}-${sideID}`;
};
exports.getOddID = getOddID;

// Keeping this here temporarily until we decide to delete it for good
// const getMarketID = (oddData) => {
//   const { sideID, statID, periodID, betTypeID } = oddData || {};
//   const statEntityID = refreshStatEntityID(oddData);
//   if (!statEntityID) return null;
//   const sideDefaultStatEntityID = getDefaultStatEntityIDForSide(sideID, betTypeID);
//   const statLevelID = statEntityID === sideDefaultStatEntityID || statEntityID === "all" ? "main" : statEntityID;
//   if (!betTypeID || !periodID || !statID || !statLevelID) return null;
//   return `${statLevelID}-${periodID}-${statID}-${betTypeID}`;
// };
// exports.getMarketID = getMarketID;

const getDefaultStatEntityIDForSide = (sideID, betTypeID) => {
  if (betTypeID === "ml" || betTypeID === "sp") {
    if (sideID === "home") return "home";
    if (sideID === "away") return "away";
  }
  if (betTypeID === "ml3way") {
    if (sideID === "home") return "home";
    if (sideID === "away") return "away";
    if (sideID === "draw") return "all";

    if (sideID === "away+draw") return "away";
    if (sideID === "home+draw") return "home";
    if (sideID === "not_draw") return "all";
  }
  if (betTypeID === "prop") {
    if (sideID === "side1") return "side1";
    if (sideID === "side2") return "side2";
  }
  return "";
};
exports.getDefaultStatEntityIDForSide = getDefaultStatEntityIDForSide;

// This looks at the sideID before looking at the existing statEntityID. Returns the refreshed value (doesn't write to the input object)
const refreshStatEntityID = (oddData) => {
  const { statEntityID, betTypeID, sideID, playerID, teamID } = oddData || {};
  const defaultStatEntityIDForSide = getDefaultStatEntityIDForSide(sideID, betTypeID);
  if (defaultStatEntityIDForSide) return defaultStatEntityIDForSide;

  if (playerID && statEntityID === playerID) return playerID;
  if (teamID && statEntityID === teamID) return teamID;

  if (betTypeID === "prop") return statEntityID === "side1" || statEntityID === "side2" ? statEntityID : null;
  if (["ml", "sp"].includes(betTypeID)) return statEntityID === "home" || statEntityID === "away" ? statEntityID : null;
  if (statEntityID === "all" || statEntityID === "home" || statEntityID === "away") return statEntityID;
  return null;
};
exports.refreshStatEntityID = refreshStatEntityID;

const getOpponentOddData = (oddData) => {
  const sideID = getOpponentSideID(oddData);
  const statEntityID = refreshStatEntityID({ ...(oddData || {}), sideID });
  const oddID = getOddID({ ...(oddData || {}), sideID, statEntityID });
  if (!sideID || !statEntityID || !oddID) return null;

  const {
    sideID: sid,
    statEntityID: seid,
    oddID: oid,
    score: s,
    isField: isf,
    closeOdds: co,
    closeSpread: cs,
    bookOdds: bo,
    sourceContext: sc,
    bookSpread: bs,
    odds,
    spread,
    ...sharedOddData
  } = oddData;
  const opponentOddData = { ...sharedOddData, sideID, statEntityID, oddID };

  const inverseSpread = asSpreadString(getInverseSpread(spread));
  if (inverseSpread) opponentOddData.spread = inverseSpread;

  const inverseOdds = asMoneylineString(getInverseOdds(odds));
  if (inverseOdds) opponentOddData.odds = inverseOdds;

  return opponentOddData;
};
exports.getOpponentOddData = getOpponentOddData;

const getOpponentOddID = (oddData) => getOpponentOddData(oddData)?.oddID || null;
exports.getOpponentOddID = getOpponentOddID;

const getOpponentOddIDFromOddID = (oddID) => {
  if (!oddID) return null;
  const [statID, statEntityID, periodID, betTypeID, sideID] = oddID.split("-");
  const opponentSideID = getOpponentSideID({ sideID, betTypeID });
  const opponentStatEntityID = getDefaultStatEntityIDForSide(opponentSideID, betTypeID) || statEntityID;
  return getOddID({ statID, statEntityID: opponentStatEntityID, periodID, betTypeID, sideID: opponentSideID }, true);
};
exports.getOpponentOddIDFromOddID = getOpponentOddIDFromOddID;

const getOpponentSideID = (oddData) => {
  const { sideID, betTypeID } = oddData || {};
  if (!betTypeID || !sideID) return null;
  return BET_TYPE_CONFIG[betTypeID]?.sideIDs?.[sideID]?.opponentSideID || null;
};

const getOddPickID = (oddData) => {
  const { statID, periodID, betTypeID } = oddData || {};
  const statEntityID = refreshStatEntityID(oddData);
  if (!betTypeID || !periodID || !statID || !statEntityID) return null;
  return `${statID}-${statEntityID}-${periodID}-${betTypeID}`;
};
exports.getOddPickID = getOddPickID;

const getEventPickID = (eventData, oddData) => {
  const { eventID } = eventData || {};
  const oddPickID = getOddPickID(oddData);
  if (!eventID || !oddPickID) return null;
  return `${eventID}-${oddPickID}`;
};
exports.getEventPickID = getEventPickID;

const isValidPeriodID = (periodID, sportID, leagueID) => {
  const periodIsConfigured = periodID && PERIOD_ID_CONFIG[periodID]?.periodID;
  if (!periodIsConfigured || (!sportID && !leagueID)) return !!periodIsConfigured;
  return periodIsSupportedByLeague(periodID, sportID, leagueID);
};
exports.isValidPeriodID = isValidPeriodID;

const isValidBetTypeID = (betTypeID) => !!(betTypeID && BET_TYPE_CONFIG[betTypeID]?.betTypeID);
exports.isValidBetTypeID = isValidBetTypeID;

const isValidSideID = (sideID, betTypeID = null) => {
  if (!sideID) return false;
  if (!betTypeID) return !!ALL_SIDE_IDS[sideID]?.sideID;
  return !!BET_TYPE_CONFIG[betTypeID]?.sideIDs[sideID]?.sideID;
};
exports.isValidSideID = isValidSideID;

const isValidStatEntityID = (oddData) => !!oddData?.statEntityID && oddData?.statEntityID === refreshStatEntityID(oddData);
exports.isValidStatEntityID = isValidStatEntityID;

const classifyOdds = (oddData, eventType, sportID, leagueID) => {
  const { statID, statEntityID, betTypeID, periodID, playerID, teamID } = oddData || {};

  const isSubPeriod = periodID !== "game";
  const isPropEvent = eventType === "prop";
  let isMainStat = false;
  let isMainBet = false;
  if (eventType === "prop") {
    isMainStat = statID === "prop0";
    isMainBet = betTypeID === "prop" && (statEntityID === "side1" || statEntityID === "side2");
  } else if (eventType === "tournament") {
    // TODO - outrights
    isMainStat = statID === "points";
    isMainBet = betTypeID === "outright" && !!teamID && statEntityID === teamID;
  } else {
    isMainStat = statID === "points";
    const isMainSpread = betTypeID === "sp" && (statEntityID === "home" || statEntityID === "away");
    const isMainMoneyline = betTypeID === "ml" && (statEntityID === "home" || statEntityID === "away");
    const isMainOverUnder = betTypeID === "ou" && statEntityID === "all";
    const isMain3WayMoneyline = betTypeID === "ml3way" && (statEntityID === "home" || statEntityID === "away");
    isMainBet = isMainSpread || isMainMoneyline || isMainOverUnder || isMain3WayMoneyline;
  }
  // Use isTopMarket if you are looking only for main 1-3 markets but for ALL sub-periodIDs and all eventType values
  const isMainMarket = isMainStat && isMainBet;
  // Use isTopMarket if you are looking only for main 1-3 markets and only for full game periodID but for all eventType values
  const isSportSpecificTopPeriod = SPORT_CONFIG[sportID]?.prefers3WayMoneyline ? periodID === "reg" : periodID === "game";
  const isSportIndependentTopPeriod = periodID === "game";
  const isTopMarketForSport = isMainMarket && isSportSpecificTopPeriod;
  const isTopMarket = isMainMarket && (isSportSpecificTopPeriod || isSportIndependentTopPeriod);
  // Team totals ARE considered "team props"
  // prop0 for events where Event.type=prop are NOT considered "other props"
  const isTeamProp = !isMainMarket && (statEntityID === "home" || statEntityID === "away" || (!!teamID && statEntityID === teamID));
  const isPlayerProp = !isMainMarket && !!playerID && statEntityID === playerID;
  const isGameProp = !isMainMarket && statEntityID === "all";
  const isOtherProp = !isMainMarket && betTypeID === "prop";
  // isAnyProp includes all odds for prop eventTypes. Main market odds for a sub-period are NOT included in isAnyProp
  const isAnyProp = isTeamProp || isPlayerProp || isGameProp || isOtherProp || isPropEvent;
  // Secondary markets are any type of prop markets, all markets on prop events, and all sub-period bets
  const isSecondaryOrPropMarket = isAnyProp || !isTopMarket;
  const isSecondaryOrPropMarketForSport = isAnyProp || !isTopMarketForSport;

  return { isMainMarket, isTopMarket, isTopMarketForSport, isSecondaryOrPropMarket, isSecondaryOrPropMarketForSport, isAnyProp, isTeamProp, isPlayerProp, isGameProp, isOtherProp, isSubPeriod };
};
exports.classifyOdds = classifyOdds;

const isSpecialCaseUnsupportedOdds = (oddData, eventData) => {
  const { statID, statEntityID, betTypeID, periodID } = oddData || {};
  const { sportID } = eventData || {};

  const statConfig = getStatConfig(statID, sportID);
  if (statConfig?.fixedTotalValuePerPeriod && periodID !== "game" && betTypeID !== "ml") return true;
  if (statConfig?.fixedTotalValuePerPeriod && periodID === "game" && betTypeID === "yn" && statEntityID === "all") return true;

  const yesNoBetsSupported = statConfig?.yesNoBets ?? true;
  if (!yesNoBetsSupported && betTypeID === "yn") return true;
  if (statConfig?.binary && betTypeID !== "yn" && betTypeID !== "ml") return true; //NOTE: If we want to support player vs player spreads on binary stats (ex Lebron vs Curry double-double) then this will need to be changed

  return false;
};
exports.isSpecialCaseUnsupportedOdds = isSpecialCaseUnsupportedOdds;
