const { getPeriodConfig } = require("@wagerlab/utils/sports/periods");
const { wagerEventStarted } = require("./user-wager");

const ODDS_APPLIED_OPTIONS = {
  WHEN_PROPOSED: {
    value: "whenProposed",
    enabled: true,
    name: "When Proposed",
    explanation: "Odds are locked in when the wager is created",
  },
  // TODO consider whether whenAccepted should have a limit. It's not fair to wait until you will definitely win before accepting. So maybe its whenAccepted OR X
  WHEN_ACCEPTED: {
    value: "whenAccepted",
    enabled: true,
    name: "When Accepted",
    explanation: "Odds aren't locked in until the wager is accepted",
  },
  WHEN_ODDS_STARTED: {
    value: "whenOddsStarted",
    enabled: false, // Enabling this will require more effort/complexity as not all data sources will tell us when each periodID starts. And certain logic like refreshWager needs to change to keep the expiresAt in sync
    name: "Start of Period",
    explanation: "Odds are locked in when the relevant period starts",
    excludeIfPeriodStartsWithEventStart: true,
  },
  WHEN_EVENT_STARTED: {
    value: "whenEventStarted",
    enabled: true,
    name: "Start of Event",
    explanation: "Odds are locked in when the event starts",
  },
};
exports.ODDS_APPLIED_OPTIONS = ODDS_APPLIED_OPTIONS;

// We probably should never change this value but if we ever did, we need to be certain that the option we select is "enabled": true
const DEFAULT_ODDS_APPLIED_VALUE = ODDS_APPLIED_OPTIONS.WHEN_PROPOSED.value;
exports.DEFAULT_ODDS_APPLIED_VALUE = DEFAULT_ODDS_APPLIED_VALUE;

const ODDS_APPLIED_VALUE_MAP = Object.values(ODDS_APPLIED_OPTIONS).reduce((acc, option) => {
  acc[option.value] = option;
  return acc;
}, {});

const getOddsAppliedConfig = (oddsAppliedValue) => ODDS_APPLIED_VALUE_MAP[oddsAppliedValue] || ODDS_APPLIED_VALUE_MAP[DEFAULT_ODDS_APPLIED_VALUE];
exports.getOddsAppliedConfig = getOddsAppliedConfig;

const isValidOddsAppliedOption = (oddsAppliedValue) => !!ODDS_APPLIED_VALUE_MAP[oddsAppliedValue]?.enabled;
exports.isValidOddsAppliedOption = isValidOddsAppliedOption;

const ODDS_APPLIED_LIST = Object.values(ODDS_APPLIED_OPTIONS);

const getOddsAppliedOptions = (periodID) => {
  const periodStartsAtEventStart = getPeriodConfig(periodID)?.startsAtEventStart;
  return ODDS_APPLIED_LIST.filter((option) => {
    if (!option?.enabled) return false;
    if (option.excludeIfPeriodStartsWithEventStart && periodStartsAtEventStart) return false;
    return true;
  });
};
exports.getOddsAppliedOptions = getOddsAppliedOptions;

const wagerOddsHaveApplied = (wager) => {
  const { oddsApplied, oddsOverridden } = wager?.options || {};
  const oddsAppliedConfig = getOddsAppliedConfig(oddsApplied);
  const { voided, responded, started, accepted, ended } = wager?.status || {};
  const eventStarted = wagerEventStarted(wager);

  if (oddsOverridden || voided || ended) return true;
  if (oddsAppliedConfig.value === ODDS_APPLIED_OPTIONS.WHEN_PROPOSED.value) return true;
  if (oddsAppliedConfig.value === ODDS_APPLIED_OPTIONS.WHEN_ACCEPTED.value && responded) return true;
  if (oddsAppliedConfig.value === ODDS_APPLIED_OPTIONS.WHEN_ODDS_STARTED.value && (started || (responded && !accepted))) return true;
  if (oddsAppliedConfig.value === ODDS_APPLIED_OPTIONS.WHEN_EVENT_STARTED.value && (started || eventStarted || (responded && !accepted))) return true;
  return false;
};
exports.wagerOddsHaveApplied = wagerOddsHaveApplied;
