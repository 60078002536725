import { deepQueryCollection, deleteCollectionDoc, deleteValue, getCollectionDoc, setCollectionDoc, updateCollectionDoc, writeBatch } from "@wagerlab/utils/database/firestore";
import { EVENT_FINALIZED_STATES, EVENT_TYPES } from "@wagerlab/admin/src/utils/events/constants";
import { eventToEventShort } from "@wagerlab/utils/events/eventShorts";
import { dataChanged, dataUpdates } from "@wagerlab/utils/data/mutations";
import { isEmptyObject, isObject } from "@wagerlab/utils/data/types";

export const getEvents = async (eventType, eventFinalizedState) => {
  const isValidEventType = Object.values(EVENT_TYPES).includes(eventType);
  const isValidEventFinalizedState = Object.values(EVENT_FINALIZED_STATES).includes(eventFinalizedState);
  if (!isValidEventType || !isValidEventFinalizedState) return [];
  const queryParts = [
    { key: "type", operator: "==", value: eventType },
    { key: "status.finalized", operator: "==", value: eventFinalizedState === EVENT_FINALIZED_STATES.FINALIZED ? true : false },
  ];
  return (await deepQueryCollection("Events", queryParts)) || [];
};

export const queryEvents = async (queryParts) => {
  return await deepQueryCollection("Events", queryParts);
};

export const getEvent = async (eventID) => {
  return await getCollectionDoc("Events", eventID);
};

export const getEventBookOdds = async (eventID) => {
  return await getCollectionDoc("EventBookOdds", eventID);
};

// TODO - add a mechanism in the aggregator which allows us to selectively invalidate one or more events and then just push that eventID to the aggregator config
// That way on each write here we don't invalidate the entire aggregator cache
export const updateEvent = async (eventID, updates) => {
  if (!eventID || !isObject(updates) || isEmptyObject(updates)) return false;
  const batch = [
    { method: "update", collectionName: "Events", collectionID: eventID, payload: updates },
    { method: "update", collectionName: "Config", collectionID: "aggregator", payload: { "runner.cacheInvalidated": true } },
  ];
  const batchResponse = await writeBatch(batch);
  return !!batchResponse?.allSuccess;
};

export const updateEventShort = async (prevEvent, newEvent) => {
  const eventID = newEvent?.eventID || prevEvent?.eventID;
  if (!eventID) return false;
  const prevEventShort = eventToEventShort(prevEvent);
  const newEventShort = eventToEventShort(newEvent);
  if (prevEventShort?.eventID && !newEventShort?.eventID) {
    return await deleteCollectionDoc("EventShorts", eventID);
  } else if (newEventShort?.eventID && dataChanged(prevEventShort, newEventShort, true)) {
    return await setCollectionDoc("EventShorts", eventID, newEventShort);
  }
  return false;
};

export const createEvent = async (newEvent) => {
  if (!newEvent?.eventID) return false;
  const batch = [
    { method: "set", collectionName: "Events", collectionID: newEvent.eventID, payload: newEvent },
    { method: "update", collectionName: "Config", collectionID: "aggregator", payload: { "runner.cacheInvalidated": true } },
  ];
  const batchResponse = await writeBatch(batch);
  return !!batchResponse?.allSuccess;
};

export const createEventShort = async (newEvent) => {
  if (!newEvent?.eventID) return false;
  const newEventShort = eventToEventShort(newEvent);
  if (!newEventShort?.eventID || newEventShort.eventID !== newEvent.eventID) return false;
  return await setCollectionDoc("EventShorts", newEventShort.eventID, newEventShort);
};

export const saveEventChanges = async (prevEvent, prevEventBookOdds, prevToNewFunc, canCreateNew = false, canEditFinalized = false) => {
  const { event: newEvent_fromInit, eventBookOdds: newEventBookOdds_fromInit } = prevToNewFunc({ event: prevEvent, eventBookOdds: prevEventBookOdds }) || {};
  const eventID = newEvent_fromInit?.eventID;
  if (!eventID) return "Couldn't generate a valid event (eventID invalid)";
  const isNewEventBookOdds = !prevEventBookOdds?.eventID;
  const isNewEvent = !prevEvent?.eventID;
  const initialEventUpdates = isNewEvent ? newEvent_fromInit : dataUpdates(prevEvent, newEvent_fromInit, null);
  const initialEventBookOddsUpdates = isNewEventBookOdds ? newEventBookOdds_fromInit : dataUpdates(prevEventBookOdds, newEventBookOdds_fromInit, null);
  if (!initialEventUpdates && !initialEventBookOddsUpdates) return "No changes to save";
  const latestEventDataPromise = getEvent(eventID);
  const latestEventBookOddsPromise = getEventBookOdds(eventID);
  const [latestEventData, latestEventBookOddsData] = await Promise.all([latestEventDataPromise, latestEventBookOddsPromise]);
  const { event: newEvent, eventBookOdds: newEventBookOdds } = prevToNewFunc({ event: prevEvent, eventBookOdds: prevEventBookOdds }) || {};

  if (!prevEvent?.eventID && !canCreateNew) return "Event Data is unavailable and cannot be created";
  if (!newEvent?.eventID) return "Saving would delete this event";
  if (prevEvent?.eventID && prevEvent.eventID !== newEvent.eventID) return "Event ID has changed";
  if (newEventBookOdds?.eventID && newEventBookOdds?.eventID !== newEvent?.eventID) return "EventBookOdds ID mismatch";
  if (prevEventBookOdds?.eventID && newEventBookOdds?.eventID && prevEventBookOdds?.eventID !== newEventBookOdds?.eventID) return "EventBookOdds ID changed";
  if (prevEventBookOdds?.eventID && !newEventBookOdds?.eventID) return "EventBookOdds generated was invalid.";

  let eventSavePayload = null;
  if (isNewEvent) {
    if (latestEventData?.eventID) return "Event already exists";
    if (newEvent?.status?.finalized) return "New event cannot be already finalized";
    eventSavePayload = newEvent;
  } else {
    if (latestEventData?.eventID !== newEvent.eventID) return "Couldn't verify remote event data";
    if (!canEditFinalized && latestEventData?.status?.finalized) return "Event is finalized and cannot be edited";
    const latestEventUpdates = dataUpdates(latestEventData, newEvent, null);
    const saveableEventUpdates = dataUpdates(latestEventData, newEvent, deleteValue());
    if (initialEventUpdates["status.nextUpdateAt"] && latestEventUpdates["status.nextUpdateAt"]) initialEventUpdates["status.nextUpdateAt"] = latestEventUpdates["status.nextUpdateAt"];
    if (dataChanged(initialEventUpdates, latestEventUpdates)) {
      const eventUpdatesChanges = dataUpdates(initialEventUpdates, latestEventUpdates, "<REMOVED>");
      const eventUpdatePathsChangedStr = Object.keys(eventUpdatesChanges || {}).join(", ");
      console.log("Event data has changed. Please refresh and try again. CHANGES TO UPDATES:", eventUpdatesChanges);
      return "Event data has changed. Please refresh and try again\nThe following updates would change: " + eventUpdatePathsChangedStr;
    }
    eventSavePayload = saveableEventUpdates;
  }

  let eventShortSetPayload = null;
  let shouldDeleteEventShort = false;
  const prevEventShort = eventToEventShort(latestEventData);
  const newEventShort = eventToEventShort(newEvent);
  if (prevEventShort?.eventID && !newEventShort?.eventID) {
    shouldDeleteEventShort = true;
  } else if (newEventShort?.eventID && dataChanged(prevEventShort, newEventShort, true)) {
    eventShortSetPayload = newEventShort;
  }

  let eventBookOddsSavePayload = null;
  if (isNewEventBookOdds) {
    if (latestEventBookOddsData?.eventID) return "EventBookOdds already exists";
    eventBookOddsSavePayload = newEventBookOdds?.eventID ? newEventBookOdds : null;
  } else {
    if (latestEventBookOddsData?.eventID !== newEventBookOdds.eventID) return "Couldn't verify remote eventBookOdds data";
    const latestEventBookOddsUpdates = dataUpdates(latestEventBookOddsData, newEventBookOdds, null);
    const saveableEventBookOddsUpdates = dataUpdates(latestEventBookOddsData, newEventBookOdds, deleteValue());
    if (dataChanged(initialEventBookOddsUpdates, latestEventBookOddsUpdates)) {
      const eboUpdatesChanges = dataUpdates(initialEventBookOddsUpdates, latestEventBookOddsUpdates, "<REMOVED>");
      const eboUpdatePathsChangedStr = Object.keys(eboUpdatesChanges || {}).join(", ");
      console.log("EventBookOdds data has changed. Please refresh and try again. CHANGES TO UPDATES:", eboUpdatesChanges);
      return "EventBookOdds data has changed. Please refresh and try again\nThe following updates would change: " + eboUpdatePathsChangedStr;
    }
    eventBookOddsSavePayload = saveableEventBookOddsUpdates;
  }

  const batch = [];
  if (eventSavePayload) batch.push({ method: isNewEvent ? "set" : "update", collectionName: "Events", collectionID: eventID, payload: eventSavePayload });
  if (eventBookOddsSavePayload) batch.push({ method: isNewEventBookOdds ? "set" : "update", collectionName: "EventBookOdds", collectionID: eventID, payload: eventBookOddsSavePayload });
  if (shouldDeleteEventShort) batch.push({ method: "delete", collectionName: "EventShorts", collectionID: eventID });
  if (eventShortSetPayload) batch.push({ method: "set", collectionName: "EventShorts", collectionID: eventID, payload: eventShortSetPayload });

  if (!batch?.length) return "No updates need to be made";

  const batchResponse = await writeBatch(batch);

  if (!batchResponse?.someSuccess) return "All updates failed to write to DB";
  if (!batchResponse?.allSuccess) return "ATTENTION: Some updates failed to write to DB. We could be in a weird state since some writes went through and others didn't";

  return "";
};
