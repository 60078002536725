import { TextField } from "@wagerlab/admin/src/components/inputs/TextField";
import { Typography } from "@mui/material";
import { useFormikContext } from "formik";

export const ConfigAffiliateGeneral = ({ prevConfig }) => {
  const { errors, touched } = useFormikContext();

  const fieldError = touched?.affiliateGeneral?.affiliateBottomSheetTitle && errors?.affiliateGeneral?.affiliateBottomSheetTitle;

  return (
    <div>
      <Typography variant="h4" sx={{ marginBottom: 4 }}>
        General Affiliate Config
      </Typography>
      <TextField fullWidth name="affiliateGeneral.affiliateBottomSheetTitle" label="Affiliate Bottom Sheet Title" error={fieldError} />
    </div>
  );
};
