//{ usStates, caStates, countries, affiliateIDs };  -> { code, affiliateIDs}

import { Button, Box, Typography, IconButton, Grid } from "@mui/material";
import { TextField } from "@wagerlab/admin/src/components/inputs/TextField";
import { SelectBoolean } from "@wagerlab/admin/src/components/inputs/SelectBoolean";
import { useFormikContext, FieldArray } from "formik";
import _ from "lodash";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { AffiliateLocaleGroup } from "@wagerlab/admin/src/components/config/AffiliateLocaleGroup";

export const ConfigAffiliateLocales = ({ prevConfig }) => {
  const { values, errors, touched, initialValues } = useFormikContext();

  return (
    <Box sx={{ margin: 2 }}>
      <AffiliateLocaleGroup groupKey={"usStates"} groupDisplaySingular={"US State"} title={"United States"} prevConfig={prevConfig} />
      <AffiliateLocaleGroup groupKey={"caStates"} groupDisplaySingular={"CA State"} title={"Canada"} prevConfig={prevConfig} />
      <AffiliateLocaleGroup groupKey={"countries"} groupDisplaySingular={"Country"} title={"Other Countries"} prevConfig={prevConfig} />
    </Box>
  );
};
