const { LEAGUE_CONFIG } = require("@wagerlab/utils/sports/leagueConfig");

const CATEGORY_CONFIG = {
  NHL: {
    enabled: true,
    name: "NHL",
    shortName: "NHL",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FNHL_imageicon.png?alt=media&token=9b2c2053-9e17-49fa-add6-874f504e818f",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FNHL_squareImage.jpg?alt=media&token=89409c21-994e-46bf-b22f-40929da23182",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FNHL_backgroundImage.jpg?alt=media&token=6689d1d3-2212-46e9-aeb9-775772524bf3",
    defaultPopularityScore: 7.0,
    categoryID: "NHL",
    sportID: "HOCKEY",
    leagueIDs: {
      NHL: true,
    },
  },
  OTHER_HOCKEY: {
    enabled: true,
    name: "Hockey",
    shortName: "Hockey",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_HOCKEY_imageicon.png?alt=media&token=fbc6cc0d-139f-46d4-99a8-0d2a91aa6462",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_HOCKEY_squareImage.jpg?alt=media&token=dc3235dd-dd79-48ef-a266-4a28fbf3d11d",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_HOCKEY_backgroundImage.jpg?alt=media&token=75587b97-577c-466a-b2a8-4d0de3797050",
    defaultPopularityScore: 5.0,
    categoryID: "OTHER_HOCKEY",
    sportID: "HOCKEY",
    leagueIDs: {
      AHL: true,
      SHL: true,
      KHL: true,
      NHL: true,
    },
  },
  NBA: {
    enabled: true,
    name: "NBA",
    shortName: "NBA",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FNBA_imageicon.png?alt=media&token=2f709a78-02f2-4b7f-9e7f-64974d43b7ec",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FNBA_squareImage.jpg?alt=media&token=9e810f26-4c00-49a0-bd44-14902fa59c24",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2Fnba_backgroundImage.jpg?alt=media&token=2ef50e72-b0a5-49f4-b61e-06d7f02db785",
    defaultPopularityScore: 9.0,
    categoryID: "NBA",
    sportID: "BASKETBALL",
    leagueIDs: {
      NBA: true,
    },
  },
  NCAAB: {
    enabled: true,
    name: "NCAA Basketball",
    shortName: "NCAAB",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FNCAAB_imageicon.png?alt=media&token=f49d52ff-498f-463d-af42-3a42521c7ba6",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FNCAAB_squareImage.jpg?alt=media&token=2d71c002-1b04-488a-a5d3-bb9233e0b661",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FNCAAB_backgroundImage.jpg?alt=media&token=5d9dd590-a8f5-4111-b295-b26c8d63b5fd",
    defaultPopularityScore: 7.0,
    categoryID: "NCAAB",
    sportID: "BASKETBALL",
    leagueIDs: {
      NCAAB: true,
    },
  },
  OTHER_BASKETBALL: {
    enabled: true,
    name: "Basketball",
    shortName: "Basketball",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASKETBALL_imageicon.png?alt=media&token=d8fff787-34b2-4a6c-b0cd-d758a58a1087",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASKETBALL_squareImage.jpg?alt=media&token=0e30ce03-b382-42fb-b1bf-a0f2adc72fea",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASKETBALL_backgroundImage.jpg?alt=media&token=a04ba9e7-6f96-4d94-9f64-48fed55a89f1",
    defaultPopularityScore: 5.0,
    categoryID: "OTHER_BASKETBALL",
    sportID: "BASKETBALL",
    leagueIDs: {
      WNBA: true,
      NBA_G_LEAGUE: true,
      NBA: true,
      NCAAB: true,
    },
  },
  NFL: {
    enabled: true,
    name: "NFL",
    shortName: "NFL",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FNFL_imageIcon.png?alt=media&token=7521aca6-d8d6-4264-a513-0b0cddc7d9c5",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FNFL_squareImage.jpg?alt=media&token=1dc1ed37-75ef-4cbc-99cd-174602d5bb81",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FNFL_backgroundImage.jpg?alt=media&token=ec1b24ce-1a30-44ff-bfc4-ad618bbc4a8c",
    defaultPopularityScore: 10.0,
    categoryID: "NFL",
    sportID: "FOOTBALL",
    leagueIDs: {
      NFL: true,
    },
  },
  NCAAF: {
    enabled: true,
    name: "NCAA Football",
    shortName: "NCAAF",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FNCAAF_imageicon.png?alt=media&token=40cdd5c5-5774-40bf-b043-4615b1aa21d6",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FNCAAF_squareImage.jpg?alt=media&token=73cf1af9-3fb1-47fa-a6d3-fcb6ca39ac11",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FNCAAF_backgroundImage.jpg?alt=media&token=0a971f6c-6432-4a2c-be28-e8881bc430d2",
    defaultPopularityScore: 8.0,
    categoryID: "NCAAF",
    sportID: "FOOTBALL",
    leagueIDs: {
      NCAAF: true,
    },
  },
  OTHER_FOOTBALL: {
    enabled: true,
    name: "Football",
    shortName: "Football",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_FOOTBALL_imageicon.png?alt=media&token=90476918-cc97-44da-ab6b-c0c92963fbba",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_FOOTBALL_squareImage.jpg?alt=media&token=7f6d0b40-97aa-4bd7-8be6-b4ab38aed5a7",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_FOOTBALL_backgroundImage.jpg?alt=media&token=f45229ba-09f7-450d-9727-9a5be40aef07",
    defaultPopularityScore: 7.0,
    categoryID: "OTHER_FOOTBALL",
    sportID: "FOOTBALL",
    leagueIDs: {
      CFL: true,
      XFL: true,
      USFL: true,
      NFL: true,
      NCAAF: true,
    },
  },
  MENS_TENNIS: {
    enabled: true,
    name: "Men's Tennis",
    shortName: "Tennis (M)",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FTennis_ImageIcon.png?alt=media&token=39d5bcfb-156b-4761-bdb1-916aa0285e79",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FTENNIS_squareImage.jpg?alt=media&token=9f1b8280-404a-451b-bd8f-1210206d1207",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FTENNIS_backgroundImage.jpg?alt=media&token=13b97567-77f9-48d8-8681-75f3734016f8",
    defaultPopularityScore: 5.0,
    categoryID: "TENNIS",
    sportID: "TENNIS",
    leagueIDs: {
      ATP: true,
      //ITF: true,
    },
    WOMENS_TENNIS: {
      enabled: true,
      name: "Women's Tennis",
      shortName: "Tennis (W)",
      imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FTennis_ImageIcon.png?alt=media&token=39d5bcfb-156b-4761-bdb1-916aa0285e79",
      squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FTENNIS_squareImage.jpg?alt=media&token=9f1b8280-404a-451b-bd8f-1210206d1207",
      backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FTENNIS_backgroundImage.jpg?alt=media&token=13b97567-77f9-48d8-8681-75f3734016f8",
      defaultPopularityScore: 5.0,
      categoryID: "TENNIS",
      sportID: "TENNIS",
      leagueIDs: {
        WTA: true,
      },
    },
    INTERNATIONAL_SOCCER: {
      categoryID: "INTERNATIONAL_SOCCER",
      enabled: true,
      name: "International Soccer",
      shortName: "Intl. Soccer",
      imageIcon:
        "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FINTERNATIONAL_SOCCER_imageicon.png?alt=media&token=f3dfabca-e8dd-4697-a4ff-42b3861c8ca7",
      squareImage:
        "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FINTERNATIONAL_SOCCER_squareImage.jpg?alt=media&token=85a052f1-fb72-4cee-be9c-e16f92cf738f",
      backgroundImage:
        "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FINTERNATIONAL_SOCCER_backgroundImage.jpg?alt=media&token=4c34ba1f-f0fa-40f0-8f18-2d9c1c1424f8",
      defaultPopularityScore: 7.0,
      leagueID: "INTERNATIONAL_SOCCER",
      sportID: "SOCCER",
      leagueIDs: {},
    },
  },
  EPL: {
    categoryID: "EPL",
    enabled: true,
    name: "EPL",
    shortName: "EPL",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FEPL_imageIcon.png?alt=media&token=34de639c-5101-4be1-8fe4-e6a8ae46a775",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FEPL_squareImage.jpg?alt=media&token=ae87a421-9f57-4b48-9c66-da0f127c0d1b",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FEPL_backgroundImage.jpg?alt=media&token=9433368c-5322-44a6-b9ba-bdf18c833533",
    defaultPopularityScore: 7.5,
    leagueID: "EPL",
    sportID: "SOCCER",
    leagueIDs: {
      EPL: true,
    },
  },
  OTHER_SOCCER: {
    enabled: true,
    name: "Soccer",
    shortName: "Soccer",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_imageicon.png?alt=media&token=03efe8ba-0a30-4ca4-8fa9-80e272ca1828",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_squareImage.jpg?alt=media&token=9b2ff9ac-3e9d-4965-979c-fc1a0d873baa",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_SOCCER_backgroundImage.jpg?alt=media&token=9c7e2109-12a1-400b-aa5f-90e369ac282d",
    defaultPopularityScore: 8.0,
    categoryID: "OTHER_SOCCER",
    sportID: "SOCCER",
    leagueIDs: {
      BR_SERIE_A: true,
      BUNDESLIGA: true,
      FR_LIGUE_1: true,
      IT_SERIE_A: true,
      LA_LIGA: true,
      MLS: true,
      UEFA_CHAMPIONS_LEAGUE: true,
      LIGA_MX: true,
      UEFA_EUROPA_LEAGUE: true,
      EPL: true,
    },
  },
  HANDBALL: {
    enabled: true,
    categoryID: "HANDBALL",
    sportID: "HANDBALL",
    name: "Handball",
    shortName: "Handball",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FHANDBALL_imageicon.png?alt=media&token=cd2302b4-4d33-4f9b-aa32-6c58784be30b",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FHANDBALL_squareImage.jpg?alt=media&token=b9117d62-c85b-42d2-b06a-a87561b209d5",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FHANDBALL_backgroundImage.jpg?alt=media&token=09281591-62c3-480c-bb61-91eb1bbea957",
    defaultPopularityScore: 2.0,
    leagueIDs: {
      IHF_SUPER_GLOBE: true,
      EHF_EURO_CUP: true,
      ASOBAL: true,
      SEHA: true,
      EHF_EURO: true,
    },
  },
  MLB: {
    enabled: true,
    name: "MLB",
    shortName: "MLB",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FMLB_imageicon.png?alt=media&token=86edf8d5-f5fc-4f47-9ba2-fdd14ce775fa",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FMLB_squareImage.jpg?alt=media&token=4e208ab0-a925-4c7d-8f82-63ce8fbcc880",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2Fmlb_backgroundImage.jpg?alt=media&token=7d34fc9c-afa5-4487-8b2b-aa0e021bdc97",
    defaultPopularityScore: 8.0,
    categoryID: "MLB",
    sportID: "BASEBALL",
    leagueIDs: {
      MLB: true,
    },
  },
  OTHER_BASEBALL: {
    enabled: true,
    name: "Baseball",
    shortName: "Baseball",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASEBALL_imageicon.png?alt=media&token=53f343ee-c10b-47cd-af4a-beef6ee0cd5f",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASEBALL_squareImage.jpg?alt=media&token=0affd222-2c6e-48c1-b6f3-c6f6492eb594",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FOTHER_BASEBALL_backgroundImage.jpg?alt=media&token=c2c1f4cc-6920-40c5-a61b-30fd353fcf3d",
    defaultPopularityScore: 7.0,
    categoryID: "OTHER_BASEBALL",
    sportID: "BASEBALL",
    leagueIDs: {
      CPBL: true,
      KBO: true,
      LBPRC: true,
      LIDOM: true,
      LMP: true,
      LVBP: true,
      MLB_MINORS: true,
      NPB: true,
      WBC: true,
      MLB: true,
    },
  },
  GOLF: {
    enabled: true,
    name: "Golf",
    shortName: "Golf",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FGOLF_imageicon.png?alt=media&token=3bd873bc-bf22-4e85-9d8c-242af468549b",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FGOLF_squareImage.jpg?alt=media&token=4c4f3ebd-2b45-4184-87ab-6a24d4050cb2",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FGOLF_backgroundImage.jpg?alt=media&token=aa7de464-56a5-49a9-87cd-390221035834",
    defaultPopularityScore: 7.0,
    categoryID: "GOLF",
    sportID: "GOLF",
    leagueIDs: {
      PGA_MEN: true,
      PGA_WOMEN: true,
    },
  },
  MMA: {
    enabled: true,
    name: "MMA",
    shortName: "MMA",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FMMA_imageicon.png?alt=media&token=1492fefa-1547-43df-a5a5-4ed81f8ffe65",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FMMA_squareImage.jpg?alt=media&token=10c02ea6-bfc8-4995-b9f1-b0653f0d37e8",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2Fmma_backgroundImage.jpg?alt=media&token=2dbae8d6-4c16-41f2-b088-40cc565b07fc",
    defaultPopularityScore: 7.0,
    categoryID: "MMA",
    sportID: "MMA",
    leagueIDs: {
      UFC: true,
    },
  },
  HORSE_RACING: {
    enabled: true,
    name: "Horses",
    shortName: "Horses",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FHORSE_RACING_imageIcon.png?alt=media&token=b5be95cd-afcc-486b-9d9b-cdb98dee8813",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FHORSE_RACING_squareImage.jpg?alt=media&token=4019abd8-7ab9-402c-96ac-94b91a3caf59",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FHORSE_RACING_backgroundImage.jpg?alt=media&token=b8b8aede-3438-4fc1-92f6-862b28f38568",
    defaultPopularityScore: 6.0,
    categoryID: "HORSE_RACING",
    sportID: "HORSE_RACING",
    leagueIDs: {
      HORSE_RACING: true,
    },
  },
  MOTORSPORTS: {
    enabled: false,
    name: "Motorsports",
    shortName: "Motorsports",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FMOTORSPORTS_imageicon.png?alt=media&token=a38df1e0-7c5a-4199-a5f1-d0ea42bb25f8",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FMOTORSPORTS_squareImage.jpg?alt=media&token=0abbf887-1275-45e5-82b3-42318259613e",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FMOTORSPORTS_backgroundImage.jpg?alt=media&token=bcee14fd-d037-45bd-a977-ec432a708eba",
    defaultPopularityScore: 6.0,
    categoryID: "MOTORSPORTS",
    sportID: "MOTORSPORTS",
    leagueIDs: {
      F1: true,
      NASCAR: true,
    },
  },
  CRICKET: {
    enabled: false,
    name: "Cricket",
    shortName: "Cricket",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FCRICKET_imageicon.png?alt=media&token=3653358f-2641-4bb2-b6db-21d396139643",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FCRICKET_squareImage.jpg?alt=media&token=3e16142f-372e-49f1-8bda-599ed295cd3c",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FCRICKET_backgroundImage.jpg?alt=media&token=8b0beeab-97e1-4019-91a7-76952fb1fbdc",
    defaultPopularityScore: 6.0,
    categoryID: "CRICKET",
    sportID: "CRICKET",
    leagueIDs: {
      IPL: true,
      BBL: true,
      PSL: true,
      MLC: true,
      SA20: true,
    },
  },
  ESPORTS: {
    enabled: false,
    name: "Esports",
    shortName: "Esports",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FESPORTS_imageicon.png?alt=media&token=da1ecdf0-da80-420f-8aab-ff30aedec8e2",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FESPORTS_squareImage.jpg?alt=media&token=b1800e25-7b23-4272-ba42-6c70022b8da1",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FESports_backgroundImage.jpg?alt=media&token=06ae96f9-18d7-4201-a1d0-ddfb0285578a",
    defaultPopularityScore: 8.0,
    categoryID: "ESPORTS",
    sportID: "ESPORTS",
    leagueIDs: {
      ESPORTS: true,
    },
  },
  ENTERTAINMENT: {
    enabled: true,
    name: "Entertainment",
    shortName: "ENTMT",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FENTMT_imageicon.png?alt=media&token=63f0deb2-32fa-4943-ab70-4dfb32138d68",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FENTMT_squareImage.jpg?alt=media&token=e6db1fe3-5c3a-41b4-95fc-465fed819323",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FEPL_backgroundImage.jpg?alt=media&token=9433368c-5322-44a6-b9ba-bdf18c833533",
    defaultPopularityScore: 6.0,
    categoryID: "ENTERTAINMENT",
    sportID: "NON_SPORTS",
    leagueIDs: {
      MARKETS: true,
      POLITICS: true,
      TV: true,
      MOVIES: true,
      MUSIC: true,
      FUN: true,
      EVENTS: true,
      CELEBRITY: true,
    },
  },
  POLITICS: {
    enabled: true,
    name: "Politics",
    shortName: "Politics",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FPOLITICS_imageicon.png?alt=media&token=5edb8d7b-72aa-4356-94ed-162551eca737",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FPOLITICS_squareImage.jpg?alt=media&token=edaa9987-1086-4c3f-88c7-80a9ad927ccd",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FPOLITICS_backgroundImage.jpg?alt=media&token=bbad2d78-8092-46ea-b669-eee3c5092470",
    defaultPopularityScore: 8.0,
    categoryID: "POLITICS",
    sportID: "NON_SPORTS",
    leagueIDs: {
      POLITICS: true,
    },
  },
  TV: {
    enabled: true,
    name: "TV",
    shortName: "TV",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FTV_imageicon.png?alt=media&token=6714c47d-f70c-4737-b8f2-ce4577cb5af1",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FTV_squareImage.jpg?alt=media&token=5476ef3d-8286-4352-8c83-4e68ca1f0a14",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FTV_backgroundImage.jpg?alt=media&token=cf61b5ef-b68f-4b87-9ba0-dd69713727ac",
    defaultPopularityScore: 6.0,
    categoryID: "TV",
    sportID: "NON_SPORTS",
    leagueIDs: {
      TV: true,
    },
  },
  MOVIES: {
    enabled: true,
    name: "Movies",
    shortName: "Movies",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FMovie_imageicon.png?alt=media&token=f9e5280d-64bd-4bbf-805e-bf9de2199b5c",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FMOVIES_squareImage.jpg?alt=media&token=163c6447-3074-4e31-b8ea-a97abbfa2cda",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FMOVIES_backgroundImage.jpg?alt=media&token=1774d16f-864b-45c3-a571-cac165cff76e",
    defaultPopularityScore: 5.0,
    categoryID: "MOVIES",
    sportID: "NON_SPORTS",
    leagueIDs: {
      MOVIES: true,
    },
  },
  MUSIC: {
    enabled: true,
    name: "Music",
    shortName: "Music",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FMusic_imageicon.png?alt=media&token=9a9329c1-137e-4cf3-b5ef-c62ae5a0146e",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FMUSIC_squareImage.jpg?alt=media&token=e2d226e5-f12f-4900-a038-39c8e8b8b472",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FMUSIC_backgroundImage.jpg?alt=media&token=31615a04-6ab5-4527-93cd-7541eb87a86b",
    defaultPopularityScore: 5.0,
    categoryID: "MUSIC",
    sportID: "NON_SPORTS",
    leagueIDs: {
      MUSIC: true,
    },
  },
  FUN: {
    enabled: true,
    name: "Fun",
    shortName: "Fun",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FFun_imageicon.png?alt=media&token=aca12de4-dcea-40e2-bfa1-a33cb7c0c497",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FFUN_squareImage.jpg?alt=media&token=6cba6081-b11d-47f5-a53c-3a79d7e4a5a2",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2Ffun_backgroundImage.jpg?alt=media&token=ef898d6e-a016-4c89-94dd-15851eb7ee9c",
    defaultPopularityScore: 5.0,
    categoryID: "FUN",
    sportID: "NON_SPORTS",
    leagueIDs: {
      FUN: true,
    },
  },
  EVENTS: {
    enabled: true,
    shortName: "Events",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FEvent_imageicon.png?alt=media&token=8455c897-da88-4699-b394-29d3714af6a3",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FEVENT_squareImage.jpg?alt=media&token=7af1bb18-ef36-4176-a54a-5b7f9288b2ba",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2Fevents_backgroundImage.jpg?alt=media&token=01381cbb-7e76-4f0e-9655-fe5431771d8c",
    defaultPopularityScore: 5.0,
    categoryID: "EVENTS",
    sportID: "NON_SPORTS",
    leagueIDs: {
      EVENTS: true,
    },
  },
  CELEBRITY: {
    enabled: true,
    name: "Celebrity",
    shortName: "Celebrity",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FCelebrity_imageIcon.png?alt=media&token=56694746-9bb4-4c2b-9f12-01a340956610",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FCELEBRITY_squareImage.jpg?alt=media&token=1d936e51-59a5-4919-9321-5df92616cebe",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2Fcelebrity_backgroundImage.jpg?alt=media&token=84f46d78-9774-40f5-84e1-08a85ac60ad3",
    defaultPopularityScore: 5.0,
    categoryID: "CELEBRITY",
    sportID: "NON_SPORTS",
    leagueIDs: {
      CELEBRITY: true,
    },
  },
  MARKETS: {
    enabled: true,
    name: "Markets",
    shortName: "Markets",
    imageIcon: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FMarkets_imageicon.png?alt=media&token=fbde35db-3956-4454-af44-8fb30ac1d2f6",
    squareImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2FMARKETS_squareImage.jpg?alt=media&token=e3008875-9455-47f4-a036-a8ef646f1a48",
    backgroundImage: "https://firebasestorage.googleapis.com/v0/b/socialbetproduction.appspot.com/o/categoryConfig%2Fmarkets_backgroundImage.jpg?alt=media&token=04447fe0-2909-46eb-bce4-572ce4d8a5cf",
    defaultPopularityScore: 7.0,
    categoryID: "MARKETS",
    sportID: "NON_SPORTS",
    leagueIDs: {
      MARKETS: true,
    },
  },
};
exports.CATEGORY_CONFIG = CATEGORY_CONFIG;

const ENABLED_CATEGORIES = Object.values(CATEGORY_CONFIG).filter((category) => category?.enabled);
exports.ENABLED_CATEGORIES = ENABLED_CATEGORIES;

const LEAGUEID_TO_CATEGORYIDS_MAP = Object.entries(CATEGORY_CONFIG).reduce((leagueToCategoryMap, [categoryID, categoryData]) => {
  if (!categoryData?.enabled) return leagueToCategoryMap;
  Object.entries(categoryData?.leagueIDs || {}).forEach(([leagueID, categoryLeagueEnabled]) => {
    if (!categoryLeagueEnabled || !leagueID || !LEAGUE_CONFIG[leagueID]?.enabled) return;
    leagueToCategoryMap[leagueID] = leagueToCategoryMap[leagueID] || [];
    leagueToCategoryMap[leagueID].push(categoryID);
  });
  return leagueToCategoryMap;
}, {});

const getCategoryIDs = (leagueID) => {
  return LEAGUEID_TO_CATEGORYIDS_MAP[leagueID] || [];
};
exports.getCategoryIDs = getCategoryIDs;

const isCategoryEnabled = (categoryID) => CATEGORY_CONFIG[categoryID]?.enabled || false;
exports.isCategoryEnabled = isCategoryEnabled;

const CATEGORIES_BY_NAME = [...ENABLED_CATEGORIES].sort((a, b) => {
  if (!a?.name && !b?.name) return 0;
  if (!a?.name) return 1;
  if (!b?.name) return -1;
  return a.name.localeCompare(b.name);
});
exports.CATEGORIES_BY_NAME = CATEGORIES_BY_NAME;

const CATEGORIES_BY_DEFAULT_POPULARITY = [...ENABLED_CATEGORIES].sort((a, b) => (b?.defaultPopularityScore || 0) - (a?.defaultPopularityScore || 0));
exports.CATEGORIES_BY_DEFAULT_POPULARITY = CATEGORIES_BY_DEFAULT_POPULARITY;
