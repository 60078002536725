import { formatPotentialTeamID, getTeamName } from "@wagerlab/utils/aggregator/lookups";

export const getUnknownEntityData = (unknownEntity) => unknownEntity?.teamData;

export const formatPotentialID = (unknownEntity) => formatPotentialTeamID(unknownEntity?.teamData?.names?.long, unknownEntity?.teamData?.leagueID);

export const getEntityTypeDisplayName = (capital = false, plural = false) => `${capital ? "T" : "t"}eam${plural ? "s" : ""}`;

export const getSourceID = (unknownEntity) => unknownEntity?.sourceID;

export const getSourceEntityID = (unknownEntity) => unknownEntity?.sourceTeamID;

export const getName = (unknownEntity) => getTeamName(unknownEntity?.teamData);

export const getSortName = (unknownEntity) => getTeamName(unknownEntity?.teamData);

export const getEntityNameLookupsOrig = (unknownEntity) => unknownEntity?.teamNameLookupsOrig;

export const getEntityNameLookups = (unknownEntity) => unknownEntity?.teamNameLookups;
