const { isString, isInteger, isNumber } = require("@wagerlab/utils/data/types");
const { EventDisplays } = require("@wagerlab/utils/displays/EventDisplays");
const { BET_TYPE_CONFIG } = require("@wagerlab/utils/odds/betTypes");
const { asSpreadFloat, asMoneylineInt, asOverUnderFloat } = require("@wagerlab/utils/odds/converters");
const { getOpponentOddID, classifyOdds } = require("@wagerlab/utils/odds/identifiers");
const { getPeriodConfig } = require("@wagerlab/utils/sports/periods");
const { SPORT_CONFIG } = require("@wagerlab/utils/sports/sportConfig");
const { getStatConfigFromEventOdds } = require("@wagerlab/utils/sports/stats");

class OddsDisplays {
  constructor(oddData, eventData) {
    this.odd = oddData;
    this.event = eventData;
    this.eventDisplays = new EventDisplays(eventData);
  }

  // Lebron James Over 15 Rebounds
  // Teneseee Titans +5.5 points
  // Chicago Cubs +3 Strikeouts (at St. Louis)
  // Yes (Will the game go to overtime?)

  // Over \n 15         Lebron James Rebounds
  // Titans \n +5.5 pts

  // LAL   Lakers   Los Angeles Lakers
  // Republican
  // Over     Yes    Even
  getSideDisplay(length) {
    const sideID = this.odd?.sideID || "";
    const betTypeConfig = BET_TYPE_CONFIG[this.odd?.betTypeID] || null;
    const sideIDConfig = betTypeConfig?.sideIDs?.[sideID] || null;

    if (!sideID || !sideIDConfig) return "";
    if (sideID === "home" || sideID === "away") return this.eventDisplays.getTeamDisplay(sideID, length);
    if (sideID === "side1" || sideID === "side2") {
      const propStatID = this.odd?.statID || "";
      return this.event?.props?.[propStatID]?.sides?.[sideID]?.sideDisplay || "";
    }
    return sideIDConfig?.display || "";
  }

  // Who will score first
  // Who will win the next presidential election
  // Presidential Election 2024 - Winner
  getPropTitle(length) {
    const propStatID = this.odd?.statID || "";
    const prop = this.event?.props?.[propStatID] || {};
    return prop?.propTitle || "";
  }

  // For ou....  5   5 Blks (+230)    5 Blocks (+230)
  // For ou....  5   5 pts    5 points
  //  For sp.... +5   +5 pts (-420)   +5 points (-420)
  //  For sp.... +5   +5 pts   +5 points
  // For ml (or other non even)....  <blank>   +250   +250
  getOddModifierDisplay(length, statDisplayType = "units", oddsVisibility = "dynamic") {
    const betTypeID = this.odd?.betTypeID || "";
    const oddsString = this?.odd?.odds || "";
    const oddsInt = asMoneylineInt(oddsString);
    const isEvenOdds = oddsInt === 100;

    let pointsModifierDisplay = "";
    if (betTypeID === "sp" || betTypeID === "ou") {
      pointsModifierDisplay = betTypeID === "sp" ? this.odd?.spread : this.odd?.overUnder;
      const statUnitDisplayLength = length === "long" ? "long" : "short";
      const statUnitDisplay = statDisplayType === "units" ? this.getStatUnitDisplay(statUnitDisplayLength) : this.getStatDisplay(statUnitDisplayLength);
      if (pointsModifierDisplay && statUnitDisplay && length !== "short") pointsModifierDisplay += ` ${statUnitDisplay}`;
    }
    let showOdds = true;
    if (oddsVisibility === "always") showOdds = true;
    else if (oddsVisibility === "never") showOdds = false;
    else if (length === "short") showOdds = false;
    else if (length === "medium") showOdds = !isEvenOdds;
    else if (length === "long" && pointsModifierDisplay) showOdds = !isEvenOdds;
    else if (length === "long" && !pointsModifierDisplay) showOdds = true;

    if (showOdds && !oddsString) showOdds = false;

    const oddsDisplay = !showOdds ? "" : betTypeID !== "ml" ? `(${oddsString})` : oddsString;
    return oddsDisplay && pointsModifierDisplay ? `${pointsModifierDisplay} ${oddsDisplay}` : pointsModifierDisplay || oddsDisplay;
  }

  // short: LAL +5.5 pts     ML-> LAL                                 Republican
  // medium Lakers +5.5 points     Over 100 points   Lakers +110
  // long: Los Angeles Lakers +5.5 points      Republican
  getOddTitle(length, oddsVisibility) {
    let title = this.getSideDisplay(length);
    if (!title) return "";
    const oddModifierDisplay = this.getOddModifierDisplay(length === "short" ? "medium" : "long", "units", oddsVisibility) || ""; //Must be medium (15.5 Longest Rec.) or long (15.5 Longest Reception)
    if (oddModifierDisplay) title += ` ${oddModifierDisplay}`;
    return title;
  }

  getFullTitle() {
    const { statEntityID, sideID, betTypeID, periodID } = this.odd || {};

    let fullTitle = this.getSideDisplay("medium");
    if (!fullTitle) return "";
    const oddModifierDisplay = this.getOddModifierDisplay("medium", "statName", "dynamic") || "";
    if (oddModifierDisplay) fullTitle += ` ${oddModifierDisplay}`;
    const statEntityDisplay = statEntityID === sideID ? "" : this.getStatEntityDisplay("medium");
    if (statEntityDisplay) fullTitle += ` ${statEntityDisplay}`;

    let context = "";
    if (betTypeID === "prop") {
      context = this.getPropTitle("medium");
    } else if (sideID === "home" || sideID === "away") {
      context = this.eventDisplays.getOpponentContext(sideID, "short");
    } else {
      context = this.eventDisplays.getTitle("short");
    }
    const periodContext = periodID === "game" ? "" : this.getPeriodDisplay("short");
    if (periodContext) context += ` - ${periodContext}`;

    if (context) fullTitle += ` (${context})`;
    return fullTitle;
  }

  // Who will score first?
  //  Spread (1Q) (LAL at HOU), First Quarter Spread (Lakers at Rockets), Total Rebounds (1st Q) (LAL at HOU), Lebron James First Quarter Assists (Lakers at Rockets)
  getOddSubTitle(length) {
    const { sideID, betTypeID } = this.odd || {};
    let subTitle = this.getOddGroupName(length) || "";
    if (!subTitle) return "";

    if (sideID === "home" || sideID === "away") {
      const opponentContextLength = length === "long" ? "medium" : "short";
      const opponentContext = this.eventDisplays.getOpponentContext(sideID, opponentContextLength);
      if (opponentContext) subTitle += ` (${opponentContext})`;
    } else if (betTypeID === "prop") {
      // Right now dispplay nothing more here but upon adding prop eventName I'd display that here instead (if its different from propTitle)
    } else {
      const eventTitleLength = length === "long" ? "medium" : "short";
      const eventTitle = this.eventDisplays.getTitle(eventTitleLength);
      if (eventTitle) subTitle += ` (${eventTitle})`;
    }
    return subTitle;
  }

  getOddEventContext(length) {
    const { sideID, betTypeID } = this.odd || {};
    if (sideID === "home" || sideID === "away") {
      return this.eventDisplays.getOpponentContext(sideID, length);
    } else if (betTypeID === "prop") {
      return "";
      // Right now dispplay nothing more here but upon adding prop eventName I'd display that here instead (if its different from propTitle)
    }
    return this.eventDisplays.getTitle(length);
  }

  getOddTitleList(length, oddsVisibility) {
    const betTypeID = this.odd?.betTypeID || "";
    const sideDisplay = this.getSideDisplay(length);
    if (!sideDisplay) return [];
    const oddModifierDisplay = this.getOddModifierDisplay(length, "units", oddsVisibility) || "";

    if (betTypeID === "prop") {
      const singleString = `${sideDisplay} ${oddModifierDisplay}`.trim();
      return singleString ? [singleString] : [];
    }
    return oddModifierDisplay ? [sideDisplay, oddModifierDisplay] : [sideDisplay];
  }

  // Blks, Blocks
  // +/-  Plus/Minus
  // 3PT Att, Three Pointers Attempted
  getStatDisplay(length) {
    const statConfig = getStatConfigFromEventOdds(this.event, this.odd);
    return statConfig?.displays?.[length] || statConfig?.displays?.long || "";
  }

  // blks, blocks
  // pts, points
  // This will be blank for many stats
  getStatUnitDisplay(length, amount = null) {
    let number = isString(amount) ? parseFloat(amount) : amount;
    if (!isNumber(number) || number == 0) number = this.odd?.betTypeID === "sp" ? asSpreadFloat(this.odd?.spread) : this.odd?.betTypeID === "ou" ? asOverUnderFloat(this.odd?.overUnder) : null;
    if (!isNumber(number)) number = 0;
    const isSingular = number === 1 || number === -1;
    const statConfig = getStatConfigFromEventOdds(this.event, this.odd);
    const statUnitConfig = statConfig?.units?.[length] || statConfig?.units?.long || {};
    if (isSingular && statUnitConfig?.singular) return statUnitConfig.singular;
    return statUnitConfig.plural || "";
  }

  // Lebron James
  // Returns empty string if its not a player-related odd
  getPlayerDisplay(length) {
    const playerID = this.odd?.playerID;
    if (!playerID) return "";

    const { firstName, lastName, name, nickname } = this.event?.players?.[playerID] || {};

    const firstLastName = (firstName && lastName && `${firstName} ${lastName}`) || "";
    const firstInitialLastName = (firstName && lastName && `${firstName[0]}. ${lastName}`) || "";
    if (length === "long") return name || firstLastName || nickname || lastName || "";

    const targetLength = length === "short" ? 10 : 20;
    if (name && name.length < targetLength) return name;
    if (nickname && nickname.length < targetLength) return nickname;
    if (firstInitialLastName && firstInitialLastName.length < targetLength) return firstInitialLastName;
    if (lastName && lastName.length < targetLength) return lastName;
    let bestOptions = [name, firstLastName, nickname, lastName].filter((s) => !!s && isString(s));
    if (length === "short") bestOptions = bestOptions.sort((a, b) => a.length - b.length);
    return bestOptions[0] || "";
  }

  // Reg, Regulation
  // 3Q, 3rd Q, 3rd Quarter
  // Match, Full Match
  // Game, Full Game
  // Fight, Full Fight
  getPeriodDisplay(length) {
    const periodID = this.odd?.periodID;
    if (!periodID) return "";
    const periodConfig = getPeriodConfig(this.odd?.periodID);
    if (!periodConfig) return "";
    const periodDisplay = periodConfig?.displays?.[length] || periodConfig?.displays?.medium || "";
    const eventWord = this.eventDisplays.getEventWord(false, length, true) || "Event";
    return periodDisplay.replaceAll("Event", eventWord);
  }

  // LA Lakers, Lebron James
  // Total (when statEntityID is all)
  getStatEntityDisplay(length) {
    const { statEntityID, playerID } = this.odd || {};

    if (!statEntityID) return "";
    if (statEntityID === "all") return "Total";
    if (statEntityID === "home") return this.eventDisplays.getTeamDisplay("home", length);
    if (statEntityID === "away") return this.eventDisplays.getTeamDisplay("away", length);
    if (playerID && statEntityID === playerID) return this.getPlayerDisplay(length);
    return "";
  }

  // props -> prop title (Who will score first?)
  // main odds (game points sp,ml,ou) -> Spread, Moneyline, Over/Under
  // period odds ->  Spread (1Q), First Quarter Spread, Total Rebounds (1Q), Lebron James First Quarter Assists
  getOddGroupName(length, uniqueOnly = false, moneylineWord = "Moneyline", isMarketName = false, altVariation = false) {
    const { betTypeID, periodID, statID, statEntityID } = this.odd || {};
    const { sportID, leagueID, type: eventType } = this.event || {};

    const { isTopMarketForSport } = classifyOdds(this.odd, eventType, sportID, leagueID);
    if (isTopMarketForSport && uniqueOnly) return "";

    if (betTypeID === "prop") {
      const propTitle = this.getPropTitle(length);
      if (!propTitle) return "";
      const periodContext = periodID === "game" ? "" : this.getPeriodDisplay(length);
      return periodContext ? `${propTitle} (${periodContext})` : propTitle;
    }

    let statDisplay = this.getStatDisplay(length);
    let statEntityDisplay = this.getStatEntityDisplay(length === "long" ? "long" : "medium");

    if (!statDisplay || !statEntityDisplay) return "";

    let periodDisplay = this.getPeriodDisplay(length);
    const prefers3WayMoneyline = SPORT_CONFIG[sportID]?.prefers3WayMoneyline;
    const shouldHidePeriod = periodID === "game" && !prefers3WayMoneyline;
    if (shouldHidePeriod) periodDisplay = "";

    let alwaysDisplayBetType = isMarketName;
    let showPeriodInMiddle = length === "long";
    let betTypeDisplay = BET_TYPE_CONFIG?.[betTypeID]?.name || "";
    if (prefers3WayMoneyline && (periodID === "game" || periodID === "reg")) showPeriodInMiddle = false;

    if (betTypeID === "sp") {
      betTypeDisplay = "Spread";
      // if (statEntityID === "home" || statEntityID === "away") statEntityDisplay = "";
      statEntityDisplay = "";
      if (statID === "points") statDisplay = "";
    } else if (betTypeID === "ml") {
      betTypeDisplay = moneylineWord || "";
      // if (statEntityID === "home" || statEntityID === "away") statEntityDisplay = "";
      statEntityDisplay = "";
      if (statID === "points") statDisplay = "";
    } else if (betTypeID === "ou") {
      betTypeDisplay = "";
      if (statEntityID === "all" && statID === "points") {
        statEntityDisplay = "";
        statDisplay = "";
        betTypeDisplay = "Over/Under";
      }
    } else if (betTypeID === "ml3way") {
      betTypeDisplay = moneylineWord ? `3-Way ${moneylineWord}` : "";
      statEntityDisplay = "";
      if (statID === "points") statDisplay = "";
    } else if (betTypeID === "yn") {
      betTypeDisplay = "";
      const statConfig = getStatConfigFromEventOdds(this.event, this.odd);
      const { infinitive, defaultEntity } = statConfig?.displays || {};
      if (infinitive) {
        if (statEntityID === "all") statEntityDisplay = defaultEntity || (this.event?.type === "match" && "Any Team") || "Anyone";
        statDisplay = infinitive;
        showPeriodInMiddle = false;
      } else {
        if (altVariation) {
          statEntityDisplay = statEntityID !== "all" && statEntityDisplay ? `${statEntityDisplay} Anytime` : "Anytime";
          showPeriodInMiddle = false;
          alwaysDisplayBetType = false;
        } else {
          statEntityDisplay = statEntityID !== "all" && statEntityDisplay ? `${statEntityDisplay} Any` : "Any";
        }
      }
    }

    if (alwaysDisplayBetType && !betTypeDisplay) betTypeDisplay = BET_TYPE_CONFIG?.[betTypeID]?.name || "";

    let oddGroupName = `${statEntityDisplay}`;
    if (periodDisplay && showPeriodInMiddle) oddGroupName += ` ${periodDisplay}`;
    if (statDisplay) oddGroupName += ` ${statDisplay}`;
    if (betTypeDisplay) oddGroupName += ` ${betTypeDisplay}`;
    if (periodDisplay && !showPeriodInMiddle) oddGroupName += ` (${periodDisplay})`;
    return oddGroupName.trim();
  }

  getResultStatName(length) {
    const { betTypeID, periodID, statID, statEntityID } = this.odd || {};
    if (betTypeID === "prop") {
      const propTitle = this.getPropTitle(length);
      if (!propTitle) return "";
      const periodContext = periodID === "game" ? "" : this.getPeriodDisplay(length);
      return periodContext ? `${propTitle} (${periodContext})` : propTitle;
    }
    let statDisplay = this.getStatDisplay(length);
    let statEntityDisplay = this.getStatEntityDisplay(length === "long" ? "long" : "medium");
    let periodDisplay = this.getPeriodDisplay(length);
    if (!statDisplay || !statEntityDisplay || !periodDisplay) return "";
    let oddGroupName = `${statEntityDisplay}`;
    if (periodDisplay && length === "long") oddGroupName += ` ${periodDisplay}`;
    if (statDisplay) oddGroupName += ` ${statDisplay}`;
    if (periodDisplay && length !== "long") oddGroupName += ` (${periodDisplay})`;
    return oddGroupName.trim();
  }

  getExplainedOdds(format, allBetTypes = true) {
    const { betTypeID, periodID, statID, statEntityID } = this.odd || {};
    const moneylineInt = asMoneylineInt(this?.odd?.odds);

    if (!moneylineInt) return "";
    if (moneylineInt === 100) {
      if (!allBetTypes && betTypeID !== "ml") return "";
      if (format === "long") return "Even odds - Risk & win the same amount";
      if (format === "medium") return "Risk & win the same amount";
      if (format === "short") return "Even odds";
    }
    const multiplier = moneylineInt > 0 ? moneylineInt / 100 : -100 / moneylineInt;
    const multiplierString = multiplier.toFixed(2);
    if (format === "long") return moneylineInt > 0 ? `Win ${moneylineInt} for every 100 risked` : `Win 100 for every ${moneylineInt * -1} risked`;
    if (format === "short") return `${multiplierString}x odds`;
    return `Win ${multiplierString}x your bet`;
  }

  getOddDetails() {
    const { betTypeID, statID } = this.odd || {};
    if (betTypeID !== "prop") return "";
    const propData = this.event?.props?.[statID] || {};
    return propData?.propDetails || "";
  }

  getEventOddDetails() {
    return this.getOddDetails() || this.eventDisplays.getEventDetails() || "";
  }

  getVsOrAt(isPrefix = true) {
    const { sideID } = this.odd || {};
    if (isPrefix && sideID === "home" && SPORT_CONFIG[this?.event?.sportID]?.hasMeaningfulHomeAway) return "at";
    if (!isPrefix && sideID === "away" && SPORT_CONFIG[this?.event?.sportID]?.hasMeaningfulHomeAway) return "at ";
    return "vs";
  }

  getScoreList(length) {
    const oddData = this.odd;
    const { betTypeID, sideID, statID, periodID, score, statEntityID } = oddData || {};
    if (betTypeID === "prop") return [];
    const awayTeamName = this.eventDisplays.getTeamDisplay("away", length);
    const homeTeamName = this.eventDisplays.getTeamDisplay("home", length);
    const awayTeamScore = this.eventDisplays.getTeamScore("away");
    const homeTeamScore = this.eventDisplays.getTeamScore("home");

    if (!homeTeamName || !awayTeamName || !isNumber(homeTeamScore) || !isNumber(awayTeamScore)) return [];
    let homeScoreString = `${homeTeamScore}`;
    let awayScoreString = `${awayTeamScore}`;
    const scoreList = [];

    const opponentScore = this.event?.odds?.[getOpponentOddID(oddData)]?.score;

    let homeScoreBreakdown = "";
    let awayScoreBreakdown = "";
    let showExtraRow = true;
    if (statEntityID === "home" || statEntityID === "away") {
      const periodBreakdownLabel = periodID !== "game" ? this.getPeriodDisplay(length) : "";
      const statBreakdownLabel = statID !== "points" ? this.getStatDisplay(length) : "";

      const breakdownLabel = periodBreakdownLabel || statBreakdownLabel ? `${periodBreakdownLabel || ""} ${statBreakdownLabel || ""}`.trim() : "";
      const homeOddScore = statEntityID === "home" ? score : opponentScore;
      const awayOddScore = statEntityID === "away" ? score : opponentScore;

      homeScoreBreakdown = breakdownLabel && isNumber(homeOddScore) ? `${breakdownLabel}: ${homeOddScore}` : "";
      awayScoreBreakdown = breakdownLabel && isNumber(awayOddScore) ? `${breakdownLabel}: ${awayOddScore}` : "";
      if (homeScoreBreakdown && awayScoreBreakdown) {
        homeScoreString = `${homeTeamScore} (${homeScoreBreakdown})`;
        awayScoreString = `${awayTeamScore} (${awayScoreBreakdown})`;
        showExtraRow = false;
      }
      if (periodID === "game" && statID === "points") showExtraRow = false;
    }
    scoreList.push({ label: homeTeamName, score: homeScoreString });
    scoreList.push({ label: awayTeamName, score: awayScoreString });

    if (showExtraRow) {
      let statDisplay = this.getStatDisplay(length === "short" ? "short" : "long");
      let statEntityDisplay = this.getStatEntityDisplay(length === "long" ? "long" : "medium");
      let periodDisplay = periodID === "game" ? "" : this.getPeriodDisplay(length);

      const statEntityPeriodDisplay = statEntityDisplay && periodDisplay ? `${statEntityDisplay} ${periodDisplay}` : statEntityDisplay;
      const extraItemLabel = statEntityPeriodDisplay && statDisplay ? `${statEntityPeriodDisplay} ${statDisplay}` : "";
      if (extraItemLabel && isNumber(score)) {
        scoreList.push({ label: extraItemLabel, score: `${score}`, isExtra: true });
      }
    }
    return scoreList;
  }

  getDisplayString(displayType) {
    switch (displayType) {
      case "title_full":
        return this.getFullTitle();
      case "title_alwaysShowOdds":
        return this.getOddTitle("medium", "always");
      case "title_long":
        return this.getOddTitle("long", "dynamic");
      case "title_medium":
        return this.getOddTitle("medium", "dynamic");
      case "title_short":
        return this.getOddTitle("short", "dynamic");
      case "subTitle_long":
        return this.getOddSubTitle("long");
      case "subTitle_medium":
        return this.getOddSubTitle("medium");
      case "subTitle_short":
        return this.getOddSubTitle("short");
      case "titleList_long":
        return this.getOddTitleList("long", "dynamic");
      case "titleList_medium":
        return this.getOddTitleList("medium", "dynamic");
      case "titleList_short":
        return this.getOddTitleList("short", "dynamic");
      case "titleList_noOdds_long":
        return this.getOddTitleList("long", "never");
      case "titleList_noOdds_medium":
        return this.getOddTitleList("medium", "never");
      case "titleList_noOdds_short":
        return this.getOddTitleList("short", "never");
      case "explainedMoneyline_short":
        return this.getExplainedOdds("short", false);
      case "explainedMoneyline_medium":
        return this.getExplainedOdds("medium", false);
      case "explainedMoneyline_long":
        return this.getExplainedOdds("long", false);
      case "explainedOdds_short":
        return this.getExplainedOdds("short", true);
      case "explainedOdds_medium":
        return this.getExplainedOdds("medium", true);
      case "explainedOdds_long":
        return this.getExplainedOdds("long", true);
      case "oddGroupName_long":
        return this.getOddGroupName("long");
      case "oddGroupName_medium":
        return this.getOddGroupName("medium");
      case "oddGroupName_short":
        return this.getOddGroupName("short");
      case "oddGroupName_unique_long":
        return this.getOddGroupName("long", true);
      case "oddGroupName_unique_medium":
        return this.getOddGroupName("medium", true);
      case "oddGroupName_unique_short":
        return this.getOddGroupName("short", true);
      case "oddGroupName_winner_long":
        return this.getOddGroupName("long", false, "Winner");
      case "oddGroupName_winner_medium":
        return this.getOddGroupName("medium", false, "Winner");
      case "oddGroupName_winner_short":
        return this.getOddGroupName("short", false, "Winner");
      case "marketName":
        return this.getOddGroupName("long", false, "Moneyline", true, false);
      case "marketNameVariant":
        return this.getOddGroupName("long", false, "Moneyline", true, true);
      case "oddEventContext_long":
        return this.getOddEventContext("long");
      case "oddEventContext_medium":
        return this.getOddEventContext("medium");
      case "oddEventContext_short":
        return this.getOddEventContext("short");
      case "oddDetails":
        return this.getOddDetails();
      case "eventOddDetails":
        return this.getEventOddDetails();
      case "vsOrAt_prefix":
        return this.getVsOrAt(true);
      case "vsOrAt_suffix":
        return this.getVsOrAt(false);
      case "resultStatName_long":
        return this.getResultStatName("long");
      case "resultStatName_medium":
        return this.getResultStatName("medium");
      case "resultStatName_short":
        return this.getResultStatName("short");
      case "scoreList_long":
        return this.getScoreList("long");
      case "scoreList_medium":
        return this.getScoreList("medium");
      case "scoreList_short":
        return this.getScoreList("short");
      case "statUnit_long":
        return this.getStatUnitDisplay("long");
      case "statUnit_medium":
        return this.getStatUnitDisplay("medium");
      case "statUnit_short":
        return this.getStatUnitDisplay("short");
      default:
        return "";
    }
  }
}
exports.OddsDisplays = OddsDisplays;
