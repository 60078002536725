import { formatPotentialPlayerID, getPlayerName, getPlayerSortName } from "@wagerlab/utils/aggregator/lookups";
import { getPlayerDisplay } from "@wagerlab/utils/displays";

export const getUnknownEntityData = (unknownEntity) => unknownEntity?.playerData;

export const formatPotentialID = (unknownEntity, teamID) => formatPotentialPlayerID(getPlayerDisplay("name_full", unknownEntity?.playerData), teamID);

export const getEntityTypeDisplayName = (capital = false, plural = false) => `${capital ? "P" : "p"}layer${plural ? "s" : ""}`;

export const getSourceID = (unknownEntity) => unknownEntity?.sourceID;

export const getSourceEntityID = (unknownEntity) => unknownEntity?.sourcePlayerID;

export const getName = (unknownEntity) => getPlayerName(unknownEntity?.playerData);

export const getSortName = (unknownEntity) => getPlayerSortName(unknownEntity?.playerData);

export const getEntityNameLookupsOrig = (unknownEntity) => unknownEntity?.playerNameLookupsOrig;

export const getEntityNameLookups = (unknownEntity) => unknownEntity?.playerNameLookups;
