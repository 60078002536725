const { LEAGUE_CONFIG } = require("@wagerlab/utils/sports/leagueConfig");

exports.refreshAndSyncMetadata = (event, eventBookOdds) => {
  event = applyEventDefaults(event);
  eventBookOdds = syncEventBookOddsMetadata(event, eventBookOdds);
  return { event, eventBookOdds };
};

const applyEventDefaults = (eventData) => {
  eventData.sportID = eventData.sportID || LEAGUE_CONFIG?.[eventData?.leagueID]?.sportID || null;
  eventData.type = eventData.type || "match";
  eventData.manual = eventData.manual ?? false;

  return eventData;
};

const syncEventBookOddsMetadata = (event, eventBookOdds) => {
  const { eventID, sportID, leagueID, type, manual } = event || {};

  eventBookOdds = eventBookOdds || {};
  eventBookOdds.eventID = eventID;
  eventBookOdds.type = type;
  eventBookOdds.sportID = sportID;
  eventBookOdds.leagueID = leagueID;
  eventBookOdds.manual = manual;

  return eventBookOdds;
};
