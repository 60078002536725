import React, { useMemo } from "react";
import { TableCell, TextField } from "@mui/material";
import { getAllLookupEntityIDs, getNormalizedString } from "@wagerlab/utils/aggregator/lookups";
import Autocomplete from "@mui/material/Autocomplete";

export const IDCell = ({ unknownEntity, lookupKey, parentLookupsObj, parentID, lookupOverrides, setLookupOverrides, lookupErrors, aiSuggestions, getters }) => {
  const handleInput = (lookupKey, newValue) => {
    if (!lookupKey) return;
    setLookupOverrides({ ...(lookupOverrides || {}), [lookupKey]: newValue || "" });
  };

  const { allEntityIDs, allEntityIDInfo } = useMemo(() => {
    const { [lookupKey]: inputValue, ...otherLookupOverrides } = lookupOverrides || {};
    const allEntityIDInfoMap = {};
    const aiEntityIDList = [];
    (aiSuggestions?.[lookupKey] || []).forEach((suggestion) => {
      if (!suggestion?.id) return;
      const manualScore = getManualRecommendation(unknownEntity, getters, suggestion.id);
      const isManualRecommended = manualScore > 0;
      allEntityIDInfoMap[suggestion.id] = { isAiRecommended: true, aiScore: suggestion.score || 0, isManualRecommended, manualScore, isNewByUser: false, isNewByAi: true };
      aiEntityIDList.push(suggestion.id);
    });
    const manualRecommendedEntityIDList = [];
    const otherEntityIDList = [];
    Object.values(otherLookupOverrides || {}).forEach((id) => {
      if (!id) return;
      if (allEntityIDInfoMap[id]) {
        allEntityIDInfoMap[id].isNewByAi = false;
        allEntityIDInfoMap[id].isNewByUser = true;
      } else {
        const manualScore = getManualRecommendation(unknownEntity, getters, id);
        const isManualRecommended = manualScore > 0;
        allEntityIDInfoMap[id] = { isAiRecommended: false, aiScore: 0, isManualRecommended, manualScore, isNewByUser: true, isNewByAi: false };
        if (isManualRecommended) manualRecommendedEntityIDList.push(id);
        else otherEntityIDList.push(id);
      }
    });
    (getAllLookupEntityIDs(parentLookupsObj) || []).forEach((id) => {
      if (!id) return;
      if (allEntityIDInfoMap[id]) {
        allEntityIDInfoMap[id].isNewByAi = false;
        allEntityIDInfoMap[id].isNewByUser = false;
      } else {
        const manualScore = getManualRecommendation(unknownEntity, getters, id);
        const isManualRecommended = manualScore > 0;
        allEntityIDInfoMap[id] = { isAiRecommended: false, aiScore: 0, isManualRecommended, manualScore, isNewByUser: false, isNewByAi: false };
        if (isManualRecommended) manualRecommendedEntityIDList.push(id);
        else otherEntityIDList.push(id);
      }
    });

    const sortedManualRecEntityIDList = manualRecommendedEntityIDList.sort((a, b) => {
      const aManualScore = allEntityIDInfoMap[a].manualScore || 0;
      const bManualScore = allEntityIDInfoMap[b].manualScore || 0;
      if (aManualScore === bManualScore) return a.localeCompare(b);
      return bManualScore - aManualScore;
    });
    const sortedOtherEntityIDList = otherEntityIDList.sort();

    return {
      allEntityIDs: [...aiEntityIDList, ...sortedManualRecEntityIDList, ...sortedOtherEntityIDList],
      allEntityIDInfo: allEntityIDInfoMap,
    };
  }, [parentLookupsObj, lookupOverrides, aiSuggestions, lookupKey, unknownEntity]);

  return (
    <TableCell>
      <Autocomplete
        freeSolo
        options={allEntityIDs}
        value={lookupOverrides[lookupKey] || ""}
        onInputChange={(event, newValue) => handleInput(lookupKey, newValue)}
        sx={{ width: "100%" }}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ minWidth: "300px", height: "100px" }}
            error={!!lookupErrors?.[lookupKey]?.error}
            helperText={<InputHelperText lookupErrors={lookupErrors} lookupKey={lookupKey} unknownEntity={unknownEntity} handleInput={handleInput} parentID={parentID} getters={getters} />}
          />
        )}
        renderOption={(autoCompleteProps, option, state) => <AutoCompleteIDOption autoCompleteProps={autoCompleteProps} option={option} optionInfo={allEntityIDInfo[option]} />}
      />
    </TableCell>
  );
};

const InputHelperText = ({ lookupErrors, lookupKey, unknownEntity, handleInput, parentID, getters }) => {
  if (lookupErrors?.[lookupKey]?.helperText) return <>{lookupErrors?.[lookupKey]?.helperText}</>;

  const suggestedID = getters.formatPotentialID(unknownEntity, parentID);
  if (!suggestedID) return null;
  return (
    <>
      {`Suggested New ${getters.getEntityTypeDisplayName(true)}ID: `}
      <span onClick={() => handleInput(lookupKey, suggestedID)} style={{ textDecoration: "underline", cursor: "pointer" }}>
        {suggestedID}
      </span>
    </>
  );
};

const AutoCompleteIDOption = ({ autoCompleteProps, option, optionInfo }) => {
  const { isAiRecommended, aiScore, isManualRecommended, manualScore, isNewByUser, isNewByAi } = optionInfo || {};

  let color = "#FFF";

  if (isAiRecommended) color = aiScore > 0.86 ? "#FFC107" : aiScore > 0.8 ? "#FFF150" : "#FF7F7F";
  else if (isManualRecommended) color = manualScore > 100 ? "#AED581" : manualScore >= 4 ? "#C5E1A5" : manualScore >= 3 ? "#D5E8D5" : manualScore > 0 ? "#E5F0E5" : "#FF7F7F";

  let notice = isAiRecommended && isNewByAi ? "NEW" : "";
  if (notice) notice = ` (${notice})`;

  return (
    <li {...autoCompleteProps} style={{ backgroundColor: color }}>
      {option && notice ? `${option} (${notice})` : option || ""}
    </li>
  );
};

const getManualRecommendation = (unknownEntity, getters, compareEntityID, parentID) => {
  const unknownEntityName = getters.getName(unknownEntity);
  const unknownEntityCompareWords_A = getNormalizedString(unknownEntityName)
    .toUpperCase()
    .replace(/[^A-Z0-9\s]+/g, "")
    .replace(/\s+/g, " ")
    .trim()
    .split(" ");
  const unknownEntityCompareWords_B = getNormalizedString(unknownEntityName)
    .toUpperCase()
    .replace(/[^A-Z0-9]+/g, " ")
    .trim()
    .split(" ");
  const knownEntityCompareID = parentID && compareEntityID.endsWith(`_${parentID}`) ? compareEntityID.replace(`_${parentID}`, "") : compareEntityID;

  const score_A = calculateSingleManualRecScore(knownEntityCompareID, unknownEntityCompareWords_A);
  const score_B = calculateSingleManualRecScore(knownEntityCompareID, unknownEntityCompareWords_B);
  return Math.max(score_A, score_B);
};

const calculateSingleManualRecScore = (compareToID, compareWords) => {
  if (!compareToID || !compareWords.length) return 0;
  const score = compareWords.reduce((total, word) => {
    if (!word?.length) return total;
    if (word.length > 1 && (compareToID.startsWith(word) || compareToID.includes(`_${word}`))) return total + 3;
    if (word.length > 2) {
      const first3Chars = word.slice(0, 3);
      if (compareToID.startsWith(first3Chars) || compareToID.includes(`_${first3Chars}`)) return total + 1;
    }
    return total;
  }, 0);
  if (score === compareWords.length * 3) return 1000;
  return score;
};
