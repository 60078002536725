const { parseNumber, parseInteger } = require("@wagerlab/utils/data/numbers");
const { isNumber, isString } = require("@wagerlab/utils/data/types");
const _ = require("lodash");

const asSpreadFloat = (inputSpread) => {
  if (!isNumber(inputSpread) && !inputSpread) return null;
  const spreadFloat = parseFloat(inputSpread);
  if (!isNumber(spreadFloat) || (spreadFloat * 10) % 5 !== 0) return null;
  return spreadFloat;
};
exports.asSpreadFloat = asSpreadFloat;

const asSpreadString = (inputSpread) => {
  const spreadFloat = asSpreadFloat(inputSpread);
  if (spreadFloat == null) return null;
  const prefix = spreadFloat < 0 ? "" : "+";
  const numberOutput = spreadFloat % 1 === 0 ? spreadFloat.toFixed(0) : spreadFloat.toFixed(1);
  return `${prefix}${numberOutput}`;
};
exports.asSpreadString = asSpreadString;

const asMoneylineInt = (inputMoneyline) => {
  if (!isNumber(inputMoneyline) && !inputMoneyline) return null;
  const withoutPlus = isString(inputMoneyline) && inputMoneyline?.startsWith?.("+") ? inputMoneyline?.slice(1) : inputMoneyline;
  const mlInt = parseInteger(withoutPlus);
  if (!mlInt || (mlInt > -100 && mlInt < 100)) return null;
  if (mlInt == -100) return 100;
  return mlInt;
};
exports.asMoneylineInt = asMoneylineInt;

const asMoneylineString = (inputMoneyline) => {
  let mlInt = asMoneylineInt(inputMoneyline);
  if (!mlInt) return null;
  const prefix = mlInt < 0 ? "" : "+";
  return `${prefix}${mlInt}`;
};
exports.asMoneylineString = asMoneylineString;

const asOverUnderFloat = (inputOverUnder) => {
  if (!isNumber(inputOverUnder) && !inputOverUnder) return null;
  const ouFloat = parseFloat(inputOverUnder);
  if (!ouFloat || !isNumber(ouFloat) || ouFloat <= 0 || (ouFloat * 10) % 5 !== 0) return null;
  return ouFloat;
};
exports.asOverUnderFloat = asOverUnderFloat;

const asOverUnderString = (inputOverUnder) => {
  const ouFloat = asOverUnderFloat(inputOverUnder);
  if (!ouFloat) return null;
  const numberOutput = ouFloat % 1 === 0 ? ouFloat.toFixed(0) : ouFloat.toFixed(1);
  return `${numberOutput}`;
};
exports.asOverUnderString = asOverUnderString;

const calculateViglessMoneyline = (inputHomeMoneyline, inputAwayMoneyline) => {
  const awayInt = asMoneylineInt(inputAwayMoneyline);
  const homeInt = asMoneylineInt(inputHomeMoneyline);
  if (!homeInt || !awayInt) return { home: null, away: null };

  const homeProb = homeInt < 0 ? homeInt / (homeInt - 100) : 100 / (homeInt + 100);
  const awayProb = awayInt < 0 ? awayInt / (awayInt - 100) : 100 / (awayInt + 100);
  const combinedProb = homeProb + awayProb;
  const homeFairProb = homeProb / combinedProb;
  const awayFairProb = awayProb / combinedProb;
  const homeFairML = homeFairProb < 0.5 ? 100 / homeFairProb - 100 : (-100 * homeFairProb) / (1 - homeFairProb);
  const awayFairML = awayFairProb < 0.5 ? 100 / awayFairProb - 100 : (-100 * awayFairProb) / (1 - awayFairProb);
  const homeViglessMLInt = Math.round(homeFairML);
  let awayViglessMLInt = Math.round(awayFairML);
  if (awayViglessMLInt !== homeViglessMLInt * -1) {
    const awayAdjustHigh = homeViglessMLInt * -1 + 1;
    const awayAdjustLow = homeViglessMLInt * -1 - 1;
    if (awayViglessMLInt === awayAdjustHigh || awayViglessMLInt === awayAdjustLow) awayViglessMLInt = homeViglessMLInt * -1;
  }

  const homeViglessMlString = asMoneylineString(homeViglessMLInt);
  const awayViglessMlString = asMoneylineString(awayViglessMLInt);

  return { home: homeViglessMlString, away: awayViglessMlString };
};
exports.calculateViglessMoneyline = calculateViglessMoneyline;

const roundMoneyline = (inputMoneyline, multipleOf = 5, maxDistanceToEven = 9) => {
  const moneylineInt = asMoneylineInt(inputMoneyline);
  if (!moneylineInt) return null;
  const evenMin = -100 - maxDistanceToEven;
  const evenMax = 100 + maxDistanceToEven;
  const roundToEven = moneylineInt >= evenMin && moneylineInt <= evenMax;
  const willRoundedUnevenly = moneylineInt > 0 && Math.abs(moneylineInt / multipleOf) % 1 === 0.5;
  let roundedMoneylineInt;
  if (roundToEven) {
    roundedMoneylineInt = 100;
  } else if (willRoundedUnevenly) {
    roundedMoneylineInt = Math.floor(moneylineInt / multipleOf) * multipleOf;
  } else {
    roundedMoneylineInt = Math.round(moneylineInt / multipleOf) * multipleOf;
  }
  return asMoneylineString(roundedMoneylineInt);
};
exports.roundMoneyline = roundMoneyline;

const roundLine = (inputLine) => {
  const lineFloat = parseFloat(inputLine);
  if (!isNumber(lineFloat)) return null;
  return Math.round(lineFloat * 2) / 2;
};
exports.roundLine = roundLine;

const roundSpread = (inputSpread) => asSpreadFloat(roundLine(inputSpread));
exports.roundSpread = roundSpread;

const roundOverUnder = (inputOverUnder) => asOverUnderFloat(roundLine(inputOverUnder));
exports.roundOverUnder = roundOverUnder;

const oddsToImpliedProbability = (inputOdds) => {
  const americanOddsInt = asMoneylineInt(inputOdds);
  if (!americanOddsInt) return 0;
  if (americanOddsInt > 0) return 100 / (americanOddsInt + 100);
  const americanOddsAbs = americanOddsInt * -1;
  return americanOddsAbs / (americanOddsAbs + 100);
};
exports.oddsToImpliedProbability = oddsToImpliedProbability;

const impliedProbabilityToOdds = (inputProbability) => {
  let impliedProbabilityFloat = inputProbability && parseFloat(inputProbability);
  if (!impliedProbabilityFloat || !isNumber(impliedProbabilityFloat) || impliedProbabilityFloat <= 0 || impliedProbabilityFloat >= 1) return 0;
  const roundedOdds = impliedProbabilityFloat >= 0.5 ? Math.round((100 * impliedProbabilityFloat) / (impliedProbabilityFloat - 1)) : Math.round(100 / impliedProbabilityFloat - 100);
  return asMoneylineInt(roundedOdds);
};
exports.impliedProbabilityToOdds = impliedProbabilityToOdds;

const getInverseOdds = (inputOdds, juiceProbability = null) => {
  const oddsInt = asMoneylineInt(inputOdds);
  if (!oddsInt) return null;
  let inverseOdds = -1 * oddsInt;
  if (!juiceProbability || !isNumber(juiceProbability) || juiceProbability < 0) return asMoneylineInt(inverseOdds);
  const opponentProbabilityBase = oddsToImpliedProbability(inverseOdds);
  if (opponentProbabilityBase) {
    const opponentProbability = opponentProbabilityBase * (1 + juiceProbability);
    inverseOdds = impliedProbabilityToOdds(opponentProbability);
  }
  return asMoneylineInt(inverseOdds);
};
exports.getInverseOdds = getInverseOdds;

const getInverseSpread = (inputSpread) => {
  const spreadFloat = asSpreadFloat(inputSpread);
  if (!isNumber(spreadFloat)) return null;
  return -1 * spreadFloat;
};
exports.getInverseSpread = getInverseSpread;

const threeWayToTwoWayOdds = ({ home, away, draw }) => {
  // Step 1: Calculate Implied Probabilities
  const homeImpProb = oddsToImpliedProbability(home);
  const awayImpProb = oddsToImpliedProbability(away);
  const drawImpProb = oddsToImpliedProbability(draw);

  // Validation
  if (!homeImpProb || !awayImpProb) return null;
  if (!drawImpProb) return { home, away };
  const totalMarketImpProb = homeImpProb + awayImpProb + drawImpProb; // This can be greater than 1 due to juice/vig

  // Step 2: Redistribute Draw Probability proportional to the home and away implied probabilities
  // In other words the draw probability is redistributed to home and away based on home+away's probabilities relative to eachother
  const totalWithoutDrawProb = homeImpProb + awayImpProb;
  const homeRelativeProb = homeImpProb / totalWithoutDrawProb;
  const awayRelativeProb = awayImpProb / totalWithoutDrawProb;
  const adjustedHomeImpProb = homeImpProb + drawImpProb * homeRelativeProb;
  const adjustedAwayImpProb = awayImpProb + drawImpProb * awayRelativeProb;

  // Step 3: Convert Adjusted Implied Probabilities Back to Odds
  // Vig is re-included here. It's important to divide by the totalMarketImpProb to achieve this
  const home2WayOdds = impliedProbabilityToOdds(adjustedHomeImpProb / totalMarketImpProb);
  const away2WayOdds = impliedProbabilityToOdds(adjustedAwayImpProb / totalMarketImpProb);

  if (!home2WayOdds || !away2WayOdds) return null;
  return { home: home2WayOdds, away: away2WayOdds };
};
exports.threeWayToTwoWayOdds = threeWayToTwoWayOdds;

exports.asDecimalOdds = (americanOdds, shouldRound = false) => {
  // Converts American-style odds (can be string or integer) to Decimal-style odds (float)    ex. -200 to 1.5
  let americanOddsInt = asMoneylineInt(americanOdds);
  if (!americanOddsInt) return null;
  const decimalOddsFloat = (americanOddsInt > 0 ? americanOddsInt / 100 : -100 / americanOddsInt) + 1;
  if (!shouldRound) return decimalOddsFloat;
  return parseFloat(decimalOddsFloat.toFixed(2));
};

const asAmericanOdds = (decimalOdds) => {
  // Converts Decimal-style odds (float) to American-style odds (integer)    ex. 1.5 to -200
  const decimalOddsFloat = decimalOdds && parseFloat(decimalOdds);
  if (!isNumber(decimalOddsFloat) || decimalOddsFloat <= 1) return null;
  const americanOddsInt = decimalOddsFloat >= 2 ? Math.round((decimalOddsFloat - 1) * 100) : Math.round(-100 / (decimalOddsFloat - 1));
  return asMoneylineInt(americanOddsInt);
};
exports.asAmericanOdds = asAmericanOdds;

const parseAmericanOdds = (inputAmericanOdds) => {
  return asMoneylineInt(inputAmericanOdds);
};

const parseDecimalOdds = (inputDecimalOdds) => {
  const inputDecimalOddsFloat = parseNumber(inputDecimalOdds);
  if (!inputDecimalOddsFloat || inputDecimalOddsFloat <= 1) return null;
  return inputDecimalOddsFloat;
};

const parseProbabilityOdds = (inputProbability) => {
  const inputProbabilityFloat = parseNumber(inputProbability);
  if (!inputProbabilityFloat || inputProbabilityFloat <= 0 || inputProbabilityFloat >= 1) return null;
  return inputProbabilityFloat;
};

const getCommonInputOddsFormat = (inputOddsExamples) => {
  if (!inputOddsExamples?.length) return null;
  let foundDefinititveAmerican = false;
  let foundDefinitiveDecimal = false;
  let foundDefinitiveProbability = false;
  (inputOddsExamples || []).forEach((inputOdds) => {
    let american = parseAmericanOdds(inputOdds);
    let decimal = parseDecimalOdds(inputOdds);
    let probability = parseProbabilityOdds(inputOdds);
    if (american && !decimal && !probability) foundDefinititveAmerican = true;
    else if (decimal && !american && !probability) foundDefinitiveDecimal = true;
    else if (probability && !american && !decimal) foundDefinitiveProbability = true;
  });
  if (foundDefinititveAmerican && !foundDefinitiveDecimal && !foundDefinitiveProbability) return "american";
  if (foundDefinitiveDecimal && !foundDefinititveAmerican && !foundDefinitiveProbability) return "decimal";
  if (foundDefinitiveProbability && !foundDefinititveAmerican && !foundDefinitiveDecimal) return "probability";
  return null;
};

// Converts any format of input odds into an american/moneyline integer
// Returns null if it can't be converted. Only input formats that are explicitly allowed will be considered
// If it can be interpreted as both american and decimal odds, it can try to match the format of others found in the otherInputExamples array. Oterwise returns null.
const parseOdds = (inputOdds, validInputFormats = { american: true, decimal: true, probability: true }, otherInputExamples = []) => {
  const { american: canBeAmerican, decimal: canBeDecimal, probability: canBeProbability } = validInputFormats || {};
  let american = canBeAmerican ? parseAmericanOdds(inputOdds) : null;
  let decimal = canBeDecimal ? parseDecimalOdds(inputOdds) : null;
  // Edge Case: If it's >= 100 then it could be decimal odds or american odds. though it's more likely American odds
  if (american && decimal) {
    // We handle this by allowing for a list of other input examples to be passed in. If we find that they're all either indeterminite or a single format, we use that format
    // To force interpretation as american odds in this case, pass in [-100] (must be negative). To force interpretation as decimal odds, pass in [1.5] (must be positive)
    const otherInputExamplesCommonFormat = getCommonInputOddsFormat(otherInputExamples);
    if (otherInputExamplesCommonFormat === "american") decimal = null;
    else if (otherInputExamplesCommonFormat === "decimal") american = null;
    if (american && decimal) return null;
  }
  if (american) return american;
  if (decimal) return asAmericanOdds(decimal);

  let probability = canBeProbability ? parseProbabilityOdds(inputOdds) : null;
  if (probability) return impliedProbabilityToOdds(inputOdds);
  return null;
};
exports.parseOdds = parseOdds;
