class PlayerDisplays {
  constructor(playerData) {
    this.player = playerData;
  }

  getNameDisplay(length) {
    const firstName = this.player?.firstName || "";
    const lastName = this.player?.lastName || "";
    const name = this.player?.name;

    if (length === "first") return firstName;
    if (length === "last") return lastName;
    if (length === "full") return name ? name : `${firstName} ${lastName}`;
  }

  getDisplayString(displayType) {
    switch (displayType) {
      case "name_first":
        return this.getNameDisplay("first");
      case "name_last":
        return this.getNameDisplay("last");
      case "name_full":
        return this.getNameDisplay("full");

      default:
        return "";
    }
  }
}
exports.PlayerDisplays = PlayerDisplays;
