const { selectVariant } = require("@wagerlab/utils/displays/displayUtils");

class TeamDisplays {
  constructor(teamData) {
    this.team = teamData;
  }

  getNameDisplay(length) {
    const { short, medium, long } = this.team?.names || {};
    return selectVariant(length, { long, medium, short });
  }

  getDisplayString(displayType) {
    switch (displayType) {
      case "name_short":
        return this.getNameDisplay("short");
      case "name_medium":
        return this.getNameDisplay("medium");
      case "name_long":
        return this.getNameDisplay("long");

      default:
        return "";
    }
  }
}
exports.TeamDisplays = TeamDisplays;
