import React from "react";
import { TableCell } from "@mui/material";
import _ from "lodash";
import { ellipsisStyle } from "@wagerlab/admin/src/components/lookups/shared/add/styles";

export const LookupsCell = ({ lookupsList }) => {
  return (
    <TableCell sx={{ verticalAlign: "top" }}>
      {(lookupsList || []).map((lookupStr, index) =>
        lookupStr ? (
          <div key={index} style={ellipsisStyle} title={lookupStr}>
            {lookupStr}
          </div>
        ) : null
      )}
    </TableCell>
  );
};
