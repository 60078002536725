import { Suspense } from "react";
import { Loading } from "@wagerlab/admin/src/pages/Loading";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";
import { AppRouter } from "@wagerlab/admin/src/AppRouter";
import moment from "moment-timezone";

const queryClient = new QueryClient();

export const App = () => {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
          <Suspense fallback={<Loading />}>
            <AppRouter />
          </Suspense>
        </LocalizationProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};
