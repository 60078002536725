import React, { useState } from "react";
import { Collapse, IconButton, TableCell, TableRow } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { EventTableRowSummary } from "@wagerlab/admin/src/components/events/EventTableRowSummary";
import { getEventDisplay } from "@wagerlab/utils/displays";
import moment from "moment-timezone";

export const EventTableRow = ({ event }) => {
  const [open, setOpen] = useState(false);

  const eventTitle = getEventDisplay("title_long", event);
  const timezoneFormattedDate = moment(event.status?.startsAt).tz(moment.tz.guess()).format("dddd MMMM D, YYYY [@] h:mma zz");

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {event?.eventID || "???"}
        </TableCell>
        <TableCell align="right">{eventTitle}</TableCell>
        <TableCell align="right">{event.sportID}</TableCell>
        <TableCell align="right">{event.leagueID}</TableCell>
        <TableCell align="right">{timezoneFormattedDate}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <EventTableRowSummary event={event} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
