import React, { useEffect, useState } from "react";
import { Typography, Container, Select, MenuItem, Box, Tabs, Tab } from "@mui/material";
import { LEAGUE_CONFIG } from "@wagerlab/utils/sports/leagueConfig";
import { ManageExistingTeamIds } from "@wagerlab/admin/src/components/lookups/teams/manage/ManageExistingTeamIds";
import { AddNewTeamIds } from "@wagerlab/admin/src/components/lookups/teams/add/AddNewTeamIds";
import { spaceship } from "@wagerlab/utils/data/compare";

export const LinkTeams = () => {
  const [selectedLeagueID, setSelectedLeagueID] = useState("");
  const [activeTab, setActiveTab] = useState(0);

  const handleLeagueChange = (event) => {
    setSelectedLeagueID(event.target.value);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Container maxWidth="xl" sx={{ marginTop: 16, marginBottom: 8 }}>
      <Typography variant="h4">Link Entities</Typography>

      <Typography>Select League</Typography>
      <Select value={selectedLeagueID} onChange={handleLeagueChange} autoWidth={true}>
        {Object.values(LEAGUE_CONFIG)
          .sort((league1, league2) => spaceship(league1.name, league2.name))
          .map((league, index) => (
            <MenuItem key={index} value={league.leagueID}>
              {league.name}
            </MenuItem>
          ))}
      </Select>

      <Box sx={{ marginTop: 4, marginBottom: 4 }}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Manage Existing Team IDs" />
          <Tab label="Add New Team ID Links" />
        </Tabs>

        {activeTab === 0 && <ManageExistingTeamIds leagueID={selectedLeagueID} />}
        {activeTab === 1 && <AddNewTeamIds leagueID={selectedLeagueID} />}
      </Box>
    </Container>
  );
};
