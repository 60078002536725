import { getCollectionDoc, updateCollectionDoc } from "@wagerlab/utils/database/firestore";

export const getTeam = async (teamID) => {
  return await getCollectionDoc("Teams", teamID);
};

export const updateTeamBatch = (teamID, updates) => {
  return { method: "update", collectionName: "Teams", collectionID: teamID, payload: updates };
};

export const setAggregatorQueryPlayerLookups = async (leagueID) => {
  try {
    return await updateCollectionDoc("Config", "aggregator", { "runner.queryPlayerLookups": leagueID });
  } catch (e) {
    console.error("Error setting aggregator config to query team player lookups:", e);
    return false;
  }
};

export const updateAggregatorPlayerLookupsBatch = (teamID, ranAt, data) => {
  return {
    method: "update",
    collectionName: "AggregatorPlayerLookups",
    collectionID: teamID,
    payload: {
      ranAt,
      manualUpdateAt: new Date(),
      unknownTeams: data,
    },
  };
};

export const getUnknownPlayerLookups = async (teamID) => {
  try {
    return (await getCollectionDoc("AggregatorPlayerLookups", teamID)) || {};
  } catch (e) {
    console.error("Error getting aggregator unknown lookups:", e);
    return false;
  }
};
