import React from "react";
import { IconButton, MenuItem, TextField, Tooltip } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { isString } from "@wagerlab/utils/data/types";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const SelectMenu = ({ name, options, disabled, tooltip, onChange, ...otherProps }) => {
  const { isSubmitting } = useFormikContext();
  const [field, { touched, error }, { setValue }] = useField(name);

  const isDisabled = !!(isSubmitting || disabled);
  const hasErrors = !!(touched && error && isString(error));

  const inputProps = tooltip
    ? {
        endAdornment: (
          <Tooltip title={tooltip} placement="top">
            <IconButton color="primary" aria-label="help icon" component="span" sx={{ mr: 2 }}>
              <InfoOutlinedIcon sx={{ color: "lightblue" }} />
            </IconButton>
          </Tooltip>
        ),
      }
    : null;

  return (
    <TextField
      {...field}
      {...otherProps}
      disabled={isDisabled}
      select={true}
      variant={"outlined"}
      fullWidth={true}
      onChange={(e) => {
        setValue(e?.target?.value);
        onChange?.(e?.target?.value);
      }}
      error={hasErrors}
      InputProps={inputProps}
      helperText={hasErrors ? error : null}
    >
      {Object.entries(options || {}).map(([value, display], index) => {
        return (
          <MenuItem key={index} value={value}>
            {display}
          </MenuItem>
        );
      })}
    </TextField>
  );
};
