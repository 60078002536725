const { EventDisplays } = require("@wagerlab/utils/displays/EventDisplays");
const { UserDisplays } = require("@wagerlab/utils/displays/UserDisplays");
const { BET_TYPE_CONFIG } = require("@wagerlab/utils/odds/betTypes");
const { calculateToWin } = require("@wagerlab/utils/wagers/amounts");
const { DEFAULT_WAGER_UNIT } = require("@wagerlab/utils/constants");
const { SUCCESS_COLOR, ERROR_COLOR, WARNING_COLOR, MIDDLE_DARK_CONTENT_COLOR, PRIMARY_COLOR_DARK } = require("@wagerlab/utils/styles/colors");
const { formatTime } = require("@wagerlab/utils/displays/displayUtils");
const { wagerExpired, wagerPendingResponse } = require("@wagerlab/utils/wagers/user-wager");
const { ODDS_APPLIED_OPTIONS, getOddsAppliedConfig } = require("@wagerlab/utils/wagers/options");
const { OddsDisplays } = require("@wagerlab/utils/displays/OddsDisplays");
const { isNumber } = require("@wagerlab/utils/data/types");

const HOUSE_ID = "house";
const TICKETS_ID = "tickets";
const HOUSE_USERNAME = "The House";
const TICKETS_USERNAME = "tickets";

class WagerDisplays {
  constructor(wagerData) {
    this.wager = wagerData;
    this.eventDisplays = new EventDisplays(wagerData?.event);
    this.proposerDisplays = new UserDisplays(wagerData?.proposer);
    this.recipientDisplays = new UserDisplays(wagerData?.recipient);
  }

  getOddData(role) {
    if (role !== "proposer" && role !== "recipient") return null;
    return this.wager?.odds?.[role] || null;
  }

  getSideID(role) {
    return this.getOddData(role)?.sideID || "";
  }

  getBetTypeID(role) {
    return this.getOddData(role)?.betTypeID || "";
  }

  getBetTypeConfig(role) {
    const betTypeID = this.getBetTypeID(role);
    return BET_TYPE_CONFIG[betTypeID] || null;
  }

  getSideIDConfig(role) {
    const sideID = this.getSideID(role);
    const betTypeConfig = this.getBetTypeConfig(role);
    return betTypeConfig?.sideIDs?.[sideID] || null;
  }

  // LAL   Lakers   Los Angeles Lakers
  // Republican
  // Over     Yes    Even
  getSideDisplay(role, length) {
    const sideID = this.getSideID(role);
    const sideIDConfig = this.getSideIDConfig(role);
    if (!sideID || !sideIDConfig) return "";
    if (sideID === "home" || sideID === "away") return this.eventDisplays.getTeamDisplay(sideID, length);
    if (sideID === "side1" || sideID === "side2") return this.getPropSideDisplay(role);
    return sideIDConfig?.display || "";
  }

  getPropTitle() {
    const propStatID = this.wager?.odds?.proposer?.statID || this.wager?.odds?.recipient?.statID || "";
    return this.wager?.event?.props?.[propStatID]?.propTitle || "";
  }

  getPropSideDisplay(role) {
    const sideID = this.getSideID(role);
    const propStatID = this.wager?.odds?.proposer?.statID || this.wager?.odds?.recipient?.statID || "";
    return this.wager?.event?.props?.[propStatID]?.sides?.[sideID]?.sideDisplay || "";
  }

  getContext(role, length) {
    const betTypeID = this.getBetTypeID(role);
    if (betTypeID === "prop") return this.getPropTitle();
    const sideID = this.getSideID(role);
    if (sideID === "home" || sideID === "away") return this.eventDisplays.getOpponentContext(sideID, length);
    return this.eventDisplays.getTitle(length);
  }

  // For ou....  5   5 pts    5 points
  //  For sp.... +5   +5 pts   +5 points
  // For ml (or other non even)....  <blank>   +250   +250 Odds
  getOddModifierDisplay(role, length) {
    const oddData = this.getOddData(role);
    const oddsDisplays = new OddsDisplays(oddData, this.wager?.event);
    return oddsDisplays.getOddModifierDisplay(length);
  }

  // short: LAL +5.5     ML-> LAL                                 Republican
  // medium Lakers +5.5 pts (at DEN)        Over 100 pts (LAL at DEN)   Lakers +100 (at DEN)
  // long: Los Angeles Lakers +5.5 points (at DEN)    Republican (Winning Party of Next Presidential Election)
  getWagerTitle(role, length) {
    let title = this.getSideDisplay(role, length);
    if (!title) return "";
    const oddModifierDisplay = this.getOddModifierDisplay(role, length);
    if (oddModifierDisplay) title += ` ${oddModifierDisplay}`;
    const context = this.getContext(role, "short");
    if (context) title += ` (${context})`;
    //TODO add the period display info to the CONTEXT
    return title;
  }

  getWagerAmounts() {
    let proposerRisk = this.wager?.amount && parseInt(this.wager.amount);
    if (!proposerRisk || isNaN(proposerRisk)) return null;
    let recipientToWin = proposerRisk;
    let recipientRisk = this.wager.recipientAmount && parseInt(this.wager.recipientAmount);
    let proposerToWin = recipientRisk;
    if (!recipientRisk || isNaN(recipientRisk)) {
      proposerToWin = calculateToWin(this.wager.proposerMoneyline, proposerRisk);
      recipientRisk = proposerToWin;
    }

    const isUneven = proposerRisk !== proposerToWin || recipientRisk !== recipientToWin;
    return {
      isUneven,
      proposerRisk: proposerRisk || 0,
      proposerToWin: proposerToWin || 0,
      recipientRisk: recipientRisk || 0,
      recipientToWin: recipientToWin || 0,
      amount: proposerRisk || 0,
      unit: this.wager?.unit || DEFAULT_WAGER_UNIT,
    };
  }

  getWagerRiskingString() {
    return this.wager?.status?.isGraded ? "risked" : "risking";
  }

  getWagerRisk(role, units) {
    if (role !== "proposer" && role !== "recipient") return null;
    const amount = this.wager?.amount?.[`${role}Risk`];
    const unit = this?.wager?.amount?.unit;

    return `${amount} ${units && unit ? unit : ""}`;
  }

  getWagerToWin(role, units) {
    const odds = this.getOddData(role);
    const toWin = calculateToWin(odds.odds, this.getWagerRisk(role, false));
    const unit = this?.wager?.amount?.unit;
    return `${toWin} ${units && unit ? unit : ""}`;
  }

  getProposerType() {
    if (!this.wager || !this.wager.proposer.userID || !this.wager.proposer.username) {
      return "";
    }
    if (this.wager.proposer.userID === HOUSE_ID || this.wager.proposer.userID === TICKETS_ID) {
      return "computer";
    }
    return "username";
  }

  getRecipientType() {
    //TODO: get logic on recipient type for commented sections
    if (!this.wager) {
      return "";
    }
    // if (wager.recipientType === "username" || wager.recipientType === "computer" || wager.recipientType === "phone") {
    //   return wager.recipientType;
    // }
    if (this.wager.recipient.userID === HOUSE_ID || this.wager.recipient.userID === TICKETS_ID) {
      return "computer";
    }
    // if (wager.recipientPhone && wager.recipientPhoneDisplay) {
    //   // old wagers didn't have a recipientType but also didn't have a recipientPhone unless they were phone wagers
    //   return "phone";
    // }
    if (this.wager.recipient.username) {
      return "username";
    }
    return "";
  }

  getProposerName(format) {
    return this.getFormattedName(this.wager?.proposer?.firstName, this.wager?.proposer?.lastName, format);
  }

  getRecipientName(format) {
    return this.getFormattedName(this.wager?.recipient?.firstName, this.wager?.recipient?.lastName, format);
  }

  getFormattedName(wagerFirstName, wagerLastName, format) {
    if (!format || format === "none" || (!wagerFirstName && !wagerLastName)) {
      return "";
    }
    const firstNameTrimmed = (wagerFirstName || "").trim();
    const lastNameTrimmed = (wagerLastName || "").trim();

    const firstName = firstNameTrimmed.charAt(0).toUpperCase() + firstNameTrimmed.slice(1);
    const lastName = lastNameTrimmed.charAt(0).toUpperCase() + lastNameTrimmed.slice(1);
    const longFormat = firstName && lastName ? `${firstName} ${lastName}` : "";
    const mediumFormat = firstName && lastName ? `${firstName} ${lastName.charAt(0)}` : "";
    const shortFormat = firstName || lastName || "";

    if (format === "long") {
      return longFormat || mediumFormat || shortFormat;
    }
    if (format === "medium") {
      return mediumFormat || shortFormat;
    }
    if (format === "short") {
      return shortFormat;
    }
    return "";
  }

  getFormattedUsername(userType, username, phoneDisplay, nameDisplay, format) {
    if (!format || format === "none") {
      return "";
    }

    // special formats for phone wagers
    if (format === "phone" && phoneDisplay && userType === "phone") {
      return phoneDisplay;
    }
    if (format === "name" && nameDisplay && userType === "phone") {
      return nameDisplay;
    }
    if (format === "username/phone") {
      return username || phoneDisplay || "";
    }
    if (format === "username/name") {
      return username || nameDisplay || "";
    }
    return username || "";
  }

  getProposerUsername(format) {
    const proposerType = this.getProposerType();
    const nameDisplay = format === "name" || format === "username/name" ? this.getProposerName("short") : "";
    return this.getFormattedUsername(proposerType, this.wager?.proposer?.username, this.wager?.proposer?.phone, nameDisplay, format);
  }
  getRecipientUsername(format) {
    const recipientType = this.getRecipientType();
    const nameDisplay = format === "name" || format === "username/name" ? this.getRecipientName("short") : "";
    return this.getFormattedUsername(recipientType, this.wager?.recipient?.username, this.wager?.recipient?.phone, nameDisplay, format);
  }

  getDisplayName(who, nameFormat = "short", usernameFormat = "username", showComputers = true, defaultDisplay = "") {
    if (!who || !this.wager || (who !== "proposer" && who !== "recipient")) {
      return "";
    }
    const getProposer = who === "proposer";
    const userType = getProposer ? this.getProposerType() : this.getRecipientType();
    if (!showComputers && userType === "computer") {
      return "";
    }
    if (!userType) {
      return defaultDisplay || "";
    }
    if (userType === "computer") {
      const userID = getProposer ? this.wager.proposer.userID : this.wager.recipient.userID;
      // special sprmats for computer wagers
      if (userID === HOUSE_ID) {
        return "The House";
      }
      if (userID === TICKETS_ID) {
        return "Tickets";
      }
      return "";
    }
    // get rid of a weird case
    if (usernameFormat === "name" && nameFormat && nameFormat !== "none") {
      usernameFormat = "none";
    }

    const nameDisplay = getProposer ? this.getProposerName(nameFormat) : this.getRecipientName(nameFormat);
    const usernameDisplay = getProposer ? this.getProposerUsername(usernameFormat) : this.getRecipientUsername(usernameFormat);

    if (nameDisplay && usernameDisplay) {
      return usernameDisplay.charAt(0) === "(" ? `${nameDisplay} - ${usernameDisplay}` : `${nameDisplay} (${usernameDisplay})`;
    }
    return usernameDisplay || nameDisplay || defaultDisplay || "";
  }

  getWagerTeam(role) {
    const sideID = this.getSideID(role);
    return this?.wager?.event?.teams?.[sideID]?.names?.long;
  }

  getPointTypeString(role, type, mode) {
    if (role !== "proposer" && role !== "recipient") return null;

    const sport = this.wager?.event?.sportID;
    let numPoints;

    if (type == "ou") {
      numPoints = this.wager?.odds?.[role]?.overUnder;
    } else if (type == "sp") {
      numPoints = this.wager?.odds?.[role]?.spread;
    }

    const singleFormat = Math.abs(numPoints) <= 1 && Math.abs(numPoints) > 0;
    let pointTypeLong = singleFormat ? "point" : "points";
    let pointTypeShort = singleFormat ? "pt" : "pts";
    if (sport === "HOCKEY") {
      pointTypeLong = singleFormat ? "goal" : "goals";
      pointTypeShort = pointTypeLong;
    } else if (sport === "BASEBALL") {
      pointTypeLong = singleFormat ? "run" : "runs";
      pointTypeShort = pointTypeLong;
    } else if (sport === "SOCCER") {
      pointTypeLong = singleFormat ? "goal" : "goals";
      pointTypeShort = pointTypeLong;
    } else if (sport === "MMA") {
      pointTypeLong = singleFormat ? "round" : "rounds";
      pointTypeShort = singleFormat ? "rnd" : "rnds";
    } else if (sport === "TENNIS") {
      pointTypeLong = singleFormat ? "game" : "games";
      pointTypeShort = pointTypeLong;
    }
    return mode === "short" ? pointTypeShort : pointTypeLong;
  }

  getSportMeaningfulHomeAway() {
    const sport = this?.wager?.event?.sportID;

    if (!sport) {
      return "vs";
    }
    if (sport === "MMA") {
      return "vs";
    }
    if (sport === "SOCCER") {
      return "vs";
    }
    if (sport === "TENNIS") {
      return "vs";
    }
    return "at";
  }

  getTeamName(side, length) {
    if (side !== "home" && side !== "away") return null;
    if (length !== "short" && length !== "medium" && length !== "long") return null;

    return this?.wager?.event?.teams?.[side]?.names?.[length];
  }

  getSideIDString(role) {
    const sideID = this.getSideID(role);
    const firstLetter = sideID.charAt(0);
    const firstLetterCap = firstLetter.toUpperCase();
    const remainingLetters = sideID.slice(1);

    return firstLetterCap + remainingLetters;
  }

  getEventResults(role) {
    if (role !== "proposer" && role !== "recipient") return null;

    const oddData = this.getOddData(role);

    return this.eventDisplays?.getResults(oddData?.statEntityID, oddData?.periodID, oddData?.statID);
  }

  getWinnerUserID() {
    const winnerRole = this?.wager?.status?.winner;
    return this.wager?.[winnerRole]?.userID;
  }

  getExpirationTime(length, timezone = null) {
    if (!this?.wager?.status?.expiresAt) return "";
    return formatTime(this.wager?.status?.expiresAt, length, timezone) || "";
  }

  getResponseTime(length, timezone = null) {
    if (!this?.wager?.status?.responded || !this?.wager?.status?.respondedAt) return "";
    return formatTime(this.wager.status.respondedAt, length, timezone) || "";
  }

  getGradedTime(length, timezone = null) {
    if (!this?.wager?.status?.graded || !this?.wager?.status?.gradedAt) return "";
    return formatTime(this.wager.status.gradedAt, length, timezone) || "";
  }

  getWithdrawnTime(length, timezone = null) {
    if (!this?.wager?.status?.withdrawn || !this?.wager?.status?.withdrawnAt) return "";
    return formatTime(this.wager.status.withdrawnAt, length, timezone) || "";
  }

  getCreateTime(length, timezone = null) {
    if (!this?.wager?.status?.createdAt) return "";
    return formatTime(this.wager.status.createdAt, length, timezone) || "";
  }

  getScoreList(role, length) {
    const oddData = this.getOddData(role);
    const { betTypeID, sideID, statID, periodID, score, statEntityID } = oddData || {};
    if (betTypeID === "prop") return [];
    const awayTeamName = this.eventDisplays.getTeamDisplay("away", length);
    const homeTeamName = this.eventDisplays.getTeamDisplay("home", length);
    const awayTeamScore = this.eventDisplays.getTeamScore("away");
    const homeTeamScore = this.eventDisplays.getTeamScore("home");

    if (!homeTeamName || !awayTeamName || !isNumber(homeTeamScore) || !isNumber(awayTeamScore)) return [];
    let homeScoreString = `${homeTeamScore}`;
    let awayScoreString = `${awayTeamScore}`;
    const scoreList = [];

    const oddsDisplays = new OddsDisplays(oddData, this.wager?.event);
    const opponentScore = this.getOddData(role === "proposer" ? "recipient" : "proposer")?.score;

    let homeScoreBreakdown = "";
    let awayScoreBreakdown = "";
    let showExtraRow = true;
    if (statEntityID === "home" || statEntityID === "away") {
      const periodBreakdownLabel = periodID !== "game" ? oddsDisplays.getPeriodDisplay(length) : "";
      const statBreakdownLabel = statID !== "points" ? oddsDisplays.getStatDisplay(length) : "";

      const breakdownLabel = periodBreakdownLabel || statBreakdownLabel ? `${periodBreakdownLabel || ""} ${statBreakdownLabel || ""}`.trim() : "";
      const homeOddScore = statEntityID === "home" ? score : opponentScore;
      const awayOddScore = statEntityID === "away" ? score : opponentScore;

      homeScoreBreakdown = breakdownLabel && isNumber(homeOddScore) ? `${breakdownLabel}: ${homeOddScore}` : "";
      awayScoreBreakdown = breakdownLabel && isNumber(awayOddScore) ? `${breakdownLabel}: ${awayOddScore}` : "";
      if (homeScoreBreakdown && awayScoreBreakdown) {
        homeScoreString = `${homeTeamScore} (${homeScoreBreakdown})`;
        awayScoreString = `${awayTeamScore} (${awayScoreBreakdown})`;
        showExtraRow = false;
      }
      if (periodID === "game" && statID === "points") showExtraRow = false;
    }
    scoreList.push({ label: homeTeamName, score: homeScoreString });
    scoreList.push({ label: awayTeamName, score: awayScoreString });

    if (showExtraRow) {
      let statDisplay = oddsDisplays.getStatDisplay(length === "short" ? "short" : "long");
      let statEntityDisplay = oddsDisplays.getStatEntityDisplay(length === "long" ? "long" : "medium");
      let periodDisplay = periodID === "game" ? "" : oddsDisplays.getPeriodDisplay(length);

      const statEntityPeriodDisplay = statEntityDisplay && periodDisplay ? `${statEntityDisplay} ${periodDisplay}` : statEntityDisplay;
      const extraItemLabel = statEntityPeriodDisplay && statDisplay ? `${statEntityPeriodDisplay} ${statDisplay}` : "";
      if (extraItemLabel && isNumber(score)) {
        scoreList.push({ label: extraItemLabel, score: `${score}`, isExtra: true });
      }
    }
    return scoreList;
  }

  getOddsAppliedNotice() {
    //* IMPORTANT: The oddsOverridden field is prioritized over oddsApplied. If oddsOverridden=true, then oddsApplied is always whenProposed

    const { oddsApplied, oddsOverridden } = this.wager?.options || {};
    const oddsAppliedConfig = getOddsAppliedConfig(oddsApplied);
    const { voided, responded, started, accepted, ended } = this.wager?.status || {};
    const { started: eventStarted } = this.wager?.event?.status || {};

    if (oddsAppliedConfig.value === ODDS_APPLIED_OPTIONS.WHEN_PROPOSED.value || oddsOverridden || voided || ended) return "";
    if (oddsAppliedConfig.value === ODDS_APPLIED_OPTIONS.WHEN_ACCEPTED.value && responded) return "";
    if (oddsAppliedConfig.value === ODDS_APPLIED_OPTIONS.WHEN_ODDS_STARTED.value && (started || (responded && !accepted))) return "";
    if (oddsAppliedConfig.value === ODDS_APPLIED_OPTIONS.WHEN_EVENT_STARTED.value && (started || eventStarted || (responded && !accepted))) return "";
    return oddsAppliedConfig.explanation || "";
  }

  getOddsMovedNotice(role) {
    // In general, don't show this if you are already showing the oddsOverridden notice
    const wagerOddData = role === "recipient" ? this.wager?.odds?.recipient : this.wager?.odds?.proposer;
    const oddID = wagerOddData?.oddID;
    const eventOddData = this.wager?.event?.odds?.[oddID];
    if (!oddID || !eventOddData?.oddID) return "";

    const wagerOdds = wagerOddData?.odds;
    const marketOdds = eventOddData?.odds;
    const oddsChanged = wagerOdds && marketOdds && wagerOdds !== marketOdds;

    const wagerSpread = wagerOddData?.spread;
    const marketSpread = eventOddData?.spread;
    const spreadChanged = wagerSpread && marketSpread && wagerSpread !== marketSpread;

    const wagerOverUnder = wagerOddData?.overUnder;
    const marketOverUnder = eventOddData?.overUnder;
    const overUnderChanged = wagerOverUnder && marketOverUnder && wagerOverUnder !== marketOverUnder;

    if (wagerOddData?.betTypeID === "ou" && overUnderChanged) {
      if (oddsChanged) return `Current market over/under: ${marketOverUnder} (${marketOdds})`;
      return `Current market over/under: ${marketOverUnder}`;
    }
    if (wagerOddData?.betTypeID === "sp" && spreadChanged) {
      if (oddsChanged) return `Current market spread: ${marketSpread} (${marketOdds})`;
      return `Current market spread: ${marketSpread}`;
    }
    if (oddsChanged) return `Current market odds: ${marketOdds}`;
    return "";
  }

  getOddsOverriddenNotice(role) {
    const wagerOddData = role === "recipient" ? this.wager?.odds?.recipient : this.wager?.odds?.proposer;
    const oddID = wagerOddData?.oddID;
    const eventOddData = this.wager?.event?.odds?.[oddID];
    if (!oddID || !eventOddData?.oddID) return "";

    const { oddsFallback, oddsOverridden } = this.wager?.options || {};

    if (oddsFallback && wagerOddData?.odds === "+100" && wagerOddData?.betTypeID === "ml") return `Even odds applied. Fair market odds may differ.`;
    if (oddsFallback || wagerOddData?.isFallbackOdds) return `Fair market odds may differ`;

    if (!oddsOverridden) return "";

    const wagerOdds = wagerOddData?.odds;
    const whenProposedOdds = wagerOddData?.oddsWhenProposed;
    const oddsChangedFromWhenProposed = wagerOdds && whenProposedOdds && wagerOdds !== whenProposedOdds;

    const wagerSpread = wagerOddData?.spread;
    const whenProposedSpread = wagerOddData?.spreadWhenProposed;
    const spreadChangedFromWhenProposed = wagerSpread && whenProposedSpread && wagerSpread !== whenProposedSpread;

    const wagerOverUnder = wagerOddData?.overUnder;
    const whenProposedOverUnder = wagerOddData?.overUnderWhenProposed;
    const overUnderChangedFromWhenProposed = wagerOverUnder && whenProposedOverUnder && wagerOverUnder !== whenProposedOverUnder;

    if (wagerOddData?.betTypeID === "ou" && overUnderChangedFromWhenProposed) {
      if (oddsChangedFromWhenProposed) return `Over/Under changed from ${whenProposedOverUnder} (${whenProposedOdds}) to ${wagerOverUnder} (${wagerOdds})`;
      return `Over/Under changed from ${whenProposedOverUnder} to ${wagerOverUnder}`;
    }
    if (wagerOddData?.betTypeID === "sp" && spreadChangedFromWhenProposed) {
      if (oddsChangedFromWhenProposed) return `Spread changed from ${whenProposedSpread} (${whenProposedOdds}) to ${wagerSpread} (${wagerOdds})`;
      return `Spread changed from ${whenProposedSpread} to ${wagerSpread}`;
    }
    if (oddsChangedFromWhenProposed) return `Odds changed from ${whenProposedOdds} to ${wagerOdds}`;
    return "";
  }

  getOddsDisplays(role) {
    const oddData = this.getOddData(role);
    return new OddsDisplays(oddData, this?.wager?.event);
  }

  getPickTitle(length, role) {
    const oddsDisplays = this.getOddsDisplays(role);
    return oddsDisplays.getOddTitle(length, "dynamic");
  }

  getOddGroupName(length, role) {
    const oddsDisplays = this.getOddsDisplays(role);
    return oddsDisplays?.getOddGroupName(length);
  }

  getEventOddDetails(role) {
    const oddsDisplays = this.getOddsDisplays(role);
    return oddsDisplays?.getEventOddDetails();
  }

  getVsOrAt(role, isPrefix = true) {
    const oddsDisplays = this.getOddsDisplays(role);
    return oddsDisplays?.getVsOrAt(isPrefix);
  }

  getDisplayString(displayType) {
    switch (displayType) {
      // Wager-related displays
      case "wagerTitle_proposer_long":
        return this.getWagerTitle("proposer", "long");
      case "wagerTitle_proposer_medium":
        return this.getWagerTitle("proposer", "medium");
      case "wagerTitle_proposer_short":
        return this.getWagerTitle("proposer", "short");
      case "wagerTitle_recipient_long":
        return this.getWagerTitle("recipient", "long");
      case "wagerTitle_recipient_medium":
        return this.getWagerTitle("recipient", "medium");
      case "wagerTitle_recipient_short":
        return this.getWagerTitle("recipient", "short");
      case "wagerRiskingString":
        return this.getWagerRiskingString();
      case "wagerRisk_proposer":
        return this.getWagerRisk("proposer", false);
      case "wagerRisk_recipient":
        return this.getWagerRisk("recipient", false);
      case "wagerToWin_proposer":
        return this.getWagerToWin("proposer", false);
      case "wagerToWin_recipient":
        return this.getWagerToWin("recipient", false);
      case "wagerRiskWithUnits_proposer":
        return this.getWagerRisk("proposer", true);
      case "wagerRiskWithUnits_recipient":
        return this.getWagerRisk("recipient", true);
      case "wagerToWinWithUnits_proposer":
        return this.getWagerToWin("proposer", true);
      case "wagerToWinWithUnits_recipient":
        return this.getWagerToWin("recipient", true);

      case "wagerTeam_proposer":
        return this.getWagerTeam("proposer");
      case "wagerTeam_recipient":
        return this.getWagerTeam("recipient");

      case "wagerOpponentFilterDisplay_proposer":
        return this.getDisplayName("proposer", "medium", "username/[hone", true, "");
      case "wagerOpponentFilterDisplay_recipient":
        return this.getDisplayName("recipient", "medium", "username/[hone", true, "");
      case "wagerSideID_proposer":
        return this.getSideIDString("proposer");
      case "wagerSideID_recipient":
        return this.getSideIDString("recipient");
      case "wagerSportHasMeaningfulHomeAway":
        return this.getSportMeaningfulHomeAway();
      case "wagerTeamName_short_home":
        return this.getTeamName("home", "short");
      case "wagerTeamName_short_away":
        return this.getTeamName("away", "short");
      case "wagerTeamName_medium_home":
        return this.getTeamName("home", "medium");
      case "wagerTeamName_medium_away":
        return this.getTeamName("away", "medium");
      case "wagerTeamName_long_home":
        return this.getTeamName("home", "long");
      case "wagerTeamName_long_away":
        return this.getTeamName("away", "long");
      case "wagerGetEventResults_proposer":
        return this.getEventResults("proposer");
      case "wagerGetEventResults_recipient":
        return this.getEventResults("recipient");
      case "wagerWinnerUserID":
        return this.getWinnerUserID();

      // Odd-related displays
      case "pickTitle_long_proposer":
        return this.getPickTitle("long", "proposer");
      case "pickTitle_long_recipient":
        return this.getPickTitle("long", "recipient");
      case "oddGroupName_long_proposer":
        return this.getOddGroupName("long", "proposer");
      case "oddGroupName_long_recipient":
        return this.getOddGroupName("long", "recipient");
      case "eventOddDetails_proposer":
        return this.getEventOddDetails("proposer");
      case "eventOddDetails_recipient":
        return this.getEventOddDetails("recipient");
      case "vsOrAt_suffix_proposer":
        return this.getVsOrAt("proposer", false);
      case "vsOrAt_suffix_recipient":
        return this.getVsOrAt("recipient", false);

      // Event-related displays
      case "eventTitle_long":
        return this.eventDisplays.getTitle("long");
      case "eventTitle_medium":
        return this.eventDisplays.getTitle("medium");
      case "eventTitle_short":
        return this.eventDisplays.getTitle("short");
      case "getFinalScore_home":
        return this.eventDisplays.getScore("home", "game");
      case "getFinalScore_away":
        return this.eventDisplays.getScore("away", "game");
      case "getFinalScoreWithTeam_home":
        return this.eventDisplays.getScoreWithTeam("home", "game");
      case "getFinalScoreWithTeam_away":
        return this.eventDisplays.getScoreWithTeam("away", "game");
      case "clockDateStatus_local_medium":
        return this.eventDisplays.getClockDateStatus("medium", null);
      case "startTime_local_long":
        return this.eventDisplays.getStartTime("long");

      // Proposer-related Displays
      case "displayLong_proposer":
        return this.proposerDisplays.getDisplayString("displayLong");
      case "displayMedium_proposer":
        return this.proposerDisplays.getDisplayString("displayMedium");
      case "displayShort_proposer":
        return this.proposerDisplays.getDisplayString("displayShort");
      case "displayPhone_proposer":
        return this.proposerDisplays.getDisplayString("displayPhone");
      case "displayUsername_proposer":
        return this.proposerDisplays.getDisplayString("displayUsername");
      case "fullName_proposer":
        return this.proposerDisplays.getDisplayString("fullName");
      case "firstNameLastInitial_proposer":
        return this.proposerDisplays.getDisplayString("firstNameLastInitial");
      case "firstName_proposer":
        return this.proposerDisplays.getDisplayString("firstName");

      //Recipient-related Displays
      case "displayLong_recipient":
        return this.recipientDisplays.getDisplayString("displayLong");
      case "displayMedium_recipient":
        return this.recipientDisplays.getDisplayString("displayMedium");
      case "displayShort_recipient":
        return this.recipientDisplays.getDisplayString("displayShort");
      case "displayPhone_recipient":
        return this.recipientDisplays.getDisplayString("displayPhone");
      case "displayUsername_recipient":
        return this.recipientDisplays.getDisplayString("displayUsername");
      case "fullName_recipient":
        return this.recipientDisplays.getDisplayString("fullName");
      case "firstNameLastInitial_recipient":
        return this.recipientDisplays.getDisplayString("firstNameLastInitial");
      case "firstName_recipient":
        return this.recipientDisplays.getDisplayString("firstName");
      // expiration ex. "Wed. 7/21 @ 5:30PM"
      case "expirationTime_local_short":
        return this.getExpirationTime("short", null);
      case "expirationTime_local_medium":
        return this.getExpirationTime("medium", null);
      case "expirationTime_local_long":
        return this.getExpirationTime("long", null);
      case "expirationTime_ET_short":
        return this.getExpirationTime("short", "America/New_York");
      case "expirationTime_ET_medium":
        return this.getExpirationTime("medium", "America/New_York");
      case "expirationTime_ET_long":
        return this.getExpirationTime("long", "America/New_York");

      // response ex. "Wed. 7/21 @ 5:30PM"
      case "responseTime_local_short":
        return this.getResponseTime("short", null);
      case "responseTime_local_medium":
        return this.getResponseTime("medium", null);
      case "responseTime_local_long":
        return this.getResponseTime("long", null);
      case "responseTime_ET_short":
        return this.getResponseTime("short", "America/New_York");
      case "responseTime_ET_medium":
        return this.getResponseTime("medium", "America/New_York");
      case "responseTime_ET_long":
        return this.getResponseTime("long", "America/New_York");

      // graded ex. "Wed. 7/21 @ 5:30PM"
      case "gradedTime_local_short":
        return this.getGradedTime("short", null);
      case "gradedTime_local_medium":
        return this.getGradedTime("medium", null);
      case "gradedTime_local_long":
        return this.getGradedTime("long", null);
      case "gradedTime_ET_short":
        return this.getGradedTime("short", "America/New_York");
      case "gradedTime_ET_medium":
        return this.getGradedTime("medium", "America/New_York");
      case "gradedTime_ET_long":
        return this.getGradedTime("long", "America/New_York");

      // withdrawn ex. "Wed. 7/21 @ 5:30PM"
      case "withdrawnTime_local_short":
        return this.getWithdrawnTime("short", null);
      case "withdrawnTime_local_medium":
        return this.getWithdrawnTime("medium", null);
      case "withdrawnTime_local_long":
        return this.getWithdrawnTime("long", null);
      case "withdrawnTime_ET_short":
        return this.getWithdrawnTime("short", "America/New_York");
      case "withdrawnTime_ET_medium":
        return this.getWithdrawnTime("medium", "America/New_York");
      case "withdrawnTime_ET_long":
        return this.getWithdrawnTime("long", "America/New_York");

      // created ex. "Wed. 7/21 @ 5:30PM"
      case "createTime_local_short":
        return this.getCreateTime("short", null);
      case "createTime_local_medium":
        return this.getCreateTime("medium", null);
      case "createTime_local_long":
        return this.getCreateTime("long", null);
      case "createTime_ET_short":
        return this.getCreateTime("short", "America/New_York");
      case "createTime_ET_medium":
        return this.getCreateTime("medium", "America/New_York");
      case "createTime_ET_long":
        return this.getCreateTime("long", "America/New_York");

      case "oddsOverriddenNotice_proposer":
        return this.getOddsOverriddenNotice("proposer");
      case "oddsOverriddenNotice_recipient":
        return this.getOddsOverriddenNotice("recipient");
      case "oddsMovedNotice_proposer":
        return this.getOddsMovedNotice("proposer");
      case "oddsMovedNotice_recipient":
        return this.getOddsMovedNotice("recipient");
      case "oddsAppliedNotice":
        return this.getOddsAppliedNotice();

      case "scoreList_proposer_long":
        return this.getScoreList("proposer", "long");
      case "scoreList_proposer_medium":
        return this.getScoreList("proposer", "medium");
      case "scoreList_proposer_short":
        return this.getScoreList("proposer", "short");
      case "scoreList_recipient_long":
        return this.getScoreList("recipient", "long");
      case "scoreList_recipient_medium":
        return this.getScoreList("recipient", "medium");
      case "scoreList_recipient_short":
        return this.getScoreList("recipient", "short");
      default:
        return "";
    }
  }
}
exports.WagerDisplays = WagerDisplays;
