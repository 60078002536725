import React from "react";
import { DateTimePicker as MaterialDateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useField, useFormikContext } from "formik";
import { isString } from "@wagerlab/utils/data/types";

export const DateTimePicker = ({ name, disabled, ...otherProps }) => {
  const { isSubmitting } = useFormikContext();
  const [field, { error, touched }, { setValue, setTouched }] = useField(name);

  const hasErrors = !!(touched && error && isString(error));
  const isDisabled = !!(isSubmitting || disabled);
  const slotProps = { textField: { ...otherProps, disabled: isDisabled } };
  if (hasErrors) {
    slotProps.textField.helperText = error;
    slotProps.textField.error = true;
  }

  return (
    <MaterialDateTimePicker
      sx={{
        width: "100%",
      }}
      {...field}
      {...otherProps}
      disabled={isDisabled}
      slotProps={slotProps}
      onChange={(value) => setValue(value)}
      onClose={() => setTouched(true)}
    />
  );
};
