const { isNumber } = require("@wagerlab/utils/data/types");
const { EventDisplays } = require("@wagerlab/utils/displays/EventDisplays");
const { formatDate, formatTime } = require("@wagerlab/utils/displays/displayUtils");
const { SPORT_CONFIG } = require("@wagerlab/utils/sports/sportConfig");

// All EventDisplays will work with an EventShort object with the following exceptions:
// <NO EXCEPTIONS RIGHT NOW>

class EventShortDisplays {
  constructor(eventShortData) {
    this.eventShort = eventShortData;
    this.sportConfig = SPORT_CONFIG[eventShortData?.sportID];
    const { eventID, sportID, leagueID, categoryIDs, status, teams, props, type, ...rest } = eventShortData || {};
    const eventShortAsEvent = { eventID, sportID, leagueID, categoryIDs, status, teams, props, type };
    this.eventDisplays = new EventDisplays(eventShortAsEvent);
  }

  getNumAdditionalOddsDisplay(length) {
    const numAdditionalOdds = this.eventShort?.numAdditionalOdds;
    if (!isNumber(numAdditionalOdds) || numAdditionalOdds < 1) return "";
    return length === "short" ? `+${numAdditionalOdds}` : `+${numAdditionalOdds} more bet${numAdditionalOdds > 1 ? "s" : ""}`;
  }

  getDisplayString(displayType) {
    switch (displayType) {
      case "numAdditionalOdds_short":
        return this.getNumAdditionalOddsDisplay("short");
      case "numAdditionalOdds_long":
        return this.getNumAdditionalOddsDisplay("long");
      default:
        return this.eventDisplays.getDisplayString(displayType) || "";
    }
  }
}
exports.EventShortDisplays = EventShortDisplays;
