import React, { useState, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import _ from "lodash";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  TextField,
  IconButton,
  Modal,
  Box,
  Typography,
  Chip,
  Container,
  ThemeProvider,
  createTheme,
  CssBaseline,
  AppBar,
  Toolbar,
  InputAdornment,
  Tooltip,
  Snackbar,
} from "@mui/material";
import { Visibility, Search, ContentCopy } from "@mui/icons-material";
import { getAllCollectionDocs } from "@wagerlab/utils/database/firestore";

const theme = createTheme({
  palette: {
    primary: { main: "#1976d2" },
    secondary: { main: "#dc004e" },
    background: { default: "#f5f5f5" },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: { padding: "16px" },
        head: { fontWeight: 700, backgroundColor: "#f5f5f5" },
      },
    },
    MuiChip: {
      styleOverrides: { root: { fontWeight: 600 } },
    },
  },
});

const USAGE_PERIODS = ["month", "week", "day", "hour", "minute", "second"];
const TABLE_HEADERS = ["Email", "Last Request", "Usage", "Tier", "Active", "ID", "Actions"];
const USAGE_PERIODS_REVERSED = USAGE_PERIODS.slice().reverse();

const getLastRequestDate = (apiKey) => {
  for (let i = 0; i < USAGE_PERIODS_REVERSED.length; i++) {
    const period = USAGE_PERIODS_REVERSED[i];
    const resetDateForPeriod = apiKey?.rateLimiting?.resets?.[period];
    if (resetDateForPeriod) return moment(resetDateForPeriod).subtract(0.5, period).toDate();
  }
  return null;
};

export const ApiKeysList = () => {
  const [orderBy, setOrderBy] = useState("email");
  const [order, setOrder] = useState("asc");
  const [filter, setFilter] = useState("");
  const [selectedKey, setSelectedKey] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const {
    data: apiKeys = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: ["apiKeys"],
    queryFn: () => getAllCollectionDocs("APIKeys"),
    staleTime: Infinity,
    cacheTime: 1000 * 60 * 60,
  });

  const filteredAndSortedKeys = useMemo(() => {
    const searchTerm = filter.toLowerCase();

    const filteredApiKeys = apiKeys.filter((key) => {
      const matchesEmailOrTier = key.email?.toLowerCase().includes(searchTerm) || key.tier?.toLowerCase().includes(searchTerm);
      const matchesActive = (searchTerm.includes("active") && key.active) || (searchTerm.includes("inactive") && !key.active);
      return matchesEmailOrTier || matchesActive || (searchTerm === "" && true);
    });

    const filteredSortedApiKeys = filteredApiKeys.sort((keyA, keyB) => {
      if (orderBy === "last request") {
        const lastRequestTime_A = getLastRequestDate(keyA)?.getTime?.() || 0;
        const lastRequestTime_B = getLastRequestDate(keyB)?.getTime?.() || 0;
        return order === "asc" ? lastRequestTime_A - lastRequestTime_B : lastRequestTime_B - lastRequestTime_A;
      }
      if (orderBy === "usage") {
        const usage_A =
          keyA?.rateLimiting?.usage?.month?.requests ||
          keyA?.rateLimiting?.usage?.week?.requests ||
          keyA?.rateLimiting?.usage?.day?.requests ||
          keyA?.rateLimiting?.usage?.hour?.requests ||
          keyA?.rateLimiting?.usage?.minute?.requests ||
          keyA?.rateLimiting?.usage?.second?.requests ||
          0;
        const usage_B =
          keyB?.rateLimiting?.usage?.month?.requests ||
          keyB?.rateLimiting?.usage?.week?.requests ||
          keyB?.rateLimiting?.usage?.day?.requests ||
          keyB?.rateLimiting?.usage?.hour?.requests ||
          keyB?.rateLimiting?.usage?.minute?.requests ||
          keyB?.rateLimiting?.usage?.second?.requests ||
          0;
        return order === "asc" ? usage_A - usage_B : usage_B - usage_A;
      }
      if (orderBy === "active") {
        const active_A = keyA.active ? 1 : 0;
        const active_B = keyB.active ? 1 : 0;
        return order === "asc" ? active_A - active_B : active_B - active_A;
      }
      if (orderBy === "tier") {
        const tier_A = keyA.tier || "";
        const tier_B = keyB.tier || "";
        return order === "asc" ? tier_A.localeCompare(tier_B) : tier_B.localeCompare(tier_A);
      }
      if (orderBy === "email") {
        const email_A = keyA.email || "";
        const email_B = keyB.email || "";
        return order === "asc" ? email_A.localeCompare(email_B) : email_B.localeCompare(email_A);
      }
    });

    return filteredSortedApiKeys;
  }, [apiKeys, filter, orderBy, order]);

  const formatUsage = (key) => {
    const usageLines = USAGE_PERIODS.filter((period) => key.rateLimiting?.usage?.[period]?.requests).map((period) => `${period}: ${key.rateLimiting.usage[period].requests} requests`);
    return usageLines.length > 0 ? usageLines.join(", ") : "NO USAGE";
  };

  const estimateLastRequest = (key) => {
    const lastRequestDate = getLastRequestDate(key);
    return lastRequestDate ? moment(lastRequestDate).format("ddd[.] M/D [@] h:mma") : "N/A";
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => setSnackbarOpen(true))
      .catch((err) => console.error("Could not copy text: ", err));
  };

  if (isLoading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">Error: {error.message}</Typography>;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" elevation={0}>
          <Toolbar>
            <Typography variant="h6">Sports Game Odds API Keys</Typography>
          </Toolbar>
        </AppBar>
        <Container maxWidth={false} sx={{ mt: 4, px: 2 }}>
          <Paper elevation={3} sx={{ width: "100%", overflow: "hidden", borderRadius: 2 }}>
            <Box sx={{ pl: 2, pr: 6 }}>
              <TextField
                label="Filter by Email, Tier, or Active/Inactive"
                variant="outlined"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                fullWidth
                sx={{ m: 2 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {TABLE_HEADERS.map((header) => (
                      <TableCell key={header}>
                        <TableSortLabel
                          active={orderBy === header.toLowerCase()}
                          direction={orderBy === header.toLowerCase() ? order : "asc"}
                          onClick={() => {
                            const isAsc = orderBy === header.toLowerCase() && order === "asc";
                            setOrder(isAsc ? "desc" : "asc");
                            setOrderBy(header.toLowerCase());
                          }}
                        >
                          {header}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredAndSortedKeys.map((key) => (
                    <TableRow key={key.apiKeyHash} hover>
                      <TableCell>{key.email}</TableCell>
                      <TableCell>{estimateLastRequest(key)}</TableCell>
                      <TableCell>{formatUsage(key)}</TableCell>
                      <TableCell>{key.tier}</TableCell>
                      <TableCell>
                        <Chip label={key.active ? "Active" : "Inactive"} color={key.active ? "success" : "error"} size="small" />
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Tooltip title={key.apiKeyHash} arrow>
                            <Typography
                              sx={{
                                "cursor": "pointer",
                                "&:hover": { textDecoration: "underline" },
                              }}
                              onClick={() => copyToClipboard(key.apiKeyHash)}
                            >
                              {key.apiKeyHash.substring(0, 8)}...
                            </Typography>
                          </Tooltip>
                          <IconButton size="small" onClick={() => copyToClipboard(key.apiKeyHash)} sx={{ ml: 1 }}>
                            <ContentCopy fontSize="small" />
                          </IconButton>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => setSelectedKey(key)} color="primary">
                          <Visibility />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Container>
      </Box>
      <Modal open={Boolean(selectedKey)} onClose={() => setSelectedKey(null)} aria-labelledby="modal-title">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: 800,
            maxHeight: "90%",
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            overflow: "auto",
          }}
        >
          <Typography id="modal-title" variant="h6" gutterBottom>
            Raw API Key Data
          </Typography>
          <Paper elevation={2} sx={{ p: 2, bgcolor: "#f5f5f5" }}>
            <pre style={{ whiteSpace: "pre-wrap", wordBreak: "break-word", margin: 0 }}>{JSON.stringify(selectedKey, null, 2)}</pre>
          </Paper>
        </Box>
      </Modal>
      <Snackbar open={snackbarOpen} autoHideDuration={2000} onClose={() => setSnackbarOpen(false)} message="Copied to clipboard" />
    </ThemeProvider>
  );
};
