import React, { useEffect, useMemo, useState } from "react";
import { Paper, TableContainer, TextField, Typography } from "@mui/material";
import _ from "lodash";
import { getLeague, getTeam, getTeamsForLeague, getUnknownTeamLookups, setAggregatorQueryTeamLookups } from "@wagerlab/admin/src/utils/teamLookups/database";
import { formatLookups, getAllLookupEntityIDs, getSearchLookup, getTeamName, setOverrideLookup } from "@wagerlab/utils/aggregator/lookups";
import { expandAllTeamLookups } from "@wagerlab/utils/aggregator/teamNames";
import { generateEmbeddings, queryPineconeIndex, upsertPineconeIndexData } from "@wagerlab/admin/src/utils/ai/ai";
import { deleteValue, writeBatch } from "@wagerlab/utils/database/firestore";
import { copyData, dataAdditions, dataChanged, dataUpdates, mergeData, setObjVal } from "@wagerlab/utils/data/mutations";
import { getDataUpdatedColor } from "@wagerlab/admin/src/components/lookups/shared/add/utils";
import ColorLegend from "@wagerlab/admin/src/components/lookups/shared/add/ColorLegend";
import { isEmptyObject, isString } from "@wagerlab/utils/data/types";
import { HeaderButtons } from "@wagerlab/admin/src/components/lookups/shared/add/HeaderButtons";
import { OverridesTable } from "@wagerlab/admin/src/components/lookups/shared/add/OverridesTable";
import { UnknownsTable } from "@wagerlab/admin/src/components/lookups/shared/add/UnknownsTable";
import { formatModalData, ObjectDataDialog } from "@wagerlab/admin/src/components/lookups/shared/add/ObjectDataDialog";
import * as teamGetters from "@wagerlab/admin/src/components/lookups/teams/add/getters";

const validateNewTeamID = (teamID, leagueID) => {
  if (!teamID?.length) return `Team ID have a value`;
  const teamIDRegex = new RegExp(`^[A-Z0-9_]+_${leagueID}$`);
  if (!teamIDRegex.test(teamID)) return `Team ID must contain only capital letters, numbers, and underscores and end with _${leagueID}`;
  return "";
};

export const AddNewTeamIds = ({ leagueID }) => {
  const [dataUpdatedAt, setDataUpdatedAt] = useState();
  const [originalUnknownTeams, setOriginalUnknownTeams] = useState({});
  const [unknownTeams, setUnknownTeams] = useState({});
  const [teamLookupOverrides, setTeamLookupOverrides] = useState({});
  const [teamLookupErrors, setTeamLookupErrors] = useState({});
  const [aiSuggestions, setAISuggestions] = useState({});

  const [searchName, setSearchName] = useState("");
  const [leagueData, setLeagueData] = useState(null);
  const [expandedTeamLookups, setExpandedTeamLookups] = useState(null);

  const [error, setError] = useState(null);
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const [modalData, setModalData] = useState(null);

  const unknownTeamSearchPatterns = useMemo(() => {
    return Object.entries(originalUnknownTeams || {}).reduce((searchPatternsMap, [lookupKey, unknownTeam]) => {
      if (!lookupKey) return searchPatternsMap;
      const rawTermsList = [
        ...Object.values(unknownTeam?.teamData?.names || {}).filter((term) => isString(term)),
        ...(unknownTeam?.teamNameLookups || []),
        ...(unknownTeam?.teamNameLookupsOrig || []),
      ];
      const searchableTermsList = rawTermsList.map((term) => (term || "").toLowerCase().replace(/\s/g, ""));
      searchPatternsMap[lookupKey] = searchableTermsList.join("");
      return searchPatternsMap;
    }, {});
  }, [originalUnknownTeams]);

  useEffect(() => {
    reset();
  }, [leagueID]);

  useEffect(() => {
    validateAllEntries();
  }, [teamLookupOverrides]);

  const handleOpenModal = (event, modalUnknownTeam, modalLookupKey) => {
    event.stopPropagation();
    setModalData(formatModalData(modalUnknownTeam, modalLookupKey));
  };

  const reset = () => {
    setDataUpdatedAt(null);
    setOriginalUnknownTeams({});
    setUnknownTeams({});
    setTeamLookupOverrides({});
    setSearchName("");
    setLeagueData(null);
    setExpandedTeamLookups(null);
    setTeamLookupErrors({});
  };

  const loadData = async () => {
    if (!leagueID) return;
    setLoading(true);
    reset();
    const unknownTeamsPromise = getUnknownTeamLookups(leagueID);
    const leaguePromise = getLeague(leagueID);
    const teamsPromise = getTeamsForLeague(leagueID);

    const [unknownTeamsResponse, league, teamsList] = await Promise.all([unknownTeamsPromise, leaguePromise, teamsPromise]);

    setExpandedTeamLookups(expandAllTeamLookups(league, teamsList));
    setDataUpdatedAt(unknownTeamsResponse?.ranAt);
    setUnknownTeams(copyData(unknownTeamsResponse?.unknownTeams || {}));
    setOriginalUnknownTeams(unknownTeamsResponse?.unknownTeams || {});
    setLeagueData(league);
    setLoading(false);
  };

  const setErrorMsg = (msg) => {
    setError(msg);
    setInfo(null);
    setTimeout(() => setError(null), 10000);
  };

  const setInfoMsg = (msg) => {
    setInfo(msg);
    setError(null);
    setTimeout(() => setInfo(null), 10000);
  };

  const refreshData = async () => {
    if (!leagueID) {
      setErrorMsg(`Must set leagueID before refreshing data!`);
      return;
    }

    setLoading(true);

    const result = await setAggregatorQueryTeamLookups(leagueID);
    if (!result) {
      setErrorMsg(`Error setting aggregator to refresh ${leagueID} data!`);
      setLoading(false);
      return;
    }

    setLoading(false);
    setInfoMsg(
      `Refreshing ${leagueID} data, this can take a few minutes. Press "Load Data" after a few minutes to pull the updated data. The "ranAt" timestamp should update once the data is up to date.`
    );
  };

  const deselectAll = () => {
    const { newOverrides, newUnknowns } = Object.entries(teamLookupOverrides || {}).reduce(
      (acc, [lookupKey, assignedTeamID]) => {
        if (!assignedTeamID) {
          _.unset(acc.newOverrides, lookupKey);
          setObjVal(acc.newUnknowns, lookupKey, originalUnknownTeams[lookupKey]);
        }
        return acc;
      },
      { newOverrides: { ...teamLookupOverrides }, newUnknowns: { ...unknownTeams } }
    );

    setUnknownTeams(newUnknowns);
    setTeamLookupOverrides(newOverrides);
    setTeamLookupErrors({});
  };

  const upsertLeagueEmbeddings = async () => {
    setLoading(true);
    if (!expandedTeamLookups) {
      setErrorMsg("There is no existing league data for AI to compare names against!");
      setLoading(false);
      return;
    }

    const lookupData = getAllLookupEntityIDs(expandedTeamLookups);
    const vectors = await generateEmbeddings(lookupData);
    await upsertPineconeIndexData("team-lookups", leagueID, vectors);

    setInfoMsg("Successfully updated league embeddings!");
    setLoading(false);
  };

  const fetchAISuggestions = async () => {
    setLoading(true);
    if (!teamLookupOverrides || !expandedTeamLookups) {
      setErrorMsg("There is no existing league data for AI to compare names against!");
      setLoading(false);
      return;
    }

    const overrideKeys = Object.keys(teamLookupOverrides).filter((key) => teamLookupOverrides[key]?.length === 0);
    if (overrideKeys.length > 50) {
      setErrorMsg("AI suggestions are limited to 50 lookup names at a time!");
      setLoading(false);
      return;
    }

    if (overrideKeys.length === 0) {
      setErrorMsg("No empty entries to get AI suggestions for!");
      setLoading(false);
      return;
    }

    try {
      const teamNames = overrideKeys.map((key) => getTeamName(originalUnknownTeams[key]?.teamData));
      const lookupVectors = await generateEmbeddings(teamNames);
      if (!lookupVectors) throw new Error("Error generating embeddings for AI suggestions!");

      const pineconePromises = (lookupVectors || []).map((vector) => queryPineconeIndex("team-lookups", leagueID, vector.values, 3));
      const queryResults = await Promise.all(pineconePromises);
      const aiResults = queryResults.reduce((results, queryResult, index) => {
        const matches = queryResult?.matches?.slice(0, 3)?.map((match) => ({ id: match.id, score: match.score }));
        if (matches) {
          matches.sort((a, b) => b.score - a.score);
          _.set(results, overrideKeys[index], matches);
        }
        return results;
      }, {});

      setAISuggestions(aiResults);
    } catch (e) {
      setLoading(false);
      console.error("Error fetching AI suggestion data:", e);
      setErrorMsg("Error fetching AI suggestion data!");
      return;
    }

    setInfoMsg("Successfully got AI suggestions for selected team lookups!");
    setLoading(false);
  };

  const handleAdd = (lookupKey) => {
    if (!lookupKey) return;
    setTeamLookupOverrides({ ...teamLookupOverrides, [lookupKey]: "" });
    const newUnknownTeamLookups = { ...unknownTeams };
    _.unset(newUnknownTeamLookups, lookupKey);
    setUnknownTeams(newUnknownTeamLookups);
  };

  const handleDelete = (lookupKey) => {
    if (!lookupKey) return;
    const newUnknownTeams = { ...unknownTeams, [lookupKey]: { ...originalUnknownTeams[lookupKey] } };
    const newTeamLookupOverrides = { ...teamLookupOverrides };
    const newTeamLookupErrors = { ...teamLookupErrors };
    _.unset(newTeamLookupOverrides, lookupKey);
    _.unset(newTeamLookupErrors, lookupKey);

    setTeamLookupOverrides(newTeamLookupOverrides);
    setUnknownTeams(newUnknownTeams);
    setTeamLookupErrors(newTeamLookupErrors);
  };

  const handleSearchChange = (event) => setSearchName(event?.target?.value || "");

  const checkShouldContinue = (confirmMessage) => {
    const shouldContinue = window.confirm(confirmMessage);
    if (!shouldContinue) setLoading(false);
    return !!shouldContinue;
  };

  const handleSubmit = async () => {
    if (!leagueID || leagueData?.leagueID !== leagueID) return;
    setLoading(true);

    const overrideEntries = Object.entries(teamLookupOverrides || {});
    for (let i = 0; i < overrideEntries.length; i++) {
      const [lookupKey, teamID] = overrideEntries[i];
      const teamIDValidationError = validateNewTeamID(teamID, leagueID);
      if (teamIDValidationError) {
        const teamIDValidationErrorMessage = `Error in teamID for ${lookupKey}: ${teamIDValidationError}`;
        setErrorMsg(teamIDValidationErrorMessage);
        setLoading(false);
        return;
      }
    }

    if (Object.values(teamLookupErrors || {}).some((e) => e?.error)) {
      const shouldContinue = checkShouldContinue("There are errors in the data you provided. Are you sure you want to submit changes?");
      if (!shouldContinue) return;
    }

    const dbLeagueData = await getLeague(leagueID);
    const didChange = dataChanged(leagueData, dbLeagueData);
    if (didChange) {
      const shouldContinue = checkShouldContinue("League data has changed since you loaded it. Are you sure you want to submit changes?");
      if (!shouldContinue) return;
    }

    const { teamsToWrite, lookupKeysToWrite, newUnknownTeams } = Object.entries(teamLookupOverrides || {}).reduce(
      (acc, [lookupKey, teamID]) => {
        const teamData = { ...(originalUnknownTeams?.[lookupKey]?.teamData || {}), teamID };
        if (!teamData?.teamID || teamData?.leagueID !== leagueID || !teamData.teamID.endsWith(`_${leagueID}`)) return acc;

        // If we're writing multiple times to the same teamID, merge the results.
        const sameTeamToWriteIndex = acc.teamsToWrite.findIndex((t) => t?.teamID === teamID);
        if (sameTeamToWriteIndex >= 0) {
          const mergedTeam = mergeData(acc.teamsToWrite[sameTeamToWriteIndex], teamData);
          acc.teamsToWrite[sameTeamToWriteIndex] = mergedTeam;
        } else {
          acc.teamsToWrite.push(teamData);
        }

        acc.lookupKeysToWrite.push(lookupKey);
        _.unset(acc.newUnknownTeams, lookupKey);
        return acc;
      },
      { teamsToWrite: [], lookupKeysToWrite: [], newUnknownTeams: { ...unknownTeams } }
    );

    const batchWrites = [];

    let newLeagueTeamLookups = copyData(leagueData?.teamLookups || {});
    lookupKeysToWrite.forEach((lookupKey) => {
      const teamNameLookupsToAdd = originalUnknownTeams[lookupKey].teamNameLookups || [];
      const teamID = teamLookupOverrides[lookupKey];
      if (!teamID || !teamNameLookupsToAdd || !teamsToWrite.some((team) => team.teamID === teamID)) return;
      teamNameLookupsToAdd.forEach((newLookupString) => {
        const remoteExistingLookup = getSearchLookup(expandedTeamLookups, newLookupString);
        if (remoteExistingLookup && remoteExistingLookup !== teamID) {
          const shouldContinue = window.confirm(`Is it ok to change the remote lookup ${newLookupString} from ${remoteExistingLookup} to ${teamID}`);
          if (!shouldContinue) return;
        }
        const localExistingLookup = getSearchLookup(newLeagueTeamLookups, newLookupString);
        //if (localExistingLookup && localExistingLookup !== teamID && localExistingLookup !== remoteExistingLookup) {
        if (localExistingLookup && localExistingLookup !== teamID) {
          const shouldContinue = window.confirm(`This lookup was changed multiple times: ${newLookupString} Do you NOT want to map to ${localExistingLookup} and instead MAP TO ${teamID}`);
          if (!shouldContinue) return;
        }
        newLeagueTeamLookups = setOverrideLookup(newLeagueTeamLookups, newLookupString, teamID);
      });
    });
    const formattedLeagueTeamLookups = formatLookups(newLeagueTeamLookups);
    const lookupDataChanges = dataUpdates({ teamLookups: leagueData?.teamLookups || {} }, { teamLookups: formattedLeagueTeamLookups }, deleteValue());
    if (lookupDataChanges) batchWrites.push({ method: "update", collectionName: "Leagues", collectionID: leagueID, payload: lookupDataChanges });

    // We have a retry on this promise for added safety
    const remoteTeamsPromises = teamsToWrite.map((teamData) => getTeam(teamData?.teamID).then((t) => (t?.teamID ? t : getTeam(teamData?.teamID))));
    const remoteTeams = await Promise.all(remoteTeamsPromises);

    const newTeamIDs = [];
    teamsToWrite.forEach((teamData, index) => {
      const teamID = teamData?.teamID;
      const remoteTeamData = remoteTeams[index];
      if (!remoteTeamData?.teamID) {
        batchWrites.push({ method: "set", collectionName: "Teams", collectionID: teamID, payload: teamData });
        newTeamIDs.push(teamID);
      } else {
        const dataAdditionUpdates = dataAdditions(remoteTeamData, teamData, ["sourceContext"]);
        if (dataAdditionUpdates) batchWrites.push({ method: "update", collectionName: "Teams", collectionID: teamID, payload: dataAdditionUpdates });
      }
    });

    if (!batchWrites.length) {
      setInfoMsg(`Nothing to update for ${leagueID} team lookups!`);
      setLoading(false);
      return;
    }

    batchWrites.push({
      method: "update",
      collectionName: "AggregatorTeamLookups",
      collectionID: leagueID,
      payload: {
        manualUpdateAt: new Date(),
        unknownTeams: newUnknownTeams,
      },
    });

    batchWrites.push({ method: "update", collectionName: "Config", collectionID: "aggregator", payload: { "runner.cacheInvalidated": true } });
    let result = await writeBatch(batchWrites);
    if (!result) {
      setErrorMsg(`Error writing batched data, changes will not apply!`);
      setLoading(false);
      return;
    }

    // If we created new TeamIDs, upsert them in the Pinecone DB
    if (newTeamIDs.length > 0) {
      const vectors = await generateEmbeddings(newTeamIDs);
      await upsertPineconeIndexData("team-lookups", leagueID, vectors);
    }

    reset();
    await loadData();

    setInfoMsg(`Successfully updated team lookups for ${leagueID}!`);
    setLoading(false);
  };

  const validateAllEntries = () => {
    const newErrors = {};

    Object.entries(teamLookupOverrides).forEach(([lookupKey, teamID]) => {
      const error = getTeamIDFieldError(lookupKey, teamID);
      const notice = getTeamIDFieldNotice(lookupKey, teamID);
      if (error) {
        newErrors[lookupKey] = {
          error: true,
          helperText: error,
        };
      } else if (notice) {
        newErrors[lookupKey] = {
          error: false,
          helperText: notice,
        };
      }
    });

    setTeamLookupErrors(newErrors);
  };

  const getTeamIDFieldError = (lookupKey, teamID) => {
    if (!teamID || !teamID.length) return null;

    const teamIDValidationError = validateNewTeamID(teamID, leagueID);
    if (teamIDValidationError) return teamIDValidationError;

    const unknownTeamLookupNames = originalUnknownTeams[lookupKey].teamNameLookups;
    for (let i = 0; i < unknownTeamLookupNames.length; i++) {
      const lookupString = unknownTeamLookupNames[i];
      const existingSearchLookup = expandedTeamLookups?.searches?.[lookupString];
      if (existingSearchLookup && existingSearchLookup !== teamID) return `Assigning team to this teamID causes search clash...lookup:${lookupString}  clashing teamID:${existingSearchLookup}`;
      const existingOverrideLookup = expandedTeamLookups?.overrides?.[lookupString];
      if (existingOverrideLookup && existingOverrideLookup !== teamID) return `Assigning team to this teamID causes override clash...lookup:${lookupString}  clashing teamID:${existingOverrideLookup}`;
      const existingClashLookupsStr = (expandedTeamLookups?.clashes?.[lookupString] || []).filter((id) => id && id !== teamID).join(", ");
      if (existingClashLookupsStr) return `Assigning team to this teamID adds to existing clash...lookup:${lookupString}  clashing teamIDs:${existingClashLookupsStr}`;
    }

    return null;
  };

  const getTeamIDFieldNotice = (lookupKey, teamID) => {
    if (!teamID || !teamID.length) return null;
    const countTeamID = Object.values(teamLookupOverrides).filter((id) => id === teamID).length;
    if (countTeamID > 1) return `This TeamID being assigned to ${countTeamID} unknown teams`;
    return null;
  };

  const searchTeamFilter = (lookupKey) => {
    const teamSearchPattern = unknownTeamSearchPatterns?.[lookupKey] || "";
    const inputSearchPattern = searchName.toLowerCase().replace(/\s/g, "");
    return !inputSearchPattern?.length || teamSearchPattern.includes(inputSearchPattern);
  };

  return (
    <TableContainer component={Paper} style={{ padding: 20 }}>
      <ColorLegend />
      {error && <Typography sx={{ color: "red", fontSize: 20, margin: "10px" }}>{error}</Typography>}
      {info && <Typography sx={{ color: "green", fontSize: 20, margin: "10px" }}>{info}</Typography>}
      <TextField value={searchName} onChange={(event) => handleSearchChange(event)} placeholder={"Search for name..."} />
      <HeaderButtons
        loading={loading}
        loadData={loadData}
        refreshData={refreshData}
        deselectAll={deselectAll}
        upsertEmbeddings={upsertLeagueEmbeddings}
        fetchAISuggestions={fetchAISuggestions}
        handleSubmit={handleSubmit}
        entityType={"team"}
      />

      {dataUpdatedAt && (
        <Typography sx={{ fontSize: 20, color: getDataUpdatedColor(dataUpdatedAt), margin: "10px" }}>Data last updated at: {dataUpdatedAt?.toLocaleString?.() || "Unknown"}</Typography>
      )}
      <OverridesTable
        searchFilter={searchTeamFilter}
        originalUnknownEntities={originalUnknownTeams}
        parentLookupsObj={expandedTeamLookups}
        parentID={leagueData?.leagueID}
        entityLookupOverrides={teamLookupOverrides}
        setEntityLookupOverrides={setTeamLookupOverrides}
        entityLookupErrors={teamLookupErrors}
        aiSuggestions={aiSuggestions}
        handleOpenModal={handleOpenModal}
        handleDelete={handleDelete}
        getters={teamGetters}
      />
      <UnknownsTable unknownEntities={unknownTeams} searchFilter={searchTeamFilter} handleAdd={handleAdd} handleOpenModal={handleOpenModal} getters={teamGetters} />
      <ObjectDataDialog data={modalData} setData={setModalData} setOriginalUnknownEntities={setOriginalUnknownTeams} dataPath="teamData" namesPath="teamData.names" lookupsPath="teamNameLookups" />
    </TableContainer>
  );
};
