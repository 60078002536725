import React, { useMemo } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, Box, Typography } from "@mui/material";
import { ActionsCell } from "@wagerlab/admin/src/components/lookups/shared/add/ActionsCell";
import { LookupsCell } from "@wagerlab/admin/src/components/lookups/shared/add/LookupsCell";
import { NameCell } from "@wagerlab/admin/src/components/lookups/shared/add/NameCell";
import { IDCell } from "@wagerlab/admin/src/components/lookups/shared/add/IDCell";
import { RowNotice } from "@wagerlab/admin/src/components/lookups/shared/add/RowNotice";

export const OverridesTable = ({
  searchFilter,
  originalUnknownEntities,
  parentLookupsObj,
  parentID,
  entityLookupOverrides,
  setEntityLookupOverrides,
  entityLookupErrors,
  aiSuggestions,
  handleOpenModal,
  handleDelete,
  getters,
}) => {
  const overrideEntityEntries = useMemo(() => {
    return Object.entries(entityLookupOverrides || {})
      .filter(([lookupKey, entityID]) => searchFilter(lookupKey))
      .sort(([lookupKeyA, aVal], [lookupKeyB, bVal]) => {
        const unknownEntity_A = originalUnknownEntities?.[lookupKeyA];
        const unknownEntity_B = originalUnknownEntities?.[lookupKeyB];
        const name_A = getters.getSortName(unknownEntity_A);
        const name_B = getters.getSortName(unknownEntity_B);
        return name_A.localeCompare(name_B);
      })
      .map(([lookupKey, entityID]) => [lookupKey, originalUnknownEntities?.[lookupKey]]);
  }, [entityLookupOverrides, originalUnknownEntities, searchFilter]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell sx={{ fontSize: 22 }}>{getters.getEntityTypeDisplayName(true)}</TableCell>
          <TableCell sx={{ fontSize: 22 }}>Orig. Lookups</TableCell>
          <TableCell sx={{ fontSize: 22 }}>Search Lookups</TableCell>
          <TableCell sx={{ fontSize: 22 }}>{getters.getEntityTypeDisplayName(true)}ID</TableCell>
          <TableCell sx={{ fontSize: 22 }}>Actions</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {overrideEntityEntries.map(([lookupKey, unknownEntity]) => {
          return (
            <React.Fragment key={lookupKey}>
              <TableRow
                key={lookupKey}
                sx={{
                  "& td": {
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    borderBottom: "none",
                  },
                }}
              >
                <NameCell
                  sourceID={getters.getSourceID(unknownEntity)}
                  sourceEntityID={getters.getSourceEntityID(unknownEntity)}
                  name={getters.getName(unknownEntity)}
                  unknownEntity={unknownEntity}
                  handleOpenModal={handleOpenModal}
                  lookupKey={lookupKey}
                />
                <LookupsCell lookupsList={getters.getEntityNameLookupsOrig(unknownEntity)} />
                <LookupsCell lookupsList={getters.getEntityNameLookups(unknownEntity)} />
                <IDCell
                  unknownEntity={unknownEntity}
                  lookupKey={lookupKey}
                  parentLookupsObj={parentLookupsObj}
                  parentID={parentID}
                  lookupOverrides={entityLookupOverrides}
                  setLookupOverrides={setEntityLookupOverrides}
                  lookupErrors={entityLookupErrors}
                  aiSuggestions={aiSuggestions}
                  getters={getters}
                />
                <ActionsCell name={getters.getName(unknownEntity)} handleDelete={() => handleDelete(lookupKey)} />
              </TableRow>
              <RowNotice unknownEntity={unknownEntity} getters={getters} />
            </React.Fragment>
          );
        })}
      </TableBody>
    </Table>
  );
};
