import React, { useState } from "react";
import { getTeam } from "@wagerlab/admin/src/utils/teamLookups/database";
import _ from "lodash";
import { deleteValue, writeBatch } from "@wagerlab/utils/database/firestore";
import { dataChanged, dataUpdates } from "@wagerlab/utils/data/mutations";
import { ManageExistingLookups } from "@wagerlab/admin/src/components/lookups/shared/manage/ManageExistingLookups";

export const ManageExistingPlayerIds = ({ leagueID, teamID }) => {
  const [teamData, setTeamData] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleRefresh = async () => {
    setLoading(true);
    const dbTeamData = await getTeam(teamID);
    setTeamData(dbTeamData);
    setLoading(false);
  };
  const handleSubmit = async (newPlayerLookups) => {
    setLoading(true);

    const dbTeamData = await getTeam(teamID);
    const didChange = dataChanged(teamData?.playerLookups || {}, dbTeamData?.playerLookups || {});
    if (didChange) {
      const shouldContinue = window.confirm("Team lookup data has changed since you loaded it. Are you sure you want to submit changes? We recommend NO");
      if (!shouldContinue) {
        setLoading(false);
        return "Changes not submitted";
      }
    }

    const lookupDataChanges = dataUpdates(dbTeamData?.playerLookups || {}, newPlayerLookups, deleteValue(), ".", "playerLookups");

    if (!lookupDataChanges) {
      setLoading(false);
      return "No changes to write";
    }

    const batchWrites = [
      { method: "update", collectionName: "Teams", collectionID: teamID, payload: lookupDataChanges },
      { method: "update", collectionName: "Config", collectionID: "aggregator", payload: { "runner.cacheInvalidated": true } },
    ];

    let batchResult = await writeBatch(batchWrites);
    if (!batchResult?.allSuccess) {
      return "Failed to save changes to DB.  Write failed.";
    }

    return "";
  };

  return <ManageExistingLookups loading={loading} lookupData={teamData?.playerLookups} handleSubmit={handleSubmit} handleRefresh={handleRefresh} />;
};
