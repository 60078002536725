const { isNumber } = require("@wagerlab/utils/data/types");
const { CLOCK_TYPES } = require("@wagerlab/utils/sports/gameClock");
const { SPORT_CONFIG } = require("@wagerlab/utils/sports/sportConfig");
const { LEAGUE_CONFIG } = require("@wagerlab/utils/sports/leagueConfig");

const ALL_PERIOD_IDS = [
  "1mx10",
  "1mx8",
  "1mx5",
  "1mx4",
  "1m",

  "1qx3",

  "1rx2",

  "1ix3",
  "1ix5",
  "1ix7",

  "so",
  "ot",

  "12r",
  "11r",
  "10r",
  "9r",
  "8r",
  "7r",
  "6r",
  "5r",
  "4r",
  "3r",
  "2r",
  "1r",

  "3p",
  "2p",
  "1p",

  "9i",
  "8i",
  "7i",
  "6i",
  "5i",
  "4i",
  "3i",
  "2i",
  "1i",

  "5s",
  "4s",
  "3s",
  "2s",
  "1s",

  "4q",
  "3q",
  "2q",
  "1q",

  "2h",
  "1h",

  "reg",
  "game",
];
exports.ALL_PERIOD_IDS = ALL_PERIOD_IDS;

const PERIOD_UNIT_TYPES = {
  QUARTERS: "QUARTERS",
  HALVES: "HALVES",
  INNINGS: "INNINGS",
  PERIODS: "PERIODS",
  SETS: "SETS",
  ROUNDS: "ROUNDS",
  MINUTES: "MINUTES",
};
exports.PERIOD_UNIT_TYPES = PERIOD_UNIT_TYPES;

//* Notes on adding to PERIOD_ID_CONFIG
// - The ordering on each individual sumParts list matters. It should go from first to last periodID
// - isSubsetOf should not include reg or game

const PERIOD_ID_CONFIG = {
  "1h": {
    periodID: "1h",
    startedIfAnyOfTheseEnded: ALL_PERIOD_IDS,
    startedIfAnyOfTheseStarted: ALL_PERIOD_IDS,
    endedIfAnyOfTheseEnded: ["2q", "3q", "4q", "2h", "6i", "7i", "8i", "9i", "1ix5", "1ix7", "ot", "so"],
    endedIfAnyOfTheseStarted: ["2h", "3q", "4q", "6i", "7i", "8i", "9i", "ot", "so"],
    sumParts: [
      ["1q", "2q"],
      ["1i", "2i", "3i", "4i", "5i"],
    ],
    supportsLiveOdds: true,
    isSubsetOf: [],
    correlatedWith: ["game", "reg", "1q", "2q", "1i", "2i", "3i", "4i", "5i", "1ix3", "1ix5", "1ix7", "1m", "1mx4", "1mx5", "1mx8", "1mx10"],
    startsAtEventStart: true,
    displays: {
      short: "1H",
      medium: "1st Half",
      long: "1st Half",
    },
    unit: PERIOD_UNIT_TYPES.HALVES,
    order: 1,
    multiple: 1,
  },
  "2h": {
    periodID: "2h",
    sumParts: [
      ["3q", "4q"],
      ["6i", "7i", "8i", "9i"],
    ],
    supportsLiveOdds: true,
    startedIfAnyOfTheseEnded: ["3q", "4q", "6i", "7i", "8i", "9i", "1ix7", "ot", "so"],
    startedIfAnyOfTheseStarted: ["3q", "4q", "6i", "7i", "8i", "9i", "ot", "so"],
    endedIfAnyOfTheseEnded: ["game", "4q", "9i", "ot", "so", "reg"],
    endedIfAnyOfTheseStarted: ["ot", "so"],
    isSubsetOf: [],
    correlatedWith: ["game", "reg", "3q", "4q", "6i", "7i", "8i", "9i", "1ix7"],
    startsAtEventStart: false,
    displays: {
      short: "2H",
      medium: "2nd Half",
      long: "2nd Half",
    },
    unit: PERIOD_UNIT_TYPES.HALVES,
    order: 2,
    multiple: 1,
  },
  "1q": {
    periodID: "1q",
    supportsLiveOdds: true,
    startedIfAnyOfTheseEnded: ["1h", "2h", "2q", "3q", "4q", "ot", "reg", "game"],
    startedIfAnyOfTheseStarted: ["1h", "2q", "2h", "3q", "4q", "ot", "reg", "game"],
    endedIfAnyOfTheseEnded: ["2q", "2h", "3q", "4q", "ot", "reg", "game"],
    endedIfAnyOfTheseStarted: ["2q", "2h", "3q", "4q", "ot"],
    isSubsetOf: ["1h", "1qx3"],
    correlatedWith: ["game", "reg", "1h", "1m", "1mx4", "1mx5", "1mx8", "1mx10"],
    startsAtEventStart: true,
    displays: {
      short: "Q1",
      medium: "1st Q",
      long: "1st Quarter",
    },
    unit: PERIOD_UNIT_TYPES.QUARTERS,
    order: 1,
    multiple: 1,
  },
  "2q": {
    periodID: "2q",
    supportsLiveOdds: true,
    startedIfAnyOfTheseEnded: ["1h", "3q", "4q", "2h", "ot", "reg", "game"],
    startedIfAnyOfTheseStarted: ["2h", "3q", "4q", "ot"],
    endedIfAnyOfTheseEnded: ["1h", "3q", "4q", "2h", "ot", "reg", "game"],
    endedIfAnyOfTheseStarted: ["3q", "4q", "2h", "ot"],
    isSubsetOf: ["1h"],
    correlatedWith: ["game", "reg", "1h"],
    startsAtEventStart: false,
    displays: {
      short: "Q2",
      medium: "2nd Q",
      long: "2nd Quarter",
    },
    unit: PERIOD_UNIT_TYPES.QUARTERS,
    order: 2,
    multiple: 1,
  },
  "3q": {
    periodID: "3q",
    supportsLiveOdds: true,
    startedIfAnyOfTheseEnded: ["2h", "4q", "ot", "reg", "game"],
    startedIfAnyOfTheseStarted: ["2h", "4q", "ot"],
    endedIfAnyOfTheseEnded: ["4q", "2h", "reg", "ot", "game"],
    endedIfAnyOfTheseStarted: ["4q", "ot"],
    isSubsetOf: ["2h"],
    correlatedWith: ["game", "reg", "2h"],
    startsAtEventStart: false,
    displays: {
      short: "Q3",
      medium: "3rd Q",
      long: "3rd Quarter",
    },
    unit: PERIOD_UNIT_TYPES.QUARTERS,
    order: 3,
    multiple: 1,
  },
  "4q": {
    periodID: "4q",
    supportsLiveOdds: true,
    startedIfAnyOfTheseEnded: ["3q", "1qx3", "game", "reg", "ot"],
    startedIfAnyOfTheseStarted: ["ot"],
    endedIfAnyOfTheseEnded: ["2h", "reg", "ot", "game"],
    endedIfAnyOfTheseStarted: ["ot"],
    isSubsetOf: ["2h"],
    correlatedWith: ["game", "reg", "2h"],
    startsAtEventStart: false,
    displays: {
      short: "Q4",
      medium: "4th Q",
      long: "4th Quarter",
    },
    unit: PERIOD_UNIT_TYPES.QUARTERS,
    order: 4,
    multiple: 1,
  },
  "1qx3": {
    periodID: "1qx3",
    supportsLiveOdds: true,
    sumParts: [["1q", "2q", "3q"]],
    startedIfAnyOfTheseEnded: ALL_PERIOD_IDS,
    startedIfAnyOfTheseStarted: ALL_PERIOD_IDS,
    endedIfAnyOfTheseEnded: ["4q", "2h", "reg", "ot", "game"],
    endedIfAnyOfTheseStarted: ["4q", "ot"],
    isSubsetOf: [],
    correlatedWith: ["game", "reg", "1h", "2h", "1q", "2q", "3q", "1m", "1mx4", "1mx5", "1mx8", "1mx10"],
    startsAtEventStart: true,
    displays: {
      short: "Q1-3",
      medium: "1st 3 Qs",
      long: "1st 3 Quarters",
    },
    unit: PERIOD_UNIT_TYPES.QUARTERS,
    order: 1,
    multiple: 3,
  },
  "1p": {
    periodID: "1p",
    supportsLiveOdds: true,
    startedIfAnyOfTheseEnded: ALL_PERIOD_IDS,
    startedIfAnyOfTheseStarted: ALL_PERIOD_IDS,
    endedIfAnyOfTheseEnded: ["2p", "3p", "ot", "so", "reg", "game"],
    endedIfAnyOfTheseStarted: ["2p", "3p", "ot", "so"],
    isSubsetOf: [],
    correlatedWith: ["game", "reg", "1m", "1mx4", "1mx5", "1mx8", "1mx10"],
    startsAtEventStart: true,
    displays: {
      short: "P1",
      medium: "1st P",
      long: "1st Period",
    },
    unit: PERIOD_UNIT_TYPES.PERIODS,
    order: 1,
    multiple: 1,
  },
  "2p": {
    periodID: "2p",
    supportsLiveOdds: true,
    startedIfAnyOfTheseEnded: ["3p", "ot", "so", "reg", "game"],
    startedIfAnyOfTheseStarted: ["3p", "ot", "so"],
    endedIfAnyOfTheseEnded: ["3p", "ot", "so", "reg", "game"],
    endedIfAnyOfTheseStarted: ["3p", "ot", "so"],
    isSubsetOf: [],
    correlatedWith: ["game", "reg"],
    startsAtEventStart: false,
    displays: {
      short: "P2",
      medium: "2nd P",
      long: "2nd Period",
    },
    unit: PERIOD_UNIT_TYPES.PERIODS,
    order: 2,
    multiple: 1,
  },
  "3p": {
    periodID: "3p",
    supportsLiveOdds: true,
    startedIfAnyOfTheseEnded: ["reg", "ot", "so", "game"],
    startedIfAnyOfTheseStarted: ["ot", "so"],
    endedIfAnyOfTheseEnded: ["reg", "ot", "so", "game"],
    endedIfAnyOfTheseStarted: ["ot", "so"],
    isSubsetOf: [],
    correlatedWith: ["game", "reg"],
    startsAtEventStart: false,
    displays: {
      short: "P3",
      medium: "3rd Period",
      long: "3rd Period",
    },
    unit: PERIOD_UNIT_TYPES.PERIODS,
    order: 3,
    multiple: 1,
  },
  "1i": {
    periodID: "1i",
    supportsLiveOdds: true,
    startedIfAnyOfTheseEnded: ALL_PERIOD_IDS,
    startedIfAnyOfTheseStarted: ALL_PERIOD_IDS,
    endedIfAnyOfTheseEnded: ["2i", "3i", "4i", "5i", "6i", "7i", "8i", "9i", "1ix3", "1ix5", "1ix7", "reg", "ot", "1h", "2h", "game"],
    endedIfAnyOfTheseStarted: ["2i", "3i", "4i", "5i", "6i", "7i", "8i", "9i", "ot", "2h"],
    isSubsetOf: ["1ix3", "1ix5", "1ix7", "1h"],
    correlatedWith: ["game", "reg", "1ix3", "1ix5", "1ix7", "1h", "1m", "1mx4", "1mx5", "1mx8", "1mx10"],
    startsAtEventStart: true,
    displays: {
      short: "1st",
      medium: "1st Inning",
      long: "1st Inning",
    },
    unit: PERIOD_UNIT_TYPES.INNINGS,
    order: 1,
    multiple: 1,
  },
  "2i": {
    periodID: "2i",
    supportsLiveOdds: true,
    startedIfAnyOfTheseEnded: ["3i", "4i", "5i", "6i", "7i", "8i", "9i", "1ix3", "1ix5", "1ix7", "reg", "ot", "1h", "2h", "game"],
    startedIfAnyOfTheseStarted: ["3i", "4i", "5i", "6i", "7i", "8i", "9i", "ot", "2h"],
    endedIfAnyOfTheseEnded: ["3i", "4i", "5i", "6i", "7i", "8i", "9i", "1ix3", "1ix5", "1ix7", "reg", "ot", "1h", "2h", "game"],
    endedIfAnyOfTheseStarted: ["3i", "4i", "5i", "6i", "7i", "8i", "9i", "ot", "2h"],
    isSubsetOf: ["1ix3", "1ix5", "1ix7", "1h"],
    correlatedWith: ["game", "reg", "1ix3", "1ix5", "1ix7", "1h"],
    startsAtEventStart: false,
    displays: {
      short: "2nd",
      medium: "2nd Inning",
      long: "2nd Inning",
    },
    unit: PERIOD_UNIT_TYPES.INNINGS,
    order: 2,
    multiple: 1,
  },
  "3i": {
    periodID: "3i",
    supportsLiveOdds: true,
    startedIfAnyOfTheseEnded: ["4i", "5i", "6i", "7i", "8i", "9i", "1ix3", "1ix5", "1ix7", "reg", "ot", "1h", "2h", "game"],
    startedIfAnyOfTheseStarted: ["4i", "5i", "6i", "7i", "8i", "9i", "ot", "2h"],
    endedIfAnyOfTheseEnded: ["4i", "5i", "6i", "7i", "8i", "9i", "1ix3", "1ix5", "1ix7", "reg", "ot", "1h", "2h", "game"],
    endedIfAnyOfTheseStarted: ["4i", "5i", "6i", "7i", "8i", "9i", "ot", "2h"],
    isSubsetOf: ["1ix3", "1ix5", "1ix7", "1h"],
    correlatedWith: ["game", "reg", "1ix3", "1ix5", "1ix7", "1h"],
    startsAtEventStart: false,
    displays: {
      short: "3rd",
      medium: "3rd Inning",
      long: "3rd Inning",
    },
    unit: PERIOD_UNIT_TYPES.INNINGS,
    order: 3,
    multiple: 1,
  },
  "4i": {
    periodID: "4i",
    supportsLiveOdds: true,
    startedIfAnyOfTheseEnded: ["5i", "6i", "7i", "8i", "9i", "1ix5", "1ix7", "reg", "ot", "1h", "2h", "game"],
    startedIfAnyOfTheseStarted: ["5i", "6i", "7i", "8i", "9i", "ot", "2h"],
    endedIfAnyOfTheseEnded: ["5i", "6i", "7i", "8i", "9i", "1ix5", "1ix7", "reg", "ot", "1h", "2h", "game"],
    endedIfAnyOfTheseStarted: ["5i", "6i", "7i", "8i", "9i", "ot", "2h"],
    isSubsetOf: ["1ix5", "1ix7", "1h"],
    correlatedWith: ["game", "reg", "1ix5", "1ix7", "1h"],
    startsAtEventStart: false,
    displays: {
      short: "4th",
      medium: "4th Inning",
      long: "4th Inning",
    },
    unit: PERIOD_UNIT_TYPES.INNINGS,
    order: 4,
    multiple: 1,
  },
  "5i": {
    periodID: "5i",
    supportsLiveOdds: true,
    startedIfAnyOfTheseEnded: ["6i", "7i", "8i", "9i", "1ix5", "1ix7", "reg", "ot", "1h", "2h", "game"],
    startedIfAnyOfTheseStarted: ["6i", "7i", "8i", "9i", "ot", "2h"],
    endedIfAnyOfTheseEnded: ["6i", "7i", "8i", "9i", "1ix5", "1ix7", "reg", "ot", "1h", "2h", "game"],
    endedIfAnyOfTheseStarted: ["6i", "7i", "8i", "9i", "ot", "2h"],
    isSubsetOf: ["1ix5", "1ix7", "1h"],
    correlatedWith: ["game", "reg", "1ix5", "1ix7", "1h"],
    startsAtEventStart: false,
    displays: {
      short: "5th",
      medium: "5th Inning",
      long: "5th Inning",
    },
    unit: PERIOD_UNIT_TYPES.INNINGS,
    order: 5,
    multiple: 1,
  },
  "6i": {
    periodID: "6i",
    supportsLiveOdds: true,
    startedIfAnyOfTheseEnded: ["7i", "8i", "9i", "1ix7", "reg", "ot", "2h", "game"],
    startedIfAnyOfTheseStarted: ["7i", "8i", "9i", "ot", "2h"],
    endedIfAnyOfTheseEnded: ["7i", "8i", "9i", "1ix7", "reg", "ot", "2h", "game"],
    endedIfAnyOfTheseStarted: ["7i", "8i", "9i", "ot"],
    isSubsetOf: ["2h", "1ix7"],
    correlatedWith: ["game", "reg", "2h", "1ix7"],
    startsAtEventStart: false,
    displays: {
      short: "6th",
      medium: "6th Inning",
      long: "6th Inning",
    },
    unit: PERIOD_UNIT_TYPES.INNINGS,
    order: 6,
    multiple: 1,
  },
  "7i": {
    periodID: "7i",
    supportsLiveOdds: true,
    startedIfAnyOfTheseEnded: ["8i", "9i", "reg", "ot", "2h", "1ix7", "game"],
    startedIfAnyOfTheseStarted: ["8i", "9i", "ot"],
    endedIfAnyOfTheseEnded: ["8i", "9i", "reg", "ot", "2h", "game", "1ix7"],
    endedIfAnyOfTheseStarted: ["8i", "9i", "ot"],
    isSubsetOf: ["2h", "1ix7"],
    correlatedWith: ["game", "reg", "2h", "1ix7"],
    startsAtEventStart: false,
    displays: {
      short: "7th",
      medium: "7th Inning",
      long: "7th Inning",
    },
    unit: PERIOD_UNIT_TYPES.INNINGS,
    order: 7,
    multiple: 1,
  },
  "8i": {
    periodID: "8i",
    supportsLiveOdds: true,
    startedIfAnyOfTheseEnded: ["9i", "reg", "ot", "2h", "game"],
    startedIfAnyOfTheseStarted: ["9i", "ot"],
    endedIfAnyOfTheseEnded: ["9i", "reg", "ot", "2h", "game"],
    endedIfAnyOfTheseStarted: ["9i", "ot"],
    isSubsetOf: ["2h"],
    correlatedWith: ["game", "reg", "2h"],
    startsAtEventStart: false,
    displays: {
      short: "8th",
      medium: "8th Inning",
      long: "8th Inning",
    },
    unit: PERIOD_UNIT_TYPES.INNINGS,
    order: 8,
    multiple: 1,
  },
  "9i": {
    periodID: "9i",
    supportsLiveOdds: true,
    startedIfAnyOfTheseEnded: ["reg", "ot", "2h", "game"],
    startedIfAnyOfTheseStarted: ["ot"],
    endedIfAnyOfTheseEnded: ["reg", "ot", "2h", "game"],
    endedIfAnyOfTheseStarted: ["ot"],
    isSubsetOf: ["2h"],
    correlatedWith: ["game", "reg", "2h"],
    startsAtEventStart: false,
    displays: {
      short: "9th",
      medium: "9th Inning",
      long: "9th Inning",
    },
    unit: PERIOD_UNIT_TYPES.INNINGS,
    order: 9,
    multiple: 1,
  },
  "1ix3": {
    periodID: "1ix3",
    supportsLiveOdds: true,
    sumParts: [["1i", "2i", "3i"]],
    startedIfAnyOfTheseEnded: ALL_PERIOD_IDS,
    startedIfAnyOfTheseStarted: ALL_PERIOD_IDS,
    endedIfAnyOfTheseEnded: ["3i", "4i", "5i", "6i", "7i", "8i", "9i", "1ix5", "1ix7", "reg", "ot", "1h", "2h", "game"],
    endedIfAnyOfTheseStarted: ["4i", "5i", "6i", "7i", "8i", "9i", "ot", "2h"],
    isSubsetOf: ["1h", "1ix5", "1ix7"],
    correlatedWith: ["game", "reg", "1h", "1i", "2i", "3i", "1ix5", "1ix7", "1m", "1mx4", "1mx5", "1mx8", "1mx10"],
    startsAtEventStart: true,
    displays: {
      short: "1st 3",
      medium: "1st 3 In.",
      long: "1st 3 Innings",
    },
    unit: PERIOD_UNIT_TYPES.INNINGS,
    order: 1,
    multiple: 3,
  },
  "1ix5": {
    periodID: "1ix5",
    supportsLiveOdds: true,
    sumParts: [["1i", "2i", "3i", "4i", "5i"]],
    startedIfAnyOfTheseEnded: ALL_PERIOD_IDS,
    startedIfAnyOfTheseStarted: ALL_PERIOD_IDS,
    endedIfAnyOfTheseEnded: ["5i", "6i", "7i", "8i", "9i", "1ix7", "reg", "ot", "1h", "2h", "game"],
    endedIfAnyOfTheseStarted: ["6i", "7i", "8i", "9i", "ot", "2h"],
    isSubsetOf: ["1ix7"],
    correlatedWith: ["game", "reg", "1h", "1i", "2i", "3i", "4i", "5i", "1ix3", "1ix7", "1m", "1mx4", "1mx5", "1mx8", "1mx10"],
    startsAtEventStart: true,
    displays: {
      short: "1st 5",
      medium: "1st 5 In.",
      long: "1st 5 Innings",
    },
    unit: PERIOD_UNIT_TYPES.INNINGS,
    order: 1,
    multiple: 5,
  },
  "1ix7": {
    periodID: "1ix7",
    supportsLiveOdds: true,
    sumParts: [["1i", "2i", "3i", "4i", "5i", "6i", "7i"]],
    startedIfAnyOfTheseEnded: ALL_PERIOD_IDS,
    startedIfAnyOfTheseStarted: ALL_PERIOD_IDS,
    endedIfAnyOfTheseEnded: ["7i", "8i", "9i", "reg", "ot", "1h", "2h", "game"],
    endedIfAnyOfTheseStarted: ["8i", "9i", "ot", "2h"],
    isSubsetOf: [],
    correlatedWith: ["game", "reg", "1h", "1i", "2i", "3i", "4i", "5i", "6i", "7i", "1ix3", "1ix5", "1m", "1mx4", "1mx5", "1mx8", "1mx10"],
    startsAtEventStart: true,
    displays: {
      short: "1st 7",
      medium: "1st 7 In.",
      long: "1st 7 Innings",
    },
    unit: PERIOD_UNIT_TYPES.INNINGS,
    order: 1,
    multiple: 7,
  },
  "1s": {
    periodID: "1s",
    supportsLiveOdds: true,
    startedIfAnyOfTheseEnded: ["2s", "3s", "4s", "5s", "game"],
    startedIfAnyOfTheseStarted: ["2s", "3s", "4s", "5s"],
    endedIfAnyOfTheseEnded: ["2s", "3s", "4s", "5s", "game"],
    endedIfAnyOfTheseStarted: ["2s", "3s", "4s", "5s"],
    isSubsetOf: [],
    correlatedWith: ["game", "reg", "1m", "1mx4", "1mx5", "1mx8", "1mx10"],
    startsAtEventStart: true,
    displays: {
      short: "1st",
      medium: "Set 1",
      long: "1st Set",
    },
    unit: PERIOD_UNIT_TYPES.SETS,
    order: 1,
    multiple: 1,
  },
  "2s": {
    periodID: "2s",
    supportsLiveOdds: true,
    startedIfAnyOfTheseEnded: ["3s", "4s", "5s", "game"],
    startedIfAnyOfTheseStarted: ["3s", "4s", "5s"],
    endedIfAnyOfTheseEnded: ["3s", "4s", "5s", "game"],
    endedIfAnyOfTheseStarted: ["3s", "4s", "5s"],
    isSubsetOf: ["game"],
    correlatedWith: ["game", "reg"],
    startsAtEventStart: false,
    displays: {
      short: "2nd",
      medium: "Set 2",
      long: "2nd Set",
    },
    unit: PERIOD_UNIT_TYPES.SETS,
    order: 2,
    multiple: 1,
  },
  "3s": {
    periodID: "3s",
    supportsLiveOdds: true,
    startedIfAnyOfTheseEnded: ["4s", "5s", "game"],
    startedIfAnyOfTheseStarted: ["4s", "5s"],
    endedIfAnyOfTheseEnded: ["4s", "5s", "game"],
    endedIfAnyOfTheseStarted: ["4s", "5s"],
    isSubsetOf: [],
    correlatedWith: ["game", "reg"],
    startsAtEventStart: false,
    displays: {
      short: "3rd",
      medium: "Set 3",
      long: "3rd Set",
    },
    unit: PERIOD_UNIT_TYPES.SETS,
    order: 3,
    multiple: 1,
  },
  "4s": {
    periodID: "4s",
    supportsLiveOdds: true,
    startedIfAnyOfTheseEnded: ["5s", "game"],
    startedIfAnyOfTheseStarted: ["5s"],
    endedIfAnyOfTheseEnded: ["5s", "game"],
    endedIfAnyOfTheseStarted: ["5s"],
    isSubsetOf: [],
    correlatedWith: ["game", "reg"],
    startsAtEventStart: false,
    displays: {
      short: "4th",
      medium: "Set 4",
      long: "4th Set",
    },
    unit: PERIOD_UNIT_TYPES.SETS,
    order: 4,
    multiple: 1,
  },
  "5s": {
    periodID: "5s",
    supportsLiveOdds: true,
    startedIfAnyOfTheseEnded: ["game"],
    startedIfAnyOfTheseStarted: [],
    endedIfAnyOfTheseEnded: ["game"],
    endedIfAnyOfTheseStarted: [],
    isSubsetOf: [],
    correlatedWith: ["game", "reg"],
    startsAtEventStart: false,
    displays: {
      short: "5th",
      medium: "Set 5",
      long: "5th Set",
    },
    unit: PERIOD_UNIT_TYPES.SETS,
    order: 5,
    multiple: 1,
  },
  "1r": {
    periodID: "1r",
    supportsLiveOdds: true,
    startedIfAnyOfTheseEnded: ALL_PERIOD_IDS,
    startedIfAnyOfTheseStarted: ALL_PERIOD_IDS,
    endedIfAnyOfTheseEnded: ["2r", "3r", "4r", "5r", "6r", "7r", "8r", "9r", "10r", "11r", "12r", "1rx2", "game"],
    endedIfAnyOfTheseStarted: ["2r", "3r", "4r", "5r", "6r", "7r", "8r", "9r", "10r", "11r", "12r"],
    isSubsetOf: ["1rx2"],
    correlatedWith: ["game", "reg", "1rx2", "1m", "1mx4", "1mx5", "1mx8", "1mx10"],
    startsAtEventStart: true,
    displays: {
      short: "R1",
      medium: "1st Rnd",
      long: "1st Round",
    },
    unit: PERIOD_UNIT_TYPES.ROUNDS,
    order: 1,
    multiple: 1,
  },
  "2r": {
    periodID: "2r",
    supportsLiveOdds: true,
    startedIfAnyOfTheseEnded: ["3r", "4r", "5r", "6r", "7r", "8r", "9r", "10r", "11r", "12r", "game", "1rx2"],
    startedIfAnyOfTheseStarted: ["3r", "4r", "5r", "6r", "7r", "8r", "9r", "10r", "11r", "12r"],
    endedIfAnyOfTheseEnded: ["3r", "4r", "5r", "6r", "7r", "8r", "9r", "10r", "11r", "12r", "game", "1rx2"],
    endedIfAnyOfTheseStarted: ["3r", "4r", "5r", "6r", "7r", "8r", "9r", "10r", "11r", "12r"],
    isSubsetOf: ["1rx2"],
    correlatedWith: ["game", "reg", "1rx2", "1r", "2r", "1mx8", "1mx10"],
    startsAtEventStart: false,
    displays: {
      short: "R2",
      medium: "2nd Rnd",
      long: "2nd Round",
    },
    unit: PERIOD_UNIT_TYPES.ROUNDS,
    order: 2,
    multiple: 1,
  },
  "3r": {
    periodID: "3r",
    supportsLiveOdds: true,
    startedIfAnyOfTheseEnded: ["4r", "5r", "6r", "7r", "8r", "9r", "10r", "11r", "12r", "game"],
    startedIfAnyOfTheseStarted: ["4r", "5r", "6r", "7r", "8r", "9r", "10r", "11r", "12r"],
    endedIfAnyOfTheseEnded: ["4r", "5r", "6r", "7r", "8r", "9r", "10r", "11r", "12r", "game"],
    endedIfAnyOfTheseStarted: ["4r", "5r", "6r", "7r", "8r", "9r", "10r", "11r", "12r"],
    isSubsetOf: [],
    correlatedWith: ["game", "reg"],
    startsAtEventStart: false,
    displays: {
      short: "R3",
      medium: "3rd Rnd",
      long: "3rd Round",
    },
    unit: PERIOD_UNIT_TYPES.ROUNDS,
    order: 3,
    multiple: 1,
  },
  "4r": {
    periodID: "4r",
    supportsLiveOdds: true,
    startedIfAnyOfTheseEnded: ["5r", "6r", "7r", "8r", "9r", "10r", "11r", "12r", "game"],
    startedIfAnyOfTheseStarted: ["5r", "6r", "7r", "8r", "9r", "10r", "11r", "12r"],
    endedIfAnyOfTheseEnded: ["5r", "6r", "7r", "8r", "9r", "10r", "11r", "12r", "game"],
    endedIfAnyOfTheseStarted: ["5r", "6r", "7r", "8r", "9r", "10r", "11r", "12r"],
    isSubsetOf: [],
    correlatedWith: ["game", "reg"],
    startsAtEventStart: false,
    displays: {
      short: "R4",
      medium: "4th Rnd",
      long: "4th Round",
    },
    unit: PERIOD_UNIT_TYPES.ROUNDS,
    order: 4,
    multiple: 1,
  },
  "5r": {
    periodID: "5r",
    supportsLiveOdds: true,
    startedIfAnyOfTheseEnded: ["6r", "7r", "8r", "9r", "10r", "11r", "12r", "game"],
    startedIfAnyOfTheseStarted: ["6r", "7r", "8r", "9r", "10r", "11r", "12r"],
    endedIfAnyOfTheseEnded: ["6r", "7r", "8r", "9r", "10r", "11r", "12r", "game"],
    endedIfAnyOfTheseStarted: ["6r", "7r", "8r", "9r", "10r", "11r", "12r"],
    isSubsetOf: [],
    correlatedWith: ["game", "reg"],
    startsAtEventStart: false,
    displays: {
      short: "R5",
      medium: "5th Rnd",
      long: "5th Round",
    },
    unit: PERIOD_UNIT_TYPES.ROUNDS,
    order: 5,
    multiple: 1,
  },
  "6r": {
    periodID: "6r",
    supportsLiveOdds: true,
    startedIfAnyOfTheseEnded: ["7r", "8r", "9r", "10r", "11r", "12r", "game"],
    startedIfAnyOfTheseStarted: ["7r", "8r", "9r", "10r", "11r", "12r"],
    endedIfAnyOfTheseEnded: ["7r", "8r", "9r", "10r", "11r", "12r", "game"],
    endedIfAnyOfTheseStarted: ["7r", "8r", "9r", "10r", "11r", "12r"],
    isSubsetOf: [],
    correlatedWith: ["game", "reg"],
    startsAtEventStart: false,
    displays: {
      short: "R6",
      medium: "6th Rnd",
      long: "6th Round",
    },
    unit: PERIOD_UNIT_TYPES.ROUNDS,
    order: 6,
    multiple: 1,
  },
  "7r": {
    periodID: "7r",
    supportsLiveOdds: true,
    startedIfAnyOfTheseEnded: ["8r", "9r", "10r", "11r", "12r", "game"],
    startedIfAnyOfTheseStarted: ["8r", "9r", "10r", "11r", "12r"],
    endedIfAnyOfTheseEnded: ["8r", "9r", "10r", "11r", "12r", "game"],
    endedIfAnyOfTheseStarted: ["8r", "9r", "10r", "11r", "12r"],
    isSubsetOf: [],
    correlatedWith: ["game", "reg"],
    startsAtEventStart: false,
    displays: {
      short: "R7",
      medium: "7th Rnd",
      long: "7th Round",
    },
    unit: PERIOD_UNIT_TYPES.ROUNDS,
    order: 7,
    multiple: 1,
  },
  "8r": {
    periodID: "8r",
    supportsLiveOdds: true,
    startedIfAnyOfTheseEnded: ["9r", "10r", "11r", "12r", "game"],
    startedIfAnyOfTheseStarted: ["9r", "10r", "11r", "12r"],
    endedIfAnyOfTheseEnded: ["9r", "10r", "11r", "12r", "game"],
    endedIfAnyOfTheseStarted: ["9r", "10r", "11r", "12r"],
    isSubsetOf: [],
    correlatedWith: ["game", "reg"],
    startsAtEventStart: false,
    displays: {
      short: "R8",
      medium: "8th Rnd",
      long: "8th Round",
    },
    unit: PERIOD_UNIT_TYPES.ROUNDS,
    order: 8,
    multiple: 1,
  },
  "9r": {
    periodID: "9r",
    supportsLiveOdds: true,
    startedIfAnyOfTheseEnded: ["10r", "11r", "12r", "game"],
    startedIfAnyOfTheseStarted: ["10r", "11r", "12r"],
    endedIfAnyOfTheseEnded: ["10r", "11r", "12r", "game"],
    endedIfAnyOfTheseStarted: ["10r", "11r", "12r"],
    isSubsetOf: [],
    correlatedWith: ["game", "reg"],
    startsAtEventStart: false,
    displays: {
      short: "R9",
      medium: "9th Rnd",
      long: "9th Round",
    },
    unit: PERIOD_UNIT_TYPES.ROUNDS,
    order: 9,
    multiple: 1,
  },
  "10r": {
    periodID: "10r",
    supportsLiveOdds: true,
    startedIfAnyOfTheseEnded: ["11r", "12r", "game"],
    startedIfAnyOfTheseStarted: ["11r", "12r"],
    endedIfAnyOfTheseEnded: ["11r", "12r", "game"],
    endedIfAnyOfTheseStarted: ["11r", "12r"],
    isSubsetOf: [],
    correlatedWith: ["game", "reg"],
    startsAtEventStart: false,
    displays: {
      short: "R10",
      medium: "10th Rnd",
      long: "10th Round",
    },
    unit: PERIOD_UNIT_TYPES.ROUNDS,
    order: 10,
    multiple: 1,
  },
  "11r": {
    periodID: "11r",
    supportsLiveOdds: true,
    startedIfAnyOfTheseEnded: ["12r", "game"],
    startedIfAnyOfTheseStarted: ["11r", "12r"],
    endedIfAnyOfTheseEnded: ["12r", "game"],
    endedIfAnyOfTheseStarted: ["12r"],
    isSubsetOf: [],
    correlatedWith: ["game", "reg"],
    startsAtEventStart: false,
    displays: {
      short: "R11",
      medium: "11th Rnd",
      long: "11th Round",
    },
    unit: PERIOD_UNIT_TYPES.ROUNDS,
    order: 11,
    multiple: 1,
  },
  "12r": {
    periodID: "12r",
    supportsLiveOdds: true,
    startedIfAnyOfTheseEnded: ["game"],
    startedIfAnyOfTheseStarted: [],
    endedIfAnyOfTheseEnded: ["game"],
    endedIfAnyOfTheseStarted: [],
    isSubsetOf: [],
    correlatedWith: ["game", "reg"],
    startsAtEventStart: false,
    displays: {
      short: "R12",
      medium: "12th Rnd",
      long: "12th Round",
    },
    unit: PERIOD_UNIT_TYPES.ROUNDS,
    order: 12,
    multiple: 1,
  },
  "1rx2": {
    periodID: "1rx2",
    supportsLiveOdds: true,
    sumParts: [["1r", "2r"]],
    startedIfAnyOfTheseEnded: ALL_PERIOD_IDS,
    startedIfAnyOfTheseStarted: ALL_PERIOD_IDS,
    endedIfAnyOfTheseEnded: ["2r", "3r", "4r", "5r", "6r", "7r", "8r", "9r", "10r", "11r", "12r", "game"],
    endedIfAnyOfTheseStarted: ["3r", "4r", "5r", "6r", "7r", "8r", "9r", "10r", "11r", "12r"],
    isSubsetOf: [],
    correlatedWith: ["game", "reg", "1r", "2r", "1m", "1mx4", "1mx5", "1mx8", "1mx10"],
    startsAtEventStart: true,
    displays: {
      short: "1st 2",
      medium: "1st 2 Rnds",
      long: "1st 2 Rounds",
    },
    unit: PERIOD_UNIT_TYPES.ROUNDS,
    order: 1,
    multiple: 2,
  },
  "1m": {
    periodID: "1m",
    supportsLiveOdds: false,
    startedIfAnyOfTheseEnded: ALL_PERIOD_IDS,
    startedIfAnyOfTheseStarted: ALL_PERIOD_IDS,
    endedIfAnyOfTheseEnded: ["game", "1q", "1qx3", "1h", "2h", "2q", "3q", "4q", "1p", "2p", "3p", "ot", "reg", "so", "1mx4", "1mx5", "1mx8", "1mx10", "1r", "1s", "1i"],
    endedIfAnyOfTheseStarted: ["2h", "2q", "3q", "4q", "2p", "3p", "ot", "so", "2r", "2s", "2i"],
    isSubsetOf: ["1mx4", "1mx5", "1mx8", "1mx10"],
    correlatedWith: ["game", "reg", "1q", "1qx3", "1h", "1p", "1r", "1rx2", "1s", "1i", "1mx4", "1mx5", "1mx8", "1mx10"],
    startsAtEventStart: true,
    displays: {
      short: "1st Min",
      medium: "1st Min.",
      long: "1st Minute",
    },
    unit: PERIOD_UNIT_TYPES.MINUTES,
    order: 1,
    multiple: 1,
  },
  "1mx4": {
    periodID: "1mx4",
    supportsLiveOdds: false,
    startedIfAnyOfTheseEnded: ALL_PERIOD_IDS,
    startedIfAnyOfTheseStarted: ALL_PERIOD_IDS,
    endedIfAnyOfTheseEnded: ["game", "1q", "1qx3", "1h", "2h", "2q", "3q", "4q", "1p", "2p", "3p", "ot", "reg", "so", "1mx4", "1mx5", "1mx8", "1mx10"],
    endedIfAnyOfTheseStarted: ["2h", "2q", "3q", "4q", "2p", "3p", "ot", "so"],
    isSubsetOf: ["1mx5", "1mx8", "1mx10"],
    correlatedWith: ["game", "reg", "1q", "1qx3", "1h", "1p", "1i", "1r", "1rx2", "1s", "1m", "1mx5", "1mx8", "1mx10"],
    startsAtEventStart: true,
    displays: {
      short: "1st 4 Min",
      medium: "1st 4 Mins.",
      long: "1st 4 Minutes",
    },
    unit: PERIOD_UNIT_TYPES.MINUTES,
    order: 1,
    multiple: 4,
  },
  "1mx5": {
    periodID: "1mx5",
    supportsLiveOdds: false,
    startedIfAnyOfTheseEnded: ALL_PERIOD_IDS,
    startedIfAnyOfTheseStarted: ALL_PERIOD_IDS,
    endedIfAnyOfTheseEnded: ["game", "1q", "1qx3", "1h", "2h", "2q", "3q", "4q", "1p", "2p", "3p", "ot", "reg", "so", "1mx4", "1mx5", "1mx8", "1mx10"],
    endedIfAnyOfTheseStarted: ["2h", "2q", "3q", "4q", "2p", "3p", "ot", "so"],
    isSubsetOf: ["1mx8", "1mx10"],
    correlatedWith: ["game", "reg", "1q", "1qx3", "1h", "1p", "1i", "1r", "2r", "1rx2", "1s", "1i", "1m", "1mx8", "1mx10"],
    startsAtEventStart: true,
    displays: {
      short: "1st 5 Min",
      medium: "1st 5 Mins.",
      long: "1st 5 Minutes",
    },
    unit: PERIOD_UNIT_TYPES.MINUTES,
    order: 1,
    multiple: 5,
  },
  "1mx8": {
    periodID: "1mx8",
    supportsLiveOdds: false,
    startedIfAnyOfTheseEnded: ALL_PERIOD_IDS,
    startedIfAnyOfTheseStarted: ALL_PERIOD_IDS,
    endedIfAnyOfTheseEnded: ["game", "1q", "1qx3", "1h", "2h", "2q", "3q", "4q", "1p", "2p", "3p", "ot", "reg", "so", "1mx4", "1mx5", "1mx8", "1mx10"],
    endedIfAnyOfTheseStarted: ["2h", "2q", "3q", "4q", "2p", "3p", "ot", "so"],
    isSubsetOf: ["1mx10"],
    correlatedWith: ["game", "reg", "1q", "1qx3", "1h", "1p", "1i", "1r", "1rx2", "1s", "2r", "1i", "1m", "1mx8", "1mx10"],
    startsAtEventStart: true,
    displays: {
      short: "1st 8 Min",
      medium: "1st 8 Mins.",
      long: "1st 8 Minutes",
    },
    unit: PERIOD_UNIT_TYPES.MINUTES,
    order: 1,
    multiple: 8,
  },
  "1mx10": {
    periodID: "1mx10",
    supportsLiveOdds: false,
    startedIfAnyOfTheseEnded: ALL_PERIOD_IDS,
    startedIfAnyOfTheseStarted: ALL_PERIOD_IDS,
    endedIfAnyOfTheseEnded: ["game", "1q", "1qx3", "1h", "2h", "2q", "3q", "4q", "1p", "2p", "3p", "ot", "reg", "so", "1mx4", "1mx5", "1mx8", "1mx10"],
    endedIfAnyOfTheseStarted: ["2h", "2q", "3q", "4q", "2p", "3p", "ot", "so"],
    isSubsetOf: [],
    correlatedWith: ["game", "reg", "1q", "1qx3", "1h", "1p", "1i", "1r", "1rx2", "1s", "2r", "1i", "1m", "1mx8", "1mx10"],
    startsAtEventStart: true,
    displays: {
      short: "1st 10 Min",
      medium: "1st 10 Mins.",
      long: "1st 10 Minutes",
    },
    unit: PERIOD_UNIT_TYPES.MINUTES,
    order: 1,
    multiple: 10,
  },
  "reg": {
    periodID: "reg",
    supportsLiveOdds: true,
    sumParts: [
      ["1q", "2q", "3q", "4q"],
      ["1h", "2h"],
      ["1p", "2p", "3p"],
      ["1i", "2i", "3i", "4i", "5i", "6i", "7i", "8i", "9i"],
    ],
    startedIfAnyOfTheseEnded: ALL_PERIOD_IDS,
    startedIfAnyOfTheseStarted: ALL_PERIOD_IDS,
    endedIfAnyOfTheseEnded: ["game", "2h", "4q", "3p", "9i", "ot", "so"],
    endedIfAnyOfTheseStarted: ["ot", "so"],
    isSubsetOf: [],
    correlatedWith: ALL_PERIOD_IDS.filter((pid) => pid !== "ot" && pid !== "so"),
    startsAtEventStart: true,
    displays: {
      short: "Reg",
      medium: "Regulation",
      long: "Regulation",
    },
  },
  "game": {
    periodID: "game",
    supportsLiveOdds: true,
    sumParts: [
      ["1q", "2q", "3q", "4q", "ot", "so"],
      ["1h", "2h", "ot", "so"],
      ["1p", "2p", "3p", "ot", "so"],
      ["1i", "2i", "3i", "4i", "5i", "6i", "7i", "8i", "9i", "ot"],
      ["1s", "2s", "3s", "4s", "5s"], //TODO may need to add ot/so when we add more sports
      ["1r", "2r", "3r", "4r", "5r", "6r", "7r", "8r", "9r", "10r", "11r", "12r"],
    ],
    startedIfAnyOfTheseEnded: ALL_PERIOD_IDS,
    startedIfAnyOfTheseStarted: ALL_PERIOD_IDS,
    isSubsetOf: [],
    correlatedWith: ALL_PERIOD_IDS,
    endedIfAnyOfTheseEnded: [], // Consider adding values here
    endedIfAnyOfTheseStarted: [],
    startsAtEventStart: true,
    displays: {
      short: "Event",
      medium: "Full Event",
      long: "Full Event",
    },
  },
  "ot": {
    periodID: "ot",
    supportsLiveOdds: false, // we can probably safely set this to true
    startedIfAnyOfTheseEnded: ["so"],
    startedIfAnyOfTheseStarted: ["so"],
    endedIfAnyOfTheseEnded: ["so"],
    endedIfAnyOfTheseStarted: ["so"],
    isSubsetOf: [],
    correlatedWith: ["game"],
    startsAtEventStart: false,
    displays: {
      short: "OT",
      medium: "Overtime",
      long: "Extra Time",
    },
  },
  "so": {
    periodID: "so",
    supportsLiveOdds: false, // we can probably safely set this to true
    sumPartsOnePointMode: true, // Means that if this periodID is found in sumParts - it only applies for team/game for the "points" stat and only grants 1 point to the winning side
    startedIfAnyOfTheseEnded: [],
    startedIfAnyOfTheseStarted: [],
    endedIfAnyOfTheseEnded: [],
    endedIfAnyOfTheseStarted: [],
    isSubsetOf: [],
    correlatedWith: ["game"],
    startsAtEventStart: false,
    displays: {
      short: "SO",
      medium: "Shootout",
      long: "Penalty Shootout",
    },
  },
};
exports.PERIOD_ID_CONFIG = PERIOD_ID_CONFIG;

const isExtraPeriodForLeague = (periodID, sportID, leagueID) => {
  if (!periodID) return false;
  const supportedExtrasMap = {};
  const extraPeriodIDs = getExtraPeriodIDs(sportID, leagueID);
  for (let i = 0; i < extraPeriodIDs.length; i++) {
    const extraPeriodID = extraPeriodIDs[i];
    if (extraPeriodID === periodID) return true;
    supportedExtrasMap[extraPeriodID] = true;
  }
  const periodConfig = getPeriodConfig(periodID);
  return (periodConfig?.sumParts || []).some((sumPartsList) => sumPartsList.every((pid) => supportedExtrasMap[pid]));
};
exports.isExtraPeriodForLeague = isExtraPeriodForLeague;

const periodIsSupportedByLeague = (periodID, sportID, leagueID) => {
  if (!periodID) return false;
  if (periodID === "game") return true;
  if (leagueID && !sportID) sportID = LEAGUE_CONFIG?.[leagueID]?.sportID;
  const extraUnsupportedPeriods = LEAGUE_CONFIG?.[leagueID]?.unsupportedPeriods || SPORT_CONFIG?.[sportID]?.unsupportedPeriods || [];
  if (extraUnsupportedPeriods.includes(periodID)) return false;
  const baseOnlyPeriodIDs = getBasePeriodIDs(sportID, leagueID, false);
  const extraPeriodIDs = getExtraPeriodIDs(sportID, leagueID);
  if (periodID === "reg") return extraPeriodIDs.length > 0;
  const supportedBaseExtrasMap = {};
  for (let i = 0; i < baseOnlyPeriodIDs.length; i++) {
    const supportedBaseOnlyPeriodID = baseOnlyPeriodIDs[i];
    if (supportedBaseOnlyPeriodID === periodID) return true;
    supportedBaseExtrasMap[supportedBaseOnlyPeriodID] = true;
  }
  for (let j = 0; j < extraPeriodIDs.length; j++) {
    const supportedExtraPeriodID = extraPeriodIDs[j];
    if (supportedExtraPeriodID === periodID) return true;
    supportedBaseExtrasMap[supportedExtraPeriodID] = true;
  }
  const periodConfig = getPeriodConfig(periodID);
  const clockType = LEAGUE_CONFIG?.[leagueID]?.clockType || SPORT_CONFIG?.[sportID]?.clockType || CLOCK_TYPES.NO_CLOCK;
  if (periodConfig?.unitType === PERIOD_UNIT_TYPES.MINUTES) return clockType !== CLOCK_TYPES.NO_CLOCK;
  const isSumPartsOfBases = (periodConfig?.sumParts || []).some((sumPartsList) => sumPartsList.every((pid) => supportedBaseExtrasMap[pid]));
  if (isSumPartsOfBases) return true;

  return false;
};
exports.periodIsSupportedByLeague = periodIsSupportedByLeague;

const getExtraPeriodIDs = (sportID, leagueID) => {
  const sportConfig = SPORT_CONFIG?.[sportID];
  const leagueConfig = LEAGUE_CONFIG?.[leagueID];
  const hasOverride = !!(leagueConfig?.basePeriods || leagueConfig?.extraPeriods);
  const extraPeriods = (hasOverride ? leagueConfig?.extraPeriods : sportConfig?.extraPeriods) || [];
  return [...extraPeriods];
};
exports.getExtraPeriodIDs = getExtraPeriodIDs;

const getBasePeriodIDs = (sportID, leagueID, includeExtraPeriods = true) => {
  const sportConfig = SPORT_CONFIG?.[sportID];
  const leagueConfig = LEAGUE_CONFIG?.[leagueID];
  const hasOverride = !!(leagueConfig?.basePeriods || leagueConfig?.extraPeriods);
  const basePeriods = (hasOverride ? leagueConfig?.basePeriods : sportConfig?.basePeriods) || [];
  const extraPeriods = includeExtraPeriods ? getExtraPeriodIDs(sportID, leagueID) : [];
  return [...basePeriods, ...extraPeriods];
};
exports.getBasePeriodIDs = getBasePeriodIDs;

const getPeriodConfig = (periodID) => {
  return PERIOD_ID_CONFIG?.[periodID] || null;
};
exports.getPeriodConfig = getPeriodConfig;

const getPeriodOrderPriority = (periodID) => ALL_PERIOD_IDS.indexOf(periodID) + 1;
exports.getPeriodOrderPriority = getPeriodOrderPriority;

const sortPeriods = (periodsToSort, basePeriodIDsArr) => {
  return periodsToSort.sort((a, b) => {
    const aIndex = (basePeriodIDsArr || []).indexOf(a);
    const bIndex = (basePeriodIDsArr || []).indexOf(b);
    if (aIndex === -1 && bIndex === -1) return a.localeCompare(b);
    if (aIndex === -1) return 1;
    if (bIndex === -1) return -1;
    return aIndex - bIndex;
  });
};
exports.sortPeriods = sortPeriods;

const getPeriodStatSumParts = (periodID, statEntityID, statID) => {
  const sumPartsConfigured = getPeriodConfig(periodID)?.sumParts || [];
  return sumPartsConfigured.reduce((allSumParts, singleSumParts) => {
    const filteredSingleSumParts = (singleSumParts || []).filter((sumPartPeriodID) => {
      const sumPartPeriodIDConfig = getPeriodConfig(sumPartPeriodID);
      if (!sumPartPeriodIDConfig) return false;
      if (!sumPartPeriodIDConfig.sumPartsOnePointMode) return true;
      if (statID !== "points") return false;
      if (statEntityID !== "all" && statEntityID !== "home" && statEntityID !== "away") return false;
      return true;
    });
    if (!filteredSingleSumParts.length) return allSumParts;
    allSumParts.push(filteredSingleSumParts);
    return allSumParts;
  }, []);
};
exports.getPeriodStatSumParts = getPeriodStatSumParts;

const calculatePeriodSumPartScore = (sumPartPeriodID, origOddData, getScoreForOddData) => {
  const { statID, statEntityID } = origOddData || {};
  const sumPartPeriodIDConfig = getPeriodConfig(sumPartPeriodID);
  if (!sumPartPeriodIDConfig || !statID || !statEntityID) return null;
  if (!sumPartPeriodIDConfig?.sumPartsOnePointMode) return getScoreForOddData({ ...origOddData, periodID: sumPartPeriodID });
  if (statID !== "points") return null;
  if (statEntityID !== "all" && statEntityID !== "home" && statEntityID !== "away") return null;
  if (statEntityID === "all") {
    const totalScore = getScoreForOddData({ ...origOddData, periodID: sumPartPeriodID });
    if (!isNumber(totalScore)) return null;
    return totalScore > 0 ? 1 : 0;
  }
  const homeScore = getScoreForOddData({ ...origOddData, statEntityID: "home", periodID: sumPartPeriodID });
  const awayScore = getScoreForOddData({ ...origOddData, statEntityID: "away", periodID: sumPartPeriodID });
  if (!isNumber(homeScore) && !isNumber(awayScore)) return null;
  const currentOddScore = (statEntityID === "home" ? homeScore : awayScore) ?? 0;
  const opponentScore = (statEntityID === "home" ? awayScore : homeScore) ?? 0;
  return currentOddScore > opponentScore ? 1 : 0;
};
exports.calculatePeriodSumPartScore = calculatePeriodSumPartScore;
