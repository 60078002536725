import React from "react";
import { Grid } from "@mui/material";
import { ENABLED_SPORT_IDS, SPORT_CONFIG } from "@wagerlab/utils/sports/sportConfig";
import { LEAGUE_CONFIG, SPORT_ENABLED_LEAGUES } from "@wagerlab/utils/sports/leagueConfig";
import { useFormikContext } from "formik";
import { SelectMenu } from "@wagerlab/admin/src/components/inputs/SelectMenu";
import { TextField } from "@wagerlab/admin/src/components/inputs/TextField";

const sportOptions = ENABLED_SPORT_IDS.reduce((sportOpts, sportID) => ({ ...sportOpts, [sportID]: SPORT_CONFIG?.[sportID]?.name || SPORT_CONFIG?.[sportID]?.shortName || sportID }), {});

export const EventFormIdentifiers = ({ isNew }) => {
  const { values } = useFormikContext();

  const selectedSportID = values?.sportID;
  const leageIDOptions = SPORT_ENABLED_LEAGUES?.[selectedSportID] || [];
  const leagueOptions = leageIDOptions.reduce((leagueOpts, leagueID) => ({ ...leagueOpts, [leagueID]: LEAGUE_CONFIG?.[leagueID]?.name || LEAGUE_CONFIG?.[leagueID]?.shortName || leagueID }), {});

  const canChangeSportLeague = values?.type === "prop";

  return (
    <>
      <Grid item xs={12} md={6}>
        <TextField name="eventID" label="Event ID" disabled />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField name="eventName" label="Event Name" />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectMenu name="sportID" label="Sport ID" options={sportOptions} disabled={!canChangeSportLeague} />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectMenu name="leagueID" label="League ID" options={leagueOptions} disabled={!canChangeSportLeague} />
      </Grid>
    </>
  );
};
