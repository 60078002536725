import React, { useMemo, useState } from "react";
import { Alert, Container, Snackbar, Typography } from "@mui/material";
import { copyData, dataChanged, dataUpdates } from "@wagerlab/utils/data/mutations";
import { initializeFormData, buildEventFromFormData, formFieldValidation, validateFormData } from "@wagerlab/admin/src/utils/events/eventReGradeForm";
import { getEvent, getEventBookOdds, saveEventChanges } from "@wagerlab/admin/src/utils/events/database";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import { EventReGraderForm } from "@wagerlab/admin/src/components/events/EventReGraderForm";
import { Loading } from "@wagerlab/admin/src/pages/Loading";
import { useEventsQuery } from "@wagerlab/admin/src/utils/events/useEventsQuery";

export const ReGradeEvent = () => {
  const { eventID } = useParams();
  const { fetchedEvent, fetchedEventBookOdds, loadingEvent } = useEventsQuery(eventID);
  const [message, setMessage] = useState("");

  const screenLoading = loadingEvent || !fetchedEvent?.eventID;

  const { initialEvent, initialEventBookOdds, initialEventForm } = useMemo(() => {
    const initEventData = screenLoading ? null : copyData(fetchedEvent);
    const initEventBookOddsData = screenLoading ? null : copyData(fetchedEventBookOdds);
    const initialEventFormData = initializeFormData(initEventData, initEventBookOddsData);
    return { initialEvent: initEventData, initialEventBookOdds: initEventBookOddsData, initialEventForm: initialEventFormData };
  }, [screenLoading, fetchedEvent, fetchedEventBookOdds]);

  const handleFormSubmit = async (formData, { resetForm }) => {
    const validationError = validateFormData(formData, initialEvent, initialEventBookOdds);
    if (validationError) return setMessage(validationError);

    const prevToNew = ({ event: prevEvent, eventBookOdds: prevEventBookOdds }) => buildEventFromFormData(formData, prevEvent, prevEventBookOdds);
    const eventSaveError = await saveEventChanges(initialEvent, initialEventBookOdds, prevToNew, false, true);
    if (eventSaveError) return setMessage(eventSaveError);

    return setMessage("Success!");
  };

  if (!initialEvent?.eventID) return <Loading />;

  return (
    <>
      <Container maxWidth="xl" sx={{ marginTop: 16, marginBottom: 8 }}>
        {initialEvent?.status?.finalized ? (
          <Formik initialValues={initialEventForm} onSubmit={handleFormSubmit} validationSchema={formFieldValidation}>
            <EventReGraderForm prevEvent={initialEvent} />
          </Formik>
        ) : (
          <Typography variant="h6">Event must be finalized to re-grade it</Typography>
        )}
      </Container>

      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={!!message} onClose={() => setMessage("")} autoHideDuration={10000}>
        <Alert onClose={() => setMessage("")} severity={"info"} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};
