const { isArray, isObject, isDate, isString, isEmptyObject, isEmptyValue, isNumber } = require("@wagerlab/utils/data/types");
const _ = require("lodash");
const { deepEqual } = require("fast-equals");

const copyData = (item) => {
  if (item == null || typeof item !== "object") return item;
  if (isArray(item)) {
    const newArr = [];
    for (let i = 0; i < item.length; i++) {
      newArr[i] = copyData(item[i]);
    }
    return newArr;
  }
  if (isDate(item)) return new Date(item.getTime());
  if (isObject(item)) {
    const newObj = {};
    const keys = Object.keys(item);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      newObj[key] = copyData(item[key]);
    }
    return newObj;
  }
  return item;
};
exports.copyData = copyData;

const DEFAULT_MERGE_STRATEGY = "updates_unlessEmpty";
// mergeConfig can have the following keys:  defaultStrategy, pathStrategy, pathErrorIfValueChanged
// defaultStrategy can be one of: existing_always, updates_always , existing_unlessNull, updates_unlessNull, existing_unlessEmpty, updates_unlessEmpty
// pathStrategy is an object where the keys are paths (dot-notation) and the values are one of the defaultStrategy values shown above. These will override defaultStrategy for the given path
// pathErrorIfValueChanged is an object where the keys are paths (dot-notation) and the values are true or false. If true, then if the existing value at the path is not null and the value at the path in the updates is different, then an error will be thrown. This is useful for catching data discrepancies.
// Notes: pathStrategy and pathErrorIfValueChanged must represent the full path to a value that is not an object/array. You can't reference a parent object. However you can use * as a wildcard (a.*.b, a.*.*.b, etc) to represent any number of levels of nesting.
const mergeDataAtPath = (existingData, dataUpdates, mergeConfig, path) => {
  const { defaultStrategy, pathStrategy, pathErrorIfValueChanged } = mergeConfig || {};
  if (pathErrorIfValueChanged?.[path] === true && existingData != null && dataUpdates != null && dataChanged(existingData, dataUpdates)) throw new Error("DATA_DISCREPANCY_FOUND_ERROR");
  const currentDepth = path.split(".").filter((p) => p).length;
  const currentMergeStrategyKey = Object.keys(pathStrategy || {})
    .filter((mergeConfigPath) => {
      const mergeConfigPathDepth = mergeConfigPath.split(".").filter((p) => p).length;
      if (mergeConfigPathDepth !== currentDepth) return false;
      const pathRegexString = mergeConfigPath
        .replace(".", "\\.")
        .split("*")
        .filter((p) => p)
        .join("[^.]+");
      const pathRegex = new RegExp(`^${pathRegexString}$`);
      return pathRegex.test(path);
    })
    .sort((pathA, pathB) => {
      const pathAParts = (pathA?.split?.(".") || []).filter((p) => p);
      const pathBParts = (pathB?.split?.(".") || []).filter((p) => p);
      const aNumNonWildcardParts = pathAParts.filter((p) => p !== "*").length;
      const bNumNonWildcardParts = pathBParts.filter((p) => p !== "*").length;
      if (aNumNonWildcardParts !== bNumNonWildcardParts) return bNumNonWildcardParts - aNumNonWildcardParts;
      const aFirstWildcardIndex = pathAParts.indexOf("*");
      const bFirstWildcardIndex = pathBParts.indexOf("*");
      return bFirstWildcardIndex - aFirstWildcardIndex;
    })?.[0];
  const mergeStrategy = mergeConfig?.[currentMergeStrategyKey] || defaultStrategy || DEFAULT_MERGE_STRATEGY;

  // if (isArray(existingData) && isArray(dataUpdates)) {
  //   const largerArray = existingData.length > dataUpdates.length ? existingData : dataUpdates;
  //   return largerArray.map((x, i) => mergeDataAtPath(existingData[i], dataUpdates[i], mergeConfig, `${path}.${i}`)).filter((x) => x != null);
  // }
  if (isObject(existingData) && isObject(dataUpdates)) {
    const keySet = new Set([...Object.keys(existingData), ...Object.keys(dataUpdates)]);
    return Array.from(keySet).reduce((mergedObj, key) => {
      const newPath = path ? `${path}.${key}` : key;
      const existingVal = existingData[key];
      const updatesVal = dataUpdates[key];
      mergedObj[key] = mergeDataAtPath(existingVal, updatesVal, mergeConfig, newPath);
      return mergedObj;
    }, {});
  }

  //Possible values are: existing_always, updates_always, existing_unlessNull, updates_unlessNull, existing_unlessEmpty, updates_unlessEmpty
  // If its existing_always or updates_always, always return the corresponding value
  // If its existing_unlessNull or updates_unlessNull, then if both are null, return null (or undefined). If one is null but not the other, return the non-null one. If both are non-null, return the corresponding values (existing for existing_unlessNull and updates for updates_unlessNull ).
  // If its existing_unlessEmpty or updates_unlessEmpty, then if both are null, return null (or undefined). If one is null but not the other, return the non-null one. If both are non-null,then check to see if each is empty and if both are empty, return null (or undefined). If one is empty but not the other, return the non-empty one. If both are non-empty, return the corresponding values (existing for existing_unlessEmpty and updates for updates_unlessEmpty ).
  // Order is important here.

  if (mergeStrategy === "existing_always") return existingData;
  if (mergeStrategy === "updates_always") return dataUpdates;

  const existingDataNull = existingData == null;
  const dataUpdatesNull = dataUpdates == null;
  if (mergeStrategy === "existing_unlessNull") return existingDataNull ? dataUpdates : existingData;
  if (mergeStrategy === "updates_unlessNull") return dataUpdatesNull ? existingData : dataUpdates;

  const existingDataEmpty = isEmptyValue(existingData);
  const dataUpdatesEmpty = isEmptyValue(dataUpdates);
  if (mergeStrategy === "existing_unlessEmpty") return existingDataEmpty ? dataUpdates : existingData;
  return dataUpdatesEmpty ? existingData : dataUpdates;
};
const mergeData = (existingData, dataUpdates, mergeConfig = {}) => {
  try {
    return mergeDataAtPath(existingData, dataUpdates, mergeConfig, "");
  } catch (e) {
    if (e.message === "DATA_DISCREPANCY_FOUND_ERROR") return null;
    throw e;
  }
};
exports.mergeData = mergeData;

const dataChanged = (a, b, treatEmptyValuesSame = true, onlyCommonPaths = false) => {
  if (a == null) return treatEmptyValuesSame ? b !== "" && b != null : b != null;
  if (typeof a === "object") {
    if (Array.isArray(a)) {
      if (!Array.isArray(b)) {
        if (!treatEmptyValuesSame) return true;
        // If both empty then they are equal, so return false
        return a.length === 0 && (b == null || b === "" || isEmptyObject(b)) ? false : true;
      }
      const arrLen = onlyCommonPaths ? Math.min(a.length, b.length) : Math.max(a.length, b.length);
      for (let i = 0; i < arrLen; i++) {
        if (dataChanged(a[i], b[i], treatEmptyValuesSame)) return true;
      }
      return false;
    }
    if (isDate(a)) return isDate(b) ? a.getTime() !== b.getTime() : true;
    if (isObject(a)) {
      if (!isObject(b)) {
        if (!treatEmptyValuesSame) return true;
        // If both empty then they are equal, so return false
        return (b == null || b?.length === 0) && Object.keys(a).length === 0 ? false : true;
      }
      const aKeys = Object.keys(a);
      for (let i = 0; i < aKeys.length; i++) {
        if (onlyCommonPaths) {
          const aIsNull = treatEmptyValuesSame ? a[aKeys[i]] == null || a[aKeys[i]] === "" : a[aKeys[i]] == null;
          const bIsNull = treatEmptyValuesSame ? b[aKeys[i]] == null || b[aKeys[i]] === "" : b[aKeys[i]] == null;
          if (aIsNull || bIsNull) continue;
        }
        if (dataChanged(a[aKeys[i]], b[aKeys[i]], treatEmptyValuesSame)) return true;
      }
      const bUniqueKeys = Object.keys(b).filter((key) => !(key in a));
      for (let i = 0; i < bUniqueKeys.length; i++) {
        if (onlyCommonPaths) {
          const aIsNull = treatEmptyValuesSame ? a[bUniqueKeys[i]] == null || a[bUniqueKeys[i]] === "" : a[bUniqueKeys[i]] == null;
          const bIsNull = treatEmptyValuesSame ? b[bUniqueKeys[i]] == null || b[bUniqueKeys[i]] === "" : b[bUniqueKeys[i]] == null;
          if (aIsNull || bIsNull) continue;
        }
        if (dataChanged(a[bUniqueKeys[i]], b[bUniqueKeys[i]], treatEmptyValuesSame)) return true;
      }
      return false;
    }
  }
  if (a === "") return treatEmptyValuesSame ? b !== "" && b != null : b !== "";
  return a !== b;
};
exports.dataChanged = dataChanged;

//  Returns updates in specified-notation
const dataUpdates = (prevData, newData, removalValue = null, pathSeparator = ".", path = "", canRollUpUpdates = true) => {
  const newDataIsObj = typeof newData === "object" && !!newData && !isArray(newData) && !isDate(newData);
  if (!path && !newDataIsObj) return null;
  if (!newDataIsObj) return newData ?? removalValue;
  const prevDataIsObj = typeof prevData === "object" && !!prevData && !isArray(prevData) && !isDate(prevData);
  if (!prevDataIsObj) return newData ?? removalValue;
  let updates = {};
  for (let key in newData) {
    const newPath = path ? `${path}${pathSeparator || "."}${key}` : key;
    const newChild = newData[key];
    const prevChild = prevData[key];
    const bothChildrenObjs =
      typeof newChild === "object" && !!newChild && !isArray(newChild) && !isDate(newChild) && typeof prevChild === "object" && !!prevChild && !isArray(prevChild) && !isDate(prevChild);
    let shouldGetChildDrillDownUpdates = bothChildrenObjs;
    if (canRollUpUpdates) shouldGetChildDrillDownUpdates = shouldGetChildDrillDownUpdates && Object.keys(newData[key]).some((k) => !dataChanged(prevData[key][k], newData[key][k]));

    if (shouldGetChildDrillDownUpdates) {
      const childUpdates = dataUpdates(prevData[key], newData[key], removalValue, pathSeparator, newPath, canRollUpUpdates) || {};
      updates = { ...updates, ...childUpdates };
    } else if (dataChanged(prevData[key], newData[key])) {
      updates[newPath] = newData[key];
    }
  }
  for (let key in prevData) {
    if (!(key in newData)) {
      const newPath = path ? `${path}${pathSeparator || "."}${key}` : key;
      updates[newPath] = removalValue;
    }
  }
  if (!Object.keys(updates).length && !path) return null;
  return updates;
};
exports.dataUpdates = dataUpdates;

// Returns data updates but only for paths that are new (or updated if canOverwrite=true) and have non-empty values.  Setting onlyPaths will cause only paths at or nested under those paths to be included
// The output format will be the same as dataUpdates - ie: an object with keys as dot-notation or specified notation paths. only 1 level deep. null if no updates
const dataAdditions = (prevData, newData, onlyPaths = [], addPrefixToPaths = "") => {
  const keysToExplore = onlyPaths.length ? onlyPaths : Object.keys(newData);
  const allDataAdditions = keysToExplore.reduce((additions, key) => {
    const childAdditions = appendDataAdditions(prevData, newData, addPrefixToPaths, key);
    return childAdditions ? { ...additions, ...childAdditions } : additions;
  }, {});
  return isEmptyObject(allDataAdditions) ? null : allDataAdditions;
};
exports.dataAdditions = dataAdditions;
const appendDataAdditions = (prevData, newData, addPrefixToPaths, currentPath) => {
  const prevItem = _.get(prevData, currentPath);
  const newItem = _.get(newData, currentPath);
  if (isEmptyValue(newItem)) return null;
  if (isObject(newItem) && (isObject(prevItem) || isEmptyValue(prevItem))) {
    const objectAdditions = Object.keys(newItem).reduce((additions, childKey) => {
      const childAdditions = appendDataAdditions(prevData, newData, addPrefixToPaths, `${currentPath}.${childKey}`);
      return childAdditions ? { ...additions, ...childAdditions } : additions;
    }, {});
    return isEmptyObject(objectAdditions) ? null : objectAdditions;
  }
  if (!isEmptyValue(prevItem)) return null;
  const additionPath = addPrefixToPaths && addPrefixToPaths.endsWith(".") ? `${addPrefixToPaths}${currentPath}` : addPrefixToPaths ? `${addPrefixToPaths}.${currentPath}` : currentPath;
  return { [additionPath]: newItem };
};

const stripValues = (item, stripAllEmptyValues = false) => {
  if (item == null) {
    return stripAllEmptyValues ? null : item;
  } else if (typeof item === "object") {
    if (isArray(item)) {
      item = item.reduce((acc, value) => {
        const strippedVal = stripValues(value, stripAllEmptyValues);
        if (strippedVal != null) acc.push(strippedVal);
        return acc;
      }, []);
      if (!item.length && stripAllEmptyValues) item = null;
      return item;
    } else if (isDate(item)) {
      return item;
    } else {
      let isEmpty = true;
      let newObj = {};
      for (const [key, value] of Object.entries(item)) {
        const strippedVal = stripValues(value, stripAllEmptyValues);
        if (strippedVal != null) {
          newObj[key] = strippedVal;
          isEmpty = false;
        }
      }
      item = isEmpty && stripAllEmptyValues ? null : newObj;
      return item;
    }
  } else {
    return item === "" && stripAllEmptyValues ? null : item;
  }
};
exports.stripEmptyValues = (item) => stripValues(item, true);
exports.stripNullValues = (item) => stripValues(item, false);

const setObjVal = (data, path, value) => {
  const keys = path?.split?.(".");
  if (!keys?.length) return data;
  let current = data;
  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];
    if (!(key in current) || typeof current[key] !== "object" || Array.isArray(current[key])) {
      current[key] = {};
    }
    current = current[key];
  }
  const lastKey = keys[keys.length - 1];
  current[lastKey] = value;
  return data;
};
exports.setObjVal = setObjVal;

const DEFAULT_CHANGE_CONFIG = {
  ignorePaths: {
    // note: does not work with array indexes.  only top level of arrays
    //ex  "some.path.to.any.level": true  //causes these paths to be ignored completely
  },
  defaultPaths: {
    // note: does not work with array indexes. only top level of arrays
    // IMPORTANT: default paths will be UNIGNORED. If you ignore them (or their parent), they will be reinitialized based on the provided values + default value
    //ex "some.path.to.any.level": 0 // causes this path to default to 0 if FALSY
  },
  onlyCommonPaths: false, //if true, only paths that exist in both prevData and newData will be considered
  treatEmptyValuesSame: true, //if true, all empty values will be treated as null
};
const meaningfulDataChanged = (prevData, newData, changeConfig = null) => {
  const { ignorePaths, defaultPaths, onlyCommonPaths, treatEmptyValuesSame } = changeConfig || DEFAULT_CHANGE_CONFIG;

  if (!isObject(prevData) || !isObject(newData)) return dataChanged(prevData, newData, treatEmptyValuesSame, onlyCommonPaths);

  const hasIgnorePaths = !isEmptyObject(changeConfig?.ignorePaths || {});
  const hasDefaultPaths = !isEmptyObject(changeConfig?.defaultPaths || {});

  const prevDataObj = copyData(prevData);
  const newDataObj = copyData(newData);

  if (hasIgnorePaths) {
    Object.entries(ignorePaths).forEach(([pathString, shouldIgnorePath]) => {
      if (!pathString || !shouldIgnorePath) return;
      const pathsToUnset = getPathsWithoutCatchAll(prevData, newData, pathString);
      pathsToUnset.forEach((path) => {
        _.unset(prevDataObj, path);
        _.unset(newDataObj, path);
      });
    });
  }
  if (hasDefaultPaths) {
    Object.entries(defaultPaths).forEach(([pathString, defaultValue]) => {
      if (!pathString) return;
      const pathsToSet = getPathsWithoutCatchAll(prevData, newData, pathString);
      pathsToSet.forEach((path) => {
        const prevVal = copyData(_.get(prevData, path) || defaultValue);
        const newVal = copyData(_.get(newData, path) || defaultValue);
        setObjVal(prevDataObj, path, prevVal);
        setObjVal(newDataObj, path, newVal);
      });
    });
  }

  return dataChanged(prevDataObj, newDataObj, treatEmptyValuesSame, onlyCommonPaths);
};
exports.meaningfulDataChanged = meaningfulDataChanged;

const getPathsWithoutCatchAll = (prevData, newData, pathString) => {
  if (!isString(pathString) || !pathString?.length || pathString.startsWith(".") || pathString.startsWith("*")) return [];
  return parsePaths(prevData, newData, "", pathString);
};
const parsePaths = (prevData, newData, currentPath, remainingPath) => {
  const catchAllIndex = remainingPath.indexOf(".*");
  if (catchAllIndex > -1) {
    const remainingBeforeCatchAll = remainingPath.substring(0, catchAllIndex);
    const newCurrentPath = `${currentPath || ""}${currentPath && remainingBeforeCatchAll ? "." : ""}${remainingBeforeCatchAll || ""}`;
    const newRemainingPath = remainingPath.substring(catchAllIndex + 2);
    const prevDataAtPath = _.get(prevData, newCurrentPath);
    const newDataAtPath = _.get(newData, newCurrentPath);
    const prevDataKeysAtPath = isObject(prevDataAtPath) ? Object.keys(prevDataAtPath) : [];
    const newDataKeysAtPath = isObject(newDataAtPath) ? Object.keys(newDataAtPath) : [];
    const keys = [...new Set([...prevDataKeysAtPath, ...newDataKeysAtPath])];
    const paths = keys.reduce((pathsArr, keyStr) => {
      if (!keyStr) return pathsArr;
      const newPathPrefix = `${newCurrentPath || ""}${newCurrentPath && keyStr ? "." : ""}${keyStr || ""}`;
      const newPathsArr = parsePaths(prevData, newData, newPathPrefix, newRemainingPath);
      return [...pathsArr, ...newPathsArr];
    }, []);
    return paths;
  }
  return [`${currentPath || ""}${currentPath && remainingPath && !remainingPath.startsWith(".") ? "." : ""}${remainingPath || ""}`];
};

const serialize = (data, shouldConvert, converter, inPlace = false) => {
  if (shouldConvert?.(data) && converter) return converter(data);
  // if (isDate(data)) return { _agg_type: "Date", value: data.toISOString() };
  if (isArray(data)) {
    if (inPlace) {
      for (let i = 0; i < data.length; i++) {
        data[i] = serialize(data[i], shouldConvert, converter, true);
      }
      return data;
    }
    return data.map((item) => serialize(item, shouldConvert, converter, false));
  }
  if (isObject(data)) {
    const result = inPlace ? data : {};
    for (const key of Object.keys(data)) {
      result[key] = serialize(data[key], shouldConvert, converter, inPlace);
    }
    return result;
  }
  return data;
};
exports.serialize = serialize;

const deserialize = (data, shouldUnconvert, unconverter, inPlace = false) => {
  if (shouldUnconvert?.(data) && unconverter) return unconverter(data);
  if (isObject(data)) {
    // if (data._agg_type === "Date") return new Date(data.value);

    const result = inPlace ? data : {};
    for (const key of Object.keys(data)) {
      result[key] = deserialize(data[key], shouldUnconvert, unconverter, inPlace);
    }
    return result;
  }
  if (isArray(data)) {
    if (inPlace) {
      for (let i = 0; i < data.length; i++) {
        data[i] = deserialize(data[i], shouldUnconvert, unconverter, true);
      }
      return data;
    }
    return data.map((item) => deserialize(item, shouldUnconvert, unconverter, false));
  }
  return data;
};
exports.deserialize = deserialize;

const serializeDates = (data, inPlace = false) => serialize(data, isDate, (date) => ({ _wl_type: "_WL_Date", value: date.toISOString() }), inPlace);
exports.serializeDates = serializeDates;

const deserializeDates = (data, inPlace = false) =>
  deserialize(
    data,
    (item) => item?._wl_type === "_WL_Date",
    (item) => new Date(item.value),
    inPlace
  );
exports.deserializeDates = deserializeDates;

const updateNestedValue = (obj, path, operator, value, unique = true) => {
  if (!["add", "subtract", "append"].includes(operator)) return obj;

  let currentValue = _.get(obj, path);

  // If currentValue is undefined, initialize it with a default value based on the operator
  if (currentValue == null) {
    if (operator === "add" || operator === "subtract") currentValue = 0;
    if (operator === "append") currentValue = [];
  }

  switch (operator) {
    case "add":
      if (isNumber(currentValue)) setObjVal(obj, path, currentValue + value);
      break;
    case "subtract":
      if (isNumber(currentValue)) setObjVal(obj, path, currentValue - value);
      break;
    case "append":
      if (isArray(currentValue)) {
        if (unique && currentValue?.includes(value)) return obj;
        setObjVal(obj, path, currentValue.concat(value));
      }
      break;
  }

  return obj;
};
exports.updateNestedValue = updateNestedValue;

const trimObjectKeys = (obj, keyLength = 10) => {
  let trimmedObj = {};
  const keys = Object.keys(obj);
  const minLen = Math.min(keys.length, keyLength);
  for (let i = 0; i < minLen; i++) {
    const key = keys[i];
    trimmedObj[key] = obj[key];
  }

  return trimmedObj;
};
exports.trimObjectKeys = trimObjectKeys;

const escapePath = (path, oneWay = false) => {
  if (oneWay) return `${path ?? ""}`.replace(/[\.\[\]]/g, "_-_") || null;
  return `${path ?? ""}`.replace(/\./g, "{DOT}").replace(/\[/g, "{LBRACK}").replace(/\]/g, "{RBRACK}") || null;
};
exports.escapePath = escapePath;

const unEscapePath = (escapedPath) => {
  return (
    `${escapedPath ?? ""}`
      .replace(/{DOT}/g, ".")
      .replace(/{LBRACK}/g, "[")
      .replace(/{RBRACK}/g, "]") || null
  );
};
exports.unEscapePath = unEscapePath;
