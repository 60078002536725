import React, { useState } from "react";
import { getLeague } from "@wagerlab/admin/src/utils/teamLookups/database";
import _ from "lodash";
import { deleteValue, writeBatch } from "@wagerlab/utils/database/firestore";
import { dataChanged, dataUpdates } from "@wagerlab/utils/data/mutations";
import { ManageExistingLookups } from "@wagerlab/admin/src/components/lookups/shared/manage/ManageExistingLookups";

export const ManageExistingTeamIds = ({ leagueID }) => {
  const [leagueData, setLeagueData] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleRefresh = async () => {
    setLoading(true);
    const dbLeagueData = await getLeague(leagueID);
    setLeagueData(dbLeagueData);
    setLoading(false);
  };
  const handleSubmit = async (newTeamLookups) => {
    setLoading(true);

    const dbLeagueData = await getLeague(leagueID);
    const didChange = dataChanged(leagueData?.teamLookups || {}, dbLeagueData?.teamLookups || {});
    if (didChange) {
      const shouldContinue = window.confirm("League data has changed since you loaded it. Are you sure you want to submit changes?");
      if (!shouldContinue) {
        setLoading(false);
        return "Changes not submitted";
      }
    }

    const lookupDataChanges = dataUpdates(dbLeagueData?.teamLookups || {}, newTeamLookups, deleteValue(), ".", "teamLookups");

    if (!lookupDataChanges) {
      setLoading(false);
      return "No changes to write";
    }

    const batchWrites = [
      { method: "update", collectionName: "Leagues", collectionID: leagueID, payload: lookupDataChanges },
      { method: "update", collectionName: "Config", collectionID: "aggregator", payload: { "runner.cacheInvalidated": true } },
    ];

    let batchResult = await writeBatch(batchWrites);
    if (!batchResult?.allSuccess) {
      return "Failed to save changes to DB.  Write failed.";
    }

    return "";
  };

  return <ManageExistingLookups loading={loading} lookupData={leagueData?.teamLookups} handleSubmit={handleSubmit} handleRefresh={handleRefresh} />;
};
