import { copyData, setObjVal, stripNullValues } from "@wagerlab/utils/data/mutations";
import { isNumber } from "@wagerlab/utils/data/types";
import { refreshEvent } from "@wagerlab/utils/events/refresh";
import * as Yup from "yup";
import moment from "moment-timezone";
import { oddsSetSorter, oddsSorter } from "@wagerlab/utils/events/oddOrdering";
import _ from "lodash";

// cancelled, ended, displayShort, displayLong, propList
// each propList item -> periodID, statID, propTitle, propDetails, cancelled, ended, oddIDs, propSideWinner

export const propSideWinnerResults = (propSideWinner) => {
  if (propSideWinner === "side1") return { side1: 1, side2: -1 };
  if (propSideWinner === "side2") return { side1: -1, side2: 1 };
  if (propSideWinner === "push") return { side1: 0, side2: 0 };
  return null;
};

export const initializeFormData = (eventData, eventBookOddsData) => {
  const propList = [];
  Object.entries(eventData?.props || {}).map(([propStatID, propData]) => {
    const { statID, propTitle, propDetails, numericScoring, sides } = propData || {};

    const oddObjects = Object.values(eventData?.odds || {}).filter(
      (odd) => odd?.statID === statID && odd.betTypeID === "prop" && (odd.statEntityID === "side1" || odd.statEntityID === "side2") && odd.statEntityID === odd.sideID
    );

    const allPeriodIDs = oddObjects.map((odd) => odd?.periodID);
    const periodIDs = _.uniq(allPeriodIDs);

    periodIDs.forEach((periodID) => {
      const cancelled = oddObjects.some((odd) => odd?.periodID === periodID && odd?.cancelled);
      const ended = oddObjects.some((odd) => odd?.periodID === periodID && (odd?.cancelled || odd?.ended));
      const oddIDs = oddObjects.filter((odd) => odd?.periodID === periodID).map((odd) => odd?.oddID);

      const side1Score = eventData?.results?.[periodID]?.side1?.[statID];
      const side2Score = eventData?.results?.[periodID]?.side2?.[statID];
      const hasScore = isNumber(side1Score) && isNumber(side2Score);
      const propSideWinner = !hasScore ? "" : side1Score > side2Score ? "side1" : side2Score > side1Score ? "side2" : "push";
      propList.push({
        periodID,
        numericScoring,
        propTitle,
        propDetails,
        statID,
        cancelled,
        ended,
        oddIDs,
        propSideWinner,
      });
    });
  });

  // let resultsSummaries = [];
  // const pathsAddedMap = {};

  // let oddIDGroups = {};
  // Object.entries(eventData?.odds || {}).forEach(([oddID, oddData]) => {
  //   const { periodID, statID, statEntityID, cancelled, ended, oddID: oid } = oddData || {};
  //   const score = eventData?.results?.[periodID]?.[statEntityID]?.[statID];
  //   if (!periodID || !statID || !statEntityID || !oddID || oddID !== oid) return;
  //   const oddIDGroup = oddIDGroups[`${periodID}.${statEntityID}.${statID}`] || [];
  //   if (!oddIDGroup.includes(oddID)) oddIDGroup.push(oddID);
  //   oddIDGroups[`${periodID}.${statEntityID}.${statID}`] = oddIDGroup;
  //   if (pathsAddedMap[`${periodID}.${statEntityID}.${statID}`]) {
  //     if (cancelled || ended) resultsSummaries = resultsSummaries.map((rs) => ({ ...rs, cancelled: !!(rs?.cancelled || cancelled), ended: !!(rs?.ended || ended) }));
  //     return;
  //   }
  //   pathsAddedMap[`${periodID}.${statEntityID}.${statID}`] = true;
  //   resultsSummaries.push({ periodID, statID, statEntityID, score, cancelled, ended });
  // });

  // resultsSummaries = resultsSummaries
  //   .map((resultSummary) => {
  //     const { periodID, statID, statEntityID, score } = resultSummary || {};
  //     const oddIDs = (oddIDGroups[`${periodID}.${statEntityID}.${statID}`] || []).sort();
  //     return { ...resultSummary, oddIDs };
  //   })
  //   .sort((a, b) => oddsSetSorter(eventData?.odds[a?.oddIDs?.[0]], eventData?.odds[b?.oddIDs?.[0]]));

  return {
    ended: eventData?.status?.ended || eventData?.status?.completed,
    cancelled: eventData?.status?.cancelled,
    displayShort: eventData?.status?.displayShort,
    displayLong: eventData?.status?.displayLong,
    propList,
    // resultsSummaries,
  };
};

export const validateFormData = (eventForm, prevEvent, prevEventBookOdds) => {
  if (!prevEvent?.manual || prevEvent?.type !== "prop") return "Event is not a manual prop event. This shouldn't happen!!!";

  if (!eventForm?.propList?.length) return "Missing odds results data. No odds found";

  const incompleteResult = eventForm?.propList?.find((rs) => !eventForm?.cancelled && !rs?.cancelled && (eventForm?.ended || rs?.ended) && !propSideWinnerResults(rs?.propSideWinner));
  if (incompleteResult) return `Result missing data to finalize: ${incompleteResult?.propTitle} - (${incompleteResult?.periodID})`;

  return "";
};

export const buildEventFromFormData = (eventForm, prevEvent, prevEventBookOdds) => {
  let event = copyData(prevEvent);
  let eventBookOdds = copyData(prevEventBookOdds);
  ({ event, eventBookOdds } = refreshEvent(event, eventBookOdds));

  if (!event?.eventID) return { event: null, eventBookOdds: null };

  setObjVal(event, "manual", true);
  setObjVal(event, "status.displayShort", eventForm.displayShort);
  setObjVal(event, "status.displayLong", eventForm.displayLong);
  setObjVal(event, "status.cancelled", eventForm.cancelled);
  setObjVal(event, "status.ended", eventForm.ended);
  setObjVal(event, "status.completed", eventForm.ended && !eventForm.cancelled);

  (eventForm?.propList || []).forEach((propListItem) => {
    const { periodID, statID, cancelled, ended, propSideWinner, oddIDs } = propListItem || {};

    if (cancelled || ended) {
      (oddIDs || []).forEach((oddID) => {
        setObjVal(event, `odds.${oddID}.cancelled`, !!cancelled);
        setObjVal(event, `odds.${oddID}.ended`, !!ended);
        setObjVal(eventBookOdds, `odds.${oddID}.cancelled`, !!cancelled);
        setObjVal(eventBookOdds, `odds.${oddID}.ended`, !!ended);
      });
    }
    if (cancelled) {
      setObjVal(event, `results.${periodID}.side1.${statID}`, null);
      setObjVal(event, `results.${periodID}.side2.${statID}`, null);
      return;
    }
    const propSideResults = propSideWinnerResults(propSideWinner);
    if (!propSideResults) return;
    setObjVal(event, "status.started", true);
    setObjVal(event, `results.${periodID}.side1.${statID}`, propSideResults.side1);
    setObjVal(event, `results.${periodID}.side2.${statID}`, propSideResults.side2);
  });

  ({ event, eventBookOdds } = refreshEvent(event, eventBookOdds));
  return { event, eventBookOdds };
};

export const formFieldValidation = Yup.object().shape({
  displayShort: Yup.string(),
  displayLong: Yup.string(),
  cancelled: Yup.boolean().required("Event cancelled must be Yes or No"),
  propList: Yup.array()
    .of(
      Yup.object({
        periodID: Yup.string().required("periodID is required"),
        statID: Yup.string().required("statID is required"),
        cancelled: Yup.boolean().required("Result cancelled is required"),
        ended: Yup.boolean().required("Result ended is required"),
        // score: Yup.mixed().when(["cancelled", "ended"], {
        //   is: (cancelled, ended) => !cancelled && !!ended,
        //   then: () => Yup.number().required("score is required"),
        //   otherwise: () => Yup.mixed().nullable(),
        // }),
      })
    )
    .required("results are required")
    .min(1, "At least one result/odd item is required"),
});
