const { parseDate } = require("@wagerlab/utils/data/dates");
const { setObjVal } = require("@wagerlab/utils/data/mutations");
const { isNumber, isDate } = require("@wagerlab/utils/data/types");
const { eventRequiresManualScoring } = require("@wagerlab/utils/events/getters");
const { logWarning } = require("@wagerlab/utils/logging");
const { asSpreadFloat, roundMoneyline } = require("@wagerlab/utils/odds/converters");
const { supportsOddScore, getOddScore } = require("@wagerlab/utils/odds/scoring");
const { getPeriodConfig } = require("@wagerlab/utils/sports/periods");
const { getPeriodsStartedEndedMap, getPeriodStartedEnded } = require("@wagerlab/utils/sports/periodStatus");

const EVENT_LIKELY_LIVE_AFTER_MINS = 60;
const LIVE_ODDS_MIN_FRESHNESS_MINS = 15;

exports.refreshAndSyncOdds = (event, eventBookOdds, sourceMap = null) => {
  const nowDate = new Date();
  const isManualEvent = eventRequiresManualScoring(event);
  const {
    oddsCloseAtDate,
    oddsCloseAtStart,
    started: eventStarted,
    startsAt: eventStartsAt,
    ended: eventEnded,
    cancelled: eventCancelled,
    hardStart: eventHardStart,
    finalized: eventPrevFinalized,
  } = event?.status || {};

  const periodsStartedEndedMap = getPeriodsStartedEndedMap(event?.status?.periods?.started, event?.status?.periods?.ended, event?.sportID, event?.leagueID);

  const closedByCloseAtDate = isDate(oddsCloseAtDate) && nowDate > oddsCloseAtDate;
  const eventPastStartsAt = eventStartsAt && nowDate > eventStartsAt;
  const eventStartedOrPastStartsAt = eventStarted || eventPastStartsAt;
  const eventStartedSafe = eventStarted || (eventHardStart && eventPastStartsAt);
  const closedByStart = oddsCloseAtStart && eventStartedSafe;
  const allOddsClosed = closedByCloseAtDate || closedByStart;

  const eventLikelyLiveAfter = parseDate(eventStartsAt, "now");
  eventLikelyLiveAfter.setMinutes(eventLikelyLiveAfter.getMinutes() + EVENT_LIKELY_LIVE_AFTER_MINS);
  const eventLikelyLive = eventStarted || (eventHardStart && nowDate > eventLikelyLiveAfter);
  const liveOddsFreshnessCutoff = new Date(nowDate);
  liveOddsFreshnessCutoff.setMinutes(liveOddsFreshnessCutoff.getMinutes() - LIVE_ODDS_MIN_FRESHNESS_MINS);

  let marketOddsAvailable = false;
  let anyOddsAvailable = false;
  let oddsAvailable = false;
  let hasMarketOdds = false;
  let hasAnyOdds = false;
  const allOddIDs = Object.keys({ ...(eventBookOdds?.odds || {}), ...(event?.odds || {}) });
  allOddIDs.forEach((oddID) => {
    if (!oddID) return;
    const eventBookOddData = eventBookOdds?.odds?.[oddID];
    const eventOddData = event?.odds?.[oddID];
    const hasEventBookOddData = !!eventBookOddData;
    const hasEventOddData = !!eventOddData;
    const anyOddData = hasEventBookOddData ? eventBookOddData : hasEventOddData ? eventOddData : null;
    let eventBookOddsScore = getOddScore(anyOddData, event);
    const hasScore = isNumber(eventBookOddsScore);
    if (!hasScore) eventBookOddsScore = null;
    const statID = eventBookOddData?.statID || eventOddData?.statID;
    const statEntityID = eventBookOddData?.statEntityID || eventOddData?.statEntityID;
    const periodID = eventBookOddData?.periodID || eventOddData?.periodID;
    const betTypeID = eventBookOddData?.betTypeID || eventOddData?.betTypeID;
    const sideID = eventBookOddData?.sideID || eventOddData?.sideID;
    const playerID = eventBookOddData?.playerID || eventOddData?.playerID;
    const teamID = eventBookOddData?.teamID || eventOddData?.teamID;
    const sourceContext = eventOddData?.sourceContext;
    const byBookmaker = eventBookOddData?.byBookmaker;

    const { started: periodStarted, ended: periodEnded } = getPeriodStartedEnded(periodID, periodsStartedEndedMap);

    const cancelled = !!(eventBookOddData?.cancelled || eventCancelled);
    const started = !!(eventBookOddData?.started || eventOddData?.started || periodStarted === true || (hasScore && eventBookOddsScore > 0));
    const ended = !!(cancelled || eventBookOddData?.ended || eventOddData?.ended || eventEnded || periodEnded === true);

    const isFallbackOdds = !!eventOddData?.isFallbackOdds && !hasEventBookOddData;

    const eventOddsScore = (started || ended) && !cancelled ? eventBookOddsScore : null;

    let fairOdds = eventBookOddData?.fairOdds || eventOddData?.odds;
    const bookOdds = eventBookOddData?.bookOdds || eventOddData?.bookOdds;
    let fairSpread = eventBookOddData?.fairSpread || eventOddData?.spread;
    const bookSpread = eventBookOddData?.bookSpread || eventOddData?.bookSpread;
    let fairOverUnder = eventBookOddData?.fairOverUnder || eventOddData?.overUnder;
    const bookOverUnder = eventBookOddData?.bookOverUnder || eventOddData?.bookOverUnder;

    // These are currently disabled
    // const fairOverUnderBookOdds = eventBookOddData?.fairOverUnderBookOdds;
    // const fairSpreadBookOdds = eventBookOddData?.fairSpreadBookOdds;

    const canSetOpeningOdds = !eventStartedSafe && !ended && !cancelled && !isFallbackOdds && !eventPrevFinalized;
    const canSetClosingOdds = (eventStartedSafe || started || ended) && !cancelled && !isFallbackOdds && !eventPrevFinalized;
    const openFairOdds = eventBookOddData?.openFairOdds || eventOddData?.openOdds || (canSetOpeningOdds && fairOdds) || null;
    const closeFairOdds = eventBookOddData?.closeFairOdds || eventOddData?.closeOdds || (canSetClosingOdds && fairOdds) || null;
    const openBookOdds = eventBookOddData?.openBookOdds || eventOddData?.openBookOdds || (canSetOpeningOdds && bookOdds) || null;
    const closeBookOdds = eventBookOddData?.closeBookOdds || eventOddData?.closeBookOdds || (canSetClosingOdds && bookOdds) || null;
    const openFairSpread = eventBookOddData?.openFairSpread || eventOddData?.openSpread || (canSetOpeningOdds && fairSpread) || null;
    const closeFairSpread = eventBookOddData?.closeFairSpread || eventOddData?.closeSpread || (canSetClosingOdds && fairSpread) || null;
    const openBookSpread = eventBookOddData?.openBookSpread || eventOddData?.openBookSpread || (canSetOpeningOdds && bookSpread) || null;
    const closeBookSpread = eventBookOddData?.closeBookSpread || eventOddData?.closeBookSpread || (canSetClosingOdds && bookSpread) || null;
    const openFairOverUnder = eventBookOddData?.openFairOverUnder || eventOddData?.openOverUnder || (canSetOpeningOdds && fairOverUnder) || null;
    const closeFairOverUnder = eventBookOddData?.closeFairOverUnder || eventOddData?.closeOverUnder || (canSetClosingOdds && fairOverUnder) || null;
    const openBookOverUnder = eventBookOddData?.openBookOverUnder || eventOddData?.openBookOverUnder || (canSetOpeningOdds && bookOverUnder) || null;
    const closeBookOverUnder = eventBookOddData?.closeBookOverUnder || eventOddData?.closeBookOverUnder || (canSetClosingOdds && bookOverUnder) || null;

    const summaryHasFairOdds = !!eventBookOddData?.fairOdds && (betTypeID === "sp" ? !!eventBookOddData?.fairSpread : betTypeID === "ou" ? !!eventBookOddData?.fairOverUnder : true);
    const oddOrEventHasStarted = started || eventStartedSafe;
    const { supportsLiveOdds } = getPeriodConfig(periodID) || {};
    const fairOddsAvailable = !!eventBookOddData?.fairOddsAvailable && !ended && summaryHasFairOdds;

    const eventPrevHadOdds = !!eventOddData?.odds && (betTypeID === "sp" ? !!eventOddData?.spread : betTypeID === "ou" ? !!eventOddData?.overUnder : true);
    const eventOddsPrevAvailable = !!eventOddData?.available && !ended && eventPrevHadOdds;

    const summaryHasBookOdds = !!eventBookOddData?.bookOdds && (betTypeID === "sp" ? !!eventBookOddData?.bookSpread : betTypeID === "ou" ? !!eventBookOddData?.bookOverUnder : true);
    const bookOddsAvailable = !!eventBookOddData?.bookOddsAvailable && !ended && summaryHasBookOdds;
    // const bookOddsAvailable = !!eventBookOddData?.bookOddsAvailable; // This option is more lenient and allows for us to just rely on at least 1 book returning available data even if unused/unshown

    const scoringSupported = !!supportsOddScore(anyOddData, event, sourceMap);

    let eventOddsAvailable = (!oddOrEventHasStarted || !!supportsLiveOdds) && !allOddsClosed && scoringSupported;
    if (isFallbackOdds) eventOddsAvailable = eventOddsAvailable && eventOddsPrevAvailable && !eventStartedOrPastStartsAt;
    else if (isManualEvent) eventOddsAvailable = eventOddsAvailable && eventOddsPrevAvailable;
    else eventOddsAvailable = eventOddsAvailable && fairOddsAvailable;
    if (betTypeID === "sp") {
      const fairSpreadFloat = asSpreadFloat(fairSpread);
      eventOddsAvailable = eventOddsAvailable && isNumber(fairSpreadFloat) && fairSpreadFloat !== 0;
    }
    if (eventOddsAvailable && !isManualEvent && !ended && (eventLikelyLive || started)) {
      const isStaleOdds = oddsAreStale(byBookmaker, liveOddsFreshnessCutoff);
      if (isStaleOdds) logWarning(`Found stale odds ${event?.eventID}  -> ${oddID}`, byBookmaker);
      eventOddsAvailable = eventOddsAvailable && !isStaleOdds;
    }

    const mergedEventBookOddData = {
      // oddID,
      // opposingOddID,
      // marketName,
      statID,
      statEntityID,
      periodID,
      betTypeID,
      sideID,
      playerID: playerID || null,
      teamID: teamID || null,
      started,
      ended,
      cancelled,
      bookOddsAvailable,
      fairOddsAvailable,
      fairOdds,
      bookOdds,
      fairSpread,
      bookSpread,
      fairOverUnder,
      bookOverUnder,
      openFairOdds,
      closeFairOdds,
      openBookOdds,
      closeBookOdds,
      openFairSpread,
      closeFairSpread,
      openBookSpread,
      closeBookSpread,
      openFairOverUnder,
      closeFairOverUnder,
      openBookOverUnder,
      closeBookOverUnder,
      score: eventBookOddsScore,
      scoringSupported,
      byBookmaker,
    };
    const mergedEventOddData = {
      oddID,
      statID,
      statEntityID,
      periodID,
      betTypeID,
      sideID,
      playerID: playerID || null,
      teamID: teamID || null,
      started,
      ended,
      cancelled,
      available: eventOddsAvailable,
      odds: roundMoneyline(fairOdds),
      spread: fairSpread,
      overUnder: fairOverUnder,
      bookOdds,
      bookSpread,
      bookOverUnder,
      closeOdds: roundMoneyline(closeFairOdds),
      closeSpread: closeFairSpread,
      closeOverUnder: closeFairOverUnder,
      score: eventOddsScore,
      isFallbackOdds: isFallbackOdds || null,
      sourceContext,
    };

    // TODO re-add this once moved to redis
    // const marketName = getOddsDisplay("marketName", mergedOddMetadata, event) || null;
    // const opposingOddID = getOpponentOddID(mergedOddMetadata) || null;

    const writeEventBookOddData = !isFallbackOdds && (hasEventBookOddData || summaryHasFairOdds || summaryHasBookOdds || eventPrevHadOdds);
    const writeEventOddData = hasEventOddData || eventOddsAvailable;

    if (writeEventBookOddData) setObjVal(eventBookOdds, `odds.${oddID}`, mergedEventBookOddData);
    if (writeEventOddData) setObjVal(event, `odds.${oddID}`, mergedEventOddData);

    if (writeEventBookOddData) hasMarketOdds = true;
    if (writeEventOddData || writeEventBookOddData) hasAnyOdds = true;
    if (eventOddsAvailable && !isFallbackOdds) marketOddsAvailable = true;
    if (eventOddsAvailable) anyOddsAvailable = true;
    if (bookOddsAvailable || fairOddsAvailable) oddsAvailable = true;
  });

  setObjVal(event, "status.marketOddsAvailable", marketOddsAvailable);
  setObjVal(event, "status.anyOddsAvailable", anyOddsAvailable);
  setObjVal(event, "status.hasAnyOdds", hasAnyOdds);
  setObjVal(event, "status.hasMarketOdds", hasMarketOdds);

  setObjVal(eventBookOdds, "status.oddsAvailable", oddsAvailable);

  return { eventBookOdds, event };
};

const oddsAreStale = (byBookmaker, oddsStaleCutoff) => {
  if (!oddsStaleCutoff) return false;
  const mostRecentUpdateAt = Object.values(byBookmaker || {}).reduce((mostRecent, byBookmakerData) => {
    const { lastUpdatedAt, available } = byBookmakerData || {};
    if (!lastUpdatedAt || available === false) return mostRecent;
    if (!mostRecent || lastUpdatedAt > mostRecent) return lastUpdatedAt;
    return mostRecent;
  }, null);
  if (!mostRecentUpdateAt) return false;
  return mostRecentUpdateAt < oddsStaleCutoff;
};
