import { setObjVal } from "@wagerlab/utils/data/mutations";
import * as Yup from "yup";

export const initializeFormData = (configData) => {
  if (!configData) return null;

  return {
    affiliateBottomSheetTitle: configData?.affiliateConfig?.bottomSheetTitle || "",
  };
};

export const validateFormData = (affiliateGeneralData, prevConfigData) => {
  if (!affiliateGeneralData?.affiliateBottomSheetTitle) {
    return "Affiliate bottom sheet title is missing";
  }

  return "";
};

export const buildConfigFromFormData = (affiliateGeneralFormData, prevConfigData, newConfigData) => {
  if (!newConfigData || !prevConfigData) return newConfigData;

  // NOTE: As the general config tab exapnds we'll want to update this
  // logic to account for other properties.
  setObjVal(newConfigData, "affiliateConfig.bottomSheetTitle", affiliateGeneralFormData?.affiliateBottomSheetTitle);
  return newConfigData;
};

export const formFieldValidation = Yup.object({
  bottomSheetTitle: Yup.string(),
});
