import React from "react";
import { Grid, Typography } from "@mui/material";
import { TextField } from "@wagerlab/admin/src/components/inputs/TextField";

export const EventFormStatusDisplays = ({ isNew }) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">Status Updates</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField name="displayShort" label="Short Update" tooltip="ex: G-1 or C-EX" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField name="displayLong" label="Long Update" tooltip="One sentence description: Graded as a win for side 1." />
      </Grid>
    </>
  );
};
