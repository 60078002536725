const { setSearchLookup, getNormalizedString, formatLookups } = require("@wagerlab/utils/aggregator/lookups");
const { copyData, mergeData } = require("@wagerlab/utils/data/mutations");
const { LEAGUE_CONFIG } = require("@wagerlab/utils/sports/leagueConfig");
const { TEAM_TYPES } = require("@wagerlab/utils/sports/enums");
const _ = require("lodash");

const REMOVE_END_WORDS = {
  W: true,
  Women: true,
};

const REMOVE_START_WORDS = {};

const PHRASE_MAPPINGS = [
  { phrase: "Institute of Technology", mapsTo: "Tech", mostUniqueWord: "Institute" },
  { phrase: "Los Angeles", mapsTo: "LA", mostUniqueWord: "Angeles" },
  { phrase: "New York City", mapsTo: "NYC", mostUniqueWord: "York" },
  { phrase: "New York", mapsTo: "NY", mostUniqueWord: "York" },
  { phrase: "San Francisco", mapsTo: "SF", mostUniqueWord: "Francisco" },
  { phrase: "San Jose", mapsTo: "SJ", mostUniqueWord: "Jose" },
];
const PHRASE_MAPPING_CHECKER = PHRASE_MAPPINGS.reduce((checker, mapping) => {
  if (mapping?.mostUniqueWord) checker[mapping.mostUniqueWord] = true;
  return checker;
}, {});

const WORD_MAPPINGS = {
  "State": "St",
  "Saint": "St",
  // "St": "State",
  // "St.": "State",
  // "St": "Saint",
  // "St.": "Saint",
  "Technology": "Tech",
  // "Tech": "Technology", Texas Tech =/= Texas Technology
  "Inst": "Institute",
  "Inst.": "Institute",

  "College": "Coll",
  "Coll.": "College",
  "Coll": "College",

  "North": "N",
  "South": "S",
  "East": "E",
  "West": "W",
  "Central": "C",
  "Northwestern": "NW",
  "Southwestern": "SW",
  "Northeastern": "NE",
  "Southeastern": "SE",
  "Northern": "N",
  "Southern": "S",
  "Eastern": "E",
  "Western": "W",
  // "N.": "North", // Could be Northern or North.  Also if these ever are added we'd also want to add NW/NE/SW/SE equivilents
  // "S.": "South",
  // "E.": "East",
  // "W.": "West",
  // "N.": "North",
  // "S.": "South",
  // "E.": "East",
  // "W.": "West",
  "C.": "Central",
  "Ctl": "Central",
  "Ctl.": "Central",
  "Ft": "Fort",
  "Ft.": "Fort",
  "Fort": "Ft",
  "Mount": "Mt",
  "Mt": "Mount",
  "Mt.": "Mount",

  "Junior": "Jr",
  "Jr": "Junior",
  "Jr.": "Junior",
  "Community": "Comm",
  "Comm": "Community",
  "Comm.": "Community",
  "Nat'l": "National",
  "Nat'l.": "National",
  "Natl": "National",
  "Natl.": "National",
  "National": "Natl",
  "Intl": "International",
  "Intl.": "International",
  "Int'l": "International",
  "Int'l.": "International",
  // "Int": "International",
  // "Int.": "International",
  "International": "Intl",
  "Univ": "University",
  "Univ.": "University",
  "University": "Univ",

  "SF": "San Francisco",

  "LA": "Los Angeles",

  "NY": "New York",

  "Philly": "Philadelphia",
  "Philadelphia": "Philly",
};

const mergeTeamNames = (existingTeamNames, teamNameUpdates, leagueID) => {
  if (existingTeamNames?.no) return existingTeamNames;

  teamNameUpdates = removeNamePrefixAndSuffixes(teamNameUpdates);

  const leagueConfig = LEAGUE_CONFIG[leagueID];
  const teamType = leagueConfig?.teamType || TEAM_TYPES.DEFAULT;
  if (teamType === TEAM_TYPES.SINGLE_PLAYER) {
    if (!teamNameUpdates?.long && teamNameUpdates?.firstName && teamNameUpdates?.lastName) teamNameUpdates.long = `${teamNameUpdates.firstName} ${teamNameUpdates.lastName}`;
    if (!existingTeamNames?.long && existingTeamNames?.firstName && existingTeamNames?.lastName) existingTeamNames.long = `${existingTeamNames.firstName} ${existingTeamNames.lastName}`;
    if (!teamNameUpdates?.medium && teamNameUpdates?.lastName) teamNameUpdates.medium = teamNameUpdates.lastName;
    if (!existingTeamNames?.medium && existingTeamNames?.lastName) existingTeamNames.medium = existingTeamNames.lastName;
  } else {
    _.unset(teamNameUpdates, "firstName");
    _.unset(teamNameUpdates, "lastName");
    _.unset(existingTeamNames, "firstName");
    _.unset(existingTeamNames, "lastName");
  }

  const preferUpdates = teamNameUpdates?.PREFER_NAMES !== false;
  return mergeData(existingTeamNames, teamNameUpdates, { defaultStrategy: preferUpdates ? "updates_unlessEmpty" : "existing_unlessEmpty" });
};
exports.mergeTeamNames = mergeTeamNames;

const expandTeamNameLookups = (mergedTeamNames, teamNameUpdates, existingLookupsList, additionalLookupsList) => {
  teamNameUpdates = removeNamePrefixAndSuffixes(teamNameUpdates);

  let nameVariationsSet = new Set([...(existingLookupsList || []), ...(additionalLookupsList || [])]);
  nameVariationsSet = addNameVariants(nameVariationsSet, mergedTeamNames?.long);
  nameVariationsSet = addNameVariants(nameVariationsSet, mergedTeamNames?.medium);
  nameVariationsSet = addNameVariants(nameVariationsSet, teamNameUpdates?.long);
  nameVariationsSet = addNameVariants(nameVariationsSet, teamNameUpdates?.medium);

  nameVariationsSet = addPlayerNameVariants(nameVariationsSet, mergedTeamNames?.firstName, mergedTeamNames?.lastName);
  nameVariationsSet = addPlayerNameVariants(nameVariationsSet, teamNameUpdates?.firstName, teamNameUpdates?.lastName);

  if (mergedTeamNames?.nickname) nameVariationsSet.add(mergedTeamNames?.nickname);
  if (teamNameUpdates?.nickname) nameVariationsSet.add(teamNameUpdates?.nickname);

  return [...nameVariationsSet];
};
exports.expandTeamNameLookups = expandTeamNameLookups;

const removeNamePrefixAndSuffixes = (nameUpdates) => {
  if (nameUpdates?.long) {
    const longNameWords = nameUpdates?.long
      .trim()
      .split(" ")
      .filter((w) => !!w);
    const shouldRemoveLastWord = longNameWords[longNameWords.length - 1] && REMOVE_END_WORDS[longNameWords[longNameWords.length - 1]];
    if (shouldRemoveLastWord) longNameWords.pop();
    const shouldRemoveFirstWord = longNameWords[0] && REMOVE_START_WORDS[longNameWords[0]];
    if (shouldRemoveFirstWord) longNameWords.shift();
    if (shouldRemoveFirstWord || shouldRemoveLastWord) nameUpdates.long = longNameWords.join(" ");
  }
  if (nameUpdates?.medium) {
    const mediumNameWords = nameUpdates?.medium
      .trim()
      .split(" ")
      .filter((w) => !!w);
    const shouldRemoveLastWord = mediumNameWords[mediumNameWords.length - 1] && REMOVE_END_WORDS[mediumNameWords[mediumNameWords.length - 1]];
    if (shouldRemoveLastWord) mediumNameWords.pop();
    const shouldRemoveFirstWord = mediumNameWords[0] && REMOVE_START_WORDS[mediumNameWords[0]];
    if (shouldRemoveFirstWord) mediumNameWords.shift();
    if (shouldRemoveFirstWord || shouldRemoveLastWord) nameUpdates.medium = mediumNameWords.join(" ");
  }

  return nameUpdates;
};

const addNameVariants = (nameVariantsSet, nameToAdd) => {
  if (!nameToAdd?.length) return nameVariantsSet;

  const normalizedName = getNormalizedString(nameToAdd);
  const normalizedWords = normalizedName.split(" ").filter((w) => !!w);

  let variations = [];
  let shouldCheckPhraseVariations = false;
  normalizedWords.forEach((word) => {
    if (PHRASE_MAPPING_CHECKER[word]) shouldCheckPhraseVariations = true;
    const numExistingVariations = variations?.length || 0;
    if (numExistingVariations) {
      for (let i = 0; i < numExistingVariations; i++) {
        const existingVariation = variations[i];
        const newBaseWordVariation = `${existingVariation} ${word}`;
        const newMappedWordVariation = WORD_MAPPINGS[word] ? `${existingVariation} ${WORD_MAPPINGS[word]}` : null;
        variations[i] = newBaseWordVariation;
        if (newMappedWordVariation) variations.push(newMappedWordVariation);
      }
    } else {
      variations = WORD_MAPPINGS[word] ? [word, WORD_MAPPINGS[word]] : [word];
    }
  });

  const bestPhraseMapping = shouldCheckPhraseVariations ? PHRASE_MAPPINGS.find((m) => normalizedName.includes(m.phrase)) : null;
  if (bestPhraseMapping?.phrase && bestPhraseMapping?.mapsTo) {
    const phraseVariation = normalizedName.replace(bestPhraseMapping.phrase, bestPhraseMapping.mapsTo);
    variations.push(phraseVariation);
  }

  variations.forEach((variation) => {
    if (variation) nameVariantsSet.add(variation);
  });
  return nameVariantsSet;
};

const addPlayerNameVariants = (nameVariantsSet, firstNameInput, lastNameInput, nicknameInput) => {
  const lastName = lastNameInput?.trim?.() || "";
  if (lastName) {
    nameVariantsSet.add(lastName);
    const firstName = firstNameInput?.trim?.() || "";
    if (firstName) {
      nameVariantsSet.add(`${firstName} ${lastName}`);
      nameVariantsSet.add(`${lastName}, ${firstName}`);

      const nickname = nicknameInput?.trim?.() || "";
      if (nickname) {
        nameVariantsSet.add(`${firstName} ${nickname} ${lastName}`);
        nameVariantsSet.add(`${firstName} "${nickname}" ${lastName}`);
        nameVariantsSet.add(`${firstName} '${nickname}' ${lastName}`);
        nameVariantsSet.add(`${firstName} (${nickname}) ${lastName}`);
      }

      const firstNameFirstChar = firstName.charAt(0);
      const lastNameFirstChar = lastName.charAt(0);
      nameVariantsSet.add(`${firstNameFirstChar}. ${lastName}`);
      nameVariantsSet.add(`${firstName} ${lastNameFirstChar}.`);
    }
  }
  return nameVariantsSet;
};

const generateTeamSearchLookups = (leagueData, teamDataList) => {
  let generatedTeamLookups = {
    searches: {},
    clashes: copyData(leagueData?.teamLookups?.clashes || {}),
    overrides: copyData(leagueData?.teamLookups?.overrides || {}),
  };
  (teamDataList || []).forEach((teamData) => {
    if (!teamData?.teamID) return;
    const teamNameLookups = expandTeamNameLookups(teamData?.names || {}, {}, [], []);
    generatedTeamLookups = teamNameLookups.reduce((lookupDataObj, variationName) => setSearchLookup(lookupDataObj, variationName, teamData.teamID), generatedTeamLookups);
  });
  return generatedTeamLookups?.searches || {};
};
exports.generateTeamSearchLookups = generateTeamSearchLookups;

const expandAllTeamLookups = (leagueData, allTeamsList) => {
  let expandedLookupData = copyData(leagueData?.teamLookups || {});
  expandedLookupData.searches = {};
  (allTeamsList || []).forEach((teamData) => {
    if (!teamData?.teamID || teamData?.leagueID !== leagueData?.leagueID) return;
    const teamNameLookups = expandTeamNameLookups(teamData?.names || {}, {}, [], []);
    expandedLookupData = teamNameLookups.reduce((lookupDataObj, variationName) => setSearchLookup(lookupDataObj, variationName, teamData.teamID), expandedLookupData);
  });
  expandedLookupData.searches = { ...expandedLookupData.searches, ...(leagueData?.teamLookups?.searches || {}) };
  return formatLookups(expandedLookupData);
};
exports.expandAllTeamLookups = expandAllTeamLookups;
