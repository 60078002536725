import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { EVENT_FINALIZED_STATES, EVENT_TYPES } from "@wagerlab/admin/src/utils/events/constants";

export const MainMenu = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        marginTop: 16,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography component="h1" variant="h5">
        Admin Main Menu
      </Typography>

      <Stack sx={{ marginTop: 4 }} spacing={2}>
        <Button onClick={() => navigate(`/events/new/${EVENT_TYPES.PROP}`)} variant="contained">
          Create New Prop (Custom) Event
        </Button>
        <Button onClick={() => navigate(`/events/list`)} variant="contained">
          Search Events
        </Button>
        <Button onClick={() => navigate(`/config/edit`)} variant="contained">
          Edit App Config
        </Button>
        <Button onClick={() => navigate(`/link/teams`)} variant="contained">
          Link Team IDs
        </Button>
        <Button onClick={() => navigate(`/link/players`)} variant="contained">
          Link Player IDs
        </Button>
        <Button onClick={() => navigate(`/api/keys`)} variant="contained">
          View API Keys
        </Button>
      </Stack>
    </Box>
  );
};
