import React, { useMemo, useState } from "react";
import { Alert, Container, Snackbar, Typography } from "@mui/material";
import { copyData, dataChanged, dataUpdates } from "@wagerlab/utils/data/mutations";
import {
  initializeFormData as initializeFormData_prop,
  buildEventFromFormData as buildEventFromFormData_prop,
  formFieldValidation as formFieldValidation_prop,
  validateFormData as validateFormData_prop,
} from "@wagerlab/admin/src/utils/events/propEventGraderForm";
import {
  initializeFormData as initializeFormData_match,
  buildEventFromFormData as buildEventFromFormData_match,
  formFieldValidation as formFieldValidation_match,
  validateFormData as validateFormData_match,
} from "@wagerlab/admin/src/utils/events/matchEventGraderForm";
import { saveEventChanges } from "@wagerlab/admin/src/utils/events/database";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import { Loading } from "@wagerlab/admin/src/pages/Loading";
import { PropEventGrader } from "@wagerlab/admin/src/components/events/PropEventGrader";
import { MatchEventGrader } from "@wagerlab/admin/src/components/events/MatchEventGrader";
import { useEventsQuery } from "@wagerlab/admin/src/utils/events/useEventsQuery";

export const GradeEvent = () => {
  const { eventID } = useParams();
  const { fetchedEvent, fetchedEventBookOdds, loadingEvent } = useEventsQuery(eventID);
  const [message, setMessage] = useState("");

  const screenLoading = loadingEvent || !fetchedEvent?.eventID;

  const { initialEvent, initialEventBookOdds, initialEventForm } = useMemo(() => {
    const initialEventData = screenLoading ? null : copyData(fetchedEvent);
    const initialEventBookOddsData = screenLoading ? null : copyData(fetchedEventBookOdds);
    const initializeFormDataFunc = initialEventData?.type === "prop" ? initializeFormData_prop : initializeFormData_match;
    const initialEventFormData = initializeFormDataFunc(initialEventData, initialEventBookOddsData);
    return { initialEvent: initialEventData, initialEventBookOdds: initialEventBookOddsData, initialEventForm: initialEventFormData };
  }, [screenLoading, fetchedEvent, fetchedEventBookOdds]);

  const isPropEvent = initialEvent?.type === "prop";
  const isMatchEvent = initialEvent?.type === "match";

  const handleFormSubmit = async (formData, { resetForm }) => {
    const validateFormData = isPropEvent ? validateFormData_prop : validateFormData_match;
    const validationError = validateFormData(formData, initialEvent, initialEventBookOdds);
    if (validationError) return setMessage(validationError);

    const buildEventFromFormData = isPropEvent ? buildEventFromFormData_prop : buildEventFromFormData_match;
    const prevToNew = ({ event: prevEvent, eventBookOdds: prevEventBookOdds }) => buildEventFromFormData(formData, prevEvent, prevEventBookOdds);
    const eventSaveError = await saveEventChanges(initialEvent, initialEventBookOdds, prevToNew);
    if (eventSaveError) return setMessage(eventSaveError);

    return setMessage("Success!");
  };

  if (!initialEvent?.eventID) return <Loading />;

  const { ended, cancelled, finalized } = initialEvent?.status || {};

  const eventTypeStatusError = !initialEventForm
    ? "Form couldn't be initialized"
    : ended || cancelled || finalized
    ? `This event has already been graded. Try re-grading it if you need to make changes. If it's not available for re-grading and you recently graded it, wait a moment and try again.`
    : !isPropEvent && !isMatchEvent
    ? `Can't grade this event type using this dashboard`
    : "";

  const formFieldValidation = isPropEvent ? formFieldValidation_prop : formFieldValidation_match;

  return (
    <>
      <Container maxWidth="xl" sx={{ marginTop: 16, marginBottom: 8 }}>
        {eventTypeStatusError ? (
          <Typography variant="h6">{eventTypeStatusError}</Typography>
        ) : (
          <Formik initialValues={initialEventForm} onSubmit={handleFormSubmit} validationSchema={formFieldValidation}>
            {isPropEvent ? <PropEventGrader prevEvent={initialEvent} /> : isMatchEvent ? <MatchEventGrader prevEvent={initialEvent} /> : null}
          </Formik>
        )}
      </Container>

      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={!!message} onClose={() => setMessage("")} autoHideDuration={10000}>
        <Alert onClose={() => setMessage("")} severity={"info"} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};
