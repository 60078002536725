import React, { useState } from "react";
import { Alert, Box, Container, Grid, Snackbar, Typography } from "@mui/material";
import { Formik, Form } from "formik";
import { SubmitButton } from "@wagerlab/admin/src/components/inputs/SubmitButton";
import { useQueryClient } from "@tanstack/react-query";
import { Loading } from "@wagerlab/admin/src/pages/Loading";
import { EventFormDependentFields } from "@wagerlab/admin/src/components/events/EventFormDependentFields";

const emptySubmitMessage = { type: "", text: "" };

export const EventFormWrapper = ({ children, title, loading, eventData, eventBookOddsData, formData, formValidation, handleSubmit, submitButtonText }) => {
  const [submitMessage, setSubmitMessage] = useState(emptySubmitMessage);
  const queryClient = useQueryClient();

  const handleFormSubmit = async (formData, formikBag) => {
    setSubmitMessage(emptySubmitMessage);
    if (!handleSubmit) return;
    const submitErrors = await handleSubmit(formData, formikBag);
    if (submitErrors) {
      setSubmitMessage({ type: "error", text: submitErrors });
    } else {
      setSubmitMessage({ type: "success", text: "SUCCESS!" });
      if (eventData?.eventID) queryClient.invalidateQueries({ queryKey: ["event", eventData.eventID] });
      queryClient.invalidateQueries({ queryKey: ["events"] });
    }
  };

  return (
    <>
      <Grid container sx={{ marginTop: 16 }}>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center", marginBottom: 4 }}>
          <Typography variant="h4">{title || "Event"}</Typography>
        </Box>

        <Grid item xs={12}>
          <Container maxWidth="md">
            {loading ? (
              <Loading />
            ) : (
              <div>
                <Formik initialValues={formData} validationSchema={formValidation} onSubmit={handleFormSubmit} validateOnChange={false} validateOnBlur={true}>
                  <Form>
                    <EventFormDependentFields />
                    {children}

                    <Box
                      sx={{
                        marginTop: 8,
                        marginBottom: 16,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <SubmitButton type="submit" size="medium" sx={{ width: 200 }} text={submitButtonText} />
                    </Box>
                  </Form>
                </Formik>
              </div>
            )}
          </Container>
        </Grid>
      </Grid>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={!!submitMessage.text} onClose={() => setSubmitMessage(emptySubmitMessage)} autoHideDuration={10000}>
        <Alert onClose={() => setSubmitMessage(emptySubmitMessage)} severity={submitMessage.type || "warning"} sx={{ width: "100%" }}>
          {submitMessage.text || "Something went wrong"}
        </Alert>
      </Snackbar>
    </>
  );
};
