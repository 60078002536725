import React, { useState, useEffect, useMemo } from "react";
import { Button, Snackbar, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ReactJson from "react-json-view";
import _ from "lodash";
import { isObject } from "@wagerlab/utils/data/types";
import { copyData, dataChanged, setObjVal } from "@wagerlab/utils/data/mutations";

export const formatModalData = (unknownEntity, lookupKey) => ({ unknownEntity, lookupKey });

export const ObjectDataDialog = ({ data, setData, dataPath, namesPath, lookupsPath, setOriginalUnknownEntities }) => {
  const [updatedData, setUpdatedData] = useState(null);
  const [notice, setNotice] = useState("");

  useEffect(() => {
    if (!data || !isObject(data) || !data?.unknownEntity || !data?.lookupKey) {
      setUpdatedData(null);
      return;
    }
    setUpdatedData(copyData(data));
  }, [data]);

  const editMade = useMemo(() => {
    if (!data?.unknownEntity || !updatedData?.unknownEntity) return false;
    return dataChanged(data?.unknownEntity, updatedData?.unknownEntity);
  }, [data, updatedData]);

  const handleClose = () => {
    setData(null);
  };

  const handleSave = () => {
    if (!updatedData?.unknownEntity || !updatedData?.lookupKey || !editMade) {
      setNotice("Unable to save any changes");
      return;
    }
    setOriginalUnknownEntities((prev) => {
      return { ...prev, [updatedData.lookupKey]: updatedData.unknownEntity };
    });
    setData(updatedData);
    setNotice("Data Saved Successfully");
  };

  const handleEdit = (edit) => {
    const prevData = updatedData;
    const newData = { ...updatedData, unknownEntity: copyData(edit?.updated_src) };
    if (!isObject(newData?.unknownEntity) || !dataChanged(prevData, newData)) return;

    const prevNamesLevel = _.get(prevData, `unknownEntity.${namesPath}`);
    const newNamesLevel = _.get(newData, `unknownEntity.${namesPath}`);

    if (dataChanged(prevNamesLevel, newNamesLevel)) setObjVal(newData, `unknownEntity.${namesPath}.no`, true);
    setUpdatedData(newData);
  };

  return (
    <Dialog open={!!data} onClose={handleClose} maxWidth={"md"} fullWidth>
      <DialogTitle>{`${dataPath} to be Saved to Database`}</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1" gutterBottom>
          By editing the JSON directly, names will be frozen and all other names updates from aggregator data sources will NOT be applied.
        </Typography>
        <ReactJson
          src={updatedData?.unknownEntity || {}}
          onEdit={handleEdit}
          onAdd={handleEdit}
          onDelete={handleEdit}
          theme="ashes"
          style={{ fontSize: "14px" }}
          enableClipboard={false}
          quotesOnKeys={false}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleSave} color="primary" disabled={!editMade}>
          Save
        </Button>
      </DialogActions>
      <Snackbar open={!!notice} autoHideDuration={5000} onClose={() => setNotice("")} message={notice} />
    </Dialog>
  );
};
