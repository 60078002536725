const { isError, isString } = require("@wagerlab/utils/data/types");
const { getLoggerModule } = require("@wagerlab/utils/initialize");

exports.logError = (message, error, ...logData) => {
  const logger = getLoggerModule();
  let errorOutput = "";
  if (isError(error)) {
    const errorString = `${error || ""}`;
    const errorMessage = isString(error?.message) && error?.message;
    errorOutput = errorString && errorMessage && !errorString?.includes?.(errorMessage) ? `${errorMessage} => ${errorString}` : errorString || errorMessage || "";
  }
  const logMessage = `[WLERROR] - ${message || "?ERROR?"} -${errorOutput}-`;
  const logObject = buildLogObject("ERROR", error, error?.message, error?.stack, ...logData);
  writeToLogs(logMessage, logObject, logger?.error);
};

exports.logWarning = (message, ...logData) => {
  const logger = getLoggerModule();
  const logMessage = `[WLWARN] - ${message || "?WARNING?"} -`;
  const logObject = buildLogObject("", ...logData);
  writeToLogs(logMessage, logObject, logger.warn);
};

exports.logInfo = (message, ...logData) => {
  const logger = getLoggerModule();
  const logMessage = `[WLINFO] - ${message || "?INFO?"} -`;
  const logObject = buildLogObject("", ...logData);
  writeToLogs(logMessage, logObject, logger.info);
};

exports.logDebug = (message, ...logData) => {
  const logger = getLoggerModule();
  if (!logger?.debugEnabled) return;
  const logMessage = `[WLDEBUG] - ${message || "?DEBUG?"} -`;
  const logObject = buildLogObject("", ...logData);
  writeToLogs(logMessage, logObject, logger.debug);
};

exports.logRead = (message, response, ...logData) => {
  const logger = getLoggerModule();
  if (!logger?.shouldLogReads) return;
  const logMessage = `[WLREAD] - ${message || "?READ?"} -`;
  const logObject = buildLogObject("RESPONSE", response, ...logData);
  writeToLogs(logMessage, logObject, logger.info);
};

exports.logWrite = (message, response, ...logData) => {
  const logger = getLoggerModule();
  if (!logger?.shouldLogWrites) return;
  const logMessage = `[WLWRITE] - ${message || "?WRITE?"} -`;
  const logObject = buildLogObject("RESPONSE", response, ...logData);
  writeToLogs(logMessage, logObject, logger.info);
};

exports.logCounter = (counterName, counterValue = 1, additionalLogData = {}) => {
  const logger = getLoggerModule();
  if (!logger?.shouldLogMetrics || !counterName) return;
  const logMessage = `counter.${counterName}`;
  const logObject = { ...(additionalLogData || {}), counterName, counterValue };
  writeToLogs(logMessage, logObject, logger.log);
};

exports.logDistribution = (distributionName, distributionValue = 1, additionalLogData = {}) => {
  const logger = getLoggerModule();
  if (!logger?.shouldLogMetrics || !distributionName) return;
  const logMessage = `distribution.${distributionName}`;
  const logObject = { ...(additionalLogData || {}), distributionName, distributionValue };
  writeToLogs(logMessage, logObject, logger.log);
};

exports.updateDefaultMetadata = (newMetadataFieldKey, newMetadataFieldValue) => {
  const logger = getLoggerModule();
  if (!logger?.updateDefaultMetadata || !newMetadataFieldKey) return;
  logger.updateDefaultMetadata(newMetadataFieldKey, newMetadataFieldValue);
};

const buildLogObject = (primaryItemName, primaryLogItem, ...otherLogItems) => {
  if (!primaryItemName && primaryLogItem === undefined && !otherLogItems?.length) return null;
  const otherLogItemsList = otherLogItems || [];
  const logItems = primaryItemName ? otherLogItemsList : [primaryLogItem, ...otherLogItemsList];
  let logObject = primaryItemName ? { [primaryItemName]: primaryLogItem } : {};
  logItems.forEach((logItem, i) => {
    logObject[`logItem_${i}`] = logItem;
  });
  return logObject;
};

const writeToLogs = (logMessage, logObject, logFunc) => {
  if (!logMessage || !logFunc) return;
  if (logObject) {
    logFunc(logMessage, logObject);
  } else {
    logFunc(logMessage);
  }
};
