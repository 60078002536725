const _ = require("lodash");

const isArray = _.isArray;
exports.isArray = isArray;

const isObject = (item) => item !== null && typeof item === "object" && !isArray(item) && !isError(item) && !isDate(item);
exports.isObject = isObject;

const isNumber = (item) => typeof item === "number" && isFinite(item);
exports.isNumber = isNumber;

//Remember when checking for floats numbers like 1.0 are integers but also may be valid floats so only use isNumber to check for floats
const isInteger = (item) => typeof item === "number" && Number.isInteger(item);
exports.isInteger = isInteger;

const isDate = (item) => _.isDate(item) && isNumber(item?.getTime?.());
exports.isDate = isDate;

const isString = _.isString;
exports.isString = isString;

const isBoolean = (item) => item === true || item === false;
exports.isBoolean = isBoolean;

const isError = _.isError;
exports.isError = isError;

const isEmptyObject = (item) => isObject(item) && Object.keys(item).length === 0;
exports.isEmptyObject = isEmptyObject;

const isEmptyValue = (item) => item == null || item === "" || isEmptyObject(item) || (isArray(item) && item.length === 0);
exports.isEmptyValue = isEmptyValue;

const isPositiveInteger = (item) => isInteger(item) && item > 0;
exports.isPositiveInteger = isPositiveInteger;
const isNegativeInteger = (item) => isInteger(item) && item < 0;
exports.isNegativeInteger = isNegativeInteger;
const isNonNegativeInteger = (item) => item === 0 || isPositiveInteger(item);
exports.isNonNegativeInteger = isNonNegativeInteger;

const isPositiveNumber = (item) => isNumber(item) && item > 0;
exports.isPositiveNumber = isPositiveNumber;
const isNegativeNumber = (item) => isNumber(item) && item < 0;
exports.isNegativeNumber = isNegativeNumber;
const isNonNegativeNumber = (item) => item === 0 || isPositiveNumber(item);
exports.isNonNegativeNumber = isNonNegativeNumber;

const stringLength = (item) => (!isString(item) || !isPositiveInteger(item?.length) ? 0 : item.length);
exports.stringLength = stringLength;

const parseBoolean = (item) => {
    if (isBoolean(item)) return item;
    if (isString(item)) {
        if (item.toLowerCase() === "true") return true;
        if (item.toLowerCase() === "false") return false;
    }
    if (isNumber(item)) return item !== 0;
    return false;
}
exports.parseBoolean = parseBoolean;