const { parsePhoneDisplay, parsePhoneNumber } = require("@wagerlab/utils/users/phoneNumber");

const HOUSE_USER = {
  userID: "house",
  username: "The House",
  display: "The House",
  subDisplay: "",
};

const COINS_USER = {
  userID: "coins",
  username: "coins",
  display: "The House",
  subDisplay: "coins",
};

const TICKETS_USER = {
  userID: "tickets",
  username: "tickets",
  display: "The House",
  subDisplay: "tickets",
};

class UserDisplays {
  constructor(user) {
    this.user = user;
  }

  matchHouseUser() {
    if (this.user?.userID === HOUSE_USER.userID || this.user?.username === HOUSE_USER.username) return HOUSE_USER;
  }

  matchTicketsUser() {
    if (this.user?.userID === TICKETS_USER.userID || this.user?.username === TICKETS_USER.username) return TICKETS_USER;
  }

  matchCoinsUser() {
    if (this.user?.userID === COINS_USER.userID || this.user?.username === COINS_USER.username) return COINS_USER;
  }

  matchComputerUser() {
    return this.matchHouseUser() || this.matchTicketsUser() || this.matchCoinsUser() || null;
  }

  getFullName() {
    const computerUserMatch = this.matchComputerUser();
    if (computerUserMatch) return computerUserMatch.display;
    if (this.user?.firstName && this.user?.lastName) return `${this.user.firstName} ${this.user.lastName}`;
    return this.getFirstName();
  }

  getFirstNameLastInitial() {
    const computerUserMatch = this.matchComputerUser();
    if (computerUserMatch) return computerUserMatch.display;
    if (this.user?.lastName && this.user?.firstName) return `${this.user.firstName} ${this.user.lastName.charAt(0)}.`;
    return this.getFirstName();
  }

  getFirstName() {
    const computerUserMatch = this.matchComputerUser();
    if (computerUserMatch) return computerUserMatch.display;
    return this.user?.firstName || this.user?.lastName || "";
  }

  getUsernameDisplay() {
    const computerUserMatch = this.matchComputerUser();
    if (computerUserMatch) return computerUserMatch.display;
    return this.user?.username || this.getPhoneDisplay() || "";
  }

  getPhoneDisplay(countryCode) {
    const computerUserMatch = this.matchComputerUser();
    if (computerUserMatch) return "";
    const rawPhoneNumber = this.user?.phone;
    return parsePhoneDisplay(rawPhoneNumber) || rawPhoneNumber || "";
  }

  getNameDisplay(length) {
    if (length === "long") return this.getFullName();
    if (length === "medium") return this.getFirstNameLastInitial();
    return this.getFirstName();
  }

  // short: stick321
  // medium: stick321 (William)     William - (713) 231-8518
  // long: stick321 (William Labanowski)
  getFullDisplay(length, reversed = false) {
    const computerUserMatch = this.matchComputerUser();
    if (computerUserMatch) return computerUserMatch.display;

    const nameDisplay = this.getNameDisplay(length);
    const phoneDisplay = this.getPhoneDisplay();
    const username = this.user?.username;
    const dynamicNameDisplay = length === "short" || length === "long" ? nameDisplay : this.getFirstName();

    if (length !== "short") {
      const prefix = reversed ? dynamicNameDisplay || username : username || dynamicNameDisplay;
      const suffixIsPhone = !username || !dynamicNameDisplay;
      const suffix = suffixIsPhone ? phoneDisplay : reversed ? username : dynamicNameDisplay;
      if (prefix && suffix) return suffixIsPhone ? `${prefix} - ${suffix}` : `${prefix} (${suffix})`;
    }
    if (reversed) return nameDisplay || username || phoneDisplay || "";
    return username || nameDisplay || phoneDisplay || "";
  }

  getDisplayString(displayType) {
    switch (displayType) {
      case "displayLong":
        return this.getFullDisplay("long");
      case "displayMedium":
        return this.getFullDisplay("medium");
      case "displayShort":
        return this.getFullDisplay("short");
      case "displayLong_reverse":
        return this.getFullDisplay("long", true);
      case "displayMedium_reverse":
        return this.getFullDisplay("medium", true);
      case "displayShort_reverse":
        return this.getFullDisplay("short", true);
      case "displayPhone":
        return this.getPhoneDisplay();
      case "displayUsername":
        return this.getUsernameDisplay();
      case "fullName":
        return this.getFullName();
      case "firstNameLastInitial":
        return this.getFirstNameLastInitial();
      case "firstName":
        return this.getFirstName();
      default:
        return "";
    }
  }
}
exports.UserDisplays = UserDisplays;
