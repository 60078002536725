import React, { useMemo } from "react";
import { TableRow, TableCell, Box, Typography, Alert } from "@mui/material";

export const RowNotice = ({ unknownEntity, getters }) => {
  const notices = useMemo(() => {
    const noticeList = [];
    if (unknownEntity?.mayInsteadMatchTeamID)
      noticeList.push(`This Player may instead match TeamID: ${unknownEntity.mayInsteadMatchTeamID}. Do not add them to this team if they are on that team and not this one!!!`);
    return noticeList;
  }, [unknownEntity]);

  if (!notices.length) return null;

  return (
    <TableRow>
      <TableCell
        colSpan={5}
        sx={{
          padding: 0,
          border: "none",
          backgroundColor: "transparent",
        }}
      >
        <Box sx={{ margin: "0px 16px" }}>
          {notices.map((notice, index) => (
            <Alert
              key={index}
              severity="warning"
              variant="outlined"
              sx={{
                "backgroundColor": "rgba(255, 244, 229, 0.8)",
                "& .MuiAlert-icon": {
                  color: "warning.main",
                },
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                {notice}
              </Typography>
            </Alert>
          ))}
        </Box>
      </TableCell>
    </TableRow>
  );
};
