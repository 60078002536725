const { isNumber, isPositiveInteger, isString, isInteger } = require("@wagerlab/utils/data/types");
const numbroModule = require("numbro");

const formatDisplayNumber = (number, maxCharacters = 6, simplify = false) => {
  const num = isString(number) ? parseFloat(number) : number;
  if (!isNumber(num)) return "";
  let maxChars = !isPositiveInteger(maxCharacters) ? 6 : maxCharacters;
  maxChars = Math.max(maxChars, 4);
  maxChars = Math.min(maxChars, 8);

  const numbro = numbroModule?.default || numbroModule;

  if (!simplify && num < 1000000 && num > -1000000) {
    const fullWithGrouping = numbro(num).format({ thousandSeparated: true, mantissa: 0 });
    if (fullWithGrouping.length <= maxChars) return fullWithGrouping;
    const fullWithoutGrouping = numbro(num).format({ thousandSeparated: false, mantissa: 0 });
    if (fullWithoutGrouping.length <= maxChars) return fullWithoutGrouping;
  }

  const totalDigits = Math.floor(Math.log10(Math.abs(num)) + 1);
  const digitsInFirstGroup = totalDigits % 3 === 0 ? 3 : totalDigits % 3;

  const compactBaseConfig = {
    average: true,
    totalLength: digitsInFirstGroup,
    spaceSeparated: false,
    mantissa: 0,
    trimMantissa: true,
  };

  const with1Decimal = numbro(num).format({ ...compactBaseConfig, totalLength: digitsInFirstGroup + 1, mantissa: 1 });
  if (with1Decimal.length >= maxChars) return with1Decimal.replace("K", "k");

  if (with1Decimal.length + 1 === maxChars) {
    const with2Decimals = numbro(num).format({ ...compactBaseConfig, totalLength: digitsInFirstGroup + 2, mantissa: 2 });
    if (with2Decimals.length === maxChars) return with2Decimals.replace("K", "k");
  }

  const with3Decimals = numbro(num).format({ ...compactBaseConfig, totalLength: digitsInFirstGroup + 3, mantissa: 3 });
  return with3Decimals.replace("K", "k");
};
exports.formatDisplayNumber = formatDisplayNumber;

const parseNumber = (numberInput, defaultValue = 0, typeFormat = "", valueFormat = "") => {
  let number = isString(numberInput) ? parseFloat(numberInput) : numberInput;
  if (!isNumber(number)) return defaultValue;
  if (!typeFormat && !valueFormat) return number;
  if ((typeFormat === "int" || typeFormat === "integer") && !isInteger(number)) number = parseInt(number);
  if ((typeFormat === "int" || typeFormat === "integer") && !isInteger(number)) return defaultValue;
  if ((valueFormat === ">0" || valueFormat === "positive") && number <= 0) return defaultValue;
  if ((valueFormat === ">=0" || valueFormat === "non-negative") && number < 0) return defaultValue;
  if ((valueFormat === "<0" || valueFormat === "negative") && number >= 0) return defaultValue;
  if ((valueFormat === "<=0" || valueFormat === "non-positive") && number > 0) return defaultValue;
  return number;
};
exports.parseNumber = parseNumber;

exports.parsePositiveNumber = (numberInput) => parseNumber(numberInput, 0, "any", "positive");
exports.parseInteger = (numberInput) => parseNumber(numberInput, 0, "integer", "any");
exports.parsePositiveInteger = (numberInput) => parseNumber(numberInput, 0, "integer", "positive");

exports.formatFullNumber = (number, defaultValue = "0") => {
  if (!isNumber(number)) return defaultValue;
  return number.toLocaleString() || defaultValue;
};

const getMedian = (arrayOfNumbers) => {
  if (!arrayOfNumbers?.length) return 0;

  const validNumbers = arrayOfNumbers.reduce((numArr, num) => {
    const parsedNum = parseNumber(num, null);
    if (parsedNum !== null) numArr.push(parsedNum);
    return numArr;
  }, []);

  if (!validNumbers.length) return 0;

  const sortedNumbers = validNumbers.sort((a, b) => a - b);

  const midIndex = Math.floor(sortedNumbers.length / 2);

  if (sortedNumbers.length % 2 === 0) return (sortedNumbers[midIndex - 1] + sortedNumbers[midIndex]) / 2;
  return sortedNumbers[midIndex];
};
exports.getMedian = getMedian;
