import React, { useState } from "react";
import { useFormikContext } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import { Typography } from "@mui/material";

export const SubmitButton = ({ text, ...otherProps }) => {
  const [submitError, setSubmitError] = useState("");
  const { submitForm, isSubmitting, isValidating, isValid, errors } = useFormikContext();

  const isLoading = isSubmitting || isValidating;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValid) {
      console.log(errors);
      setSubmitError("Please fix the errors above before submitting.");
    } else {
      setSubmitError("");
      await submitForm();
    }
  };

  return (
    <div>
      {submitError && (
        <Typography color="error" style={{ marginBottom: "10px" }}>
          {submitError}
        </Typography>
      )}
      <LoadingButton {...otherProps} type="submit" loading={!!isLoading} variant={"contained"} color={"primary"} fullWidth={true} onClick={handleSubmit}>
        {text || "Submit"}
      </LoadingButton>
    </div>
  );
};
