export const getLogger = () => {
  return {
    log: logLog,
    info: logInfo,
    warn: logWarn,
    error: logError,
    debug: logDebug,
    debugEnabled: true,
    shouldLogWrites: true,
    shouldLogReads: true,
    shouldLogMetrics: false,
  };
};

const logDebug = (...args) => console.log(...args);
const logInfo = (...args) => console.log(...args);
const logWarn = (...args) => console.log(...args);
const logError = (...args) => console.log(...args);
const logLog = (...args) => console.log(...args);
