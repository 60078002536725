import { useState, useEffect } from "react";
import { Loading } from "@wagerlab/admin/src/pages/Loading";
import { SignIn } from "@wagerlab/admin/src/pages/SignIn";
import firebase from "firebase/compat/app";
import Snackbar from "@mui/material/Snackbar";
import { Routes, Route } from "react-router-dom";
import { AppLayout } from "@wagerlab/admin/src/AppLayout";
import { CreateEvent } from "@wagerlab/admin/src/pages/CreateEvent";
import { EditEvent } from "@wagerlab/admin/src/pages/EditEvent";
import { EventsList } from "@wagerlab/admin/src/pages/EventsList";
import { ApiKeysList } from "@wagerlab/admin/src/pages/ApiKeysList";
import { MainMenu } from "@wagerlab/admin/src/pages/MainMenu";
import { ReGradeEvent } from "@wagerlab/admin/src/pages/ReGradeEvent";
import { GradeEvent } from "@wagerlab/admin/src/pages/GradeEvent";
import { EditConfig } from "@wagerlab/admin/src/pages/EditConfig";
import { LinkTeams } from "@wagerlab/admin/src/pages/LinkTeams";
import { LinkPlayers } from "@wagerlab/admin/src/pages/LinkPlayers";

export const AppRouter = () => {
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");

  useEffect(() => {
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            if (!!idTokenResult.claims.admin) {
              setLoggedIn(true);
              setLoading(false);
            } else {
              setSnackMessage("You must be an admin");
              setLoading(true);
              setLoggedIn(false);
              firebase.auth().signOut();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setLoggedIn(false);
        setLoading(false);
      }
    });
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : loggedIn ? (
        <Routes>
          <Route path="/" element={<AppLayout />}>
            <Route index element={<MainMenu />} />
            <Route path="events/new/:eventType" element={<CreateEvent />} />
            <Route path="events/list" element={<EventsList />} />
            <Route path="events/edit/:eventID" element={<EditEvent />} />
            <Route path="events/regrade/:eventID" element={<ReGradeEvent />} />
            <Route path="events/grade/:eventID" element={<GradeEvent />} />
            <Route path="config/edit" element={<EditConfig />} />
            <Route path="link/teams" element={<LinkTeams />} />
            <Route path="link/players" element={<LinkPlayers />} />
            <Route path="api/keys" element={<ApiKeysList />} />
          </Route>
        </Routes>
      ) : (
        <SignIn />
      )}
      <Snackbar open={!!snackMessage} onClose={() => setSnackMessage("")} message={snackMessage} autoHideDuration={10000} />
    </>
  );
};
