// Sweepstakes constants
exports.DAILY_WAGER_BONUS = 2;
exports.BET_SOMEONE_NEW_SHARE_BONUS = 10;

exports.HOUSE_ID = "house";
exports.HOUSE_USERNAME = "The House";
exports.COINS_ID = "coins";
exports.COINS_USERNAME = "coins";
exports.DEFAULT_WAGER_UNIT = "units";
exports.MAX_WAGER_UNIT_LENGTH = 8;
exports.MAX_WAGER_AMOUNT = 999999;
exports.TOO_HIGH_WAGER_AMOUNT_STRING = "1M";
exports.ADD_FRIEND_ID = "add-friend";
exports.TICKETS_ID = "tickets";
exports.TICKETS_USERNAME = "tickets";
