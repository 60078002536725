exports.PRIMARY_COLOR = "#5383EC";
exports.PRIMARY_COLOR_LIGHT = "#00BFEB";
exports.PRIMARY_COLOR_DARK = "#003f85";
exports.PRIMARY_COLOR_BRIGHT = "#3BDAFF";
exports.PRIMARY_COLOR_DARKER = "#2F6DE4";
exports.PRIMARY_COLOR_LIGHTER = "#8DC7F3";
exports.PRIMARY_COLOR_DARKEST = "#1B3878";
exports.PRIMARY_COLOR_LIGHTISH = "#15BFFD";

exports.ACCENT_COLOR = "#F79D00";
exports.ACCENT_COLOR_LIGHT = "#FFBF00";
exports.ACCENT_COLOR_DARK = "#EF7400";
exports.ACCENT_COLOR_LIGHTER = "#FED17C";

exports.SECONDARY_COLOR = "#418056";
exports.SECONDARY_COLOR_LIGHT = "#6BBF95";
exports.SECONDARY_COLOR_DARK = "#228252";
exports.SECONDARY_COLOR_BRIGHT = "#00f5d4";

exports.ALTERNATE_COLOR = "#715AFF";

exports.LIGHT_BACKGROUND_COLOR = "#FFFFFF";
exports.LIGHTISH_BACKGROUND_COLOR = "#f2f3f4";
exports.MIDDLE_LIGHT_BACKGROUND_COLOR = "#cacbcc";
exports.MIDDLE_LIGHTISH_BACKGROUND_COLOR = "#F2F2F2";
exports.MIDDLE_DARKISH_CONTENT_COLOR = "#C4C4C4";
exports.MIDDLE_DARK_CONTENT_COLOR = "#979797";
exports.DARKISH_BACKGROUND_COLOR = "#525252";
exports.DARK_BACKGROUND_COLOR = "#1C1B20";

exports.DARK_CONTENT_COLOR = "#000000";
exports.DARKISH_CONTENT_COLOR = "#525252";
exports.MIDDLE_DARK_CONTENT_COLOR = "#979797";
exports.MIDDLE_LIGHT_CONTENT_COLOR = "#C4C4C4";
exports.LIGHTISH_CONTENT_COLOR = "#e6e6e6";
exports.LIGHT_CONTENT_COLOR = "#FFFFFF";
exports.DARK_GREY_CONTENT_COLOR = "#2A2D37";
exports.LIVE_CONTENT_COLOR = "#00D95F";

exports.DISABLED_COLOR = exports.MIDDLE_LIGHT_CONTENT_COLOR;

exports.SUCCESS_COLOR = "#028e2c";
exports.ERROR_COLOR = "#ff6961";
exports.WARNING_COLOR = "#ffb347";
exports.INFO_COLOR = "#2a8ee1";

exports.POOL_COLOR = "#05628e";
exports.LOADING_SKELETON_COLOR = "#dde5ed";

exports.TRANSPARENT_WHITE_BACKGROUND_COLOR = "rgba(196,196,196, 0.65)";
exports.TRANSPARENT_BLACK_BACKGROUND_COLOR = "rgba(0,0,0, 0.7)";
exports.TRANSPARENT_COLOR = "#FF000000";

exports.ALTERNATING_COLOR_LIST = ["#067BC2", "#FF715B", "#715AFF", "#F9CB40", "#43C18F", "#D46469", "#43C18F"];
exports.ALTERNATING_COLOR_LIST_TWO = ["#F7FF17", "#93FFD9", "#FE9F97", "#A6F877", "#FFA16F", "#F4EDFC"];
exports.SPLASH_SCREEN_BACKGROUND_COLOR = "#1C1B20";
exports.NEUTRAL_DARK = "#141416";
exports.FORM_LABEL_COLOR = "#9f9f9f";
exports.DEFAULT_BUTTON_BACKGROUND_COLOR = "#5383EC";
exports.SECONDARY_BUTTON_BACKGROUND_COLOR = "#F4F4F4";
exports.YELLOWISH_COLOR = "#FCE8CC";
exports.COIN_COLOR = "#EAA138";
exports.ONBOARDING_TITLE_COLOR = "#224682";
exports.AMOUNT_POSITIVE_COLOR = "#171717";
exports.AMOUNT_NEGATIVE_COLOR = "#ff7575";
exports.DISABLED_TEXT_COLOR = "#7d7d7d";
exports.BORDER_COLOR = "#E3e3e3";
exports.SCORE_GRADIENT_START = "rgba(255, 255, 255, 0.5)";
exports.SCORE_GRADIENT_END = "rgba(254, 209, 124, 0.5)";
exports.YELLOW_COLOR_DARK = "#FDAE15";
