const moment = require("moment-timezone");

const TIME_FORMATS = {
  short: "M/D h:mma",
  medium: "ddd[.] M/D [@] h:mma",
  long: "dddd MMMM D, YYYY [@] h:mma",
};
const formatTime = (timestamp, length, timezone) => {
  let momentDate = timestamp && moment(timestamp);
  if (!momentDate?.isValid?.()) return "";
  let format = TIME_FORMATS[length] ?? TIME_FORMATS.medium;
  if (timezone) {
    momentDate = momentDate.tz(timezone);
    format += " z";
  }
  const minutes = momentDate?.minutes?.() || 0;
  format = minutes === 0 ? format.replace("h:mm", "h") : format;
  return momentDate?.format?.(format) || "";
};
exports.formatTime = formatTime;

const DATE_FORMATS = {
  short: "M/D/YY",
  medium: "MMMM D, YYYY",
  long: "dddd MMMM D, YYYY",
};
const formatDate = (timestamp, length, timezone = "") => {
  let momentDate = timestamp && moment(timestamp);
  if (!momentDate?.isValid?.()) return "";
  if (timezone) momentDate = momentDate.tz(timezone);
  const format = DATE_FORMATS[length] ?? DATE_FORMATS.medium;
  return momentDate.format(format) || "";
};
exports.formatDate = formatDate;

const selectVariant = (length, variants) => {
  const { long, medium, short, default: defaultVariant } = variants || {};
  if (length === "long") return long || defaultVariant || medium || short || "";
  if (length === "medium") return medium || defaultVariant || long || short || "";
  if (length === "short") return short || defaultVariant || medium || long || "";
  return defaultVariant || medium || long || short || "";
};
exports.selectVariant = selectVariant;
