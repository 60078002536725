import React from "react";
import { Box, TextField } from "@mui/material";

// Note: This input component is not built to be nested inside a Formik form

export const SearchBar = ({ label, onSearchInputChange }) => {
  return (
    <Box sx={{ marginBottom: 2, width: "100%", display: "flex", justifyContent: "flex-end" }}>
      <TextField
        sx={{ width: 300 }}
        id="search-bar"
        className="text"
        label={label}
        onInput={(e) => onSearchInputChange(e?.target?.value || "")}
        variant="outlined"
        placeholder="Search..."
        size="small"
      />
    </Box>
  );
};
